var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{attrs:{"dense":""}},_vm._l((_vm.data),function(item,i){return _c('v-col',{key:i,attrs:{"cols":item.cols[0],"sm":item.cols[1],"md":item.cols[2]}},[_c('v-card',{attrs:{"elevation":"1","tile":""}},[_c('v-toolbar',{attrs:{"flat":"","dense":"","color":item.color}},[_c('div',{staticClass:"body-1 flex font-weight-black text-center"},[_vm._v(" "+_vm._s(item.type)+" ")])]),_c('v-data-table',{attrs:{"dense":"","hide-default-footer":"","items-per-page":10000,"headers":item.headers,"items":item.items,"mobile-breakpoint":0},scopedSlots:_vm._u([{key:"item.monto",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.toCurrency(item.monto)))])]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.toCurrency(item.total)))])]}},{key:"item.kg",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.toCurrency(item.kg)))])]}},{key:"item.viaticos",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.toCurrency(item.viaticos)))])]}},{key:"item.cobrado",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.toCurrency(item.cobrado)))])]}},{key:"item.gastos",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.toCurrency(item.gastos)))])]}},{key:"item.saldo",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.toCurrency(item.saldo)))])]}}],null,true)},[_c('template',{slot:"body.append"},[(item.type == "Transporte")?_c('tr',{staticClass:"font-weight-black"},[_c('th',{staticClass:"body-1"},[_vm._v("Total")]),_c('th'),_c('th'),_c('th'),_c('th'),_c('th'),_c('th'),_c('th'),_c('th',{staticClass:"text-right body-1"},[_vm._v(_vm._s(_vm.toCurrency(item.value)))])]):_vm._e(),(item.type == "Gastos")?_c('tr',{staticClass:"font-weight-black body-1"},[_c('th',{staticClass:"body-1"},[_vm._v("Total")]),_c('th'),_c('th'),_c('th',{staticClass:"text-right body-1"},[_vm._v(_vm._s(_vm.toCurrency(item.value)))]),_c('th')]):_vm._e(),(item.type == "Gastos Viaje" || item.type == "Gastos Reembolsar")?_c('tr',{staticClass:"font-weight-black body-1"},[_c('th',{staticClass:"body-1"},[_vm._v("Total")]),_c('th',{staticClass:"text-right body-1"},[_vm._v(_vm._s(_vm.toCurrency(item.value)))]),_c('th')]):_vm._e(),(item.type == "Haberes")?_c('tr',{staticClass:"font-weight-black body-1"},[_c('th',{staticClass:"text-right body-1"},[_vm._v(_vm._s(_vm.toCurrency(item.value)))]),_c('th')]):_vm._e()])],2)],1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }