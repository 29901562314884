<template>
  <agro-siembra-create @input="$router.push('/dashboard')" mode="Route" />
</template>

<script>
import { mapActions } from 'vuex';
export default {
  mounted() {
    this.$store.commit("SET_LAYOUT", "principal-layout");
    this.Vact_campana_data();
  },
  methods:{
      ...mapActions("campana", ["Vact_campana_data"]),
  }
};
</script>

<style>
</style>