
export default {

  causaaborto_create: (state) => {
    return state.causaaborto_create
  },
  causaaborto_update: (state) => {
    return state.causaaborto_update
  },
  causaaborto_show: (state) => {
    return state.causaaborto_show
  },
  Vget_causaaborto_data: (state) => {
    return state.causaaborto_data
  },
  Vget_causaaborto_load: (state) => {
    return state.causaaborto_load
  },
  Vget_causaaborto_save_load: (state) => {
    return state.causaaborto_save_load
  },
}
