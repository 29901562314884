
export default {

  cuenta_update: (state) => {
    return state.cuenta_update
  },
  cuenta_show: (state) => {
    return state.cuenta_show
  },

  Vget_cuenta_data: (state) => {
    return state.cuenta_data
  },
  Vget_cuenta_load: (state) => {
    return state.cuenta_load
  },
  Vget_cuenta_save_load: (state) => {
    return state.cuenta_save_load
  },
}
