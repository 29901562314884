<template>
  <v-dialog persistent max-width="700" :value="value">
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
      </v-toolbar>
      <v-container>
        <v-form ref="form" @submit.prevent="">
          <v-row dense>
            <v-col cols="12">
              <c-text-field
                label="Descripcion"
                autocomplete="off"
                :rules="rules"
                ref="conceptosalario1"
                v-model="data.descripcion"
                placeholder="Ingrese una descripcion"
              ></c-text-field>
            </v-col>
            <v-col cols="6">
              <v-switch
                label="Afecta IPS?"
                hide-details
                v-model="data.afecta_ips"
              ></v-switch>

              <v-switch
                label="Es un Ingreso?"
                hide-details
                v-model="data.positivo_negativo"
              ></v-switch>
            </v-col>
            <v-col>
              <div class="caption">Disponible para:</div>
              <v-switch label="Contrato" v-model="data.disponible_contrato" color="red" hide-details></v-switch>
              <v-switch
                label="Pago de Salario"
                v-model="data.disponible_pagosalario"
                color="red"
                hide-details
              ></v-switch>
              <v-switch label="Despido" v-model="data.disponible_despido" color="red" hide-details></v-switch>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
      <v-divider></v-divider>
      <v-card-actions>
        <c-btn-crud-close @click="cancel()"></c-btn-crud-close>
        <v-spacer></v-spacer>
        <c-btn-crud-done
          ref="conceptosalario3"
          @click="save()"
        ></c-btn-crud-done>
      </v-card-actions>
      <c-overlay :value="isConceptoSalarioLoading"></c-overlay>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    props: {
      type: Object,
    },
    "sync-input": {
      type: [String, Number],
      default: null,
    },
  },

  mounted() {
    if (this.editable) this.data = JSON.parse(JSON.stringify(this.props));
    setTimeout(() => this.next(0, "-"), 20);
    if (this.syncInput != null) this.data.descripcion = this.syncInput;
    this.sync = this.syncInput;
  },
  computed: {
    ...mapGetters("conceptosalario", ["isConceptoSalarioLoading"]),
    title() {
      return this.editable
        ? "Modificar Concepto Salario"
        : "Registrar Concepto Salario";
    },
  },
  methods: {
    ...mapActions("conceptosalario", [
      "setConceptoSalario",
      "fetchConceptoSalario",
      "fetchConceptoContrato",
      "setConceptoSalarioUpdate",
    ]),
    cancel() {
      this.$emit("input", false);
    },
    next(id, data) {
      if (data != "")
        try {
          this.$refs[`conceptosalario${id + 1}`].focus();
        } catch (error) {
          this.$refs[`conceptosalario${id + 1}`].$el.focus();
        }
    },
    async save() {
      if (!this.$refs.form.validate()) return null;
      const response = this.editable
        ? await this.setConceptoSalarioUpdate({
            data: this.data,
            id: this.data.idconcepto_salario,
          })
        : await this.setConceptoSalario(this.data);
      if (response.success) {
        if (this.sync != null) {
          this.cancel();
          this.fetchConceptoContrato();
          return this.$emit("sync-input", response.data.idconcepto_salario);
        }
        this.data = JSON.parse(JSON.stringify(this.default));
        this.$refs.form.resetValidation();
        this.fetchConceptoSalario();
        this.next(0, "-");
        if (this.editable) this.cancel();
      }
    },
  },
  data: () => ({
    rules: [(v) => !!v || "Este campo es obligatorio"],
    data: {
      idconcepto_salario: 0,
      descripcion: "",
      positivo_negativo: true,
      afecta_ips: true,
      disponible_contrato: true,
      disponible_pagosalario: true,
      disponible_despido: true,
    },
    default: {
      idconcepto_salario: 0,
      descripcion: "",
      positivo_negativo: true,
      afecta_ips: true,
      disponible_contrato: true,
      disponible_pagosalario: true,
      disponible_despido: true,
    },
    sync: null,
  }),
};
</script>

<style>
</style>