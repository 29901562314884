<template>
  <div>
    <v-dialog :value="value" persistent max-width="900" @keydown.esc="cancelar()">
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>Detalle Nota de Credito Venta</v-toolbar-title>
        </v-toolbar>
        <v-container>
          <v-row dense>
            <v-col cols="12" md="2" sm="3">
              <c-text-date label="Fecha" dense outlined v-model="data.fecha" id="input1" readonly></c-text-date>
            </v-col>
            <v-col cols="12" md="3" sm="3">
              <vue-text-field label="Numero Nota Credito" readonly :value="numeroNotaCredito"></vue-text-field>
            </v-col>
            <v-col cols="12" md="4" sm="6">
              <vue-text-field
                label="Cliente"
                readonly
                v-model="data.idventa.idcontacto.idcontacto.desccontacto"
              />
            </v-col>
            <v-col cols="12" md="3" sm="3">
              <vue-text-field readonly label="Factura que modifica" v-model="factura"></vue-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-data-table
                :headers="headers"
                :items="data.notacreditoventa"
                :items-per-page="1000"
                hide-default-footer
              ></v-data-table>
            </v-col>
          </v-row>
          <v-form ref="form2">
            <v-row dense>
              <v-col cols="12">
                <vue-text-field label="Descripcion de la operacion" readonly v-model="data.comentario"></vue-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="red" text @click="cancelar()">
            <v-icon>clear</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { utilidad } from "@/services/compra_utilidad.service";
import { mapActions, mapGetters } from "vuex";
export default {

  props: {
    value: {
      type: Boolean,
      default: false
    },
    show: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters("notacreditoventa", ["Vget_notacreditoventa_save_load"]),
    numeroNotaCredito() {
      const est = this.show.idtimbrado.idestablecimiento.establecimiento;
      const pun = this.show.idtimbrado.idpuntoexpedicion.puntoexpedicion;
      const fac = utilidad.completar_nrof(
        this.show.numnotacreditoventa.toString()
      );
      return `${est}-${pun}-${fac}`;
    }
  },
  created() {
    this.data = JSON.parse(JSON.stringify(this.show));
    this.factura =
      this.show.idventa.idtimbrado.idestablecimiento.establecimiento +
      "-" +
      this.show.idventa.idtimbrado.idpuntoexpedicion.puntoexpedicion +
      "-" +
      utilidad.completar_nrof(this.show.idventa.numfventa.toString());
    this.data.notacreditoventa = [];
    this.show.notacreditoventa.forEach(item => {
      this.addDetalle(item);
    });
    
  },
  methods: {
    ...mapActions("notacreditoventa", [
      "Vact_notacreditoventa_save",
      "Vact_notacreditoventa_data"
    ]),
    cancelar() {
      this.$emit("input", false);
      this.Vact_notacreditoventa_data();
    },
    addDetalle(item) {
      switch (item.iditem.idcategoriaitem.idiva.idiva) {
        case 3:
          item.iva10 = Number(item.cantidad) * Number(item.precio);
          break;
        case 2:
          item.iva5 = Number(item.cantidad) * Number(item.precio);
          break;
        case 1:
          item.exenta = Number(item.cantidad) * Number(item.precio);
          break;
        default:
          break;
      }
      this.data.notacreditoventa.push(item);
    },
  },
  data() {
    return {
      factura: "",
      headers: [
        {
          text: "Deposito",
          sortable: false,
          value: "iddeposito.desdeposito"
        },
        { text: "Cantidad", sortable: false, value: "cantidad" },
        { text: "Item", sortable: false, value: "iditem.descitem" },
        { text: "Precio Unitario", sortable: false, value: "precio" },
        { text: "Exenta", sortable: false, value: "exenta" },
        { text: "Iva 5%", sortable: false, value: "iva5" },
        { text: "Iva 10%", sortable: false, value: "iva10" }
      ],
      data: {
        idnotacreditoventa: 0,
        anulnotacreditoventa: false,
        comentario: "",
        fecha: "",
        idventa: {
          idventa: "",
          idcontacto: {
            idcontacto: {
              desccontacto: ""
            }
          }
        },
        idtimbrado: {
          idtimbrado: ""
        },
        iddeposito: {
          iddeposito: ""
        },

        numnotacreditoventa: "",
        notacreditoventa: []
      },

      default: {
        idnotacreditoventa: 0,
        anulnotacreditoventa: false,
        comentario: "",
        fecha: "",
        idventa: {
          idventa: "",
          idcontacto: {
            idcontacto: {
              desccontacto: ""
            }
          }
        },
        idtimbrado: {
          idtimbrado: ""
        },
        iddeposito: {
          iddeposito: ""
        },

        numnotacreditoventa: "",
        notacreditoventa: []
      }
    };
  }
  
};
</script>

<style>
</style>