<template>
  <div>
    <div v-if="dialogCrud">
      <Crud v-model="dialogCrud" />
    </div>
    <v-card>
      <v-alert type="error" v-if="permission_alert"
        >Error!!! Este usuario no tiene permisos en esta ventana</v-alert
      >

      <v-toolbar flat dense>
        <v-toolbar-title>Planilla Gimnasio</v-toolbar-title>
        <widgets-Favorite :ruta="$route.path" formulario="Planilla Gimnasio" />
        <v-divider class="mx-2" inset vertical></v-divider>

        <v-spacer></v-spacer>

        <c-text-table-search
          v-if="permission.can_view"
          v-model="search"
        ></c-text-table-search>
        <v-toolbar-items>
          <c-btn-table-add
            @click="dialogCrud = true"
            v-if="permission.can_add"
            class="mr-1"
          ></c-btn-table-add>
          <c-btn-table-reload
            @click="Vact_gymplanilla_data()"
          ></c-btn-table-reload>
        </v-toolbar-items>
      </v-toolbar>
      <v-data-table
        :loading="Vget_gymplanilla_load"
        :headers="headers"
        :items="Vget_gymplanilla_data"
        :search="search"
        class="elevation-1"
        v-if="permission.can_view"
      >
        <template v-slot:[`item.detalle`]="{ item }">
          {{ verDetalle(item.detalle) }}
        </template>
        <template v-slot:[`item.accion`]="{ item }" v-if="permission.can_view">
          <v-btn
            text
            fab
            outlined
            x-small
            color="deep-purple accent-4"
            @click="ver_cuota(item)"
          >
            <v-icon> arrow_forward </v-icon>
          </v-btn>
          <c-btn-table-edit @click="editar(item)"></c-btn-table-edit>
          <c-btn-table-delete @click="eliminar(item)"></c-btn-table-delete>
        </template>
      </v-data-table>
      <v-dialog v-model="dialogEliminar" persistent max-width="400">
        <v-card>
          <v-card-title>
            <v-icon left>info</v-icon>
            <span class="subtitle-1 font-weight-light"
              >Ventana de Confirmacion</span
            >
          </v-card-title>
          <v-form ref="form" @submit.prevent="eliminarGymplanilla()">
            <v-card-subtitle>
              Desea eliminar la planilla de
              {{ objeto.idcontacto.idcontacto.desccontacto }}
            </v-card-subtitle>
          </v-form>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn color="red" text="text" @click="dialogEliminar = false">
              <v-icon>clear</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" text="text" @click="eliminarGymplanilla()">
              <v-icon>done</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
        <c-overlay :value="overlay" absolute></c-overlay>
      </v-dialog>
    </v-card>
    <Operaciones
      v-if="view"
      v-model="view"
      :props="objeto"
      editable
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { mensaje } from "@/services/notify.service";
import { PLANILLA_GIMNASIO } from "@/constants/forms";
import Crud from "./Create";
import Operaciones from "./Operaciones";
import {
  PUEDE_AGREGAR,
  PUEDE_EDITAR,
  PUEDE_ELIMINAR,
  PUEDE_LISTAR,
} from "@/constants/permissions";

export default {
  components: {
    Crud,
    Operaciones,
  },
  data() {
    return {
      search: "",
      view: false,
      headers: [
        {
          text: "Cliente",
          align: "left",
          value: "idcontacto.idcontacto.desccontacto",
        },
        { text: "Tipo", align: "left", value: "detalle" },
        { text: "Ultimo Pago", align: "left", value: "ultimo_pago" },
        { text: "Accion", value: "accion", sortable: false, align: "right" },
      ],
      dialogCrud: false,
      dialogEliminar: false,
      overlay: false,
      objeto: {
        idcontacto: {
          idcontacto: {
            desccontacto: "",
          },
        },
      },
      permission: {
        can_add: false,
        can_view: false,
        can_update: false,
        can_delete: false,
      },
    };
  },
  watch: {},
  created() {
    this.$store.commit("SET_LAYOUT", "principal-layout");
    this.Vact_gymplanilla_data();
    const permission = getPermission(PLANILLA_GIMNASIO).permiso;
    this.permission = {
      can_add: permission[PUEDE_AGREGAR],
      can_view: permission[PUEDE_LISTAR],
      can_update: permission[PUEDE_EDITAR],
      can_delete: permission[PUEDE_ELIMINAR],
    };
  },
  computed: {
    ...mapGetters("gymplanilla", [
      "Vget_gymplanilla_data",
      "Vget_gymplanilla_load",
    ]),
    permission_alert() {
      return mensaje.permission(this.permission);
    },
  },
  methods: {
    ...mapActions("gymplanilla", [
      "Vact_gymplanilla_data",
      "Vact_gymplanilla_del",
      "gymplanilla_update",
    ]),
    ver_cuota(item) {
      this.objeto = item;
      this.view = true;
    },
    editar(item) {
      this.gymplanilla_update(item);
      this.dialogCrud = true;
    },
    eliminar(item) {
      this.dialogEliminar = true;
      this.objeto = item;
    },
    eliminarCancelar() {
      this.objeto.descripcion = "";
      this.dialogEliminar = false;
    },
    async eliminarGymplanilla() {
      this.overlay = true;
      const id = this.objeto.idgymplanilla;
      const a = await this.Vact_gymplanilla_del(id);
      if (a.estado == true) {
        this.$notify(a.info);
        this.eliminarCancelar();
      } else {
        a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
      this.overlay = false;
    },
    verDetalle(item) {
      if (!item) return "";
      let detalle = "";
      let count = item.length - 1;
      item.forEach((key, i) => {
        if (i === 0) return (detalle = key.idgymtipo.descripcion);
        if (i === count) detalle = detalle + " y " + key.idgymtipo.descripcion;
        else detalle = detalle + ", " + key.idgymtipo.descripcion;
      });
      return detalle
    }
  },
};
</script>