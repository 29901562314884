export default {
  getCampanaDisponible: (state) => {
    return state.campana_data.filter((x) => x.estado);
  },
  Vget_campana_data: (state) => {
    return state.campana_data;
  },
  Vget_campana_data_all: (state) => {
    return state.campana_data_all;
  },
  Vget_campana_data_anho: (state) => {
    return state.campana_data_anho;
  },
  Vget_campana_data_byLote: (state) => {
    return state.campana_data_byLote;
  },
  Vget_campana_load: (state) => {
    return state.campana_load;
  },
  Vget_campana_save_load: (state) => {
    return state.campana_save_load;
  },
  get_reporte_async: (state) => {
    return state.reporte_async;
  },
  get_reporte_load: (state) => {
    return state.reporte_load;
  }
};
