import { get, post, put, del } from "@/services/api/api.service";
import { SET_LOADING, SET_CONCEPTO_SALARIO, url, SET_CONCEPTO_CONTRATO, SET_CONCEPTO_PAGO_SALARIO } from './constants'
export default {
    async fetchConceptoSalario({ commit, dispatch }) {
        commit(SET_LOADING, true);
        try {
            const response = await get(url);
            commit(SET_CONCEPTO_SALARIO, response);
        } catch (e) {
            dispatch('snackbar/setNotification', e, { root: true });
            commit(SET_LOADING, false);
            throw e
        }
        commit(SET_LOADING, false);
    },
    async fetchConceptoContrato({ commit, dispatch }) {
        commit(SET_LOADING, true);
        try {
            const response = await get(url + '?contrato=yes');
            commit(SET_CONCEPTO_CONTRATO, response);
        } catch (e) {
            dispatch('snackbar/setNotification', e, { root: true });
            commit(SET_LOADING, false);
            throw e
        }
        commit(SET_LOADING, false);
    },
    async fetchConceptoPagoSalario({ commit, dispatch }) {
        commit(SET_LOADING, true);
        try {
            const response = await get(url + '?salario=yes');
            commit(SET_CONCEPTO_PAGO_SALARIO, response);
        } catch (e) {
            dispatch('snackbar/setNotification', e, { root: true });
            commit(SET_LOADING, false);
            throw e
        }
        commit(SET_LOADING, false);
    },
    async setConceptoSalario({ commit, dispatch }, data) {
        commit(SET_LOADING, true);
        const response = await post(url, data);
        dispatch('snackbar/setNotification', response, { root: true })
        commit(SET_LOADING, false);
        return response
    },
    async setConceptoSalarioUpdate({ commit, dispatch }, { data, id }) {
        commit(SET_LOADING, true);
        const response = await put(`${url}${id}/`, data);
        dispatch('snackbar/setNotification', response, { root: true })
        commit(SET_LOADING, false);
        return response
    },
    async setConceptoSalarioDelete({ commit, dispatch }, id) {
        commit(SET_LOADING, true);
        const response = await del(`${url}${id}/`);
        dispatch('snackbar/setNotification', response, { root: true })
        commit(SET_LOADING, false)
        return response;
    },
}
