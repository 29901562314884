
export default {
  Vget_ajustecaja_data: (state) => {
    return state.ajustecaja_data
  },
  Vget_ajustecaja_load: (state) => {
    return state.ajustecaja_load
  },
  Vget_ajustecaja_save_load: (state) => {
    return state.ajustecaja_save_load
  },
}
