export default {

  egresoagro_update(state, request) {
    state.egresoagro_update = request;
  },
  egresoagro_data(state, request) {
    state.egresoagro_data = request;
  }, 
  egresoagro_data_byId(state, request) {
    state.egresoagro_data_byId = request;
  }, 
  egresoagro_load(state, request) {
    state.egresoagro_load = request;
  }, 
  egresoagro_save_load(state, request) {
    state.egresoagro_save_load = request;
  }, 
}