import { get, post,  } from "@/services/api/api.service";
import { SET_LOADING, SET_CONFIG, url } from './constants'
export default {
    async fetchConfigAgro({ commit, dispatch }) {
        commit(SET_LOADING, true);
        try {
            const response = await get(url);
            commit(SET_CONFIG, response);
        } catch (e) {
            dispatch('snackbar/setNotification', e, { root: true });
            commit(SET_LOADING, false);
            throw e
        }
        commit(SET_LOADING, false);
    },

    async setConfigAgro({ commit, dispatch },  data ) {
        commit(SET_LOADING, true);
        const response = await post(url, data);
        dispatch('snackbar/setNotification', response, { root: true })
        commit(SET_LOADING, false);
        return response
    }
}
