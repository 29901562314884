<template>
  <div>
    <c-alert-permission v-model="permission"></c-alert-permission>
    <v-toolbar flat v-if="permission.confirmar">
      <v-toolbar-title>{{ title }}</v-toolbar-title>
      <widgets-Favorite :ruta="$route.path" :formulario="title" />
      <v-divider class="mx-2" inset vertical></v-divider>
    </v-toolbar>
    <v-data-table
      v-if="permission.confirmar"
      :headers="headers.cuentabanco"
      :items="Vget_cheque_data_desembolso"
      :loading="Vget_cheque_load"
      :items-per-page="5"
    >
      <template v-slot:item.montoapagar="{ item }">
        <div>{{ toCurrency(item.montoapagar) }}</div>
      </template>
      <template v-slot:item.action="{ item }">
        <v-btn
          v-if="item.montoapagar > 0"
          outlined
          small
          color="red"
          @click="openDesembolso(item)"
          >Cheques a Desembolsar</v-btn
        >
      </template>
    </v-data-table>

    <v-dialog v-model="dialog" persistent max-width="700">
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>Lista de Cheques a Desembolsar</v-toolbar-title>
        </v-toolbar>
        <v-container>
          <v-data-table
            :headers="headers.cheques"
            :items="data.cheques"
            :items-per-page="10000"
            hide-default-footer
          >
            <template v-slot:item.monto="{ item }">
              <div>{{ toCurrency(item.monto) }}</div>
            </template>
            <template v-slot:item.estado="{ item }">
              <v-simple-checkbox
                :ripple="false"
                v-model="item.estado"
              ></v-simple-checkbox>
            </template>
          </v-data-table>
        </v-container>
        <v-card-actions>
          <v-btn color="red" text @click="dialog = false">
            <v-icon>clear</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="guardar()">
            <v-icon>done</v-icon>
          </v-btn>
        </v-card-actions>
        <c-overlay :value="Vget_cheque_save_load"></c-overlay>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { currency } from "@/services/util/number.service";
import { mensaje } from "@/services/notify.service";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { PUEDE_AGREGAR } from "@/constants/permissions";
import { EMITIR_CHEQUE } from "@/constants/forms";
export default {
  data() {
    return {
      title: "Desembolso y Confirmacion de Cheque",
      headers: {
        cuentabanco: [
          {
            text: "Cuenta Bancaria",
            align: "start",
            sortable: false,
            value: "desccuentabanco",
          },
          { text: "Saldo a Pagar", value: "montoapagar" },
          { text: "Accion", align: "right", value: "action" },
        ],
        cheques: [
          {
            text: "Cod",
            value: "id",
          },
          {
            text: "Numero Cheque",
            value: "nro",
          },
          {
            text: "Contacto",
            value: "contacto",
          },
          {
            text: "Comentario",
            value: "comentario",
          },
          {
            text: "Fecha Desembolso",
            value: "fecha_acredita",
          },
          {
            text: "Saldo del Cheque",
            value: "monto",
          },
          { text: "Desembolsar", align: "right", value: "estado" },
        ],
      },
      dialog: false,
      data: [],
      permission: {
        confirmar: getPermission(EMITIR_CHEQUE).permiso[PUEDE_AGREGAR],
      },
    };
  },
  mounted() {
    this.Vact_cheque_data_desembolso();
  },
  computed: {
    ...mapGetters("cheque", [
      "Vget_cheque_data_desembolso",
      "Vget_cheque_load",
      "Vget_cheque_save_load",
    ]),
  },
  methods: {
    ...mapActions("cheque", [
      "Vact_cheque_data_desembolso",
      "Vact_cheque_desembolso_save",
    ]),
    toCurrency(val) {
      return currency(val);
    },
    openDesembolso(item) {
      this.data = JSON.parse(JSON.stringify(item));
      this.dialog = true;
    },
    isSelected() {
      let isSelected = false;
      this.data.cheques.map((x) => {
        if (x.estado) isSelected = true;
      });
      return isSelected;
    },
    async guardar() {
      if (!this.isSelected())
        return this.$notify(mensaje.error("No ha seleccionado ningun Cheque"));
      const a = await this.Vact_cheque_desembolso_save(this.data);
      if (a.estado == true) {
        this.dialog = false;
        this.$notify(a.info);
      } else {
        a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
    },
  },
};
</script>

<style>
</style>