<template>
  <div>
    <v-form ref="form" lazy-validation>
      <v-row row dense>
        <v-col cols="12" v-if="modo">
          <AutocompleteAnimal
            action="fetchAnimal"
            getter="getAnimal"
            id="input1"
            :readonly="readonly"
            no-create
            v-model="data.idanimal.idanimal"
            :animal="animal"
            @keyup.native.enter="nextfocus(1, data.idanimal.idanimal)"
          />
        </v-col>
        <v-col cols="12">
          <v-autocomplete
            dense
            v-model="data.idmotivomuerte.idmotivomuerte"
            id="input2"
            :rules="validars"
            v-bind:items="motivomuerte"
            fkdes="item"
            small-chips
            label="Seleccione Motivo Muerte"
            item-text="fkdes"
            item-value="id"
            required
            :readonly="readonly"
            outlined
            :loading="motivomuerte_load"
            @keyup.enter="nextfocus(2, data.idmotivomuerte.idmotivomuerte)"
            chips
            autocomplete="off"
            :no-data-text="`No existe ninguna concidencia`"
          >
            <template v-if="motivomuerte_permission" v-slot:no-data>
              <v-list-item @click="motivomuerte_create(true)">
                <v-list-item-content>
                  <v-list-item-title
                    >No existe concidencia. Click para
                    agregar</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12">
          <c-text-date
            label="Fecha de registro"
            id="input3"
            v-model="data.fecrmuerte"
            dense
            :readonly="readonly"
            outlined
            @keyup.native.enter="nextfocus(3, data.fecrmuerte)"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            dense
            type="text"
            id="input4"
            v-model="data.comnmuerte"
            label="Comentario"
            outlined
            :readonly="readonly"
            autocomplete="off"
            @keyup.enter="nextfocus(4, '0')"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { db } from "@/services/db.service";
import { getPermission } from "@/services/storage/permissionStorage.service";

import { current_date } from "@/services/util/date.service";
import { MOTIVO_MUERTE } from "@/constants/forms";
import { PUEDE_AGREGAR } from "@/constants/permissions";

export default {
  components: {
    AutocompleteAnimal: () =>
      import("@/views/Animal/Animal/AutocompleteAnimal"),
  },
  props: {
    datos: {
      type: Object,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    modo: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      data: {
        idmuerte: 0,
        fecrmuerte: current_date(),
        fechmuerte: "2010-10-10",
        comnmuerte: "",
        idanimal: {
          idanimal: 0,
        },
        idmotivomuerte: {
          idmotivomuerte: 0,
        },
        estadoanimal: 0,
      },
      default: {
        idmuerte: 0,
        fecrmuerte: current_date(),
        fechmuerte: "2010-10-10",
        comnmuerte: "",
        idanimal: {
          idanimal: 0,
        },
        idmotivomuerte: {
          idmotivomuerte: 0,
        },
        estadoanimal: 0,
      },
      validars: [(v) => !!v || "Seleccione un campo"],
      motivomuerte_load: true,
      motivomuerte: [],
      animal: null,
    };
  },
  watch: {
    get_motivomuerte_reload(val) {
      if (val === true) {
        this.nuevo_motivomuerte();
      }
    },
    datos(val) {
      if (val === null) {
        this.data = JSON.parse(JSON.stringify(this.default));
        this.$refs.form.resetValidation();
      }
    },
  },
  computed: {
    ...mapGetters("motivomuerte", {
      get_motivomuerte_reload: "motivomuerte_reload",
    }),

    motivomuerte_permission() {
      return getPermission(MOTIVO_MUERTE).permiso[PUEDE_AGREGAR];
    },
  },
  created() {
    this.nuevo(this.datos);
  },
  mounted() {
    if (this.modo) {
      setTimeout(() => document.getElementById("input1").focus(), 500);
    } else {
      setTimeout(() => document.getElementById("input2").focus(), 500);
    }
  },
  methods: {
    ...mapActions("motivomuerte", [
      "motivomuerte_create",
      "motivomuerte_reload",
    ]),
    nextfocus(index, data) {
      if (data != null && data != "") {
        try {
          index = index + 1;
          document.getElementById(`input` + index).focus();
        } catch (e) {
          document.getElementById("aceptar").focus();
        }
      } else {
        document.getElementById(`input` + index).focus();
      }
    },
    validate() {
      return this.$refs.form.validate();
    },
    obtenerDatos() {
      if (this.data.comnmuerte === "") {
        this.data.comnmuerte = null;
      }
      return this.data;
    },

    nuevo_motivomuerte() {
      db.get("/apimotivomuerte/").then((a) => {
        a.map((element) => {
          this.motivomuerte.push({
            id: element.idmotivomuerte,
            fkdes: element.descmotivomuerte,
          });
        });
      });
      this.motivomuerte_load = false;
      this.motivomuerte_reload(false);
    },
    nuevo(val) {
      this.nuevo_motivomuerte();
      if (val === null) return null;
      this.data = JSON.parse(JSON.stringify(val));
      this.animal = val.idanimal;
    },
  },
};
</script>
