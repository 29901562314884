var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',{attrs:{"flat":"","dense":""}},[_c('v-toolbar-title',[_vm._v("Dashboard Recursos Humanos")]),_c('v-spacer')],1),_c('v-sheet',{staticClass:"d-flex justify-end mb-2"},[_c('v-slide-group',{attrs:{"multiple":"","show-arrows":""}},[_c('v-slide-item',[_c('v-btn',{attrs:{"active-class":"deep-purple--text accent-4","exact":"","text":"","tile":"","to":"/rrhh"}},[_vm._v("Inicio")])],1),_c('v-slide-item',[_c('v-btn',{attrs:{"active-class":"deep-purple--text accent-4","exact":"","text":"","tile":"","to":"/rrhh/funcionarios"}},[_vm._v("Funcionarios")])],1),_c('v-slide-item',[_c('v-btn',{attrs:{"active-class":"deep-purple--text accent-4","exact":"","text":"","tile":"","to":"/rrhh/asistencias"}},[_vm._v("Asistencias")])],1),_c('v-slide-item',[_c('v-menu',{attrs:{"bottom":"","left":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"align-self-center",attrs:{"text":"","tile":""}},'v-btn',attrs,false),on),[_vm._v(" Pagos "),_c('v-icon',{attrs:{"right":""}},[_vm._v("arrow_drop_down")])],1)]}}])},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.menu.pagos),function(sub,subkey){return _c('v-list-item',{key:subkey,attrs:{"to":sub.route,"active-class":"deep-purple--text accent-4"}},[_vm._v(_vm._s(sub.title))])}),1)],1)],1),_c('v-slide-item',[_c('v-menu',{attrs:{"bottom":"","left":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"align-self-center",attrs:{"text":"","tile":""}},'v-btn',attrs,false),on),[_vm._v(" Movimientos "),_c('v-icon',{attrs:{"right":""}},[_vm._v("arrow_drop_down")])],1)]}}])},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.menu.operaciones),function(sub,subkey){return _c('v-list-item',{key:subkey,attrs:{"to":sub.route,"active-class":"deep-purple--text accent-4"}},[_vm._v(_vm._s(sub.title))])}),1)],1)],1),_c('v-slide-item',[_c('v-menu',{attrs:{"bottom":"","left":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"align-self-center",attrs:{"text":"","tile":""}},'v-btn',attrs,false),on),[_vm._v(" Mantenimientos "),_c('v-icon',{attrs:{"right":""}},[_vm._v("arrow_drop_down")])],1)]}}])},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.menu.mantenimientos),function(sub,subkey){return _c('v-list-item',{key:subkey,attrs:{"to":sub.route,"active-class":"deep-purple--text accent-4"}},[_vm._v(_vm._s(sub.title))])}),1)],1)],1),_c('v-slide-item',[_c('v-btn',{attrs:{"active-class":"deep-purple--text accent-4","exact":"","text":"","tile":"","to":"/rrhh/reporterrhh"}},[_vm._v("Reporte RRHH")])],1),(!_vm.configRRHH)?_c('v-slide-item',[_c('v-btn',{attrs:{"tile":"","text":"","exact":"","active-class":"deep-purple--text accent-4","to":"/rrhh/settings"}},[_c('v-icon',[_vm._v("settings")])],1)],1):_vm._e()],1)],1),_c('v-divider'),_c('v-card',[_c('transition',[_c('keep-alive',[_c('router-view')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }