export default {
  viaaplicacion_create(state, request) {
    state.viaaplicacion_create = request;
  }, 
  viaaplicacion_update(state, request) {
    state.viaaplicacion_update = request;
  },
  viaaplicacion_reload(state, request) {
    state.viaaplicacion_reload = request;
  }, 
  viaaplicacion_key(state, request) {
    state.viaaplicacion_key = request;
  }, 
  viaaplicacion_show(state, request) {
    state.viaaplicacion_show = request;
  }, 
}