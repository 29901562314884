
export default {
  getJustificacion: (state) => {
    return state.justificacion
  },
  getMotivoJustificacion: (state) => {
    return state.motivo_ausencia
  },
  getListaVacacion: (state) => {
    return state.lista_vacacion
  },
  isJustificacionLoading: (state) => {
    return state.isLoading
  }
}
