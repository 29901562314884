import { db } from "@/services/db.service";
export default {

    async Vact_ajustecuenta_data({commit}){
        commit('ajustecuenta_load',true);
        try {
            const data = await db.get('/apiajustecuenta/');
            commit('ajustecuenta_data',data);
            commit('ajustecuenta_load',false);
            return true
        } catch (e) {
            commit('ajustecuenta_load',false);
            return false
        } 
    },
    async Vact_ajustecuenta_save({commit},data){
        commit('ajustecuenta_save_load',true);
        try {
            const resp = await db.save('/apiajustecuenta/',data);
            commit('ajustecuenta_save_load',false);
            return resp;
        } catch (e) {
            commit('ajustecuenta_save_load',false);
            return e
        } 
    },
}
