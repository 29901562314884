export default {
  data: [],
  datalotedep: [],
  datalotedis: [],
  dataloteloc: [],
  loading: false,
  loadingdep: false,
  loadingdis: false,
  loadingloc: false,
}
