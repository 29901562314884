<template>
  <v-autocomplete
    :value="value"
    @input="$emit('input', $event)"
    ref="input"
    :items="getEstadoAsistencia"
    :loading="isEstadoAsistenciaLoading"
    item-value="idioestado"
    :rules="rules"
    return-object
    item-text="descripcion"
    :outlined="outlined"
    :dense="dense"
    :disabled="disabled"
    :chips="chips"
    :small-chips="smallChips"
    :label="label"
  ></v-autocomplete>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
export default {
  props: {
    value: [Object, Number, String],
    outlined: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    chips: {
      type: Boolean,
      default: false,
    },
    "small-chips": {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'EstadoAsistencia',
    },
    rules:{
      type: Array,
      default: function () {
        return [(v) => !!v.idioestado || "Este campo es obligatorio"];
      },
    },
    disabled:{
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.fetchEstadoAsistencia();
  },
  computed: {
    ...mapGetters("estadoasistencia", ["getEstadoAsistencia", "isEstadoAsistenciaLoading"]),
  },
  methods: {
    ...mapActions("estadoasistencia", ["fetchEstadoAsistencia"]),
    focus(){
      this.$refs.input.focus();
    },
    isMenuActive(){
      this.$refs.input.isMenuActive = false
    }
  },
};
</script>

<style>
</style>