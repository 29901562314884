import { db } from "@/services/db.service";
export default {

    async Vact_procedencia_data({commit}){
        commit('procedencia_load',true);
        try {
            const data = await db.get('/apiprocedencia/');
            commit('procedencia_data',data);
            commit('procedencia_load',false);
            return true
        } catch (e) {
            commit('procedencia_load',false);
            return false
        } 
    },
    async Vact_procedencia_data_inactivo({commit}){
        commit('procedencia_load',true);
        try {
            const data = await db.get('/apiprocedencia/?band=inactivo');
            commit('procedencia_data_inactivo',data);
            commit('procedencia_load',false);
            return true
        } catch (e) {
            commit('procedencia_load',false);
            return false
        } 
    },
    async Vact_procedencia_save({commit},data){
        commit('procedencia_save_load',true);
        try {
            const resp = await db.save('/apiprocedencia/',data);
            commit('procedencia_save_load',false);
            return resp;
        } catch (e) {
            commit('procedencia_save_load',false);
            return e
        } 
    },
    async Vact_procedencia_del({dispatch},id){
        try {
            const data = await db.delete('/apiprocedencia/'+id);
            dispatch('Vact_procedencia_data');
            return data
        } catch (e) {
            return false
        } 
    },
    async Vact_procedencia_update({commit},{id, data}){
        commit('procedencia_save_load',true);
        try {
            const resp = await db.update('/apiprocedencia/'+id+'/',data);
            commit('procedencia_save_load',false);
            return resp;
        } catch (e) {
            commit('procedencia_save_load',false);
            return e
        } 
    },
}
