export default {
 
  agenda_data(state, request) {
    state.agenda_data = request;
  }, 
  agenda_load(state, request) {
    state.agenda_load = request;
  }, 
  agenda_save_load(state, request) {
    state.agenda_save_load = request;
  }, 
}