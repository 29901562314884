
export default {

  Vget_agromap_coordinates: (state) => {
    return state.agromap_coordinates
  },
  Vget_agromap_coordinates_list: (state) => {
    return state.agromap_coordinates_list
  },
  Vget_agromap_coordinates_view: (state) => {
    return state.agromap_coordinates_view
  },
  Vget_agromap_tile: (state) => {
    return state.agromap_tile
  },
  Vget_agromap_filter_data: (state) => {
    return state.agromap_filter_data
  },
  Vget_agromap_load: (state) => {
    return state.agromap_load
  },
  Vget_integralimages: (state) => {
    return state.integralimages
  },
  Vget_integralload: (state) => {
    return state.integralload
  },
}
