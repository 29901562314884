<template>
  <div>
    <div v-if="abrirtipoajuste">
      <Stock-Tipoajuste-create />
    </div>
    <div v-if="show">
      <Stock-Tipoajuste-show />
    </div>
    <v-card>
    <v-alert type="error" v-if="permission_alert">
      Error!!! Este usuario no tiene permisos en esta ventana
    </v-alert> 
    <v-toolbar flat >
      <v-toolbar-title>{{titulo}}</v-toolbar-title>
       <widgets-Favorite ruta="/tipoajuste" formulario="Tipo Ajuste"/>
      <v-divider class="mx-2" inset vertical></v-divider>

      <v-spacer></v-spacer>
      <v-text-field v-model="search" append-icon="search" label="Busqueda" single-line hide-details v-if="permission.can_view"></v-text-field>
      <v-btn color="primary" small fab dark @click="tipoajuste_create(true)" v-if="permission.can_add"><v-icon dark>add</v-icon></v-btn>
    </v-toolbar>
    <v-data-table :loading="load" :headers="headers" :items="datos" :search="search" class="elevation-1" v-if="permission.can_view">
      <template v-slot:[`item.accion`]="props">
          <v-icon  class="mr-2" @click="rowselect(props.item)">visibility</v-icon>
          <v-icon  class="mr-2" @click="mupdate(props.item)" v-if="permission.can_update">edit</v-icon>
          <v-icon small @click="meliminar(props.item)" v-if="permission.can_delete">delete</v-icon>
      </template>
      <template v-slot:no-data>
        <v-alert :value="true" color="info" icon="info" outlined>No existe ningun registro</v-alert>
      </template>
    </v-data-table>
    </v-card>
    <v-dialog v-model="dialogoeliminar" persistent max-width="290">
      <v-card>
        <v-card-text class="title">Desea eliminar {{ objeto.desctipoajuste }}?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="eliminarcancelar">Cancelar</v-btn>
          <v-btn color="blue darken-1" text @click="meliminarguardar">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { db } from '@/services/db.service';
import { getPermission} from "@/services/storage/permissionStorage.service";
import { mensaje } from "@/services/notify.service";
import { TIPO_AJUSTE } from "@/constants/forms";
import {
    PUEDE_AGREGAR,
    PUEDE_EDITAR,
    PUEDE_ELIMINAR,
    PUEDE_LISTAR,
} from "@/constants/permissions";


export default {
  data() {
    return {
      overlay: false,
      search: '',
      headers: [
        { text: 'Codigo', align: 'left', value: 'idtipoajuste' },
        { text: 'Descripcion', align: 'left', value: 'desctipoajuste' },
        { text: "Accion",value:"accion", sortable: false, align: "right" }
      ],
      datos: [],
      url: '/apitipoajuste/',
      titulo:TIPO_AJUSTE,
      objeto: {desctipoajuste:''},
      index: '',
      dialogoeliminar: false,
      load: false,
      permission: {
        can_add: false,
        can_view: false,
        can_update: false,
        can_delete: false
      }
    };
  },

  watch: {
    // eslint-disable-next-line
    reload(val) {
      if (val === true) {
        this.mcargartabla();
        
      }
    }
  },
  created() {
    this.$store.commit('SET_LAYOUT', 'principal-layout');
    this.mcargartabla();
   
const permission = getPermission(TIPO_AJUSTE).permiso;
this.permission = {
    can_add: permission[PUEDE_AGREGAR],
    can_view: permission[PUEDE_LISTAR],
    can_update: permission[PUEDE_EDITAR],
    can_delete: permission[PUEDE_ELIMINAR],
};
  },
  computed: {
    ...mapGetters('tipoajuste', {
      abrirtipoajuste: 'tipoajuste_create',
      reload: 'tipoajuste_reload',
      show: 'tipoajuste_show'
    }),
    permission_alert(){
      return mensaje.permission(this.permission);
    }
  },

  methods: {
    ...mapActions('tipoajuste', [
      'tipoajuste_create',
      'tipoajuste_update',
      'tipoajuste_reload',
      'tipoajuste_show'
    ]),
    rowselect(val) {
      this.tipoajuste_update(val);
      this.tipoajuste_show(true);
    },
    meliminar(item) {
      this.index = this.datos.indexOf(item);
      this.objeto = Object.assign({}, item);
      this.dialogoeliminar = true;
    },

    eliminarcancelar() {
      this.index = '';
      this.objeto = '';
      this.dialogoeliminar = false;
    },
    async meliminarguardar() {
      this.overlay = true;
      const id = this.objeto.idtipoajuste;
      const a = await db.delete(`${this.url}${id}`);
      if (a.estado === true) {
        this.datos.splice(this.index, 1);
        this.$notify(a.info);
        this.dialogoeliminar = false;
        this.eliminarcancelar();
      }else{
        a.info.map(x => { this.$notify(mensaje.error(x));});
      }
      this.overlay = false;
    },
    mupdate(val) {
      this.tipoajuste_update(val);
      this.tipoajuste_create(true);
    },
    async mcargartabla() {
      this.load = true;
      await db.get( this.url).then(a => {
        this.datos = a;
      });
      this.load = false;
      this.tipoajuste_reload(false);
    }
  }
};
</script>