
export default {

  rol_create: (state) => {
    return state.rol_create
  },
  rol_update: (state) => {
    return state.rol_update
  },
  rol_reload: (state) => {
    return state.rol_reload
  },
  rol_show: (state) => {
    return state.rol_show
  },
}
