<template>
  <v-row justify="center">
    <v-dialog
      :value="value"
      @input="$emit('input', $event)"
      :max-width="width"
      persistent
      @keydown.esc="close()"
    >
      <v-card>
        <slot name="body"></slot>
        <slot name="footer"></slot>
      </v-card>
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-dialog>
  </v-row>
</template>

<script>
import { vuex } from "@/services/vuex";
export default {
  props: {
    value: Boolean,
    overlay: Boolean,
    vuex: {
      type: [Object, Boolean],
      default: false
    },
    width: {
      type: String,
      default: "700px"
    }
  },
  methods: {
    close() {
      if (this.vuex != false) {
        vuex.save(this.vuex.create, false);
        vuex.save(this.vuex.show, false);
      }
      this.$emit("input", false);
    }
  }
};
</script>