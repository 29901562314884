var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('c-alert-permission',{model:{value:(_vm.permission),callback:function ($$v) {_vm.permission=$$v},expression:"permission"}}),(_vm.permission.confirmar)?_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.title))]),_c('widgets-Favorite',{attrs:{"ruta":_vm.$route.path,"formulario":_vm.title}}),_c('v-divider',{staticClass:"mx-2",attrs:{"inset":"","vertical":""}})],1):_vm._e(),(_vm.permission.confirmar)?_c('v-data-table',{attrs:{"headers":_vm.headers.cuentabanco,"items":_vm.Vget_cheque_data_desembolso,"loading":_vm.Vget_cheque_load,"items-per-page":5},scopedSlots:_vm._u([{key:"item.montoapagar",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.toCurrency(item.montoapagar)))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(item.montoapagar > 0)?_c('v-btn',{attrs:{"outlined":"","small":"","color":"red"},on:{"click":function($event){return _vm.openDesembolso(item)}}},[_vm._v("Cheques a Desembolsar")]):_vm._e()]}}],null,false,3532483303)}):_vm._e(),_c('v-dialog',{attrs:{"persistent":"","max-width":"700"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Lista de Cheques a Desembolsar")])],1),_c('v-container',[_c('v-data-table',{attrs:{"headers":_vm.headers.cheques,"items":_vm.data.cheques,"items-per-page":10000,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.monto",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.toCurrency(item.monto)))])]}},{key:"item.estado",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"ripple":false},model:{value:(item.estado),callback:function ($$v) {_vm.$set(item, "estado", $$v)},expression:"item.estado"}})]}}])})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("clear")])],1),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.guardar()}}},[_c('v-icon',[_vm._v("done")])],1)],1),_c('c-overlay',{attrs:{"value":_vm.Vget_cheque_save_load}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }