<template>
    <v-dialog persistent max-width="700" :value="value">
        <v-card>
            <v-toolbar flat>
                <v-toolbar-title>{{ title }}</v-toolbar-title>
            </v-toolbar>
            <v-container>
                <v-form ref="form" @submit.prevent="">
                    <v-row dense>
                        <v-col cols="12">
                            <c-text-field label="Nombre" autocomplete="off" :rules="rules" ref="categoria1"
                                id="categoria1" v-model="data.nombre" outlined dense placeholder="Ingrese nombre"
                                @keyup.native.enter="next(1, data.nombre)"></c-text-field>
                        </v-col>
                        <v-col cols="12">
                            <AutocompleteGrupo id="categoria2" v-model="data.idfinanza_grupo.idfinanza_grupo"
                                @nextfocus="next(2, '-')"
                                @keyup.native.enter="data.presupuesto ? next(2, data.idfinanza_grupo) : next(4, data.idfinanza_grupo.idfinanza_grupo);" />
                        </v-col>
                        <v-col cols="12">
                            <v-btn-toggle v-model="data.presupuesto" dense rounded color="purple darken-1">
                                <v-btn :value="false">
                                    Sin Presupuesto
                                </v-btn>
                                <v-btn :value="true">
                                    Con Presupuesto
                                </v-btn>
                            </v-btn-toggle>
                        </v-col>
                        <v-col cols="12"></v-col>
                    </v-row>
                    <v-row dense v-if="data.presupuesto">
                        <v-col cols="12">
                            <c-text-date label="Fecha Inicio" ref="categoria3" id="categoria3" outlined dense
                                v-model="presupuesto.fecha"
                                @keyup.native.enter="next(1, presupuesto.fecha)"></c-text-date>
                        </v-col>
                        <v-col cols="12">
                            <v-autocomplete :items="getTiempo" :loading="isLoadingTiempo" item-text="nombre"
                                return-object autocomplete="off" outlined dense label="Tiempo" ref="categoria4"
                                id="categoria4" v-model="presupuesto.idfinanza_tiempo"
                                @keyup.native.enter="next(3, presupuesto.idfinanza_tiempo)"></v-autocomplete>
                        </v-col>
                        <v-col cols="12">
                            <c-text-currency dense outlined ref="categoria4" id="categoria4" :rules="rules"
                                v-model="presupuesto.monto" label="Monto"
                                @keyup.native.enter="next(4, presupuesto.monto)"></c-text-currency>
                        </v-col>
                    </v-row>
                </v-form>
            </v-container>
            <v-divider></v-divider>
            <v-card-actions>
                <c-btn-crud-close @click="cancel()"></c-btn-crud-close>
                <v-spacer></v-spacer>
                <c-btn-crud-done ref="categoria5" id="categoria5" @click="save()"></c-btn-crud-done>
            </v-card-actions>
            <c-overlay :value="isLoadingCategoria"></c-overlay>
        </v-card>
    </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { focus } from "@/services/focus.service";
import AutocompleteGrupo from "../Grupo/Autocomplete.vue";
export default {
    components: {
        AutocompleteGrupo
    },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        editable: {
            type: Boolean,
            default: false,
        },
        props: {
            type: Object,
        },
        "sync-input": {
            type: [String, Number],
            default: null,
        },
    },
    mounted() {
        if (this.editable) {
            this.data = JSON.parse(JSON.stringify(this.props));
            if (this.data.finanzapresupuesto.length != 0) {
                this.presupuesto = JSON.parse(JSON.stringify(this.data.finanzapresupuesto[0]))
                this.data.finanzapresupuesto = []
            }
        }
        this.fetchTiempo();
        setTimeout(() => this.next(0, "-"), 20);
        if (this.syncInput != null) this.data.nombre = this.syncInput;
        this.sync = this.syncInput;
    },
    computed: {
        ...mapGetters("finanza", ["isLoadingCategoria", "isLoadingTiempo", "getTiempo"]),
        title() {
            return this.editable ? "Modificar Categoria" : "Registrar Categoria";
        },
    },
    methods: {
        ...mapActions("finanza", ["setCategoria", "setCategoriaUpdate", "fetchCategoria", "fetchTiempo"]),

        next(id, data) {
            focus.nextidName("categoria", id, data)
        },
        cancel() {
            this.$emit("input", false);
        },
        async save() {
            if (!this.$refs.form.validate()) return null;
            if (this.data.presupuesto == true) {
                this.data.finanzapresupuesto.push(this.presupuesto);
            } else {
                this.data.finanzapresupuesto = []
            }
            const response = this.editable
                ? await this.setCategoriaUpdate({
                    data: this.data,
                    id: this.data.idfinanza_categoria,
                })
                : await this.setCategoria(this.data);
            if (response.success) {
                if (this.sync != null) {
                    this.cancel();
                    this.fetchCategoria();
                    return this.$emit("sync-input", response.data.idfinanza_categoria);
                }
                this.data = JSON.parse(JSON.stringify(this.default));
                this.$refs.form.resetValidation();
                this.fetchCategoria();
                this.next(0, '-')
                if (this.editable) this.cancel();
            }
        }
    },
    data: () => ({
        rules: [(v) => !!v || "Este campo es obligatorio"],
        presupuesto: {
            idfinanza_tiempo: "",
            fecha: "",
            monto: 0,
        },
        presupuesto_default: {
            idfinanza_tiempo: "",
            fecha: "",
            monto: 0,
        },
        data: {
            idfinanza_categoria: 0,
            nombre: "",
            idfinanza_grupo: {
                idfinanza_grupo: ""
            },
            finanzapresupuesto: [],
            presupuesto: false,
        },
        default: {
            idfinanza_categoria: 0,
            nombre: "",
            idfinanza_grupo: {
                idfinanza_grupo: ""
            },
            finanzapresupuesto: [],
            presupuesto: false,
        }
    }),
}
</script>