<template>
  <v-autocomplete
    :value="value"
    :id="id"
    @input="$emit('input', $event)"
    :rules="rules_active"
    dense
    ref="input"
    type="text"
    :items="items"
    :label="label"
    item-text="fkdes"
    item-value="id"
    outlined
    :readonly="readonly"
    :disabled="disabled"
    height="12"
    autocomplete="off"
    :loading="Vget_load"
    :no-data-text="`No existe ninguna concidencia`"
    v-on:change="change"
    :small-chips="chips"
  ></v-autocomplete>
</template>

<script>
import store from "@/store";
export default {
  props: {
    label: String,
    value: [Number, String, Object],
    id: String,
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    chips: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      action: "moneda/Vact_moneda_cotizacion_day",
      getter: "moneda/Vget_moneda_cotizacion_day",
      load: "moneda/Vget_moneda_load",
      validar: [v => !!v.idmoneda || "Seleccion un campo"],
      items: [],
      noData: true
    };
  },
  created() {
    store.dispatch(this.action);
  },
  watch: {
    Vget_data(val) {
      this.procesar(val);
    }
  },
  computed: {
    rules_active() {
      if (this.rules) return this.validar;
      return [];
    },
    Vget_data() {
      return store.getters[this.getter];
    },
    Vget_load() {
      return store.getters[this.load];
    }
  },
  methods: {
    procesar(val) {
      this.items = [];
      if (val[0] === undefined) return null;
      val[0].cotizaciondet.forEach(x => {
        this.items.push({
          id: x.idmoneda,
          fkdes: x.idmoneda.descmoneda
        });
      });
    },
    change(evt) {
      this.$emit("change", evt);
    },
            focus() {
      this.$refs.input.focus();
    },
    isMenuActive() {
      this.$refs.input.isMenuActive = false;
    },
  }
};
</script>