<template>
  <div>
    <Crud
      v-if="crud.add"
      v-model="crud.add"
      :datos="crud.datos"
      @filter="filterSalario()"
      @datos="crud.datos = $event"
    />
    <Resumen
      v-if="crud.view"
      v-model="crud.view"
      :props="crud.datos"
      readonly
      @datos="crud.datos = $event"
    />
    <div v-if="dialogCotizacion">
      <Finanzas-Cotizacion-CreateV2 v-model="dialogCotizacion" />
    </div>
    <v-alert type="error" v-if="permission_alert"
      >Error!!! Este usuario no tiene permisos en esta ventana</v-alert
    >
    <v-toolbar flat dense>
      <v-toolbar-title>{{ $route.name }}</v-toolbar-title>
      <widgets-Favorite :ruta="$route.path" :formulario="$route.name" />
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-spacer></v-spacer>

      <c-text-table-search
        v-if="permission.can_view"
        v-model="search"
        class="mr-1"
      ></c-text-table-search>

      <v-toolbar-items>
        <c-btn-table-add
          @click="crearSalario()"
          v-if="permission.can_add"
        ></c-btn-table-add>
        <c-btn-table-reload
          class="mx-1"
          @click="filterSalario()"
        ></c-btn-table-reload>
        <v-btn small color="green" @click="filter = !filter"
          ><v-icon>filter_alt</v-icon></v-btn
        >
      </v-toolbar-items>
    </v-toolbar>
    <VFilter
      v-show="filter"
      v-model="filter"
      @filter="filterSalario($event)"
      @printer="exportacionMasiva()"
    >
    </VFilter>
    <v-data-table
      :headers="headers"
      :items="getSalario"
      :loading="isSalarioLoading"
      :search="search"
      :show-select="filter"
      v-model="selected"
      item-key="idpago_salario"
      v-if="permission.can_view"
    >
      <template v-slot:[`item.periodo_inicio`]="props">
        <div>
          {{ dateFormat(props.item.periodo_inicio) }} /
          {{ dateFormat(props.item.periodo_fin) }}
        </div>
      </template>
      <template v-slot:[`item.monto`]="{ item }">
        <div class="font-weight-black">{{ toCurrency(item.monto) }}</div>
      </template>

      <template v-slot:[`item.accion`]="props">
        <c-btn-table-view @click="view_item(props.item)" />
        <c-btn-table-delete @click="delete_item(props.item)" />
      </template>
    </v-data-table>

    <Delete
      v-if="crud.delete"
      v-model="crud.delete"
      :props="crud.datos"
      @filter="filterSalario()"
      @datos="crud.datos = $event"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { mensaje } from "@/services/notify.service";
import Crud from "./Crud";
import Resumen from "./ConfirmarPago";
import Delete from "./Delete";
import VFilter from "./Filter";
import { PAGO_SALARIO } from "@/constants/forms";
import { currency } from "@/services/util/number.service";
import { dmy } from "@/services/util/date.service";
import { configRecibo } from "./constants";
import { generarRecibo } from "./recibo";
import {
  current_first_date,
  current_last_date,
} from "@/services/util/date.service";
import {
  PUEDE_AGREGAR,
  PUEDE_EDITAR,
  PUEDE_ELIMINAR,
  PUEDE_LISTAR,
} from "@/constants/permissions";
export default {
  components: {
    Crud,
    Resumen,
    Delete,
    VFilter,
  },
  data() {
    return {
      search: "",
      filter: false,
      selected: [],
      filterParams: {
        desde: current_first_date(),
        hasta: current_last_date(),
        idsector: "",
      },
      dialogCotizacion: false,
      headers: [
        { text: "Codigo", align: "left", value: "idpago_salario" },
        {
          text: "Funcionario",
          align: "left",
          value: "idcontacto.idcontacto.desccontacto",
        },
        {
          text: "Periodo de Pago",
          align: "left",
          value: "periodo_inicio",
          sortable: false,
        },
        {
          text: "Comentario",
          align: "left",
          value: "comentario",
          sortable: false,
        },
        { text: "Total", value: "monto", align: "right" },
        { text: "Accion", value: "accion", sortable: false, align: "right" },
      ],
      permission: {
        can_add: false,
        can_view: false,
        can_update: false,
        can_delete: false,
      },
      crud: {
        delete: false,
        add: false,
        view: false,
        datos: null,
      },
    };
  },
  watch: {
    getSalario(val) {
      this.selected = JSON.parse(JSON.stringify(val));
    },
  },
  computed: {
    ...mapGetters("salario", ["getSalario", "isSalarioLoading"]),
    permission_alert() {
      return mensaje.permission(this.permission);
    },
  },
  created() {
    this.$store.commit("SET_LAYOUT", "principal-layout");
    const permission = getPermission(PAGO_SALARIO).permiso;
    this.permission = {
      can_add: permission[PUEDE_AGREGAR],
      can_view: permission[PUEDE_LISTAR],
      can_update: permission[PUEDE_EDITAR],
      can_delete: permission[PUEDE_ELIMINAR],
    };
    this.filterSalario();
  },
  methods: {
    ...mapActions("salario", ["fetchSalario"]),
    ...mapActions("cotizacion", ["Vact_cotizacion_data_day"]),
    async crearSalario() {
      const e = await this.Vact_cotizacion_data_day();
      if (e) return (this.crud.add = true);
      if (!e) return (this.dialogCotizacion = true);
    },
    filterSalario(val) {
      if(val) this.filterParams = JSON.parse(JSON.stringify(val));
      this.fetchSalario(this.filterParams);
    },
    exportacionMasiva() {
      const lote = true;
      generarRecibo(this.selected, configRecibo, "Salario", lote);
    },
    toCurrency(value) {
      return currency(value);
    },
    dateFormat(date) {
      return dmy(date);
    },
    view_item(data) {
      this.crud.datos = JSON.parse(JSON.stringify(data));
      this.crud.view = true;
    },
    delete_item(item) {
      this.crud.datos = JSON.parse(JSON.stringify(item));
      this.crud.delete = true;
    },
  },
};
</script>
