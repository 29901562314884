<template>
  <v-text-field
    :value="value"
    :id="id"
    @input="$emit('input', $event)"
    :rules="rules"
    :label="label"
    ref="input"
    :readonly="readonly"
    :suffix="suffix"
    v-on:focus="$emit('focus')"
    :error-messages="errorMessages"
    :outlined="outlined"
    :messages="messages"
    :placeholder="placeholder"
    :persistent-hint="persistentHint"
    :hint="hint"
    :solo="solo"
    :hide-details="hideDetails"
    :type="type"
    :solo-inverted="soloInverted"
    :disabled="disabled"
    :append-icon="appendIcon"
    @click:append="$emit('click:append')"
    :dense="dense"
    autocomplete="off"
  ></v-text-field>
</template>

<script>
export default {
  props: {
    suffix: String,
    label: String,
    value: [String, Number, Array,Object],
    id: String,
    "persistent-hint": {
      type: Boolean,
      default: false,
    },
    messages: {
      type: [Number, String],
    },
    hint: String,
    "error-messages": {
      type: [String, Array, Object],
    },
    rules: {
      type: [Array],
      default: function () {
        return [(v) => !!v || "Este campo es obligatorio"];
      },
    },
    type: {
      type: String,
      default: "text",
    },
    dense: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    solo:{
      type: Boolean,
      default: false
    },
    'solo-inverted':{
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String
    },
    disabled:{
      type: Boolean,
      default: false,
    },
    'append-icon':{
      type: String,
      default: ''
    },
    'hide-details':{
      type: Boolean,
      default: false
    }
  },
  methods:{
     focus(){
      this.$refs.input.focus();
    }
  }
};
</script>