export default {
  item_update: null,
  item_show: false,

  item_senave: [],

  item_data: [],
  item_ensilaje: [],
  item_vivo: [],
  item_stockeable: [],
  item_lacteo: [],
  item_tipoitem_data: [],
  item_stock2: [],
  item_stock_offline: [],
  item_agrostock: [],
  item_agrostock_todos: [],
  item_siembra: [],
  item_siembra_default: [],
  item_medicina: [],
  item_load: false,
  item_senave_load: false,
  item_load_save: false
}
