import { db } from "@/services/db.service";
import { get, post } from "@/services/api/api.service";
export default {

    async Vact_puntoventa_data({ commit }) {
        commit('puntoventa_load', true);
        try {
            const data = await db.get('/apipuntoventa/');
            commit('puntoventa_data', data);
            commit('puntoventa_load', false);
            return true
        } catch (e) {
            commit('puntoventa_load', false);
            return e
        }
    },
    async Vact_puntoventa_byIdSucursal({ commit }, sucursal) {
        if (sucursal === null) return commit('puntoventa_byIdSucursal', []);
        commit('puntoventa_load', true);
        try {
            const data = await db.get('/apipuntoventa/?sucursal=' + sucursal);
            commit('puntoventa_byIdSucursal', data);
            commit('puntoventa_load', false);
            return true
        } catch (e) {
            commit('puntoventa_load', false);
            return e
        }
    },
    async Vact_puntoventa_save({ commit }, data) {
        commit('puntoventa_save_load', true);
        try {
            const resp = await db.save('/apipuntoventa/', data);
            commit('puntoventa_save_load', false);
            return resp;
        } catch (e) {
            commit('puntoventa_save_load', false);
            return e
        }
    },
    async Vact_puntoventa_update({ commit }, { id, data }) {
        commit('puntoventa_save_load', true);
        try {
            const resp = await db.update('/apipuntoventa/' + id + '/', data);
            commit('puntoventa_save_load', false);
            return resp;
        } catch (e) {
            commit('puntoventa_save_load', false);
            return e
        }
    },
    async Vact_puntoventa_del({ dispatch }, id) {
        try {
            const data = await db.delete('/apipuntoventa/' + id);
            dispatch('Vact_puntoventa_data');
            return data
        } catch (e) {
            return false
        }
    },
    async Vact_puntoventa_punto({ commit }, mode) {
        commit('puntoventa_load', true);
        try {
            const data = await db.get(`/apipunto/?tipo=${mode}`);
            commit('puntoventa_punto', data);
            commit('puntoventa_load', false);
            return true
        } catch (e) {
            commit('puntoventa_load', false);
            return e
        }
    },

    async Vact_puntoventa_parametros({ commit }, idpuntoventa) {
        commit('puntoventa_save_load', true);
        try {
            let parametros = await db.get(`/apitim/?band=${idpuntoventa}&tipo=factura`);
            commit('puntoventa_parametros', parametros);
            commit('puntoventa_save_load', false);
            return true
        } catch (e) {
            commit('puntoventa_save_load', false);
            return e
        }
    },
    /*     async Vact_puntoventa_punto_save({ commit }, { data, mode }) {
            commit('puntoventa_save_load', true);
            try {
                const response = await db.save('/apipunto/', data);
                let parametros = await db.get(`/apitim/?band=${data.id}&tipo=${mode}`);
                parametros.push({ idpuntoventa: data.id, idcaja: data.idca });
                commit('puntoventa_parametros', parametros);
                commit('puntoventa_save_load', false);
                return response;
            } catch (e) {
                commit('puntoventa_save_load', false);
                return e
            }
        }, */
    async Vact_puntoventa_punto_save({ commit, dispatch }, { data, mode }) {
        commit('puntoventa_save_load', true);
        const response = await post('/apipunto/', data);
        if (!response.success)
            if (response.errors[0].idcaja === undefined) {
                dispatch('snackbar/setNotification', response, { root: true })
            }
        try {
            let parametros = await get(`/apitim/?band=${data.id}&tipo=${mode}`);
            parametros.push({ idpuntoventa: data.id, idcaja: data.idca });
            commit('puntoventa_parametros', parametros);
        } catch (error) {
            dispatch('snackbar/setNotification', error, { root: true });
            commit('puntoventa_save_load', false);
            return error
        }
        commit('puntoventa_save_load', false);
        return response;
    },
}
