export default {

  moneda_data(state, request) {
    state.moneda_data = request;
  }, 
  moneda_data_all(state, request) {
    state.moneda_data_all = request;
  }, 
  moneda_cotizacion_day(state, request) {
    state.moneda_cotizacion_day = request;
  }, 
  moneda_cotizacion(state, request) {
    state.moneda_cotizacion = request;
  }, 
  moneda_load(state, request) {
    state.moneda_load = request;
  }, 
}