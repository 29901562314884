
export default {

  Vget_egreso_data: (state) => {
    return state.egreso_data
  },
  Vget_egreso_load: (state) => {
    return state.egreso_load
  },
  Vget_egreso_save_load: (state) => {
    return state.egreso_save_load
  }
}
