import { money } from "@/services/moneyconvert";
import { dmy } from "@/services/util/date.service";
const decimalMask = (value) => {
  return new Intl.NumberFormat().format(value);
};
const factura = {
  async generar(
    doc,
    config,
    datos,
    factura,
    iva5,
    iva10,
    ivatotal,
    ventatotal,
    moneda
  ) {
    let data = config.parametros;
    let detalle = datos.venta;
    data.razonsocial.razonsocial = datos.idcontacto.idcontacto.desccontacto;
    data.direccion.direccion = datos.idcontacto.idcontacto.direcontacto;
    if (datos.idcontacto.idcontacto.docucontactos >= '0' & datos.idcontacto.idcontacto.docucontactos <= '9') {
      data.ruc.ruc =
        datos.idcontacto.idcontacto.ruccontacto +
        "-" +
        datos.idcontacto.idcontacto.docucontactos;
    } else {
      data.ruc.ruc = datos.idcontacto.idcontacto.ruccontacto
    }
    data.notaremision.notaremision = "";
    data.fecha.fecha = dmy(datos.fechventa);
    data.telefono.telefono = datos.idcontacto.idcontacto.telecontacto;
    data.tipofactura.tipofactura =
      datos.idtipofactura.idtipofactura === 1 ? "Contado" : "Credito";
    data.vencimiento.vencimiento =
      datos.idtipofactura.idtipofactura === 2
        ? datos.fechvencimiento === undefined
          ? ""
          : dmy(datos.fechvencimiento)
        : "";
    data.codcliente.codcliente = datos.idcontacto.idcontacto.idcontacto;

    if (config.comprobante === "Factura") {
      data.timbrado.timbrado = `TIMBRADO Nº ` + config.timbrado;
      data.fechainicio.fechainicio = config.fecha_inicio;
      data.fechafinal.fechafinal = config.fecha_final;
      data.rucnegocio.rucnegocio = `RUC: ` + config.rucempresa;
      data.textfac.textfac = "FACTURA";
    }
    if (config.comprobante === "Nota de Venta") {
      /* NOTA DE VENTA cambiado por PRESUPUESTO */
      data.textfac.textfac = "NOTA DE VENTA";
    }
    if (config.comprobante === "Presupuesto") {
      data.textfac.textfac = "PRESUPUESTO"
    }

    data.nrofactura.nrofactura = factura;
    data.exenta_sub.exenta_sub = "";
    data.iva5_sub.iva5_sub = "";
    data.iva10_sub.iva10_sub = "";
    data.total.total = JSON.stringify(ventatotal);
    data.totaliva5.totaliva5 = iva5 != "" ? JSON.stringify(iva5) : "";
    data.totaliva10.totaliva10 = iva10 != "" ? JSON.stringify(iva10) : "";
    data.totaliva.totaliva = ivatotal != "" ? JSON.stringify(ivatotal) : "";
    data.totalletras.totalletras = money.letter(ventatotal, moneda);
    /*
            La unidad de medida utilizada es 'pt'
            para determinar la hoja haga la conversion 
            en base a estos datos
            1 cm = 28.346456693 pt
        */
    var x = 0;
    var fila = 0;

    detalle.map((item) => {
      detalle.map((subitem, index) => {
        if (
          item.iditem.iditem === subitem.iditem.iditem &&
          item.iddeposito.iddeposito != subitem.iddeposito.iddeposito
        ) {
          item.cantdetventa = item.cantdetventa + subitem.cantdetventa;
          item.iditem.exenta = item.iditem.exenta + subitem.iditem.exenta;
          item.iditem.iva5 = item.iditem.iva5 + subitem.iditem.iva5;
          item.iditem.iva10 = item.iditem.iva10 + subitem.iditem.iva10;
          detalle.splice(index, 1);
        }
      });
    });

    for (var i = 1; i <= data.index; i++) {
      doc.setFontSize(data.cabecera_size);
      doc.setFontType(data.cabecera_font);
      doc.text(
        data.razonsocial.x,
        data.razonsocial.y + x,
        data.razonsocial.razonsocial
      );
      doc.text(
        data.direccion.x,
        data.direccion.y + x,
        data.direccion.direccion
      );
      doc.text(data.ruc.x, data.ruc.y + x, data.ruc.ruc);
      doc.text(
        data.notaremision.x,
        data.notaremision.y + x,
        data.notaremision.notaremision
      );
      doc.text(data.fecha.x, data.fecha.y + x, data.fecha.fecha);
      doc.text(
        data.telefono.x,
        data.telefono.y + x,
        data.telefono.telefono || ""
      );
      doc.text(
        data.tipofactura.x,
        data.tipofactura.y + x,
        data.tipofactura.tipofactura
      );
      doc.text(
        data.vencimiento.x,
        data.vencimiento.y + x,
        data.vencimiento.vencimiento
      );
      doc.text(
        data.codcliente.x,
        data.codcliente.y + x,
        JSON.stringify(data.codcliente.codcliente)
      );

      doc.setFontSize(data.timbrado.size);
      doc.text(data.timbrado.x, data.timbrado.y + x, data.timbrado.timbrado);
      doc.setFontSize(data.fechainicio.size);
      doc.text(
        data.fechainicio.x,
        data.fechainicio.y + x,
        data.fechainicio.fechainicio
      );
      doc.setFontSize(data.fechafinal.size);
      doc.text(
        data.fechafinal.x,
        data.fechafinal.y + x,
        data.fechafinal.fechafinal
      );
      doc.setFontSize(data.rucnegocio.size);
      doc.text(
        data.rucnegocio.x,
        data.rucnegocio.y + x,
        data.rucnegocio.rucnegocio
      );
      doc.setFontSize(data.textfac.size);
      doc.text(data.textfac.x, data.textfac.y + x, data.textfac.textfac);
      doc.setFontSize(data.nrofactura.size);
      doc.text(
        data.nrofactura.x,
        data.nrofactura.y + x,
        data.nrofactura.nrofactura
      );

      fila = data.detalle_y + x;
      doc.setFontSize(data.detalle_size);

      let subtotal = {
        exenta: 0,
        iva5: 0,
        iva10: 0,
      };
      detalle.map((item, index) => {
        doc.text(data.id.x, fila, JSON.stringify(detalle[index].iditem.iditem));
        doc.text(
          data.cantidad.x,
          fila,
          JSON.stringify(detalle[index].cantdetventa)
        );
        doc.text(data.item.x, fila, item.iditem.descitem);
        doc.text(data.precio.x, fila, decimalMask(detalle[index].precdetventa));
        doc.text(
          data.exenta.x,
          fila,
          decimalMask(detalle[index].iditem.exenta)
        );
        doc.text(data.iva5.x, fila, decimalMask(detalle[index].iditem.iva5));
        doc.text(data.iva10.x, fila, decimalMask(detalle[index].iditem.iva10));
        fila = fila + data.detalle_interlinea;

        subtotal.exenta = subtotal.exenta + detalle[index].iditem.exenta;
        subtotal.iva5 = subtotal.iva5 + detalle[index].iditem.iva5;
        subtotal.iva10 = subtotal.iva10 + detalle[index].iditem.iva10;
      });

      doc.setFontSize(data.subs_size);
      doc.setFontType("bold");
      doc.text(
        data.exenta_sub.x,
        data.exenta_sub.y + x,
        decimalMask(subtotal.exenta)
      );
      doc.text(
        data.iva5_sub.x,
        data.iva5_sub.y + x,
        decimalMask(subtotal.iva5)
      );
      doc.text(
        data.iva10_sub.x,
        data.iva10_sub.y + x,
        decimalMask(subtotal.iva10)
      );

      doc.setFontSize(data.total.size);
      doc.setFontType("bold");
      doc.text(data.total.x, data.total.y + x, decimalMask(data.total.total));

      doc.setFontSize(data.totalletras.size);
      doc.text(
        data.totalletras.x,
        data.totalletras.y + x,
        data.totalletras.totalletras
      );

      doc.setFontSize(data.totales_size);
      doc.text(
        data.totaliva5.x,
        data.totaliva5.y + x,
        decimalMask(data.totaliva5.totaliva5)
      );
      doc.text(
        data.totaliva10.x,
        data.totaliva10.y + x,
        decimalMask(data.totaliva10.totaliva10)
      );
      doc.text(
        data.totaliva.x,
        data.totaliva.y + x,
        decimalMask(data.totaliva.totaliva)
      );
      x = x + data.next_y;
    }
    return doc;
  },
  getDataUri(url) {
    return new Promise((resolve) => {
      var image = new Image();
      image.crossOrigin = "";
      image.onload = function() {
        var canvas = document.createElement("canvas");
        canvas.width = this.naturalWidth;
        canvas.height = this.naturalHeight;

        var ctx = canvas.getContext("2d");
        ctx.fillStyle = "#fff";
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        canvas.getContext("2d").drawImage(this, 0, 0);

        resolve(canvas.toDataURL("image/jpeg"));
      };

      image.src = url;
    });
  },
};

export { factura };
