<template>
  <v-dialog :value="value" max-width="800" persistent>
    <v-card>
      <v-toolbar flat>
        <div class="d-flex flex-column mt-3">
          <div class="text-h6">
            Resumen del pago de
            <span class="font-weight-black">{{
              props.idcontacto.idcontacto.desccontacto
            }}</span>
          </div>
          <div class="subtitle-1 mt-3" >
            <div>
              Periodo de pago:
              <span class="font-weight-black">
                {{ formatDate(props.periodo_inicio) }} /
                {{ formatDate(props.periodo_fin) }}
              </span>
              -
              Horas trabajadas:
              <span class="font-weight-black">
                {{ props.trabajadas }}
              </span>
            </div>
            <div class="d-flex justify-space-between">
              <div>
                <span>Debitado de la cuenta</span>
                <span class="font-weight-black">
                  {{ props.idcuentabanco.desccuentabanco }}
                </span>
                <span>del banco: </span>
                <span class="font-weight-black">{{
                  props.idcuentabanco.idbanco.descbanco
                }}, </span>
                <span>con el tipo de moneda </span>
                <span class="font-weight-black">
                  {{ props.idmoneda.descmoneda }}
                </span>
              </div>
       
            </div>
          </div>
        </div>
        <v-spacer></v-spacer>
        <c-text-date
          class="mt-3"
          label="Fecha de Pago"
          :readonly="readonly"
          v-model="data.fecha"
        ></c-text-date>
      </v-toolbar>

      <v-data-table
        :headers="headers"
        :items="resumen"
        :items-per-page="999999"
        hide-default-footer
      >
        <template v-slot:[`item.ingreso`]="{ item }"
          ><div>
            {{ toCurrency(item.ingreso) }}
          </div>
        </template>
        <template v-slot:[`item.egreso`]="{ item }"
          ><div>
            {{ toCurrency(item.egreso) }}
          </div>
        </template>
        <template slot="body.append">
          <tr>
            <th>
              <span class="subtitle-1 font-weight-black customtext--text"
                >TOTAL</span
              >
            </th>

            <th class="text-right">
              <span class="subtitle-1 font-weight-black customtext--text">
                {{ toCurrency(data.totalIngreso) }}</span
              >
            </th>
            <th class="text-right">
              <span class="subtitle-1 font-weight-black customtext--text">
                {{ toCurrency(data.totalEgreso) }}</span
              >
            </th>
          </tr>
          <tr>
            <th class="text-left subtitle-1 font-weight-black customtext--text">
              Neto a Recibir:
            </th>
            <th></th>

            <th
              class="text-right subtitle-1 font-weight-black customtext--text"
            >
              {{ toCurrency(data.monto) }}
            </th>
          </tr>
        </template>
      </v-data-table>
      <v-textarea
        class="mx-2"
        :readonly="readonly"
        rows="2"
        label="Comentario"
      ></v-textarea>
      <v-divider></v-divider>
      <v-card-actions>
        <c-btn-crud-close @click="cancelar()"></c-btn-crud-close>
        <v-spacer></v-spacer>
        <v-btn v-if="readonly" text color="primary" @click="generarRecibo()"
          ><v-icon>print</v-icon></v-btn
        >
        <c-btn-crud-done
          v-if="!readonly"
          @click="$emit('save')"
        ></c-btn-crud-done>
      </v-card-actions>
      <c-overlay :value="isSalarioLoading"></c-overlay>
    </v-card>
  </v-dialog>
</template>

<script>
import { configRecibo } from "./constants";
import { currency } from "@/services/util/number.service";
import { dmy, current_date } from "@/services/util/date.service";
import { mapGetters } from "vuex";
import { generarRecibo } from "./recibo";
export default {
  props: {
    value: Boolean,
    props: {
      type: Object,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    data: {
      fecha: current_date(),
      comentario: "",
    },
    headers: [
      { text: "Concepto", value: "descripcion", sortable: false },
      { text: "Ingresos", value: "ingreso", align: "right", sortable: false },
      { text: "Egresos", value: "egreso", align: "right", sortable: false },
    ],
    resumen: [],
  }),
  mounted() {
    this.data = JSON.parse(JSON.stringify(this.props));
    this.obtenerDebeHaber();
  },
  computed: {
    ...mapGetters("salario", ["isSalarioLoading"]),
  },
  methods: {
    generarRecibo() {
      generarRecibo(this.props, configRecibo, "Salario");
    },
    cancelar() {
      this.$emit("input", false);
    },
    toCurrency(value) {
      if (value) return currency(Number(value));
      return "";
    },
    formatDate(value) {
      return dmy(value);
    },
    obtenerDebeHaber() {
      let ingreso = 0;
      let egreso = 0;
      this.resumen = this.props.detalle.map((x) => {
        return {
          descripcion: x.idconcepto_salario.descripcion,
          ingreso: x.idconcepto_salario.positivo_negativo
            ? ((ingreso = ingreso + x.monto), x.monto)
            : "",
          egreso: !x.idconcepto_salario.positivo_negativo
            ? ((egreso = egreso + x.monto), x.monto)
            : "",
        };
      });
    },
  },
};
</script>

<style></style>
