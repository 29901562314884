
export default {
  Vget_causanoins_data: (state) => {
    return state.causanoins_data
  },
  Vget_causanoins_load: (state) => {
    return state.causanoins_load
  },
  Vget_causanoins_save_load: (state) => {
    return state.causanoins_save_load
  }
}
