import { SET_HA_SEMBRABLE } from "./contants";
export default {
  [SET_HA_SEMBRABLE]: (state, data) => (state.ha_sembrable = data),
  siembra_load(state, data) {
    state.siembra_load = data;
  },
  siembra_data_byId(state, data) {
    state.siembra_data_byId = data;
  },
  siembra_data(state, data) {
    state.siembra_data = data;
  },
  siembra_timeline(state, data) {
    state.siembra_timeline = data;
  },
  siembra_save_load(state, data) {
    state.siembra_save_load = data;
  },
};
