
export default {

  Vget_compra_data(state) {
    return state.compra_data;
  },
  Vget_compra_load(state) {
    return state.compra_load;
  },
  Vget_compra_save_load(state) {
    return state.compra_save_load ;
  },
}
