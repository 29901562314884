<template>
  <v-row justify="center" v-if="detalle != {}">
    <v-dialog v-model="dialog" persistent max-width="1200">
      <v-card>
        <v-card-title class="text-h5">
          {{ detalle.info.name }}
        </v-card-title>
        <v-card-text>
          <v-row dense class="ma-1">
            <v-col cols="12" sm="4">
              <v-card class="my-auto rounded-lg">
                <v-card-actions class="font-weight-bold caption mb-n4">
                  Temperatura
                  <v-divider class="mx-2"></v-divider>
                  <v-btn fab x-small color="primary"
                    ><v-icon>device_thermostat</v-icon></v-btn
                  >
                </v-card-actions>
                <v-card-text class="d-flex flex-column align-center">
                  <div class="text-h3 font-weight-bold">
                    {{ ftocelsius(detalle.lastData.tempf) }} °C
                  </div>
                  <div class="d-flex flex-row">
                    <div class="d-flex flex-column align-center">
                      <div class="caption">Punto de Rocio</div>
                      <div class="font-weight-bold">
                        {{ ftocelsius(detalle.lastData.hl.dewPoint.h) }} °C
                      </div>
                    </div>
                    <v-divider class="mx-3" vertical></v-divider>
                    <div class="d-flex flex-column align-center">
                      <div class="caption">Sensacion</div>
                      <div class="font-weight-bold">
                        {{ ftocelsius(detalle.lastData.hl.feelsLike.h) }} °C
                      </div>
                    </div>
                    <v-divider class="mx-3" vertical></v-divider>
                    <div class="d-flex flex-column align-center">
                      <div class="caption">Humedad</div>
                      <div class="font-weight-bold">
                        {{ detalle.lastData.hl.humidity.h }} %
                      </div>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="4">
              <v-card class="my-auto rounded-lg">
                <v-card-actions class="font-weight-bold caption mb-n4">
                  Viento
                  <v-divider class="mx-2"></v-divider>
                  <v-btn fab x-small color="primary"
                    ><v-icon>speed</v-icon></v-btn
                  >
                </v-card-actions>
                <v-card-text class="d-flex flex-column align-center">
                  <div class="text-h3 font-weight-bold">
                    {{ mtokm(detalle.lastData.windspeedmph) }} Km/h
                  </div>
                  <div class="d-flex flex-row">
                    <div class="d-flex flex-column align-center">
                      <div class="caption">Direccion</div>
                      <div class="font-weight-bold">
                        {{ dirwind(detalle.lastData.winddir) }}
                      </div>
                    </div>
                    <v-divider class="mx-3" vertical></v-divider>
                    <div class="d-flex flex-column align-center">
                      <div class="caption">Rafagas de Vientos</div>
                      <div class="font-weight-bold">
                        {{ mtokm(detalle.lastData.windgustmph) }} Km/h
                      </div>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="4">
              <v-card class="my-auto rounded-lg">
                <v-card-actions class="font-weight-bold caption mb-n4">
                  Radiacion Solar
                  <v-divider class="mx-2"></v-divider>
                  <v-btn fab x-small color="primary"
                    ><v-icon>brightness_7</v-icon></v-btn
                  >
                </v-card-actions>
                <v-card-text class="d-flex flex-column align-center">
                  <div class="text-h3 font-weight-bold">
                    {{ solarradiation(detalle.lastData.solarradiation) }} lux
                  </div>
                  <div class="d-flex flex-row">
                    <div class="d-flex flex-column align-center">
                      <div class="caption">UV Index</div>
                      <div class="font-weight-bold">
                        {{ detalle.lastData.uv }}
                      </div>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="8" v-if="options">
              <v-card class="rounded-lg">
                <v-card-actions class="caption mb-n8">
                  Precipitaciones
                  <v-divider class="mx-2"></v-divider>
                  <v-btn fab x-small color="primary"
                    ><v-icon>filter_drama</v-icon></v-btn
                  >
                </v-card-actions>
                <v-chart :options="options" autoresize />
              </v-card>
            </v-col>
            <v-col cols="12" sm="4" v-if="options_pression">
              <v-card class="rounded-lg">
                <v-card-actions class="caption mb-n8">
                  Presion Atmosferica
                  <v-divider class="mx-2"></v-divider>
                  <v-btn fab x-small color="primary"
                    ><v-icon>compress</v-icon></v-btn
                  >
                </v-card-actions>
                <v-chart :options="options_pression" autoresize />
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="dialog = false">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapActions } from "vuex";
import { get } from "@/services/api/api.service";
import ECharts from "vue-echarts/components/ECharts";
import generarGrafico, {presion} from "./grafico";
var self = null;
export default {
  components: {
    "v-chart": ECharts,
  },
  props: {
    google: {
      type: Object,
      required: true,
    },
    map: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      options: null,
      options_pression: null,
      timer: null,
      detalle: {
        info: {
          name: ""
        },
        lastData: {
          hl: {
            dewPoint: {
              h: 0,
            },
            feelsLike: {
              h: 0,
            },
            humidity: {
              h: 0,
            },
          }
        },
      },
      registros: [],
      value: {
        name: "",
        mac: "",
        clave_net: "",
      },
      default: {
        name: "",
        mac: "",
        clave_net: "",
      },
    };
  },
  watch: {
    detalle() {
      this.generarGrafico();
    },
    dialog(val) {
      if (val == false) clearInterval(this.timer);
    }
  },
  created() {
    self = this;
    this.addMethod();
  },
  computed: {
    darkMode() {
      return this.$vuetify.theme.dark;
    },
  },
  methods: {
    ...mapActions("agromap", ["Vact_agromap_coordinates_view"]),
    precipitaciones() {
      const data = [
        {
          title: "Hora",
          item: this.rainmm(this.detalle?.lastData?.hourlyrainin),
        },
        {
          title: "Dia",
          item: this.rainmm(this.detalle?.lastData?.dailyrainin),
        },
        {
          title: "Evento",
          item: this.rainmm(this.detalle?.lastData?.eventrainin),
        },
        {
          title: "Semana",
          item: this.rainmm(this.detalle?.lastData?.weeklyrainin),
        },
        {
          title: "Mes",
          item: this.rainmm(this.detalle?.lastData?.monthlyrainin),
        },
        {
          title: "Total",
          item: this.rainmm(this.detalle?.lastData?.totalrainin),
        },
      ];
      const max = Math.floor(
        Math.max.apply(
          null,
          data.map((x) => x.item)
        )
      );
      const y = data.map((x) => max - x.item);
      let dark = this.darkMode
      return { y, data, dark };
    },
    generarGrafico() {
      if (this.detalle == {}) return null;
      this.options = JSON.parse(
        JSON.stringify(generarGrafico(this.precipitaciones()))
      );
      this.options_pression = JSON.parse(
        JSON.stringify(presion(this.rainmm(this.detalle?.lastData?.baromrelin)))
      );
    },
    rainmm(val) {
      if (val) { 
        return (val * 25.4);
      }
      return val
    },
    solarradiation(val) {
      return (val * 126.7).toFixed(2);
    },
    ftocelsius(val) {
      return (((val - 32) * 5) / 9).toFixed(2);
    },
    mtokm(val) {
      return (val * 1.609).toFixed(2);
    },
    dirwind(val) {
      if ((val > 0) & (val < 45)) {
        return "Norte";
      } else if ((val >= 45) & (val < 90)) {
        return "Noreste";
      } else if ((val >= 90) & (val < 135)) {
        return "Leste";
      } else if ((val >= 135) & (val < 180)) {
        return "Sudeste";
      } else if ((val >= 180) & (val < 225)) {
        return "Sur";
      } else if ((val >= 225) & (val < 270)) {
        return "Suroeste";
      } else if ((val >= 270) & (val < 315)) {
        return "Oeste";
      } else {
        return "Noroeste";
      }
    },
    addMarker(val) {
      val.data.forEach((x) => {
        var position = new self.google.maps.LatLng(
          x.info.coords.coords.lat,
          x.info.coords.coords.lon
        );
        self.setSensor(
          position,
          x.info.name,
          x.lastData.tempf,
          x.macAddress,
          x._id,
          x
        );
      });
    },
    setSensor(position, name, tempf, mac, id, x) {
      const marker = new self.google.maps.Marker({
        position: position,
        map: self.map,
        title: name,
      });
      self.viewInfoMap(marker, name, tempf, mac, id, x);
    },
    verDatos(val) {
      this.detalle = val;
      this.dialog = true;
      this.timer = setInterval(() => {
        this.getUpdate(val._id)
      }, 60000)
    },
    viewInfoMap(marker, name, tempf, mac, id, x) {
      let contentString =
        `<h4 style="color: black; text-align: center;">Sensor Meteorologico</h4><hr>` +
        `<table>` +
        `<tr>` +
        `<th style="text-align:left; color:black;">Descripcion: </th>` +
        `<td style="color:black; text-align:right;"> ` +
        name +
        `</td>` +
        `</tr>` +
        `<tr>` +
        `<th style="text-align:left; color:black;">Temperatura: </th>` +
        `<td style="color:black; text-align:right;"> ` +
        Math.round((tempf - 32) / 1.8) +
        ` °C` +
        `</td>` +
        `</tr>` +
        `<tr>` +
        `<td colspan="2">` +
        `<input type="button" id="ambient${id}" value="Ver" style="background-color: #3F51B5; width: 100%;">` +
        `</td>` +
        `</tr>` +
        `</table>`;
      let infowindow = new this.google.maps.InfoWindow({
        content: contentString,
      });
      marker.addListener("click", () => {
        infowindow.open(self.map, marker);
        this.seleccionarSensor(name, mac, id);
      });
      self.google.maps.event.addListener(infowindow, "domready", function () {
        let button = document.getElementById(`ambient${id}`);
        button.addEventListener("click", function () {
          self.verDatos(x);
        });
      });
    },
    seleccionarSensor(name, mac, id) {
      this.value.name = name;
      this.value.mac = mac;
      this.value.clave_net = id;
      this.$emit("seleccionado", this.value);
    },
    addMethod() {
      self.map.addListener("idle", () => {
        this.obtenerAmbientWeather();
      });
    },
    async obtenerAmbientWeather() {
      var bounds = self.map.getBounds();
      var NECorner = bounds.getNorthEast();
      var SWCorner = bounds.getSouthWest();
      try {
        let url = `apiopenaw/?topiz=${NECorner.lng()}&topde=${NECorner.lat()}&botiz=${SWCorner.lng()}&botde=${SWCorner.lat()}`;
        let response = await get(url);
        //let url2 = `apilotecoords/?lat1=${NECorner.lat()}&lng1=${NECorner.lng()}&lat2=${SWCorner.lat()}&lng2=${SWCorner.lng()}`;
        //let response2 = await get(url2);
        //this.Vact_agromap_coordinates_view(response2)
        this.registros = response;
        this.addMarker(this.registros);
      } catch (error) {
        console.log(error);
      }
    },
    async getUpdate(id) {
      try {
        let url = `apiopenawspecific/?key=${id}`
        let response = await get(url);
        this.detalle = response;
      } catch (error) {
        console.log(error)
      }
    },
  },

  // eslint-disable-next-line
  render() {},
};
</script>