<template>
  <v-row dense class="my-3">
    <v-spacer></v-spacer>
<!--     <v-col cols="6" md="3" sm="3">
      <c-text-currency
        outlined
        dense
        :value="total.iva5"
        :readonly="true"
        label="Iva (5%):"
      ></c-text-currency>
    </v-col>
    <v-col cols="6" md="3" sm="3">
      <c-text-currency
        outlined
        dense
        :value="total.iva10"
        :readonly="true"
        label="Iva (10%):"
      ></c-text-currency>
    </v-col>
    <v-col cols="6" md="3" sm="3">
      <c-text-currency
        outlined
        dense
        :value="total.ivatotal"
        :readonly="true"
        label="Total IVA:"
      ></c-text-currency>
    </v-col> -->
    <v-col cols="6" md="3" sm="3">
      <c-text-currency
        outlined
        dense
        :value="total"
        :readonly="true"
        label="Total Venta:"
      ></c-text-currency>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    total: {
      type: [Number, String],
      required: true,
    },
  },
};
</script>
