
export default {
  loginRequest(state) {
    state.authenticating = true;
    state.authenticationError = ''
    state.authenticationErrorCode = 0
},

loginSuccess(state, accessToken) {
    state.accessToken = accessToken
    state.authenticating = false;
},

loginError(state, {errorCode, errorMessage}) {
    state.authenticating = false
    state.authenticationErrorCode = errorCode
    state.authenticationError = errorMessage
},

logoutSuccess(state) {
    state.accessToken = ''
},

refreshTokenPromise(state, promise) {
    state.refreshTokenPromise = promise
}
}
