<template>
  <v-card>
    <v-alert type="error" v-if="permission_alert">Error!!! Este usuario no tiene permisos en esta ventana</v-alert>
    <v-toolbar flat dense>
      <v-toolbar-title>{{ $route.name }}</v-toolbar-title>
      <widgets-Favorite :ruta="$route.path" :formulario="$route.name" />
      <v-divider class="mx-4" inset vertical></v-divider>
      <v-spacer></v-spacer>
      <v-text-field v-model="search" append-icon="search" label="Busqueda" single-line hide-details
        v-if="permission.can_view" class="mr-1"></v-text-field>
      <v-toolbar-items>
        <c-btn-table-add class="mr-1" @click="add()"></c-btn-table-add>
        <c-btn-table-reload @click="fetchGanado()"></c-btn-table-reload>
      </v-toolbar-items>
    </v-toolbar>
    <v-data-table :headers="headers" :items="getVentaGanado" :search="search" :loading="isLoading" :items-per-page="10"
      v-if="permission.can_view">
      <template v-slot:[`item.accion`]="{ item }">
        <c-btn-table-edit @click="editar(item)"></c-btn-table-edit>
        <c-btn-table-delete @click="eliminar(item)"></c-btn-table-delete>
      </template>
    </v-data-table>
    <Crud v-model="crud.add" v-if="crud.add" :props="crud.datos" @reset="crud.datos = $event" />
    <Delete v-model="crud.delete" v-if="crud.delete" :props="crud.datos" @reset="crud.datos = null" />
    <Cotizacion v-model="crud.cotizacion" v-if="crud.cotizacion" />
  </v-card>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { VENTA_GANADO } from "@/constants/forms";
import { getPermission } from "@/services/storage/permissionStorage.service";
import Crud from "./Crud";
import Delete from "./Delete";
import Cotizacion from "@/views/Finanzas/Cotizacion/CreateV2";
import { COTIZACION } from "@/constants/forms";
import {
  PUEDE_AGREGAR,
  PUEDE_EDITAR,
  PUEDE_ELIMINAR,
  PUEDE_LISTAR,
} from "@/constants/permissions";
export default {
  components: {
    Cotizacion,
    Crud,
    Delete
  },
  data: () => ({
    search: "",
    headers: [
      { text: "Fecha", align: "left", value: "fecha" },
      { text: "Moneda", value: "idmoneda.descmoneda" },
      { text: "Accion", value: "accion", sortable: false, align: "right" },
    ],
    permission: {
      can_add: false,
      can_view: false,
      can_update: false,
      can_delete: false,
    },
    crud: {
      add: false,
      cotizacion: false,
      delete: false,
      datos: null,
    },
  }),

  mounted() {
    this.$store.commit("SET_LAYOUT", "principal-layout");
    this.fetchGanado();
    const permission = getPermission(VENTA_GANADO).permiso;
    this.permission = {
      can_add: permission[PUEDE_AGREGAR],
      can_view: permission[PUEDE_LISTAR],
      can_update: permission[PUEDE_EDITAR],
      can_delete: permission[PUEDE_ELIMINAR],
    };
  },
  computed: {
    ...mapGetters("ventaganado", ["getVentaGanado", "isLoading"]),
  },
  methods: {
    ...mapActions("ventaganado", ["fetchGanado"]),
    ...mapActions("cotizacion", ["Vact_cotizacion_data_day"]),
    async add() {
      if (getPermission(COTIZACION).permiso[PUEDE_AGREGAR]) {
        const e = await this.Vact_cotizacion_data_day();
        if (e) return (this.crud.add = true);
        if (!e) return (this.crud.cotizacion = true);
      } else {
        this.crud.add = true;
      }
    },
    editar(item) {
      this.crud.datos = JSON.parse(JSON.stringify(item));
      this.crud.add = true;
    },
    eliminar(item) {
      this.crud.datos = JSON.parse(JSON.stringify(item));
      this.crud.delete = true;
    }
  },
};
</script>
