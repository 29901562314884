<template>
  <div>
    <v-form ref="form" lazy-validation>
      <v-row row dense>
        <v-col cols="12" v-if="modo">
          <AutocompleteAnimal
            :key="irregular"
            :action="historico ? 'fetchTodos' : irregular ? 'fetchAnimalHembra' : 'fetchParaSecado'"
            :getter="historico ? 'getTodos' : irregular ? 'getHembra' : 'getParaSecado'"
            id="input1"
            no-create
            v-model="data.idanimal.idanimal"
            :animal="animal"
            @keyup.native.enter="nextfocus(1, data.idanimal.idanimal)"
          />
        </v-col>

        <v-col cols="12" v-if="operacion">
          <c-text-date
            label="Fecha"
            id="input2"
            dense
            outlined
            v-model="data.fecssecado"
            @keyup.native.enter="nextfocus(2, data.fecssecado)"
          />
        </v-col>

        <v-col cols="12" v-if="!operacion">
          <c-text-date
            label="Fecha Fin"
            id="input2"
            dense
            outlined
            v-model="data.fechfin"
            @keyup.native.enter="nextfocus(3, data.fechfin)"
          />
        </v-col>

        <v-col cols="12">
          <v-text-field
            dense
            type="text"
            id="input3"
            v-model="data.comnsecado"
            label="Comentarios"
            outlined
            autocomplete="off"
            @keyup.enter="nextfocus(3, '0')"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import { current_date } from "@/services/util/date.service";
import { focus } from "@/services/focus.service";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    AutocompleteAnimal: () =>
      import("@/views/Animal/Animal/AutocompleteAnimal"),
  },
  props: {
    props: Object,
    irregular: {
      type: Boolean,
      default: false,
    },
    historico: {
      type: Boolean,
      default: false
    },
    xidanimal: {
      type: Number,
      default: null,
    },
    modo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      data: {
        fecssecado: current_date(),
        fechsecado: current_date(),
        fechfin: null,
        comnsecado: "",
        idanimal: {
          idanimal: "",
        },
      },
      default: {
        fecssecado: current_date(),
        fechsecado: current_date(),
        fechfin: null,
        comnsecado: "",
        idanimal: {
          idanimal: "",
        },
      },
      operacion: true,
      animal: null,
    };
  },

  computed: {
    ...mapGetters("secado", ["getSecadoId"]),
  },
  async created() {
    if (this.xidanimal != null) this.data.idanimal.idanimal = this.xidanimal;
    this.nuevo(this.props);
  },
  mounted() {
    if (this.modo) {
      setTimeout(() => document.getElementById("input1").focus(), 500);
    } else {
      setTimeout(() => document.getElementById("input2").focus(), 500);
    }
  },
  methods: {
    ...mapActions("secado", ["fetchSecadoId"]),
    nextfocus(index, data) {
      focus.nextid(index, data);
    },

    validate() {
      return this.$refs.form.validate();
    },
    obtenerDatos() {
      if (this.data.comnsecado === "") {
        this.data.comnsecado = null;
      }
      return this.data;
    },

    nuevo(val) {
      if (val) {
        this.data = JSON.parse(JSON.stringify(val));
        this.animal = JSON.parse(JSON.stringify(this.data.idanimal));
        this.operacion = false;
      }
    },
  },
};
</script>
