<template>
  <div>
    <div v-if="crud.add">
      <Crud
        v-model="crud.add"
        :datos="crud.datos"
        @datos="crud.datos = $event"
      />
    </div>
    <div v-if="crud.view">
      <Crud
        readonly
        v-model="crud.view"
        :datos="crud.datos"
        @datos="crud.datos = $event"
      />
    </div>
    <div v-if="abrircotizacion">
      <Finanzas-Cotizacion-CreateV2 v-model="abrircotizacion" />
    </div>
    <v-card>
      <v-alert type="error" v-if="permission_alert"
        >Error!!! Este usuario no tiene permisos en esta ventana</v-alert
      >
      <v-toolbar flat dense>
        <v-toolbar-title>{{ titulo }}</v-toolbar-title>
        <widgets-Favorite ruta="/compra" formulario="Compra" />
        <v-divider class="mx-2" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          :append-icon="search.length > 0 ? 'cloud_download' : 'search'"
          placeholder="Busqueda por Razon Social, Ruc y Factura"
          label="Busqueda"
          single-line
          hide-details
          @click:append="busqueda()"
          v-if="permission.can_view"
          class="mr-1"
        ></v-text-field>

        <widgets-filteradvanced
          v-model="searchadvanced"
          :campos="campos"
          @resp="datos = $event"
          url="/apicomprasearch/?band=compra"
        />

        <v-toolbar-items>
          <v-speed-dial v-model="opcion" direction="left" class="mr-1">
            <template v-slot:activator>
              <v-btn v-model="opcion" height="100%" small tile color="red" dark>
                <v-icon v-if="opcion">keyboard_arrow_right</v-icon>
                <v-icon v-else>keyboard_arrow_left</v-icon>
              </v-btn>
            </template>
            <v-btn fab dark small color="indigo" @click="searchadvanced = true">
              <v-icon>zoom_in</v-icon>
            </v-btn>
            <v-btn fab dark small color="green" @click="generarCsv()">
              <v-icon>grid_on</v-icon>
            </v-btn>
            <v-btn fab dark small color="red" @click="generarPdf()">
              <v-icon>get_app</v-icon>
            </v-btn>
          </v-speed-dial>
          <c-btn-table-add
            @click="abrir_compra()"
            v-if="permission.can_add"
          ></c-btn-table-add>
        </v-toolbar-items>
      </v-toolbar>
      <v-data-table
        :loading="Vget_compra_load"
        :headers="headers"
        :items="datos"
        :search="search"
        class="elevation-1"
        v-if="permission.can_view"
      >
        <template v-slot:[`item.totalCompra`]="props">
          <p class="font-weight-black text-end pa-0 ma-0">
            {{ currency(props.item.totalCompra) }}
          </p>
        </template>
        <template v-slot:[`item.accion`]="props">
          <v-icon class="mr-2" @click="rowselect(props.item)"
            >visibility</v-icon
          >
          <v-icon
            class="mr-2"
            @click="mupdate(props.item)"
            :disabled="props.item.anulcompra"
            >edit</v-icon
          >
          <v-icon
            small
            @click="meliminar(props.item)"
            v-if="permission.can_delete"
            :disabled="props.item.anulcompra"
            >block</v-icon
          >
        </template>
        <template v-slot:no-data>
          <v-alert :value="true" color="info" icon="info" outlined
            >No existe ningun registro</v-alert
          >
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="dialogoeliminar" persistent max-width="350">
      <v-card>
        <v-card-title>
          <v-icon left>info</v-icon>
          <span class="title font-weight-light">Ventana de Confirmacion</span>
        </v-card-title>
        <v-card-text>Desea eliminar {{ objeto.numfcompra }}?</v-card-text>
        <v-card-actions>
          <v-btn color="red" text @click="eliminarcancelar">
            <v-icon>clear</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="meliminarguardar">
            <v-icon>done</v-icon>
          </v-btn>
        </v-card-actions>
        <v-overlay :value="overlay">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { mensaje } from "@/services/notify.service";
import { exportTo } from "@/services/export.service";
import { currency } from "@/services/util/number.service";
import { COMPRA, COTIZACION } from "@/constants/forms";
import Crud from "./Create";
import {
  PUEDE_AGREGAR,
  PUEDE_EDITAR,
  PUEDE_ELIMINAR,
  PUEDE_LISTAR,
} from "@/constants/permissions";
export default {
  components: {
    Crud,
  },
  data() {
    return {
      opcion: false,
      searchadvanced: false,
      overlay: false,
      search: "",
      crud: {
        add: false,
        view: false,
        datos: null,
      },
      abrircotizacion: false,
      campos: [
        {
          name: "Proveedor",
          api: "/apicontacto/?tipo=proveedor",
          id: "idcontacto",
          desc: "desccontacto",
          type: "select",
          query: [
            {
              comparison: "",
              value: "",
              logical: "",
              add: false,
            },
          ],
        },
        {
          name: "Deposito",
          api: "/apideposito/",
          id: "iddeposito",
          desc: "desdeposito",
          type: "select",
          query: [
            {
              comparison: "",
              value: "",
              logical: "",
              add: false,
            }
          ]
        },
        {
          name: "Tipo Factura",
          api: "/apitipofactura/",
          id: "idtipofactura",
          desc: "desctipofactura",
          type: "select",
          query: [
            {
              comparison: "",
              value: "",
              logical: "",
              add: false,
            },
          ],
        },
        {
          name: "Nro Factura",
          value: "numfcompra",
          type: "text",
          query: [
            {
              comparison: "",
              value: "",
              logical: "",
              add: false,
            },
          ],
        },
        {
          name: "Moneda",
          api: "/apimoneda/",
          id: "idmoneda",
          desc: "descmoneda",
          type: "select",
          query: [
            {
              comparison: "",
              value: "",
              logical: "",
              add: false,
            },
          ],
        },
        {
          name: "Fecha Compra",
          value: "fechcompra",
          type: "date",
          query: [
            {
              comparison: "",
              value: "",
              logical: "",
              add: false,
            },
          ],
        },
        {
          name: "Timbrado",
          value: "timb_compra",
          type: "text",
          query: [
            {
              comparison: "",
              value: "",
              logical: "",
              add: false,
            },
          ],
        },
      ],
      headers: [
        { text: "Fecha", align: "left", value: "fechcompra" },
        {
          text: "Proveedor",
          align: "left",
          value: "idcontacto.idcontacto.desccontacto",
        },
        { text: "Nro Factura", align: "left", value: "numfcompra" },
        {
          text: "Condicion",
          align: "left",
          value: "idtipofactura.desctipofactura",
        },
        { text: "Moneda", align: "left", value: "idmoneda.descmoneda" },
        { text: "Total", align: "left", value: "totalCompra" },
        { text: "Accion", value: "accion", sortable: false, align: "right" },
      ],
      datos: [],
      url: "/apicompra/",
      titulo: COMPRA,
      objeto: "",
      index: "",
      dialogoeliminar: false,
      load: false,
      permission: {
        can_add: false,
        can_view: false,
        can_update: false,
        can_delete: false,
      },
    };
  },

  watch: {
    $route(to, from) {
      if (from.path === "/compra") return (this.abrircotizacion = false);
    },
    Vget_compra_data(val) {
      this.datos = JSON.parse(JSON.stringify(val));
    },
  },
  created() {
    this.$store.commit("SET_LAYOUT", "principal-layout");
    const permission = getPermission(COMPRA).permiso;
    this.permission = {
      can_add: permission[PUEDE_AGREGAR],
      can_view: permission[PUEDE_LISTAR],
      can_update: permission[PUEDE_EDITAR],
      can_delete: permission[PUEDE_ELIMINAR],
    };
    this.Vact_compra_data();
  },
  computed: {
    ...mapGetters("compra", ["Vget_compra_data", "Vget_compra_load"]),
    permission_alert() {
      return mensaje.permission(this.permission);
    },
  },

  methods: {
    ...mapActions("compra", ["Vact_compra_data", "Vact_compra_del"]),
    ...mapActions("cotizacion", ["Vact_cotizacion_data_day"]),
    ...mapActions("stock", ["Vact_stock_data"]),
    currency(value) {
      return currency(value);
    },
    async busqueda() {
      this.Vact_compra_data(this.search)
    },
    generarPdf() {
      let head = [
        ["Fecha", "Cliente", "Nro Factura", "Condicion", "Moneda", "Total"],
      ];
      let body = [];
      this.datos.map((x) => {
        body.push([
          x.fechcompra,
          x.idcontacto.idcontacto.desccontacto,
          x.regi_compra + "-" + x.numfcompra,
          x.idtipofactura.desctipofactura,
          x.idmoneda.descmoneda,
          x.totalCompra,
        ]);
      });
      exportTo.pdftable(head, body);
    },
    generarCsv() {
      let data = [];
      this.datos.map((x) => {
        data.push({
          Fecha: x.fechcompra,
          Cliente: x.idcontacto.idcontacto.desccontacto,
          Factura: x.regi_compra + "-" + x.numfcompra,
          Condicion: x.idtipofactura.desctipofactura,
          Moneda: x.idmoneda.descmoneda,
          Total: x.totalCompra,
        });
      });
      exportTo.xlsx(data);
    },
    async abrir_compra() {
      if (getPermission(COTIZACION).permiso[PUEDE_AGREGAR]) {
        const e = await this.Vact_cotizacion_data_day();
        if (e) return (this.crud.add = true);
        if (!e) return (this.abrircotizacion = true);
      } else {
        this.crud.add = true;
      }
    },
    meliminar(item) {
      this.index = this.datos.indexOf(item);
      this.objeto = Object.assign({}, item);
      this.dialogoeliminar = true;
    },
    mupdate(val) {
      this.crud.datos = val;
      this.crud.add = true;
    },
    rowselect(val) {
      this.crud.datos = val;
      this.crud.view = true;
    },
    eliminarcancelar() {
      this.index = "";
      this.objeto = "";
      this.dialogoeliminar = false;
    },
    async meliminarguardar() {
      this.overlay = true;
      const id = this.objeto.idcompra;
      const a = await this.Vact_compra_del(id);
      if (a.estado == true) {
        this.datos.splice(this.index, 1);
        this.$notify(a.info);
        this.dialogoeliminar = false;
        this.Vact_stock_data();
        this.eliminarcancelar();
      } else {
        a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
      this.overlay = false;
    },
  },
};
</script>
