<template>
  <div>
    <v-toolbar dense flat>
      <v-toolbar-title>{{ $route.name }}</v-toolbar-title>
      <widgets-Favorite :ruta="$route.path" :formulario="$route.name" />
      <v-divider class="mx-4" inset vertical></v-divider>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-card-text>
      <v-form ref="form">
        <v-row dense>
          <div class="title mt-1 d-none d-sm-flex">Filtrar</div>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="3">
            <v-text-field
              hide-details
              placeholder="Busqueda"
              rounded
              dense
              filled
              :value="value"
              @input="$emit('input', $event)"
            ></v-text-field>
          </v-col>
          <v-col cols="8" sm="4">
            <AutocompleteFilter ref="filter" v-model="fetchSelected" />
          </v-col>
          <v-spacer class="hidden-sm-and-up"></v-spacer>
          <v-speed-dial v-model="opcion" direction="left" class="mr-1">
            <template v-slot:activator>
              <v-btn v-model="opcion" class="mr-1 mt-1" fab small elevation="1">
                <v-icon v-if="opcion">keyboard_arrow_right</v-icon>
                <v-icon v-else>keyboard_arrow_left</v-icon>
              </v-btn>
            </template>
            <v-btn
              class="mr-1 mt-1"
              fab
              small
              elevation="1"
              color="red"
              @click="generarPdf()"
            >
              <v-icon>get_app</v-icon>
            </v-btn>
            <v-btn
              class="mr-1 mt-1"
              fab
              small
              elevation="1"
              color="green"
              @click="generarExcel()"
            >
              <v-icon>grid_on</v-icon>
            </v-btn>
          </v-speed-dial>
          <v-btn
            class="mr-1 mt-1"
            fab
            small
            elevation="1"
            color="grey lighten-2"
            @click="filterAnimales();parametersChange();"
            ><v-icon>cloud_download</v-icon></v-btn
          >
          <v-btn
            fab
            class="mr-1 mt-1"
            small
            elevation="1"
            color="primary"
            @click="$emit('click')"
            v-if="permission"
            ><v-icon>add</v-icon></v-btn
          >
          <FiltroTabla :band="band" class="mt-1" />
        </v-row>
      </v-form>
    </v-card-text>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import store from "@/store";
import AutocompleteFilter from "./AutocompleteFilter";
import FiltroTabla from "./FiltroTabla";
import { exportTo } from "@/services/export.service";
import { dmy } from "@/services/util/date.service";
export default {
  components: {
    AutocompleteFilter,
    FiltroTabla,
  },
  props: {
    value: String,
    listAnimal: Array,
    permission: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    opcion: false,
    band: null,
    fetchSelected: {
      text: "Disponibles",
      action: "fetchAnimalByEstado",
      getter: "getAnimalByEstado",
      band: "disponible",
      idestado: 2,
    },
    reload: false,
  }),
  watch: {
    $route(to) {
      if (to.path === "/animal") {
        this.handlerQueryRoute();
        this.fetchConfigGanaderia();
      }
    },
  },
  mounted() {
    this.handlerQueryRoute();
    this.fetchConfigGanaderia();
    this.filterAnimales();
  },
  methods: {
    ...mapActions("configganaderia", ["fetchConfigGanaderia"]),
    formatDate(date) {
      if (date != 'N/A') {
        return dmy(date);
      }
      return date
    },
    formatWdays(date) {
      if (date != 'N/A') {
        return this.formatDate(date[0]) + " / " + date[1]
      }
      return date
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    async generarPdf() {
      this.$emit('itempage', -1);
      await this.sleep(1000)
      var html = document.querySelector('table');
      var data = []
      let head = [this.$store.getters["configganaderia/getColumnsAnimal"][`${this.band}`]]

      for (var x=1; x<html.rows.length; x++) {
        var tableRow = html.rows[x];
        var rowData = [];
        for (var j=0; j<tableRow.cells.length - 1; j++) {
          if (tableRow.cells[j].children.length > 1) {
            rowData.push(this.case_exect(tableRow.cells[j].children[1].innerHTML, head[0][j]));
          } else {
            rowData.push(this.case_exect(tableRow.cells[j].innerHTML, head[0][j]));
          }
        }
        data.push(rowData)
      }
      exportTo.pdftable(head, data, `Animal-${this.band}`)
    },
    async generarExcel() {
      this.$emit('itempage', -1);
      await this.sleep(1000)
      var html = document.querySelector('table');
      var data = []
      let head = [this.$store.getters["configganaderia/getColumnsAnimal"][`${this.band}`]]
      for (var x=1; x<html.rows.length; x++) {
        var tableRow = html.rows[x];
        var rowData = {};
        for (var j=0; j<tableRow.cells.length - 1; j++) {
          if (tableRow.cells[j].children.length > 1) {
            rowData[`${head[0][j]}`] = this.case_exect_excel(tableRow.cells[j].children[1].innerHTML, head[0][j]);
          } else {
            rowData[`${head[0][j]}`] = this.case_exect_excel(tableRow.cells[j].innerHTML, head[0][j]);
          }
        }
        data.push(rowData)
      }
      exportTo.xlsx(data)
    },
    case_exect_excel(x, y) {
      let data = ''
      switch(y) {
        case 'Sexo':
          data = x.replace(/<div>/gi, '').replace(/<\/div>/gi, '').replace(/<!---->/gi, '')
          break
        case 'Informacion':
          data = x.replace(/<strong>/gi, '').replace(/<\/strong>/gi, '').replace(/<div>/gi, '').replace(/<\/div>/gi, '').replace(/<div class="">/gi, '')
          break
        case 'Fecha Nacimiento':
          data = x.replace(/<div>/gi, '').replace(/<\/div>/gi, '')
          break
        case 'Fecha Parto':
          data = x.replace(/<div>/gi, '').replace(/<\/div>/gi, '')
          break
        case 'Fecha Inseminacion':
          data = x.replace(/<div>/gi, '').replace(/<\/div>/gi, '')
          break
        case 'Dias Abiertos':
          data = x.replace(/<div>/gi, '').replace(/<\/div>/gi, '')
          break
        default:
          data = x
          break
      }
      return data
    },
    case_exect(x, y) {
      let data = []
      switch(y) {
        case 'Sexo':
          data.push(x.replace(/<div>/gi, '').replace(/<\/div>/gi, '').replace(/<!---->/gi, ''))
          break
        case 'Informacion':
          data.push(x.replace(/<strong>/gi, '').replace(/<\/strong>/gi, '').replace(/<div>/gi, '').replace(/<\/div>/gi, '').replace(/<div class="">/gi, ''))
          break
        case 'Fecha Nacimiento':
          data.push(x.replace(/<div>/gi, '').replace(/<\/div>/gi, ''))
          break
        case 'Fecha Parto':
          data.push(x.replace(/<div>/gi, '').replace(/<\/div>/gi, ''))
          break
        case 'Fecha Inseminacion':
          data.push(x.replace(/<div>/gi, '').replace(/<\/div>/gi, ''))
          break
        case 'Dias Abiertos':
          data.push(x.replace(/<div>/gi, '').replace(/<\/div>/gi, ''))
          break
        default:
          data.push(x)
          break
      }
      return data
    },
    async filterAnimales() {
      this.band = this.fetchSelected.band;
      //if (!this.$refs.form.validate()) return null;
      if (this.fetchSelected.action === "fetchNomina") {
        await store.dispatch("animal/" + this.fetchSelected.action, {});
      } else {
        await store.dispatch(
          "animal/" + this.fetchSelected.action,
          this.fetchSelected.idestado || null
        );
      }
      this.$emit("listAnimal", this.getAnimales);
      this.$emit("chlist", this.band);
      //this.fetchConfigGanaderia();
    },
    async handlerQueryRoute() {
      if (this.$route.query.action) {
        const estado = this.$refs.filter.estados;
        const query = this.$route.query;
        const filter = query.idestado
          ? estado.find(({ idestado }) => idestado === Number(query.idestado))
          : estado.find(({ action }) => action === query.action);
        this.fetchSelected = JSON.parse(JSON.stringify(filter));
        await this.filterAnimales();
      }
    },
    parametersChange() {
      this.$router.push({query: {action: this.fetchSelected.action, idestado: this.fetchSelected.idestado || null}});
    }
  },
  computed: {
    getAnimales() {
      return store.getters["animal/" + this.fetchSelected.getter];
    },
  },
};
</script>
