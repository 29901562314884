
export default {
  Vget_tarjetacredito_data: (state) => {
    return state.tarjetacredito_data
  },
  Vget_tarjetacredito_load: (state) => {
    return state.tarjetacredito_load
  },
  Vget_tarjetacredito_save_load: (state) => {
    return state.tarjetacredito_save_load
  },
}
