import { db } from "@/services/db.service";
export default {
    async gymplanilla_update({commit},data){
        commit('gymplanilla_update',data);
    },

    async Vact_gymplanilla_data({commit}){
        commit('gymplanilla_load',true);
        try {
            const data = await db.get('/apigimnasioplanilla/');
            commit('gymplanilla_data',data);
            commit('gymplanilla_load',false);
            return true
        } catch (e) {
            commit('gymplanilla_load',false);
            return false
        } 
    },
    async Vact_gymplanilla_data_by({commit}, {id}) {
        const data = await db.get('/apigimnasioplanilla/?idcontacto=' + id)
        if (data[0]) {
            commit('gymplanilla_data_by', data[0])
            return true
        } else {
            commit('gymplanilla_data_by', null)
            return false
        }
    },
    async Vact_gymplanilla_save({dispatch, commit},data){
        commit('gymplanilla_save_load',true);
        try {
            const resp = await db.save('/apigimnasioplanilla/',data);
            dispatch('Vact_gymplanilla_data');
            commit('gymplanilla_save_load',false);
            return resp;
        } catch (e) {
            commit('gymplanilla_save_load',false);
            return e
        } 
    },
    async Vact_gymplanilla_update({commit},{id, data}){
        commit('gymplanilla_save_load',true);
        try {
            const resp = await db.update('/apigimnasioplanilla/'+id+'/',data);
            commit('gymplanilla_save_load',false);
            return resp;
        } catch (e) {
            commit('gymplanilla_save_load',false);
            return e
        } 
    },
    async Vact_gymplanilla_del({dispatch},id){
        try {
            const data = await db.delete('/apigimnasioplanilla/'+id+'/');
            dispatch('Vact_gymplanilla_data');
            return data
        } catch (e) {
            return false
        } 
    }
}
