export default {
  tarjetacredito_data(state, request) {
    state.tarjetacredito_data = request;
  }, 
  tarjetacredito_load(state, request) {
    state.tarjetacredito_load = request;
  }, 
  tarjetacredito_save_load(state, request) {
    state.tarjetacredito_save_load = request;
  }, 
}