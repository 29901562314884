<template>
  <v-row dense>
    <v-col
      cols="12"
      lg="3"
      md="4"
      sm="6"
      v-for="(item, i) in get_reporte_async"
      :key="i"
    >
      <v-card @click="selectrow(item.query)">
        <v-card-subtitle>
          {{ item.name }} <v-spacer></v-spacer> {{ item.category }}
        </v-card-subtitle>
        <v-card-text>
          <v-row>
            <v-col class="text-h5" cols="8">
              {{ currencyFormat(item.semillas) }} Kg
            </v-col>
            <v-col class="text-h5" cols="4">
              <v-icon large :color="item.color">grass</v-icon>
            </v-col>
            <v-col cols="12" lg="6" md="12" sm="12">
              Ingreso: {{ currencyFormat(item.ingresos) }}
            </v-col>
            <v-col cols="12" lg="6" md="12" sm="12">
              Gastos: {{ currencyFormat(item.gastos) }}
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ApiService from "@/services/auth/api.service";

export default {
  data: () => ({
    count: [],
    isLoadingSocket: false,
    mountedFirst: true,
  }),
  mounted() {
    this.fetch_reporte_async();
  },
  watch: {
    $route(to) {
      if (to.path === "/dashboard") this.fetch_reporte_async();
      if (to.path === "/") this.fetch_reporte_async();
    },
  },
  computed: {
    ...mapGetters("campana", ["get_reporte_async"]),
  },
  methods: {
    ...mapActions("campana", ["fetch_reporte_async"]),
    currencyFormat(value) {
      return new Intl.NumberFormat({ maximumSignificantDigits: 3 }).format(
        value
      );
    },
    connectWebSocket() {
      this.isLoadingSocket = true;
      const wsRoute = "/ws/general/agricultura/?token=" + this.getAccessToken;
      const [protocol, hostWithRoute] = ApiService.getBaseURL().split("//");
      const [wsHost] = hostWithRoute.split("/");
      const wsProtocol = protocol === "https:" ? "wss://" : "ws://";
      this.connection = new WebSocket(wsProtocol + wsHost + wsRoute);
      const self = this;

      this.connection.onmessage = (event) => {
        self.setSocketData(JSON.parse(event.data));
      };
      this.connection.onopen = () => {
        self.isLoadingSocket = false;
        self.setSocketData(null);
        console.info("Successfully connected websocket");
      };
      this.connection.onerror = () => {
        self.setSocketData(null);
        self.isLoadingSocket = false;
        console.error("Failed connected websocket");
      };
      this.connection.onclose = (event) => {
        console.log("WebSocket connection closed:", event.code);
      };
    },
    selectrow(item) {
      this.$router.push({
        path: "/agricultura/campo/detalle/" + item.idlote_agri,
        query: { polygon: item.id_polygon },
      });
    },
  },
};
</script>
