<template>
  <div>
    <v-dialog :value="value" max-width="600" persistent>
      <v-card>
        <v-card-title>Detalle de Sucursal</v-card-title>
        <v-card-text>
          <c-text-field dense readonly outlined label="Nombre" v-model="data.nombtambo"></c-text-field >
          <c-text-field dense readonly outlined label="Empresa" v-model="data.idempresa.descempresa"></c-text-field >
          <c-text-field dense readonly outlined label="Telefono" v-model="data.teletambo"></c-text-field >
          <c-text-field dense readonly outlined label="Correo" v-model="data.corrtambo"></c-text-field >
          <c-text-field dense readonly outlined label="Direccion" v-model="data.diretambo"></c-text-field >
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close()">
            <v-icon dark>close</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {

  props: {
    vuex: { type: Object },
    value: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      header: "Detalle",
      data: {
        idempresa: {
          idempresa: ""
        }
      },
      default: {
        idempresa: {
          idempresa: ""
        }
      },
      id: "",
      operacion: true
    };
  },
  computed:{
    ...mapGetters('tambo',{
      update_tambo: "tambo_update"
    })
  },
  watch: {
    $route(to, from) {
      if (from.path === "/tambo") return this.close();
    }
  },
  created() {
    this.update(this.update_tambo);
  },
  methods: {
    ...mapActions('tambo',["tambo_update"]),
    close() {
      this.data = JSON.parse(JSON.stringify(this.default));
      this.tambo_update(null);
      this.$emit('input',false);
      this.operacion = true;
    },
    update(data) {
      if (data === null) return null;
      this.data = data;
    }
  }
};
</script>