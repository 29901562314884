import { get, post, put, del } from "@/services/api/api.service";
import {
  LOADING,
  SET_TIPO,
  SET_PLAGAS,
  url,
  SET_COORD,
  SET_ID,
  SET_PLAGA_ZONA,
} from "./constants";
export default {
  async fetchTipoPlaga({ commit, dispatch }) {
    commit(LOADING, true);
    try {
      const response = await get(url.plagas);
      commit(SET_TIPO, response);
    } catch (e) {
      dispatch("snackbar/setNotification", e, { root: true });
      commit(LOADING, false);
      throw e;
    }
    commit(LOADING, false);
  },
  fetchPlagaZona: async ({ commit, dispatch },id) => {
    commit(LOADING, true);
    try {
        const response = await get(`${url.plagazona}${id}`);
        commit(SET_PLAGA_ZONA, response);
      } catch (e) {
        dispatch("snackbar/setNotification", e, { root: true });
        commit(LOADING, false);
        throw e;
      }
      commit(LOADING, false);
  },
  async fetchPlaga({ commit, dispatch }) {
    commit(LOADING, true);
    try {
      const response = await get(url.regplagas);
      commit(SET_PLAGAS, response);
    } catch (e) {
      dispatch("snackbar/setNotification", e, { root: true });
      commit(LOADING, false);
      throw e;
    }
    commit(LOADING, false);
  },
  async setPlaga({ commit, dispatch }, data) {
    commit(LOADING, true);
    const response = await post(url.regplagas, data);
    dispatch("snackbar/setNotification", response, { root: true });
    commit(LOADING, false);
    return response;
  },
  async setPlagaUpdate({ commit, dispatch }, { data, id }) {
    commit(LOADING, true);
    const response = await put(`${url.regplagas}${id}/`, data);
    dispatch("snackbar/setNotification", response, { root: true });
    commit(LOADING, false);
    return response;
  },

  setCoordenadaPlaga: async ({ commit }, coordenada) =>
    commit(SET_COORD, coordenada),
  fetchPlagaId: async ({ commit }, { id, data }) => {
    if (data) return commit(SET_ID, data);
    commit(LOADING, true);
    try {
      const response = await get(`${url.regplagas}${id}/`);
      commit(SET_ID, response);
    } catch (e) {
      console.log(e);
    }
    commit(LOADING, false);
    return;
  },
  async plagaDelete({ commit, dispatch }, id) {
    commit(LOADING, true);
    const response = await del(`${url.regplagas}${id}/`);
    dispatch("snackbar/setNotification", response, { root: true });
    commit(LOADING, false);
    return response;
  },
};
