<template>
  <div>
    <v-form ref="form">
      <v-row dense>
        <v-col cols="12">
          <v-autocomplete
            dense
            v-model="data.idviaaplicacion.idviaaplicacion"
            ref="input1"
            :rules="validars"
            :loading="loadvia"
            v-bind:items="via"
            fkdes="item"
            label="Seleccione Via Aplicacion"
            item-text="fkdes"
            item-value="id"
            required
            outlined
            @keyup.enter="$refs.input2.focus()"
            :no-data-text="`No existe ninguna concidencia`"
            small-chips
          >
            <template v-if="via_permission" v-slot:no-data>
              <v-list-item @click="viaaplicacion_create(true)">
                <v-list-item-content>
                  <v-list-item-title
                    >No existe concidencia. Click para
                    agregar</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row> </v-form
    ><v-form ref="formDetail">
      <v-row>
        <v-col cols="10">
          <AutocompleteTipoEnfermedad
            v-model="medicamento.idtipoenfermedad"
            returnObject
            @keyup.native.enter="$refs.input3.$el.focus()"
            ref="input2"
          />
        </v-col>

        <v-spacer></v-spacer>
        <v-col cols="1">
          <v-btn icon ref="input3" @click="addtabla()">
            <br />
            <v-icon color="blue">add_circle</v-icon>
          </v-btn>
        </v-col>

        <v-col>
          <v-data-table
            hide-default-footer
            :headers="headers"
            :items="data.medicamento"
            class="elevation-1"
          >
            <template v-slot:item="props">
              <tr @click="rowselect(props.item)">
                <td class="text-xs-letf">
                  {{ props.item.idtipoenfermedad.descenfermedad }}
                </td>
                <td class="justify-right">
                  <v-icon small @click="rowdelete(props.item)">delete</v-icon>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { db } from "@/services/db.service";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { VIA_APLICACION } from "@/constants/forms";
import { PUEDE_AGREGAR } from "@/constants/permissions";
export default {
  props:{
    props: [Object,Array]
  },
  components: {
    AutocompleteTipoEnfermedad: () =>
      import("@/views/Enfermedad/Tipoenfermedad/Autocomplete"),
  },
  data() {
    return {
      data: {
        idviaaplicacion: {
          idviaaplicacion: "",
        },
        medicamento: [],
      },
      default: {
        idviaaplicacion: {
          idviaaplicacion: "",
        },
        medicamento: [],
      },
      medicamento: {
        idtipoenfermedad: {
          idtipoenfermedad: "",
          descenfermedad: "",
        },
      },
      headers: [
        { text: "Tipo Enfermedad", sortable: false },
        { text: "Accion", sortable: false },
      ],
      validars: [(v) => !!v || "Seleccione un campo"],
      loadvia: true,
      via: [],
    };
  },
  created() {
    this.nuevo_via();
    if(this.props)
    this.data = JSON.parse(JSON.stringify(this.props));
  },
  watch: {
    reload_via() {
      this.nuevo_via();
    },
  },
  computed: {
    ...mapGetters("viaaplicacion", {
      abrirvia: "viaaplicacion_create",
      reload_via: "viaaplicacion_key",
    }),

    via_permission() {
      return getPermission(VIA_APLICACION).permiso[PUEDE_AGREGAR];
    },
  },
  methods: {
    ...mapActions("medicamento", [
      "medicamento_update",
    ]),
    ...mapActions("viaaplicacion", ["viaaplicacion_create"]),
    validate() {
      return this.$refs.form.validate();
    },
    obtenerDatos() {
      return this.data;
    },

    addtabla() {
      if (!this.$refs.formDetail.validate()) return null;
      const result = this.data.medicamento.find(
        ({ idtipoenfermedad }) =>
          idtipoenfermedad.idtipoenfermedad ===
          this.medicamento.idtipoenfermedad.idtipoenfermedad
      );
      if (result) return null;
      this.data.medicamento.push(this.medicamento);
      this.medicamento = {
        idtipoenfermedad: { idtipoenfermedad: "", descenfermedad: "" },
      };
      this.$refs.formDetail.resetValidation();
      setTimeout(() => this.$refs.input2.focus(), 200);
    },
    rowselect(item) {
      this.index = this.data.medicamento.indexOf(item);
      this.medicamento = item;
    },
    rowdelete(item) {
      this.index = this.data.medicamento.indexOf(item);
      this.data.medicamento.splice(this.index, 1);
      this.index = null;
    },
    nuevo_via() {
      db.get("/apiviaaplicacion/").then((b) => {
        b.map((element) => {
          this.via.push({
            id: element.idviaaplicacion,
            fkdes: element.descviaaplicacion,
          });
        });
        this.loadvia = false;
      });
    }
  },
};
</script>
