<template>
  <div>
    <div class="text-center" v-if="actived">
      <v-btn
        color="red lighten-2"
        dark
        @click="cancelar"
      >Cancelar edicion</v-btn>
    </div>
    <v-expansion-panels class="mb-6">
      <v-expansion-panel>
        <v-expansion-panel-header disable-icon-rotate>
          Mostrar items desactivados - Existen:
          <span class="font-weight-bold">{{ cantidad }}</span>
          <template v-slot:actions>
            <v-icon color="error" v-if="cantidad == 0">
              info
            </v-icon>
            <v-icon color="teal" v-else> done </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-list two-line dense>
            <v-list-item-group v-model="selected" active-class="blue--text">
              <div v-for="(item, index) in items" :key="index">
                <v-list-item :key="item.action">
                  <template>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.title }}
                      </v-list-item-title>

                      <v-list-item-subtitle
                        class="text--primary">
                         {{ item.headline }}
                      </v-list-item-subtitle>

                      <v-list-item-subtitle>
                        {{ item.subtitle }}
                      </v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-list-item-action-text>
                        {{ item.action }}
                      </v-list-item-action-text>

                      <c-btn-table-edit
                        @click="mupdate(item.data)"
                        v-if="permission.can_update"
                      />
                    </v-list-item-action>
                  </template>
                </v-list-item>
                <v-divider
                  v-if="index < items.length - 1"
                  :key="`a${index}`"
                ></v-divider>
              </div>
            </v-list-item-group>
          </v-list>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-progress-linear
        :active="Vget_item_load"
        :indeterminate="Vget_item_load"
        absolute
        bottom
      ></v-progress-linear>
    </v-expansion-panels>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { ITEM } from "@/constants/forms";
import { PUEDE_EDITAR } from "@/constants/permissions";
export default {
  props: {
    buscar: String,
  },
  data: () => ({
    actived: false,
    selected: [],
    items: [],
    categorias: [],
    tipoitems: [],
    marcas: [],
    objetivos: [],
    unidades: [],
    monedas: [],
    awaitingSearch: false,
    cantidad: 0,
    noexiste: [
      {
        action: "Actual",
        headline: "Puede agregar el item",
        subtitle: "No se encontro item desactivado",
        title: "Atencion",
        data: null,
      },
    ],
    permission: {
      can_update: false,
    },
  }),
  watch: {
    Vget_moneda_data(val) {
      this.monedas = val
    },
    getObjetivo(val) {
      this.objetivos = val
    },
    Vget_marca_data(val) {
      this.marcas = val
    },
    Vget_unidadmedida_data(val) {
      this.unidades = val
    },
    Vget_tipoitem_data(val) {
      this.tipoitems = val
    },
    Vget_categoria_data(val) {
      this.categorias = val
    },
    Vget_item_senave(val) {
      if (val.length == 0) {
        this.items = this.noexiste;
      } else {
        this.items = [];
        val.forEach((element) => {
          let detail = ""
          let detalle = []
          element.quimica.forEach((x) => {
            if (x.porcentaje) {
              detail = detail + x.name + " " + x.porcentaje + "; "
            } else {
              detail = detail + x.name + ";"
            }
            detalle.push({
              descripcion: x.name,
              porcentaje: x.porcentaje
            })
          })
          let categoria = this.categorias.find(function(e) {
            return e.idcategoriaitem == element.categoria;
          });
          let tipo = this.tipoitems.find(function(e) {
            return e.idtipo_item == element.tipo;
          });
          let unidad = this.unidades.find(function(e) {
            return e.idunidadmedida == element.unidadmedida;
          });
          let marca = this.marcas.find(function(e) {
            return e.idmarca == element.marca;
          });
          let objetivo = this.objetivos.find(function(e) {
            return e.descripcion == element.objetivo;
          });
          let moneda = this.monedas.find(function(e) {
            return e.idmoneda == 1
          });
          if (marca == undefined) {
            marca = {idmarca: null}
          }
          this.items.push({
            action: element._id,
            headline: `${categoria.desccategoriaitem} - ${tipo.desctipo_item}`,
            subtitle: detail,
            title: element.name,
            data: this.item_serializer(element, categoria, tipo, unidad, marca, objetivo, moneda, detalle),
          });
        });
      }
      this.cantidad = val.length;
    },
    
    // Vget_item_agrostock_todos(val) {
    //   if (val.length == 0) {
    //     this.items = this.noexiste;
    //   } else {
    //     this.items = [];
    //     val.forEach((element) => {
    //       let detail = ""
    //       element.itemquimica.forEach((x) => { 
    //         if (x.porcentaje) {
    //           detail = detail + x.idpropiedad_quimica.descripcion + " " + x.porcentaje + "; "
    //         } else {
    //           detail = detail + x.idpropiedad_quimica.descripcion + ";"
    //         }
    //       })
    //       this.items.push({
    //         action: element.iditem,
    //         headline: `${element.idcategoriaitem.desccategoriaitem} - ${element.idtipo_item.desctipo_item}`,
    //         subtitle: detail,
    //         title: element.descitem,
    //         data: element,
    //       });
    //     });
    //   }
    //   this.cantidad = val.length;
    // },
    buscar() {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.obtain_item();
          this.awaitingSearch = false;
        }, 1000);
      }
      this.awaitingSearch = true;
    },
  },
  mounted() {
    this.obtain_item();
  },
  computed: {
    ...mapGetters("item", ["Vget_item_agrostock_todos", "Vget_item_load", "Vget_item_senave", "Vget_item_senave_load"]),
    ...mapGetters("categoria", ["Vget_categoria_data"]),
    ...mapGetters("item", ["Vget_tipoitem_data"]),
    ...mapGetters("unidadmedida", ["Vget_unidadmedida_data"]),
    ...mapGetters("marca", ["Vget_marca_data"]),
    ...mapGetters("objetivo", ["getObjetivo"]),
    ...mapGetters("moneda", ["Vget_moneda_data"]),
  },
  created() {
    const permission = getPermission(ITEM).permiso;
    this.permission = {
      can_update: permission[PUEDE_EDITAR],
    };
  },
  methods: {
    ...mapActions("item", ["Vact_item_agrostock_todos", "item_update", "Vact_item_senave"]),
    item_serializer(val, categoria, tipo, unidad, marca, objetivo, moneda, detalle) {
      let data = {
        iditem: 0,
        idcategoriaitem: categoria,
        idtipo_item: tipo,
        idunidadmedida: unidad,
        cant_contiene: 0,
        descitem: val.name,
        idmarca: marca,
        idmoneda: moneda,
        preciov: "",
        porcentaje: false,
        precitem: "",
        minitem: 1,
        tipoitem: false,
        stock: false,
        idcuenta: {
          idcuenta: 1
        },
        medicamentos: [],
        itemquimica: detalle,
        band: "",
        active: true,
        idobjetivo: objetivo
      }
      return data
    },
    obtain_item() {
      if (this.buscar != null && this.buscar.length >= 3) {
        this.Vact_item_senave({
          name: this.buscar
        })
        // this.Vact_item_agrostock_todos({
        //   search: this.buscar,
        // });
      }
    },
    mupdate(val) {
      this.item_update(val);
      this.selected = [val.iditem]
      this.actived = true;
    },
    cancelar() {
      this.actived = false;
      this.item_update(null);
    }
  },
};
</script>
