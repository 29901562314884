
export default {
  Vget_inseminacion_byId: (state) => {
    return state.inseminacion_data_id
  },
  Vget_inseminacion_data: (state) => {
    return state.inseminacion_data
  },
  Vget_inseminacion_nuevo: (state) => {
    return state.inseminacion_nuevo
  },
  Vget_inseminacion_historico: (state) => {
    return state.inseminacion_historico
  },
  Vget_inseminacion_load: (state) => {
    return state.inseminacion_load
  },
  Vget_inseminacion_save_load: (state) => {
    return state.inseminacion_save_load
  }
}
