<template>
  <v-autocomplete
    dense
    :value="value"
    @input="$emit('input', $event)"
    v-on:change="$emit('change')"
    height="12px"
    :id="id"
    ref="input"
    :filter="customFilter"
    v-bind:items="item"
    :search-input.sync="searchitem"
    fkdes="item"
    label="Item"
    item-text="descitem"
    :messages="error"
    item-value="idstock"
    return-object
    :loading="Vget_item_load"
    required
    :clearable="true"
    :readonly="readonly"
    outlined
    :rules="rules_active"
    autocomplete="off"
    :no-data-text="`No existe ninguna concidencia`"
    small-chips
  >
    <template slot="selection" slot-scope="data">
      {{ data.item.iditem.descitem }}
    </template>
    <template slot="item" slot-scope="data">
      <v-list-item-content>
        <v-list-item-title>
          {{data.item.iditem.iditem}} - {{data.item.iditem.descitem}} - {{data.item.iditem.idmarca.descmarca}}
        </v-list-item-title>
        <v-list-item-subtitle>
          Deposito: {{data.item.iddeposito.desdeposito}} - Cantidad: {{data.item.cantstock}}
        </v-list-item-subtitle>
      </v-list-item-content>
    </template>
    <template
      v-if="noData && item_permission && length > 0 && create"
      v-slot:no-data
    >
      <v-list-item @click="$emit('create', true)">
        <v-list-item-content>
          <v-list-item-title
            >No existe concidencia. Click para agregar</v-list-item-title
          >
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { ITEM } from "@/constants/forms";
import { PUEDE_AGREGAR } from "@/constants/permissions";
export default {
  props: {
    value: {
      type: [String, Object, Number],
    },
    idmoneda: {
      type: [String, Number, Object],
      default: "",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: "Online",
    },
    create: {
      type: Boolean,
      default: true,
    },
    rules: {
      type: Boolean,
      default: false,
    },
    id: String,
    "search-item-id": {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      searchitem: "",
      item: [],
      iditem: "",
      noData: true,
      error: "",
      validar: [
        (v) => v !== "" || "Campo requerido",
        (v) => v !== undefined || "Campo requerido",
      ],
    };
  },
  watch: {
    searchitem(val) {
      this.$emit("sync-input", val);
      if (this.mode === "Online") {
        if (val === null || val === undefined || val === "") return null;
        if (val.length >= 3 && this.idmoneda != "") {
          this.Vact_item_stock2({
            search: this.searchitem,
            idmoneda: this.idmoneda,
          });
        }
      }
    },
    Vget_item_stock2(val) {
      this.item = JSON.parse(JSON.stringify(val));
      if (this.searchItemId != null) return this.search();
    },
    Vget_item_stock_offline(val) {
      this.item = JSON.parse(JSON.stringify(val));
    },
  },
  async mounted() {
    if (this.mode === "Offline") {
      let resp = await this.Vact_item_stock_offline({
        idmoneda: this.Vget_empresa_moneda.idmoneda,
      });
      if (resp.message) this.error = resp.message;
    }
  },
  computed: {
    ...mapGetters("item", [
      "Vget_item_stock2",
      "Vget_item_stock_offline",
      "Vget_item_load",
    ]),
    ...mapGetters("empresa", ["Vget_empresa_moneda"]),
    item_permission() {
      return getPermission(ITEM).permiso[PUEDE_AGREGAR];
    },
    rules_active() {
      if (this.rules) return this.validar;
      return [];
    },
    length() {
      if (
        this.searchitem === null ||
        this.searchitem === undefined ||
        this.searchitem === ""
      )
        return 0;
      return this.searchitem.length;
    },
  },
  methods: {
    ...mapActions("item", ["Vact_item_stock2", "Vact_item_stock_offline"]),
    customFilter(item, queryText) {
      const textOne = item.iditem.iditem.toString();
      const textTwo = item.iditem.idmarca.descmarca.toLowerCase();
      const textTree = item.iditem.descitem.toLowerCase();
      const textFour = item.iditem.cbarra ? item.iditem.cbarra.toLowerCase() : '';
      const searchText = queryText.toLowerCase();
      return (
        textOne.indexOf(searchText) > -1 ||
        textTwo.indexOf(searchText) > -1 ||
        textTree.indexOf(searchText) > -1 || 
        textFour.indexOf(searchText) > -1 
      );
    },
    search() {
      this.item.forEach((x) => {
        if (x.iditem.iditem === this.searchItemId) {
          this.$emit("input", x);
          this.$emit("change");
          this.$emit("resetSearch", null);
          return;
        }
      });
    },
    focus() {
      this.$refs.input.focus();
    },
    isMenuActive() {
      this.$refs.input.isMenuActive = false;
    },
  },
};
</script>

<style></style>
