<template>
  <div>
    <v-dialog v-model="dialogo" max-width="700px" persistent @keydown.esc="cancelar()">
      <v-card>
        <v-form ref="form" lazy-validation @submit.prevent="nextfocus(1)">
          <v-card-title>
            <span class="headline">{{titulo}}</span>
          </v-card-title>
          <v-card-text>
            <v-row row dense>
              <v-col cols="12">
                <v-text-field
                  dense
                  type="text"
                  :rules="validar"
                  id="input1"
                  v-model="data.descturnoordeno"
                  label="Descripcion"
                  required
                  outlined
                  autocomplete="off"
                  @keyup.enter="nextfocus(1,data.descturnoordeno)"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  dense
                  type="text"
                  :rules="validar"
                  id="input2"
                  v-model="data.horaturnoordeno"
                  label="Hora"
                  v-mask="mask_time"
                  required
                  outlined
                  autocomplete="off"
                  @keyup.enter="nextfocus(2,data.horaturnoordeno)"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="cancelar">Cancelar</v-btn>
            <v-btn id="aceptar" color="blue darken-1" text @click="guardar">Guardar</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { db } from "@/services/db.service";
import { focus } from "@/services/focus.service";
import { mensaje } from "@/services/notify.service";
import { mask } from "vue-the-mask";
export default {
  directives: {
    mask
  },
  data() {
    return {
      overlay: false,
      mask_time: "##:##:##",
      titulo: "Nuevo turno",
      data: {
        idturnoordeno: 0,
        descturnoordeno: "",
        horaturnoordeno: ""
      },
      default: {
        idturnoordeno: 0,
        descturnoordeno: "",
        horaturnoordeno: ""
      },
      url: "/apiturnoordeno/",
      validar: [v => !!v || "Campo requerido"],
      validars: [v => !!v || "Seleccione un campo"],
      dialogo: false,
      operacion: true
    };
  },
  computed: {
    ...mapGetters("turno", {
      getcreate: "turno_create",
      getupdate: "turno_update"
    })
  },
  watch: {
    $route(to, from) {
      if (from.path === "/turno") return this.cancelar();
    }
  },
  created() {
    this.nuevo(this.getupdate);
  },
  mounted() {
    setTimeout(() => document.getElementById("input1").focus(), 500);
  },
  methods: {
    ...mapActions("turno", ["turno_create", "turno_update", "turno_reload"]),

    nextfocus(id, data) {
      focus.nextid(id, data);
    },

    cancelar() {
      this.data = JSON.parse(JSON.stringify(this.default));
      this.dialogo = false;
      this.turno_update(null);
      this.turno_create(false);
      this.turno_reload(true);
      this.operacion = true;
    },
    async guardar_finalizar() {
      let a = await db.save(this.url, this.data);
      if (!a.estado) {
        this.overlay = false;
        return a.info.map(x => {
          this.$notify(mensaje.error(x));
        });
      }
      this.data = JSON.parse(JSON.stringify(this.default));
      this.$notify(a.info);
      this.$refs.form.resetValidation();
      setTimeout(() => document.getElementById("input1").focus(), 500);
      this.overlay = false;
    },
    async editar_finalizar() {
      const id = this.getupdate.idturnoordeno;
      let a = await db.update(`${this.url}${id}/`, this.data);
      if (!a.estado) {
        this.overlay = false;
        return a.info.map(x => {
          this.$notify(mensaje.error(x));
        });
      }
      this.$notify(a.info);
      this.cancelar();
      this.overlay = false;
    },
    guardar() {
      if (!this.$refs.form.validate()) return this.$notify(mensaje.completar());
      this.overlay = true;
      if (this.operacion) return this.guardar_finalizar();
      if (!this.operacion) return this.editar_finalizar();
    },
    nuevo(val) {
      this.dialogo = true;
      if (val === null) return null;
      this.data = val;
      this.operacion = false;
    }
  }
};
</script>

