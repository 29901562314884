<template> 
  <div>
    <v-btn color="primary" small height="100%"  tile dark @click="crearVenta()">
      <v-icon dark>add</v-icon>
    </v-btn>

    <Venta-Apertura-Create
      v-if="crearAperturaCaja"
      v-model="crearAperturaCaja"
      :idcaja="idcaja"
    />

    <div v-if="crearCotizacion">
      <Finanzas-Cotizacion-CreateV2 v-model="crearCotizacion" />
    </div>
    <v-dialog v-model="elegirPuntoVenta" max-width="330" persistent>
      <v-card class="mx-auto" max-width="330" tile>
        <v-list rounded>
          <v-card-subtitle class="text-center"
            >Seleccione el punto de Venta</v-card-subtitle
          >
          <v-list-item-group
            color="primary"
            v-if="Vget_puntoventa_punto.length > 0"
          >
            <v-list-item v-for="(item, i) in Vget_puntoventa_punto" :key="i">
              <v-list-item-content @click="abrirFormularioVenta(item)">
                <v-list-item-title> {{ item.desc }} </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>

          <v-card-text v-else>
            <v-icon left>info</v-icon>
            <span class="font-weight-light"
              >Ningun punto de venta Configurado</span
            >
          </v-card-text>
        </v-list>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="elegirPuntoVenta = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
      <c-overlay
        :value="
          Vget_puntoventa_load ||
          Vget_puntoventa_save_load ||
          Vget_cotizacion_load
        "
      ></c-overlay>
    </v-dialog>
  </div>
</template>

<script>
import { getPermission } from "@/services/storage/permissionStorage.service";
import { mapActions, mapGetters } from "vuex";

import { COTIZACION } from "@/constants/forms";
import { PUEDE_AGREGAR } from "@/constants/permissions";
export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    mode: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      crearCotizacion: false,
      elegirPuntoVenta: false,
      crearAperturaCaja: false,
      idcaja: 0,
      permission: {
        can_add: false,
        can_view: false,
        can_update: false,
        can_delete: false,
      },
    };
  },
  computed: {
    ...mapGetters("puntoventa", [
      "Vget_puntoventa_punto",
      "Vget_puntoventa_load",
      "Vget_puntoventa_save_load",
    ]),
    ...mapGetters("cotizacion", ["Vget_cotizacion_load"]),
  },
  watch: {
    $route(to, from) {
      if (from.path === "/venta") return (this.crearCotizacion = false);
    },
  },
  created() {
    this.Vact_puntoventa_punto(this.mode);
  },
  methods: {
    ...mapActions("cotizacion", ["Vact_cotizacion_data_day"]),
    ...mapActions("puntoventa", [
      "Vact_puntoventa_punto",
      "Vact_puntoventa_punto_save",
    ]),
    async crearVenta() {
      const hasPermissionCotizacion = getPermission(COTIZACION).permiso[
        PUEDE_AGREGAR
      ];
      if (hasPermissionCotizacion) {
        if (!(await this.existeCotizacion()))
          return (this.crearCotizacion = true);
      }
      this.elegirPuntoVenta = true;
    },
    async existeCotizacion() {
      const response = await this.Vact_cotizacion_data_day();
      return response;
    },
    //Este metodo abre el dialogo de la venta
    async abrirFormularioVenta(data) {
      const response = await this.Vact_puntoventa_punto_save({
        data,
        mode: this.mode,
      });
      if (response.success) {
        this.$notify(response.info);
        this.elegirPuntoVenta = false;
        this.$emit("input", true);
        return;
      }
      if (response.errors[0].idcaja) {
        this.elegirPuntoVenta = false;
        this.idcaja = response.errors[0].idcaja;
        this.crearAperturaCaja = true;
      }
    },
  },
};
</script>

<style>
</style>