<template>
  <div>
    <v-btn icon @click="operacion_elemento()">
      <v-icon color="primary">{{ icon }}</v-icon>
    </v-btn>
  </div>
</template>
<script>
import { db } from "@/services/db.service";
import { mensaje } from "@/services/notify.service";
import { mapActions, mapGetters } from 'vuex';
export default {
  props: {
    url: String,
    name: String,
  },
  data() {
    return {
      icon: "star_border",
      data: {
        idreporte: 0,
        url: "",
        name: "",
      },
      reporte: {},
    };
  },
  computed: {
    ...mapGetters("dashboardrrhh", ["getListReporte"])
  },
  watch: {
    getListReporte() {
      this.obtener_elemento();
    }
  },
  created() {
    this.obtener_elemento();
  },
  methods: {
    ...mapActions("dashboardrrhh", ["fetchList_reporte"]),
    async operacion_elemento() {
      if (this.reporte[0] === undefined) {
        this.data.url = this.url;
        this.data.name = this.name;
        let a = await db.save("/apireportech/", this.data);
        if (a.estado) {
          this.$notify(a.info);
          this.obtener_elemento();
          this.fetchList_reporte();
        } else {
          return a.info.map((x) => {
            this.$notify(mensaje.error(x));
          });
        }
      } else {
        let a = await db.delete(`/apireportech/${this.reporte[0].idreporte}/`);
        if (a.estado == true) {
          this.icon = "star_border";
          this.$notify(a.info);
          this.reporte = {};
          this.fetchList_reporte();
        } else {
          a.info.map((x) => {
            this.$notify(mensaje.error(x));
          });
        }
      }
    },
    async obtener_elemento() {
      try {
        this.reporte = await db.get(`/apireportech/?band=${this.url}`);
        if (this.reporte[0] === undefined) {
          this.icon = "star_border";
          return null;
        }
        this.icon = "star";
      } catch (error) {
        console.log(error.message);
      }
    },
  },
};
</script>
