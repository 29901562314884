<template>
  <div>
    <Crud
      v-if="crud.add"
      v-model="crud.add"
      :datos="crud.datos"
      @datos="crud.datos = $event"
    />
    <Crud
      v-if="crud.view"
      v-model="crud.view"
      readonly
      :datos="crud.datos"
      @datos="crud.datos = $event"
    />
    <v-card>
      <v-alert type="error" v-if="permission_alert"
        >Error!!! Este usuario no tiene permisos en esta ventana</v-alert
      >
      <v-toolbar flat dense>
        <v-toolbar-title>{{ titulo }}</v-toolbar-title>
        <widgets-Favorite :ruta="$route.path" :formulario="$route.name" />
        <v-divider class="mx-2" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Busqueda"
          single-line
          hide-details
          v-if="permission.can_view"
          @keyup="format_search(search)"
        ></v-text-field>
        <v-toolbar-items>
          <c-btn-table-add
            @click="crud.add = true"
            v-if="permission.can_add"
          ></c-btn-table-add>
        </v-toolbar-items>
      </v-toolbar>
      <v-data-table
        :loading="Vget_cotizacion_load"
        :headers="headers"
        :items="Vget_cotizacion_data"
        :search="search"
        class="elevation-1"
        v-if="permission.can_view"
      >
        <template v-slot:[`item.accion`]="props">
          <c-btn-table-view @click="rowselect(props.item)" />
          <c-btn-table-edit @click="update(props.item)" />
          <c-btn-table-delete @click="eliminar(props.item)" />
        </template>
      </v-data-table>
    </v-card>

    <Delete
      v-if="crud.delete"
      v-model="crud.delete"
      :props="crud.datos"
      @datos="crud.datos = $event"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { mensaje } from "@/services/notify.service";
import Crud from "@/views/Finanzas/Cotizacion/CreateV2";
import Delete from "./Delete";
import { COTIZACION } from "@/constants/forms";
import {
  PUEDE_AGREGAR,
  PUEDE_EDITAR,
  PUEDE_ELIMINAR,
  PUEDE_LISTAR,
} from "@/constants/permissions";
export default {
  components: {
    Delete,
    Crud,
  },
  data() {
    return {
      search: "",
      headers: [
        { text: "Codigo", align: "left", value: "idcotizacion" },
        { text: "Fecha", align: "left", value: "fecha" },
        { text: "Accion", value: "accion", sortable: false, align: "right" },
      ],
      titulo: COTIZACION,
      permission: {
        can_add: false,
        can_view: false,
        can_update: false,
        can_delete: false,
      },
      crud: {
        delete: false,
        add: false,
        view: false,
        datos: null,
      },
    };
  },
  created() {
    this.$store.commit("SET_LAYOUT", "principal-layout");
    const permission = getPermission(COTIZACION).permiso;
    this.permission = {
      can_add: permission[PUEDE_AGREGAR],
      can_view: permission[PUEDE_LISTAR],
      can_update: permission[PUEDE_EDITAR],
      can_delete: permission[PUEDE_ELIMINAR],
    };
    this.Vact_cotizacion_data();
  },
  computed: {
    ...mapGetters("cotizacion", [
      "Vget_cotizacion_load",
      "Vget_cotizacion_data",
    ]),
    permission_alert() {
      return mensaje.permission(this.permission);
    },
  },
  methods: {
    ...mapActions("cotizacion", [
      "Vact_cotizacion_del",
      "Vact_cotizacion_data",
    ]),
    rowselect(val) {
      this.crud.datos = JSON.parse(JSON.stringify(val));
      this.crud.view = true;
    },
    eliminar(item) {
      this.crud.datos = JSON.parse(JSON.stringify(item));
      this.crud.delete = true;
    },

    update(val) {
      this.crud.datos = JSON.parse(JSON.stringify(val));
      this.crud.add = true;
    },
  },
};
</script>
