export default {
    async medicamento_create({commit},request){
        commit('medicamento_create',request);
    },
    async medicamento_update({commit},request){
        commit('medicamento_update',request);
    },
    async medicamento_reload({commit},request){
        commit('medicamento_reload',request);
    },
    async medicamento_show({commit},request){
        commit('medicamento_show',request);
    },
    async medicamento_validar({commit},request){
        commit('medicamento_validar',request);
    },
}
