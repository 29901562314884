var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-btn',{attrs:{"height":"39","small":"","text":"","color":"red"},on:{"click":function($event){return _vm.obtenerDatos()}}},[_c('v-icon',[_vm._v("get_app")])],1),_c('v-dialog',{attrs:{"fullscreen":_vm.$vuetify.breakpoint.mobile,"width":"1200","persistent":"","transition":"dialog-bottom-transition"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"rounded":""}},[_c('v-toolbar',{attrs:{"flat":"","dense":""}},[_c('v-toolbar-title',[_vm._v("Lista de Actividades")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":function($event){return _vm.cancelar()}}},[_c('v-icon',[_vm._v("clear")])],1)],1),_c('div',{staticClass:"d-flex justify-center"},[_c('h4',[_vm._v("Cotizacion de Referencia: "+_vm._s(_vm.info.cotizacion))])]),_c('v-divider'),_c('v-container',{staticClass:"overflow-y-auto",staticStyle:{"max-height":"75vh"}},[_c('c-overlay',{attrs:{"absolute":"","value":_vm.isLoading}}),_c('v-data-table',{attrs:{"headers":_vm.permiso.can_view_numbers ? _vm.headers.aplicacion: _vm.headers.aplicacionNoP,"items":_vm.data,"dense":"","hide-default-footer":"","items-per-page":1000000,"show-expand":"","expanded":_vm.expanded2},on:{"click:row":_vm.clickRow,"update:expanded":function($event){_vm.expanded2=$event}},scopedSlots:_vm._u([{key:"item.cantidad",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.toCurrency(item.cantidad)))])]}},{key:"item.hectarea",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.toCurrency(item.hectarea)))])]}},{key:"item.cantotal",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.toCurrency(item.cantotal)))])]}},{key:"item.precio",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.toCurrency(item.precio)))])]}},{key:"item.ph",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"font-weight-black"},[_vm._v(_vm._s(_vm.toCurrency(item.ph)))])]}},{key:"item.subtotal",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"font-weight-black"},[_vm._v(" "+_vm._s(_vm.toCurrency(item.subtotal))+" ")])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_vm._v(" Propiedad Quimica: "+_vm._s(item.descripcion)+" ")])]}}],null,true)})],1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":function($event){return _vm.cancelar()}}},[_c('v-icon',[_vm._v("clear")])],1),_c('v-spacer'),_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":function($event){return _vm.descargarPdf()}}},[_vm._v("Descargar")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }