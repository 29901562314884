import { get, post, put, del } from "@/services/api/api.service";
export default {
  async Vact_cotizacion_del({ dispatch }, id) {
    const data = await del("/apicotizacion/" + id);
    dispatch("snackbar/setNotification", data, { root: true });
    dispatch("Vact_cotizacion_data");
    return data;
  },
  async fetchCotizacionBanks({ commit, dispatch }, idmoneda) {
    commit("cotizacion_load", true);
    try {
      const response = await get(`/apicotizacionvariant/?money=${idmoneda}`);
      commit('SET_COTIZACION_BANKS', response);
    } catch (e) {
      dispatch("snackbar/setNotification", e, { root: true });
      commit("cotizacion_load", false);
      throw e;
    }
    commit("cotizacion_load", false);
  },
  async Vact_cotizacion_data_day({ commit, dispatch }) {
    commit("cotizacion_load", true);
    try {
      let exists = true;
      const data = await get("/apicotizacion/?band=day");
      if (data[0] === undefined) {
        exists = false;
      }
      commit("cotizacion_load", false);
      return exists;
    } catch (e) {
      dispatch("snackbar/setNotification", e, { root: true });
      commit("cotizacion_load", false);
      throw e;
    }
  },
  async Vact_cotizacion_save({ commit, dispatch }, data) {
    commit("cotizacion_save_load", true);
    const resp = await post("/apicotizacion/", data);
    dispatch("snackbar/setNotification", resp, { root: true });
    commit("cotizacion_save_load", false);
    return resp;
  },
  async Vact_cotizacion_data({ commit, dispatch }) {
    commit("cotizacion_load", true);
    try {
      const data = await get("/apicotizacion/");
      commit("cotizacion_data", data);
      commit("cotizacion_load", false);
      return true;
    } catch (e) {
      commit("cotizacion_load", false);
      dispatch("snackbar/setNotification", e, { root: true });
      throw e;
    }
  },
  async Vact_cotizacion_update({ commit, dispatch }, { id, data }) {
    commit("cotizacion_save_load", true);
    const resp = await put("/apicotizacion/" + id + "/", data);
    dispatch("snackbar/setNotification", resp, { root: true });
    commit("cotizacion_save_load", false);
    return resp;
  },
};
