<template>
  <div>
    <v-btn icon @click="acceso_directo()">
      <v-icon color="primary">{{icon}}</v-icon>
    </v-btn>
  </div>
</template>

<script>
import { db } from "@/services/db.service";
import { mensaje } from "@/services/notify.service";
import { mapActions } from "vuex";
export default {
  props: {
    formulario: String,
    ruta: String
  },
  data() {
    return {
      icon: 'favorite_border',
      data: {
        idmenu: 0,
        nombre: "",
        url: ""
      },
      menu: {}
    };
  },
  created() {
    this.obtener_acceso();
  },
  methods: {
    ...mapActions("dashboard", ["Vact_favorites"]),
    async acceso_directo() {
      if (this.menu[0] === undefined) {
        this.data.nombre = this.formulario;
        this.data.url = this.ruta;
        let a = await db.save("/apimenu/", this.data);
        if (a.estado) {
          this.$notify(a.info);
          this.obtener_acceso();
          this.Vact_favorites();
        } else {
          return a.info.map(x => {
            this.$notify(mensaje.error(x));
          });
        }
      } else {
        let a = await db.delete(`/apimenu/${this.menu[0].idmenu}/`);
        if (a.estado == true) {
          this.icon = 'favorite_border';
          this.$notify(a.info);
          this.menu = {};
          this.Vact_favorites();
        } else {
          a.info.map(x => {
            this.$notify(mensaje.error(x));
          });
        }
      }
    },
    async obtener_acceso() {
      try {
        this.menu = await db.get(`/apimenu/?band=${this.formulario}`);
        if (this.menu[0] === undefined) return null;
        this.icon = 'favorite';
      } catch (error) {
        console.log(error.message)
      }
    }
  }
};
</script>
