<template>
  <div>
    <v-card>
      <v-toolbar flat>
        <v-divider class="mx-2" inset vertical></v-divider>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Busqueda"
          single-line
          hide-details
          v-if="permission.can_view"
        ></v-text-field>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="datos"
        :search="search"
        class="elevation-1"
        v-if="permission.can_view"
      >
        <template v-slot:item.accion="props">
          <v-icon class="mr-2" @click="rowselect(props.item)"
            >visibility</v-icon
          >
          <v-icon
            class="mr-2"
            @click="mupdate(props.item)"
            v-if="permission.can_update"
            >edit</v-icon
          >
          <v-icon
            small
            @click="meliminar(props.item)"
            v-if="permission.can_delete"
            >delete</v-icon
          >
        </template>
        <template v-slot:no-data>
          <v-alert :value="true" color="info" icon="info" outlined
            >No existe ningun registro</v-alert
          >
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="dialogoeliminar" persistent max-width="290">
      <v-card>
        <v-card-text class="title"
          >Desea eliminar {{ objeto.desccontacto }}?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="eliminarcancelar"
            >Cancelar</v-btn
          >
          <v-btn color="blue darken-1" text @click="meliminarguardar"
            >Confirmar</v-btn
          >
        </v-card-actions>
      </v-card>
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-dialog>
  </div>
</template>


<script>
import { mapActions, mapGetters } from "vuex";
import { db } from "@/services/db.service";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { mensaje } from "@/services/notify.service";
import { CONDUCTOR } from "@/constants/forms";
import {
  PUEDE_AGREGAR,
  PUEDE_EDITAR,
  PUEDE_ELIMINAR,
  PUEDE_LISTAR,
} from "@/constants/permissions";
export default {
  data() {
    return {
      overlay: false,
      search: "",
      headers: [
        { text: "Codigo", align: "left", value: "idcontacto" },
        { text: "Nombre", align: "left", value: "desccontacto" },
        { text: "RUC", align: "left", value: "ruccontacto" },
        { text: "DV", align: "left", value: "docucontactos" },
        { text: "Fecha", align: "left", value: "fechcontacto" },
        { text: "Accion", value: "accion", sortable: false, align: "right" },
      ],
      datos: [],
      url: "/apicontacto/?tipo=conductor",
      urld: "/apicontacto/",
      titulo: CONDUCTOR,
      objeto: "",
      index: "",
      dialogoeliminar: false,
      permission: {
        can_add: false,
        can_view: false,
        can_update: false,
        can_delete: false,
      },
    };
  },
  watch: {
    // eslint-disable-next-line
    reload() {
      this.mcargartabla();
    },
  },
  created() {
    this.$store.commit("SET_LAYOUT", "principal-layout");
    this.mcargartabla();

    const permission = getPermission(CONDUCTOR).permiso;
    this.permission = {
      can_add: permission[PUEDE_AGREGAR],
      can_view: permission[PUEDE_LISTAR],
      can_update: permission[PUEDE_EDITAR],
      can_delete: permission[PUEDE_ELIMINAR],
    };
  },
  computed: {
    ...mapGetters("contacto", {
      abrircontacto: "contacto_create",
      reload: "contacto_key",
    }),
  },

  methods: {
    ...mapActions("contacto", [
      "contacto_create",
      "contacto_update",
      "contacto_reload",
      "contacto_show",
    ]),
    rowselect(val) {
      this.contacto_update(val);
      this.contacto_show(true);
    },
    meliminar(item) {
      this.index = this.datos.indexOf(item);
      this.objeto = Object.assign({}, item);
      this.dialogoeliminar = true;
    },

    eliminarcancelar() {
      this.index = "";
      this.objeto = "";
      this.dialogoeliminar = false;
    },
    async meliminarguardar() {
      this.overlay = true;
      const id = this.objeto.idcontacto;
      const a = await db.delete(`${this.urld}${id}/?form=conductor`);
      if (a.estado === true) {
        this.datos.splice(this.index, 1);
        this.$notify(a.info);
        this.dialogoeliminar = false;
        this.eliminarcancelar();
      } else {
        a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
      this.overlay = false;
    },
    mupdate(val) {
      this.contacto_update(val);
      this.contacto_create(true);
    },
    async mcargartabla() {
      db.get(this.url).then((a) => {
        this.datos = a;
      });
      this.contacto_reload(false);
    },
  },
};
</script>