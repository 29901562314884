<template>
  <div>
    <v-dialog
      :value="value"
      max-width="700px"
      persistent
      @keydown.esc="cancelar()"
    >
      <v-card>
        <v-toolbar flat dense>
          <v-toolbar-title>{{
            operacion ? "Registrar Celo" : "Modificar Celo"
          }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-checkbox
            class="mt-5"
            label="Historico"
            v-model="historico"
          ></v-checkbox>
        </v-toolbar>
        <v-form ref="form" lazy-validation @submit.prevent>
          <v-container>
            <ChildForm ref="childForm" :xidanimal="xidanimal" :historico="historico" :datos="data" :readonly="readonly" />
          </v-container>
        </v-form>
        <v-divider></v-divider>
        <v-card-actions>
          <c-btn-crud-close @click="cancelar()"></c-btn-crud-close>
          <v-spacer></v-spacer>

          <c-btn-crud-done
            id="aceptar"
            :disabled="readonly"
            @click="guardar()"
          ></c-btn-crud-done>
        </v-card-actions>
      </v-card>
      <c-overlay :value="Vget_celo_save_load" />
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ChildForm from "./CreateRef";
export default {
  components: {
    ChildForm,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    datos: {
      type: Object,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
     xidanimal: {
      type: Number,
      default: null
    }
  },

  data() {
    return {
      data: null,
      historico: false,
      operacion: true,
      isEditAnimal: false,
    };
  },
  watch: {
    $route(to, from) {
      if (from.path === "/celo") return this.cancelar();
    },
  },
  computed: {
    ...mapGetters("celo", ["Vget_celo_save_load"]),
  },
  created() {
    this.nuevo(this.datos);
  },
  methods: {
    ...mapActions("celo", [
      "Vact_celo_save",
      "Vact_celo_update",
      "Vact_celo_nuevo",
      "Vact_celo_historico",
    ]),

    cancelar() {
      this.$emit("datos", null);
      this.$emit("input", false);
      this.Vact_celo_nuevo();
      this.Vact_celo_historico();
      this.operacion = true;
      this.isEditAnimal = false;
    },
    async guardar_finalizar() {
      let a = await this.Vact_celo_save(this.data);
      if (!a.success) return null;
      this.data = { idanimal: { idanimal: "" } };
      this.isEditAnimal = true;
      setTimeout(() => document.getElementById("input1").focus(), 500);
    },
    async editar_finalizar() {
      const id = this.datos.idcelo;
      let a = await this.Vact_celo_update({ id, data: this.data });
      if (!a.success) return null;
      this.data = { idanimal: { idanimal: "" } };
      this.cancelar();
    },
    async guardar() {
      if (!this.$refs.childForm.validate()) return null;
      this.data = {
        ...JSON.parse(
          JSON.stringify(await this.$refs.childForm.obtenerDatos())
        ),
        historico: this.historico,
      };
      if (this.operacion) return this.guardar_finalizar();
      return this.editar_finalizar();
    },
    nuevo(val) {
      if (val === null) return null;
      this.data = JSON.parse(JSON.stringify(val));
      this.operacion = false;
    },
  },
};
</script>
