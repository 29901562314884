import { db } from "@/services/db.service";
import dbPedidoVenta from '@/db/dbPedidoVenta';
import { TokenService } from '@/services/auth/storage.service'

export default {
    async Vact_pedidoventa_data({ commit }, { desc = null, limit = null }) {
        const xidusuario = TokenService.getToken();
        commit('pedidoventa_load', true);
        try {
            let data = ''
            if (desc) {
                data = await db.get(`/apipedidoventa/?tipo=Pendiente&desc=${desc}`);
            } else {
                data = await db.get(`/apipedidoventa/?tipo=Pendiente&limit=${limit}`);
                dbPedidoVenta.transaction("rw", dbPedidoVenta.pedidoventa, () => {
                    dbPedidoVenta.pedidoventa.clear();
                    data.map(x => {
                        let pedidoventa = { ...x, ...{ xidusuario: xidusuario } }
                        dbPedidoVenta.pedidoventa.add(pedidoventa)
                    })
                    dbPedidoVenta.pedidoventa.toArray().then((x) => {
                        commit('pedidoventa_data', x)
                    })
                }).catch(function (e) {
                    console.log(e);
                });
            }
        } catch (error) {
            if (desc) {
                desc = desc.toUpperCase()
                dbPedidoVenta.pedidoventa.where('xidusuario').equals(xidusuario).filter(function (des) {
                    return des.idcontacto.desccontacto.toUpperCase().startsWith(desc)
                }).toArray().then((x) => {
                    commit('pedidoventa_data', x)
                })
            } else {
                dbPedidoVenta.pedidoventa.where('xidusuario').equals(xidusuario).toArray().then((x) => {
                    commit('pedidoventa_data', x)
                })
            }
        }
        commit('pedidoventa_load', false);
    },
    async Vact_pedidoventa_presupuesto({ commit }, { desc = null, limit = null }) {
        commit('pedidoventa_load', true);
        try {
            let data = ''
            if (desc) {
                data = await db.get(`/apipedidoventa/?tipo=Presupuestado&desc=${desc}`)
            } else {
                data = await db.get(`/apipedidoventa/?tipo=Presupuestado&limit=${limit}`)
            }
            commit('pedidoventa_presupuesto', data)
            commit('pedidoventa_load', false);
        } catch (error) {
            commit('pedidoventa_load', false)
            return error;
        }
    },
    async Vact_pedidoventa_finalizado({ commit }, { desc = null, limit = null }) {
        commit('pedidoventa_load', true);
        try {
            let data = ''
            if (desc) {
                data = await db.get(`/apipedidoventa/?tipo=Finalizado&desc=${desc}`)
            } else {
                data = await db.get(`/apipedidoventa/?tipo=Finalizado&limit=${limit}`)
            }
            commit('pedidoventa_finalizado', data)
            commit('pedidoventa_load', false);
        } catch (error) {
            commit('pedidoventa_load', false)
            return error;
        }
    },
    async Vact_pedidoventa_reporte({ commit }, parametro) {
        commit('pedidoventa_reporte', []);
        commit('pedidoventa_load', true);
        try {
            const data = await db.get(`/apipedidoventa/?tipo=${parametro.tiporeporte || ''}&inicio=${parametro.fecha_inicio}&fin=${parametro.fecha_fin}&contacto=${parametro.idcontacto || ''}`);
            commit('pedidoventa_reporte', data)
            commit('pedidoventa_load', false);
        } catch (error) {
            commit('pedidoventa_load', false)
            return error;
        }
    },
    async Vact_pedidoventa_byId({ commit }, idpunto_venta) {
        commit('pedidoventa_load', true);
        commit('pedidoventa_data', null);
        try {
            const data = await db.get('/apipedidoventa/' + idpunto_venta + '/');
            commit('pedidoventa_byId', data);
            commit('pedidoventa_load', false);
        } catch (e) {
            commit('pedidoventa_load', false);
            return e
        }
    },
    async Vact_pedidoventa_del({ dispatch }, id) {
        try {
            const data = await db.delete('/apipedidoventa/' + id + '/');
            dispatch('Vact_pedidoventa_data');
            return data
        } catch (e) {
            return false
        }
    },
    async Vact_pedidoventa_save({ commit }, data) {
        commit('pedidoventa_save_load', true);
        try {
            const resp = await db.save('/apipedidoventa/', data);
            commit('pedidoventa_save_load', false);
            return resp;
        } catch (e) {
            commit('pedidoventa_save_load', false);
            return e
        }
    },
    async Vact_pedidoventa_update({ commit }, { id, data }) {
        commit('pedidoventa_save_load', true);
        try {
            const resp = await db.update('/apipedidoventa/' + id + '/', data);
            commit('pedidoventa_save_load', false);
            return resp;
        } catch (e) {
            commit('pedidoventa_save_load', false);
            return e
        }
    }
}
