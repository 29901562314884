<template>
  <v-dialog :value="value" persistent max-width="900px">
    <v-card>
      <v-sheet height="64">
        <v-toolbar flat>
          <v-btn outlined small @click="setToday()">Hoy</v-btn>
          <v-btn class="ma-2" fab text small @click="prev()">
            <v-icon small>chevron_left</v-icon>
          </v-btn>
          
          <v-toolbar-title class="hidden-sm-and-down subtitle-2">{{
            title
          }}</v-toolbar-title>
          <v-btn class="ma-2" fab text small @click="next()">
            <v-icon small>chevron_right</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-menu bottom right>
            <template v-slot:activator="{ on }">
              <v-btn outlined v-on="on" small class="hidden-sm-and-down">
                <span>{{ mes }}</span>
                <v-icon right>arrow_drop_down</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item @click="(mes = 2), obtenercalendario()">
                <v-list-item-title>2 Meses</v-list-item-title>
              </v-list-item>
              <v-list-item @click="(mes = 4), obtenercalendario()">
                <v-list-item-title>4 Meses</v-list-item-title>
              </v-list-item>
              <v-list-item @click="(mes = 8), obtenercalendario()">
                <v-list-item-title>8 Meses</v-list-item-title>
              </v-list-item>
              <v-list-item @click="(mes = 12), obtenercalendario()">
                <v-list-item-title>12 Meses</v-list-item-title>
              </v-list-item>
              <v-list-item @click="(mes = 24), obtenercalendario()">
                <v-list-item-title>24 Meses</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-toolbar-title class="hidden-sm-and-down subtitle-2 ma-1"
            >Tipo:</v-toolbar-title
          >
          <v-menu bottom right>
            <template v-slot:activator="{ on }">
              <v-btn outlined v-on="on" small>
                <span>{{ typeToLabel[type] }}</span>
                <v-icon right>arrow_drop_down</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item @click="type = 'day'">
                <v-list-item-title>Dia</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'week'">
                <v-list-item-title>Semana</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'month'">
                <v-list-item-title>Mes</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = '4day'">
                <v-list-item-title>4 Dias</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" small text @click="crud.agenda = true">
              <v-icon>add</v-icon>
            </v-btn>
          </v-card-actions>
        </v-toolbar>
      </v-sheet>
      <v-container>
      <v-sheet height="550">
        <v-calendar
          ref="calendar"
          v-model="focus"
          color="primary"
          :events="events"
          :event-color="getEventColor"
          :event-margin-bottom="3"
          locale="es-ES"
          :now="today"
          :type="type"
          @click:event="showEvent"
          @click:more="viewDay"
          @click:date="viewDay"
          @change="updateRange"
        ></v-calendar>
        <v-menu
          v-model="selectedOpen"
          :close-on-content-click="false"
          :activator="selectedElement"
          offset-x
        >
          <v-card color="grey lighten-4" min-width="350px" flat>
            <v-toolbar :color="selectedEvent.color" dark dense>
              <v-btn fab x-small text @click="$emit('open-form',selectedEvent)">
                <v-icon>edit</v-icon>
              </v-btn>

              <div class="subtitle">{{selectedEvent.name}}</div>
              <v-spacer></v-spacer>
              <v-btn fab x-small text @click="selectedOpen = false">
                <v-icon>clear</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text v-if="selectedEvent.detalle != undefined">
              <span v-html="selectedEvent.detalle"></span>
            </v-card-text>
            <v-data-table
              :headers="headers"
              :items="selectedEvent.details"
              v-if="selectedEvent.details != undefined"
              style="max-height: 350px"
              class="overflow-y-auto"
            ></v-data-table>
          </v-card>
        </v-menu>
      </v-sheet>
      </v-container>
      <v-divider></v-divider>
      <v-card-actions absolute elevation="3">
        <v-spacer></v-spacer>
        <v-btn color="red" text @click="$emit('input', false)">
          <v-icon>clear</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
    <AgendaCreate v-model="crud.agenda" />
  </v-dialog>
</template>
<script>
import { get } from "@/services/api/api.service";
import AgendaCreate from "@/views/Configuraciones/Agenda/Create";

export default {
  components: {
    AgendaCreate,
  },
  props: {
    value: Boolean,
  },
  data: () => ({
    today: new Date().toISOString().substr(0, 10),
    focus: new Date().toISOString().substr(0, 10),
    selectedEvent: {},
    type: 'month',
    events: [],
    mes: 2,
    start: null,
    end: null,
    selectedElement: null,
    selectedOpen: false,
    typeToLabel: {
      month: "Mes",
      week: "Semana",
      day: "Dia",
      "4day": "4 Dias",
    },
    crud: {
      agenda: false,
    },
        headers: [
      { text: "Animal", align: "left", value: "name" },
      { text: "Dias de Descarte", align: "left", value: "value" },
    ],
  }),
  mounted(){
   
      this.obtenercalendario();
  },
  computed: {
    title() {
      const { start, end } = this;
      if (!start || !end) {
        return "";
      }

      const startMonth = this.monthFormatter(start);
      const endMonth = this.monthFormatter(end);
      const suffixMonth = startMonth === endMonth ? "" : endMonth;

      const startYear = start.year;
      const endYear = end.year;
      const suffixYear = startYear === endYear ? "" : endYear;

      const startDay = start.day;
      const endDay = end.day;

      switch (this.type) {
        case "month":
          return `${startMonth} ${startYear}`;
        case "week":
        case "4day":
          return `${startMonth} ${startDay} ${startYear} - ${suffixMonth} ${endDay} ${suffixYear}`;
        case "day":
          return `${startMonth} ${startDay} ${startYear}`;
      }
      return "";
    },
    monthFormatter() {
      return this.$refs.calendar.getFormatter({
        timeZone: "UTC",
        month: "long",
      });
    },
  },
  methods: {
    async obtenercalendario() {
      try {
        const response = await get(`/apicalendar/?band=${this.mes}`);
        this.events = JSON.parse(JSON.stringify(response));
      } catch (error) {
        console.log(error.message);
      }
    },
    setToday() {
      this.focus = this.today;
    },
    getEventColor(event) {
      return event.color;
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    updateRange({ start, end }) {
      this.start = start;
      this.end = end;
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        setTimeout(() => (this.selectedOpen = true), 10);
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
  },
};
</script>
