import {SET_LOADING, SET_TIPO_FACTURA} from './constants'
export default {
  tipofactura_data(state, request) {
    state.tipofactura_data = request;
  }, 
  tipofactura_load(state, request) {
    state.tipofactura_load = request;
  },
  [SET_TIPO_FACTURA](state, request) {
    state.tipofactura = request;
  },
  [SET_LOADING](state, request) {
    state.isLoading = request;
  },
}