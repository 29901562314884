<template>
  <div v-if="value">
    <v-dialog :value="value" max-width="800px" persistent @keydown.esc="cancelar()">
      <v-card>
        <v-form ref="form" lazy-validation @submit.prevent="nextfocus(1)">
          <v-toolbar flat>
            <v-toolbar-title>{{titulo}}</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-row dense>
              <v-col cols="12" md="6">
                <vue-text-field readonly label="Caja" v-model="data.idcaja.descaja" />
              </v-col>
              <v-col cols="12" md="6">
                <vue-text-field readonly label="Fecha" v-model="dateFormatted" />
              </v-col>
            </v-row>
            <v-row dense no-gutters cols="10" v-for="(item, i) in data.apertura" :key="i">
              <v-progress-linear value="100%" height="2" class="ma-2"></v-progress-linear>
              <v-col cols="12" md="2">
                <vue-text-field readonly v-model="data.apertura[i].descmoneda" />
              </v-col>
              <v-col cols="12" md="3">
                <vue-text-currency readonly label="Saldo Vigente" v-model="data.apertura[i].montovigente" />
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="12" md="3">
                <vue-text-currency readonly
                  label="Monto Apertura"
                  v-model="data.apertura[i].montoapertura"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn color="red" text @click="cancelar">
              <v-icon>clear</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn ref="aceptar" color="primary" text @click="guardar">
              <v-icon>done</v-icon>
            </v-btn>
          </v-card-actions>
        </v-form>
        <c-overlay :value="overlay"></c-overlay>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { db } from "@/services/db.service";
import { mensaje } from "@/services/notify.service";
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    idcaja: {
      type: Number,
      default: null,
    },
    datos: {
      type: [Array, Object],
      default: null,
    },
  },
  data() {
    return {
      overlay: false,
      titulo: "Registrar apertura de Caja",
      dateFormatted: new Date().toLocaleString(),
      saldo: 0,
      caja: "",
      data: {
        idaperturacaja: 0,
        fechaapertura: "2000-01-01",
        horaapertura: "00:00",
        descpuntoventa: "",
        idcaja: {
          idcaja: "",
          descaja: "",
          cajadet: [],
        },
        apertura: [],
      },
      default: {
        idaperturacaja: 0,
        fechaapertura: "2000-01-01",
        horaapertura: "00:00",
        descpuntoventa: "",
        idcaja: {
          idcaja: "",
          descaja: "",
          cajadet: [],
        },
        apertura: [],
      },
      url: "/apiapertura/",
      operacion: true,
    };
  },
  computed: {
    ...mapGetters("apertura", ["Vget_apertura_save_load"]),
  },
  watch: {
    value(val) {
      if (val) return this.nuevo();
    },
    $route(to, from) {
      if (from.path === "/apertura") return this.cancelar();
    },
  },
  mounted() {
    if (this.value) return this.nuevo();
  },
  methods: {
    ...mapActions("apertura", [
      "Vact_apertura_data",
      "Vact_apertura_save",
      "Vact_apertura_update",
    ]),

    ...mapActions("caja", ["Vact_caja_data"]),

    cancelar() {
      this.data = JSON.parse(JSON.stringify(this.default));
      this.$emit("input", false);
      this.Vact_caja_data();
      this.operacion = true;
    },
    async guardar_finalizar() {
      let a = await this.Vact_apertura_save(this.data);
      if (a.estado == true) {
        this.data = JSON.parse(JSON.stringify(this.default));
        this.$notify(a.info);
        this.$refs.form.resetValidation();
        this.cancelar();
      } else {
        a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
      this.overlay = false;
    },

    guardar() {
      if (this.$refs.form.validate()) {
        this.overlay = true;
        if (this.operacion === true) return this.guardar_finalizar();
      } else {
        this.$notify(mensaje.completar());
      }
    },
    async nuevo() {
      let val;

      if (this.idcaja != null) {
        val = await db.get(`/apicaja/${this.idcaja}/`);
      } else if (this.datos != null) {
        val = this.datos;
      }
      if (val !== null) {
        this.data.idcaja = val;
        this.data.descpuntoventa = val.descpuntoventa;
        val.cajadet.forEach((x) => {
          this.data.apertura.push({
            idaperturacaja: 0,
            idcaja: x.idcaja,
            idmoneda: x.idmoneda.idmoneda,
            descmoneda: x.idmoneda.descmoneda,
            montoapertura: x.monto,
            montovigente: x.monto,
          });
        });
      }
    },
  },
};
</script>

