import { get, put, del, post } from "@/services/api/api.service";
export default {

    async Vact_medicacionmotivo_del({ dispatch }, id) {
        const resp = await del('/apimedicacionmotivo/' + id);
        dispatch('snackbar/setNotification', resp, { root: true })
        dispatch('Vact_medicacionmotivo_data');
        return resp
    },
    async Vact_medicacionmotivo_data({ commit, dispatch }) {
        commit('medicacionmotivo_load', true);
        try {
            const data = await get('/apimedicacionmotivo/');
            commit('medicacionmotivo_data', data);
            commit('medicacionmotivo_load', false);
            return true
        } catch (e) {
            dispatch('snackbar/setNotification', e, { root: true });
            commit('medicacionmotivo_load', false);
            throw e
        }

    },
    async Vact_medicacionmotivo_save({ commit, dispatch }, data) {
        commit('medicacionmotivo_save_load', true);
        const resp = await post('/apimedicacionmotivo/', data);
        dispatch('snackbar/setNotification', resp, { root: true })
        commit('medicacionmotivo_save_load', false);
        return resp;

    },
    async Vact_medicacionmotivo_update({ commit, dispatch }, { id, data }) {
        commit('medicacionmotivo_save_load', true);
        const resp = await put('/apimedicacionmotivo/' + id + '/', data);
        dispatch('snackbar/setNotification', resp, { root: true })
        commit('medicacionmotivo_save_load', false);
        return resp;

    },

}
