
export default {
  Vget_transladobancobanco_data: (state) => {
    return state.transladobancobanco_data
  },
  Vget_transladobancobanco_load: (state) => {
    return state.transladobancobanco_load
  },
  Vget_transladobancobanco_save_load: (state) => {
    return state.transladobancobanco_save_load
  },
}
