<script>
import { mapGetters, mapActions } from "vuex";
var self = null;
export default {
  props: {
    google: {
      type: Object,
      required: true,
    },
    map: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      listeners: {
        pest: null,
      },
      markers: {
        pest: [],
      },
    };
  },
  created() {
    self = this;
  },
  mounted() {
    if (self.getSensorId.coord) {
      this.addMarker(self.getSensorId);
    }
  },
  watch: {
    getSensorId(val) {
      this.addMarker(val);
    },
  },
  computed: {
    ...mapGetters("sensor", ["getSensorId"]),
  },
  methods: {
    ...mapActions("plaga", ["setCoordenadaPlaga"]),

    viewInfoMap(marker, name) {
      let contentString =
        `<h4 style="text-align: center;">Sensor Meteorologico</h4><hr>` +
        `<table>` +
        `<tr>` +
        `<th style="text-align: left; color:black;">Descripcion:  </th>` +
        `<td style="color:black; text-align: right;"> ` +
        name +
        `</td>` +
        `</tr>` +
        `</table>`;

      let infowindow = new this.google.maps.InfoWindow({
        content: contentString,
      });
      infowindow.open(self.map, marker);
    },
    addMarker(val) {
      if(!val.coord) return null;
      if (val.coord.length === 0) return null;
      const position = new self.google.maps.LatLng(val.coord[1], val.coord[0]);
      self.centerSensor(position, true, val.descripcion);
    },
    centerSensor(position, viewInfoMap, name) {
      const marker = new self.google.maps.Marker({
        position: position,
        map: self.map,
        icon:
          "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAQSwAAEEsB549FewAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAPtSURBVHic7ZlNTBxlGMd/z+xuCyUKqf1QatUtXSUpH9V2iT0YTVRMJFEvJCbGg0QvctdEjeGmVU8eejDqiYMfiakx9mA06a0psCjFRKWFpbEoFizQlu22uzuPB6i2ZFeB91nemMzvRGaY3/Off3Z2dt4RNohfDx2qLRQWuxQeRXgASAINy7vngSyQQfluU6Lu2O4TJ65sRC6p9oDxAwfqCQqvAi8D9as8bAE4Qpg43JTJLFQvXRULUJDswdZeRPoUblunZlZU+5JDo0cE1DTgMlUpIPvIPTWl3K0fiPK8hU/g05ow0dOYyeQsfCvctmT3728IE6VvQNLG6gHCRKf1JRFYyrS7OxbGS/1VOHmADuTaZ9rdHbOUmhYwcXbsXUS6LJ03IdI5PvnL26ZKK9FEx7521dgwZUoN4nHim2so5vOEpaLrqDAMSacyp4ZdRQBxCwmAauw9ypx8/R27qdu2E0RQVS7P/M6l6SmXUUEg+hbwhIvkb5mFJJtufRh4bOX2LVu3U7f9dpClD5qIcMuORmob1ntXXEakc6Kj7SE3yRImBahKT7nttQ1by/5/pe1rm6kvOEswKEBBVHjSIszakC41+A5zLuDsgy3NwLZy+/IX58sek7845zoWYEf2YNu9rhLnAophrLnSvsU/z5O7MHPzttk/yF2YdR0LQAmtOHu1ON8FBHZW3KnK/LlJLs9ME9+0mUL+CqXCNdeR/8yWf5m9Stxvg6HW/NeVWLyap3g17zxqJYLUujoMvgRlQ57byyHCoqvDuYBA9DdXx3rR0H22cwFhqfSTq2O9lISfXR0mzwLj6bZzwC4L1xqYaho8daerxOZpUPnKxLMm5KiFxeancEw/pEpLVpVGhspHFiKTAvaeHM0AX1i4VoOin6eGRr63cNktiIi8BhTMfJUpSBB7w0pmVkDTwMgYyJtWvkqo8nrTyR9OW/nMF0XH0+39oM9Ze5fQ/qbBUZOV5uuYrgkCBHULLyIcs/ai+nVQd+kla615Acnjk/k9d9/3FMhhK6fA+3uSzU8nj0+aP1BU9dXYmY7WZ0TlHSC1TsVYqPpKamj0S8tcN2L+CbiRvQOjR+fCxD6gV2BiDYeOA71zYaKlmicPG/By9DpL7wrb0wT6uCr3q5BaiG9pAagv5n4U5bQowyDfJodGBqv1LnAlG1bASp7t6WsshUwBxAJ2ffJxn5enyqpeAv8HogJ8B/BNVIDvAL6JCvAdwDdRAb4D+CYqwHcA30QF+A7gm6gA3wF8ExXgO4BvogJ8B/BNVIDvAL6JCvAdwDdRAb4D+CYqwHcA30QF+A7gm6gA3wF8462A6bs4DwwDw8t/e+EvEJAYca8hpZEAAAAASUVORK5CYII=",
      });
      self.markers.pest.push(marker);
      self.map.panTo(position);
      if (viewInfoMap) self.viewInfoMap(marker, name);
    },
  },
  // eslint-disable-next-line
  render() {},
};
</script>
