import { current_date } from "@/services/util/date.service";
import { Workbook } from "exceljs";
import * as fs from "file-saver";
export const exportInformes = (datos, desde, hasta) => {
    let workbook = new Workbook();
    datos.forEach((w) => {
        let data = w.data.map((x) => {
            return {
                name: x.name,
                value: x.value,
            }
        });
        if (w.api == '/apiinformeasistencia/') {
            let worksheet = workbook.addWorksheet("Informe de ausencias");
            worksheet.addRows(
                [
                    ['Informe de Ausencias'],
                    [`Desde: ${desde}`, `Hasta: ${hasta}`],
                    ['Nombre', 'Ausencias']
                ]
            );
            worksheet.eachRow((row) => {
                row.font = {
                    name: "Arial",
                    family: 4,
                    size: 12,
                    bold: true,
                }
                row.alignment = {
                    vertical: "middle",
                    horizontal: "center",
                };
            });
            data.forEach((x) => {
                let row = worksheet.addRow(Object.values(x));
                row.font = {
                    name: "Arial",
                    family: 4,
                    size: 11,
                }
            });
            worksheet.columns.forEach(function (column) {
                var dataMax = 0;
                column.eachCell({ includeEmpty: true }, function (cell) {
                    dataMax = cell.value ? cell.value.toString().length : 0;
                });
                column.width = dataMax <= 10 ? 25 : dataMax;
            });
            worksheet.mergeCells('A1:B1');
        } else if (w.api == "/apiinformehoras/") {
            let worksheet = workbook.addWorksheet("Informe de horas");
            worksheet.addRows(
                [
                    ['Informe de Horas Cumplidas'],
                    [`Desde: ${desde}`, `Hasta: ${hasta}`],
                    ['Nombre', 'Horas']
                ]
            );
            worksheet.eachRow((row) => {
                row.font = {
                    name: "Arial",
                    family: 4,
                    size: 12,
                    bold: true,
                }
                row.alignment = {
                    vertical: "middle",
                    horizontal: "center",
                };
            });
            data.forEach((x) => {
                let row = worksheet.addRow(Object.values(x));
                row.font = {
                    name: "Arial",
                    family: 4,
                    size: 11,
                }
            });
            worksheet.columns.forEach(function (column) {
                var dataMax = 0;
                column.eachCell({ includeEmpty: true }, function (cell) {
                    dataMax = cell.value ? cell.value.toString().length : 0;
                });
                column.width = dataMax <= 10 ? 25 : dataMax;
            });
            worksheet.mergeCells('A1:B1');
        } else if (w.api == "/apiinformeedad/") {
            let worksheet = workbook.addWorksheet("Informe de edades");
            worksheet.addRows(
                [
                    ['Informe de Edades'],
                    [`Desde: ${desde}`, `Hasta: ${hasta}`],
                    ['Edad', 'Cantidad']
                ]
            );
            worksheet.eachRow((row) => {
                row.font = {
                    name: "Arial",
                    family: 4,
                    size: 12,
                    bold: true,
                }
                row.alignment = {
                    vertical: "middle",
                    horizontal: "center",
                };
            });
            data.forEach((x) => {
                let row = worksheet.addRow(Object.values(x));
                row.font = {
                    name: "Arial",
                    family: 4,
                    size: 11,
                }
            });
            worksheet.columns.forEach(function (column) {
                var dataMax = 0;
                column.eachCell({ includeEmpty: true }, function (cell) {
                    dataMax = cell.value ? cell.value.toString().length : 0;
                });
                column.width = dataMax <= 10 ? 25 : dataMax;
            });
            worksheet.mergeCells('A1:B1');
        } else if (w.api == "/apiinformesalario/") {
            let worksheet = workbook.addWorksheet("Informe de salarios");
            worksheet.addRows(
                [
                    ['Informe de Salarios'],
                    [`Desde: ${desde}`, `Hasta: ${hasta}`],
                    ['Monto', 'Cantidad']
                ]
            );
            worksheet.eachRow((row) => {
                row.font = {
                    name: "Arial",
                    family: 4,
                    size: 12,
                    bold: true,
                }
                row.alignment = {
                    vertical: "middle",
                    horizontal: "center",
                };
            });
            data.forEach((x) => {
                let row = worksheet.addRow(Object.values(x));
                row.font = {
                    name: "Arial",
                    family: 4,
                    size: 11,
                }
            });
            worksheet.columns.forEach(function (column) {
                var dataMax = 0;
                column.eachCell({ includeEmpty: true }, function (cell) {
                    dataMax = cell.value ? cell.value.toString().length : 0;
                });
                column.width = dataMax <= 10 ? 25 : dataMax;
            });
            worksheet.mergeCells('A1:B1');
        } else if (w.api == "/apiinformeantiguedad/") {
            let worksheet = workbook.addWorksheet("Informe de antiguedad");
            worksheet.addRows(
                [
                    ['Informe de Antiguedades'],
                    [`Desde: ${desde}`, `Hasta: ${hasta}`],
                    ['Edad', 'Anhos']
                ]
            );
            worksheet.eachRow((row) => {
                row.font = {
                    name: "Arial",
                    family: 4,
                    size: 12,
                    bold: true,
                }
                row.alignment = {
                    vertical: "middle",
                    horizontal: "center",
                };
            });
            data.forEach((x) => {
                let row = worksheet.addRow(Object.values(x));
                row.font = {
                    name: "Arial",
                    family: 4,
                    size: 11,
                }
            });
            worksheet.columns.forEach(function (column) {
                var dataMax = 0;
                column.eachCell({ includeEmpty: true }, function (cell) {
                    dataMax = cell.value ? cell.value.toString().length : 0;
                });
                column.width = dataMax <= 10 ? 25 : dataMax;
            });
            worksheet.mergeCells('A1:B1');
        } else {
            let worksheet = workbook.addWorksheet("Informe de diversidad");
            worksheet.addRows(
                [
                    ['Informe de Diversidad'],
                    [`Desde: ${desde}`, `Hasta: ${hasta}`],
                    ['Nombre', 'Cantidad']
                ]
            );
            worksheet.eachRow((row) => {
                row.font = {
                    name: "Arial",
                    family: 4,
                    size: 12,
                    bold: true,
                }
                row.alignment = {
                    vertical: "middle",
                    horizontal: "center",
                };
            });
            data.forEach((x) => {
                let row = worksheet.addRow(Object.values(x));
                row.font = {
                    name: "Arial",
                    family: 4,
                    size: 11,
                }
            });
            worksheet.columns.forEach(function (column) {
                var dataMax = 0;
                column.eachCell({ includeEmpty: true }, function (cell) {
                    dataMax = cell.value ? cell.value.toString().length : 0;
                });
                column.width = dataMax <= 10 ? 25 : dataMax;
            });
            worksheet.mergeCells('A1:B1');
        }
    })
    workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        fs.saveAs(blob, `Reporte RRHH ${current_date()}.xlsx`);
    });
};