import { utilidad } from "@/services/compra_utilidad.service";
export const getIva5 = (venta) =>
  venta.venta.reduce((acc, curr) => acc + curr.iditem.iva5, 0) / 21;
export const getIva10 = (venta) =>
  venta.venta.reduce((acc, curr) => acc + curr.iditem.iva10, 0) / 11;
export const getTotal = (venta) =>
  venta.venta.reduce(
    (acc, curr) => acc + curr.cantdetventa * curr.precdetventa,
    0
  );

  export const formatFact = (item) =>{
    return (
      item.idtimbrado.idestablecimiento.establecimiento +
      "-" +
      item.idtimbrado.idpuntoexpedicion.puntoexpedicion +
      "-" +
      utilidad.completar_nrof(item.numfventa.toString())
    );
  }
  