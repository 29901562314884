
export default {
  getContratoFuncionario: (state) => {
    return state.contratofuncionario
  },
  getContratoFuncionarioById: (state) => {
    return state.contratofuncionariobyId
  },
  getDetContrato: (state) => {
    return state.detContratoFuncionario
  },
  isContratoFuncionarioLoading: (state) => {
    return state.isLoading
  }
}
