<template>
  <div>
    <c-alert-permission v-model="permission"></c-alert-permission>
    <v-toolbar flat dense>
      <v-toolbar-title>{{ $route.name }}</v-toolbar-title>
      <widgets-Favorite :ruta="$route.path" :formulario="$route.name" /> 
      <v-spacer></v-spacer>
      <c-text-table-search
        v-model="search"
        v-if="permission.can_view"
      ></c-text-table-search>
      <v-toolbar-items>
        <v-tabs
          color="deep-purple accent-4"
          right
          v-model="tabs"
          v-if="permission.can_view"
        >
          <v-tab v-for="(item, i) in tab" :key="i" :href="'#tab-' + i">{{
            item.title
          }}</v-tab>
        </v-tabs>
        <c-btn-table-add
          class="mr-1"
          @click="crud.add = true"
          v-if="permission.can_add"
        ></c-btn-table-add>
        <c-btn-table-reload
          @click="fetchUsers()"
          v-if="permission.can_view"
        ></c-btn-table-reload>
      </v-toolbar-items>
    </v-toolbar>
    <v-tabs-items v-model="tabs" v-if="permission.can_view">
      <v-tab-item v-for="(item, i) in tab" :key="i" :value="'tab-' + i">
        <Table :active="item.active" :search="search" />
      </v-tab-item>
    </v-tabs-items>
    <Crud v-if="crud.add" v-model="crud.add" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Table from "./Table";
import Crud from "./Crud";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { USUARIO } from "@/constants/forms";
import {
  PUEDE_AGREGAR,
  PUEDE_EDITAR,
  PUEDE_ELIMINAR,
  PUEDE_LISTAR,
} from "@/constants/permissions";
export default {
  components: {
    Table,
    Crud,
  },
  created() {
    this.$store.commit("SET_LAYOUT", "principal-layout");
  },
  mounted() {
    const permission = getPermission(USUARIO).permiso;
    this.permission = {
      can_add: permission[PUEDE_AGREGAR],
      can_view: permission[PUEDE_LISTAR],
      can_update: permission[PUEDE_EDITAR],
      can_delete: permission[PUEDE_ELIMINAR],
    };
  },
  methods: {
    ...mapActions("users", ["fetchUsersActive", "fetchUsersInactive"]),
    fetchUsers() {
      this.fetchUsersActive();
      this.fetchUsersInactive();
    },
  },
  data: () => ({
    tabs: null,
    search: "",
    crud: {
      add: false,
    },
    tab: [
      { active: true, title: "Activo" },
      { active: false, title: "Inactivo" },
    ],
    permission: {
      can_add: false,
      can_view: false,
      can_update: false,
      can_delete: false,
    },
  }),
};
</script>

<style>
</style>