<template>
  <div>
    <v-dialog
      :value="value"
      max-width="900px"
      persistent
      @keydown.esc="cancelar()"
    >
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>
            {{
              datos != null
                ? "Modificar Transferencia"
                : "Registrar Transferencia"
            }}
          </v-toolbar-title>
        </v-toolbar>
        <v-container fluid>
          <v-form ref="form">
            <v-row dense>
              <v-col cols="12" md="6" sm="6">
                <c-text-date
                  ref="transferencia1"
                  dense
                  outlined
                  label="Fecha"
                  v-model="data.fecha"
                  @keyup.native.enter="
                    next(1, data.fecha)
                  "
                ></c-text-date>
              </v-col>
              <v-col cols="12" md="6" sm="6">
                <vue-autocomplete-item-stock2
                  :idmoneda="null"
                  ref="transferencia2"
                  autocomplete="off"
                  :rules="true"
                  v-model="data.idstock"
                  @sync-input="search = $event"
                  @keyup.native.enter="next(2, data.idstock)"
                >
                
                </vue-autocomplete-item-stock2>
              </v-col>
              <v-col cols="12" md="12" sm="12">
                <vue-text-field
                  ref="transferencia3"
                  v-model="data.comentario"
                  label="Observacion"
                  :rules="false"
                  @keyup.native.enter="next(3, data.comentario)"
                ></vue-text-field>
              </v-col>
              <v-col
                cols="12"
                md="12"
                sm="12"
                v-if="data.idstock.idstock != ''"
              >
                <v-alert type="info" color="blue-grey darken-4">
                  <p>
                    Atencion! Ha seleccionado el siguiente item para la
                    transferencia.
                  </p>
                  <p>
                    <b>Item: </b>
                    {{ data.idstock.iditem.descitem }}
                    <b>Deposito: </b>
                    {{ data.idstock.iddeposito.desdeposito }}
                  </p>
                  <v-row dense>
                    <v-col cols="12" md="4" sm="4">
                      <v-chip class="ma-2" color="amber darken-4" label outlined>
                        Cantidad: {{ currencyFormat(data.idstock.cantstock) }}
                      </v-chip>
                    </v-col>
                    <v-col cols="12" md="4" sm="4">
                      <v-chip class="ma-2" color="lime" label outlined>
                        A transferir: {{ currencyFormat(transferir) }}
                      </v-chip>
                    </v-col>
                    <v-col cols="12" md="4" sm="4">
                      <v-chip
                        class="ma-2"
                        :color="sobra >= 0  ? `green accent-4` : `red darken-4`"
                        label
                        outlined
                      >
                        Sobra: {{ currencyFormat(sobra) }}
                      </v-chip>
                    </v-col>
                  </v-row>
                </v-alert>
              </v-col>
            </v-row>
          </v-form>
          <v-form ref="form_detalle">
            <v-row dense>
              <v-col cols="12" md="5" sm="5">
                <vue-autocomplete-object
                  ref="transferencia4"
                  id="trInput4"
                  action="deposito/Vact_deposito_data"
                  getter="deposito/Vget_deposito_data"
                  load="deposito/Vget_deposito_load"
                  :readonly="false"
                  validar="iddeposito"
                  pk="iddeposito"
                  return-object
                  label="Deposito"
                  title="desdeposito"
                  permission="Deposito"
                  v-model="detalle.iddeposito"
                  :create="true"
                  @create="formularios.deposito = $event"
                  @keyup.native.enter="next(4, detalle.iddeposito)"
                />
                <div v-if="formularios.deposito">
                  <Item-Deposito-Create v-model="formularios.deposito" />
                </div>
              </v-col>
              <v-col cols="12" md="5" sm="5">
                <c-text-currency
                  label="Cantidad"
                  ref="transferencia5"
                  :length="0"
                  dense
                  outlined
                  v-model="detalle.cantidad"
                  @keyup.native.enter="addtabla()"
                ></c-text-currency>
              </v-col>
              <v-col cols="12" md="2" sm="2">
                <v-btn text outlined fab small class="mr-2">
                  <v-icon color="primary" @click="addtabla()">add</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
          <DataTable-Detalle
            :headers="headers"
            :items="data.transferenciadetalle"
            @rowdelete="rowdelete($event)"
          />
        </v-container>
        <v-card-actions>
          <v-btn color="red" text @click="cancelar">
            <v-icon>clear</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="guardar()">
            <v-icon>done</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
      <c-dialog-confirmation
        v-model="formulario.editar"
        @cancel="formulario.editar = $event"
        @done="addSumOrReplace()"
      >
        <div class="subtitle-2">
          Ya existe este Deposito en el detalle, que desea hacer?
        </div>
        <v-radio-group class="mb-n6" v-model="formulario.sum_or_replace">
          <v-radio
            label="Reemplazar el deposito existente"
            :value="false"
          ></v-radio>
          <v-radio label="Sumar con el deposito existente" :value="true"></v-radio>
        </v-radio-group>
      </c-dialog-confirmation>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { current_date } from "@/services/util/date.service";
import { mensaje } from "@/services/notify.service";
import dataTableDetalle from "./Detalle";
import { currency } from "@/services/util/number.service";
export default {
  components: {
    "DataTable-Detalle": dataTableDetalle,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    datos: {
      type: [Object, Array],
      default: null,
    },
  },
  data() {
    return {
      error: [],
      transferir: 0,
      sobra: 0,
      formulario: {
        item: false,
        editar: false,
        sum_or_replace: true,
      },
      data: {
        search: null,
        idtransferencia: 0,
        idstock: {
          idstock: "",
        },
        iddeposito: null,
        iditem: null,
        fecha: current_date(),
        comentario: "",
        transferenciadetalle: [],
      },
      default: {
        idtransferencia: 0,
        idstock: {
          idstock: "",
        },
        iddeposito: null,
        iditem: null,
        fecha: current_date(),
        comentario: "",
        transferenciadetalle: [],
      },
      headers: [
        {
          text: "Deposito",
          sortable: false,
          value: "iddeposito.desdeposito",
        },
        { text: "Cantidad", sortable: false, value: "cantidad" },
        { text: "Accion", sortable: false, align: "right", value: "actions" },
      ],
      formularios: {
        deposito: false,
      },
      detalle: {
        iddeposito: {},
        cantidad: 0,
      },
    };
  },
  methods: {
    ...mapActions("stock", ["Vact_stock_data"]),
    ...mapActions("transferencia", [
      "Vact_transferencia_save", 
      "Vact_transferencia_update",
      "Vact_transferencia_data"
    ]),
    next(id, data) {
      if (data != "")
      try {
        this.$refs[`transferencia${id + 1}`].focus();
      } catch (error) {
        this.$refs[`transferencia${id + 1}`].$el.focus();
      }
    },
    cancelar() {
      this.data = JSON.parse(JSON.stringify(this.default));
      this.$emit("input", false);
      this.$emit("datos", null);
      //this.Vact_stock_data();
      this.Vact_transferencia_data();
    },
    rowdelete(item) {
      this.index = this.data.transferenciadetalle.indexOf(item);
      this.data.transferenciadetalle.splice(this.index, 1);
      this.index = null;
      this.calcularSobraTransferir();
    },
    validar_deposito(isdetalle) {
      let noduplica = true
      if (isdetalle) {
        if (this.detalle.iddeposito.iddeposito === this.data.idstock.iddeposito.iddeposito) {
          noduplica = false
        }
      } else {
        this.data.transferenciadetalle.forEach(x => {
          if (x.iddeposito.iddeposito === this.data.idstock.iddeposito.iddeposito) {
            noduplica = false
          }
        });
      }
      if (!noduplica) this.$notify(mensaje.error("No se puede realizar una transferencia a un mismo deposito"));
      return noduplica
    },
    verificar_valores() {
      let total = this.detalle.cantidad;
      this.data.transferenciadetalle.forEach(x => {
        total = total + x.cantidad;
      })
      if (total > this.data.idstock.cantstock) this.$notify(mensaje.error("No se puede agregar, ya supera la cantidad en stock"));
      return total < this.data.idstock.cantstock
    },
    verificar_duplicado() {
      const temp = this.data.transferenciadetalle.find(
        (x) => x.iddeposito.iddeposito === this.detalle.iddeposito.iddeposito || this.detalle.iddeposito === this.data.idstock.iddeposito.iddeposito
      );
      if (temp) {
        this.formulario.editar = true;
        this.item.temporal = temp;
      }
      return temp ? false : true;
    },
    addSumOrReplace() {
        const object = this.item.temporal;
        this.data.transferenciadetalle.splice(this.data.transferenciadetalle.indexOf(object), 1);
        if (this.formulario.sum_or_replace)
            this.detalle.cantidad = this.detalle.cantidad + object.cantidad;
        this.addtabla();
        this.formulario.editar = false;
    },
    async addtabla() {
      if (!this.$refs.form_detalle.validate() || !this.verificar_duplicado() || !this.verificar_valores() || !this.validar_deposito(true))
        return null;
      this.data.transferenciadetalle.push({
        ...this.detalle,
      });
      this.calcularSobraTransferir();
      this.next(3, "")
    },
    currencyFormat(value) {
      if (value != "") return currency(value);
      return "";
    },
    calcularSobraTransferir() {
      let resta = 0;
      this.data.transferenciadetalle.forEach((x) => {
        resta = resta + x.cantidad;
      });
      this.transferir = resta;
      this.sobra = this.data.idstock.cantstock - resta;
    },
    isValid() {
      if (!this.$refs.form.validate() || this.data.transferenciadetalle.length === 0) return false;
      return true;
    },
    async guardar() {
      if (!this.isValid()) return this.$notify(mensaje.completar());
      if (!this.validar_deposito(true)) return false;
      let response;
      this.data.iditem = this.data.idstock.iditem.iditem;
      this.data.iddeposito = this.data.idstock.iddeposito.iddeposito;
      if (this.datos != null) {
        response = await this.Vact_transferencia_update({
          id: this.datos.idtransferencia,
          data: this.data,
        });
      }
      if (this.datos === null) {
        response = await this.Vact_transferencia_save(this.data);
      }
      if (response.success) {
        this.$notify(response.info)
        return true
      } else {
        response.info.map((x) => {
          this.error = { ...response.data.response.data };
          this.$notify(mensaje.error(x));
        });
        return false
      }
    }
  },
};
</script>