<template>
  <div>
    <v-card-text>
      <h3 align="center">Crear Item</h3>
      <v-divider></v-divider>
      <v-row dense align="center" justify="center">
        <v-img class="mt-2" :src="require('@/assets/help/item/crearitem.png')" max-width="250"></v-img>
        <v-col cols="12">
          <ol>
            <li>Nombre del Item</li>
            <li>Precio de Compra</li>
            <li>Precio de Venta</li>
            <li>Categorial del Item</li>
            <span>
              <strong>Obs.</strong> Tenga en cuenta el iva que corresponde a la hora de asignar una categoria
            </span>
            <li>Marca del Item</li>
            <li>
              Opciones Avanzadas
              <a @click="masAdv = !masAdv">{{masAdv ? 'Menos' : 'Mas'}}</a>
            </li>
            <div v-if="masAdv">
              <v-img
                class="mt-2"
                :src="require('@/assets/help/item/opcionavanzada.png')"
                max-width="250"
              ></v-img>
              <ol>
                <li>Es un ser vivo?</li>
                <span>
                  <strong>Obs.</strong> Especifique si se trata de un ser vivo o no cuando use el modulo de
                  ganaderia.
                </span>
                <li>Controlar stock del Item</li>
                <li>Opciones de Precio Venta</li>
                <ul>
                  <li>SI</li>
                  <span>
                    El sistema calculara el precio de Venta usando como cantidad de porcentaje
                    lo que se especifice en el campo "Precio de Venta"
                  </span>
                  <li>NO</li>
                  <span>El sistema toma como precio de Venta lo que se especifique en el campo "Precio de Venta"</span>
                </ul>
                <li>Unidad de medida del Item</li>
                <li>Contenido del Item en la unidad</li>
                <span>
                  <strong>Obs.</strong> Especifique cuantas unidades contiene el item. Ejemplo:
                  <br />Item = Bolsa 10 Kg => Contiene 10
                </span>
                <li>Moneda del precio de Compra y Venta</li>
                <li>Cantidad Minima del Item</li>
                <span>
                  <strong>Obs.</strong> Una vez llegado al limite el sistema te notifica que ese item ya
                  llego al limite de stock
                </span>
                <li>Cuenta Contable del Item</li>
                <li>Tipo de Item</li>
                <span>Para habilitar el Item en un modulo especifico</span>
                <ul>
                  <li>
                    <strong>Ser vivo</strong> habilita en Ganaderia
                  </li>
                  <li>
                    <strong>Medicamento Uso Interno</strong> habilita en Medicacion
                  </li>
                  <li>
                    <strong>Medicamento Uso Externo</strong> habilita en Medicacion
                  </li>
                  <li>
                    <strong>Produccion</strong> habilita en Produccion Ganaderia
                  </li>
                  <li>
                    <strong>Siembra</strong> habilita en Agro
                  </li>
                  <li>
                    <strong>Aplicacion</strong> habilita en Agro
                  </li>
                  <li>
                    <strong>Default</strong> generico
                  </li>
                  <li>
                    <strong>Fumigacion</strong> habilita en Agro
                  </li>
                </ul>
              </ol>
            </div>
            <li>Propiedades Quimicas <a @click="masPro = !masPro">{{masPro ? 'Menos' : 'Mas'}}</a></li>
            
            <div v-if="masPro">
              <v-img
                class="mt-2"
                :src="require('@/assets/help/item/propiedadquimica.png')"
                max-width="250"
              ></v-img>
              <span>Esta opcion es especialmente util con el modulo de Agro</span>
              <ol>
                <li>Composicion Quimica</li>
                <li>Porcentaje de Principio Activo</li>
                <span><strong>Obs.</strong> Puede agregar tantas propiedades como desea con el boton <v-icon color="primary" >add</v-icon></span>
              </ol>
            </div>
          </ol>
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
export default {
  data() {
    return {
      masAdv: false,
      masPro: false
    };
  }
};
</script>

<style>
</style>