<template>
    <div>
        <Help v-model="help" :content="todos"/>
        <Movimiento v-if="crud.view" v-model="crud.view" :props="crud.datos" />
        <Crud v-if="crud.add" v-model="crud.add" :datos="crud.datos" @datos="crud.datos = $event" />
        <v-alert type="error" v-if="permission_alert">
            Error! Este usuario no tiene permiso en esta ventana
        </v-alert>
        <v-toolbar flat dense>
            <v-toolbar-title>{{ $route.name }}</v-toolbar-title>
            <v-divider class="mx-2" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <c-btn-table-help @click="help = true"></c-btn-table-help>
                <c-btn-table-add class="ml-1" @click="crud.add = true" v-if="permission.can_add"></c-btn-table-add>
                <c-btn-table-reload class="ml-1" @click="load()"></c-btn-table-reload>
                <c-btn-table-hide class="ml-1" :hide="hide" @click="hide = !hide"></c-btn-table-hide>
            </v-toolbar-items>
        </v-toolbar>
        <v-container>
            <v-row dense v-if="permission.can_view">
                <v-col cols="12" lg="3" md="4" sm="12" v-for="(item, index) in getSaldo" :key="index">
                    <v-card color="#385F73" dark @click="verMovimiento(item)">
                        <v-card-title class="text-h5">
                            {{ item.name }}
                        </v-card-title>
                        <v-card-subtitle>
                            {{ hide ? hidden(item.value) : toCurrency(item.value) }}
                        </v-card-subtitle>
                    </v-card>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col v-for="(item, index) in obtener" :key="index" :md="item.md" :cols="item.col">
                    <Doughnut :title="item.title" :showtitle="showtitle" :api="item.api"
                        :loadDoughnut="loadPresupuesto" />
                </v-col>
            </v-row>
            <v-row dense>
                <v-col v-for="(item, index) in presupuesto" :key="index" :md="item.md" :cols="item.col">
                    <Category :title="item.title" :showtitle="showtitle" :api="item.api"
                        :loadPresupuesto="loadPresupuesto" />
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { mensaje } from "@/services/notify.service";
import { FINANZA } from "@/constants/forms";
import { currency } from "@/services/util/number.service";
import {
    PUEDE_AGREGAR,
    PUEDE_EDITAR,
    PUEDE_ELIMINAR,
    PUEDE_LISTAR,
} from "@/constants/permissions";
import Crud from "../Movimiento/Crud.vue";
import Movimiento from "./Movimiento.vue";
import Doughnut from "./Doughnut.vue";
import Category from "./Category.vue";
import Help from "../Help.vue";
import { TODOS } from "@/constants/finanzas";
export default {
    components: {
        Crud,
        Movimiento,
        Doughnut,
        Category,
        Help,
    },
    computed: {
        ...mapGetters("finanza", ["getSaldo", "isLoadingSaldo"]),
        permission_alert() {
            return mensaje.permission(this.permission);
        },
    },
    created() {
        this.$store.commit("SET_LAYOUT", "principal-layout");
        
        this.todos = TODOS
        const permission = getPermission(FINANZA).permiso;
        this.permission = {
            can_add: permission[PUEDE_AGREGAR],
            can_view: permission[PUEDE_LISTAR],
            can_update: permission[PUEDE_EDITAR],
            can_delete: permission[PUEDE_ELIMINAR],
        };
        this.fetchSaldo();
    },
    methods: {
        ...mapActions("finanza", ["fetchSaldo"]),
        hidden(val) {
            return '*'.repeat(val.toString().length)
        },
        toCurrency(value) {
            return currency(value)
        },
        verMovimiento(value) {
            this.crud.view = true
            this.crud.datos = value
        },
        load() {
            this.fetchSaldo()
            this.loadPresupuesto = !this.loadPresupuesto
        }
    },
    data: () => ({
        todos: [],
        loadPresupuesto: true,
        hide: true,
        help: false,
        permission: {
            can_add: false,
            can_view: false,
        },
        crud: {
            add: false,
            view: false,
            datos: null,
        },
        showtitle: true,
        obtener: [
            {
                api: "/apifinanzagastos/",
                title: "Gastos",
                md: 12,
                col: 12,
            },
            {
                api: "/apifinanzaingresos/",
                title: "Ingresos",
                md: 12,
                col: 12,
            }
        ],
        presupuesto: [
            {
                api: "/apifinanzapresupuestos/",
                title: "Presupuestos",
                md: 12,
                col: 12,
            }
        ]
    })
}
</script>