export default {
  campo_ha(state, request) {
    state.campo_ha = request;
  },
  campo_data(state, request) {
    state.campo_data = request;
  }, 
  campo_clima(state, request) {
    state.campo_clima = request;
  }, 
  campo_storyndvi(state, request) {
    state.campo_storyndvi = request;
  }, 
  campo_pronostico(state, request) {
    state.campo_pronostico = request;
  }, 
  campo_geo_json(state, request) {
    state.campo_geo_json = request;
  }, 
  campo_load(state, request) {
    state.campo_load = request;
  }, 
  campo_save_load(state, request) {
    state.campo_save_load = request;
  }, 
  campo_deleted(state, request) {
    state.campo_deleted = request;
  },
  campo_baja_load(state, request) {
    state.campo_baja_load = request;
  }
}