<template>
  <div>
    <v-card>
      <v-alert type="error" v-if="permission_alert"
        >Error!!! Este usuario no tiene permisos en esta ventana</v-alert
      >
      <v-toolbar flat dense>
        <v-toolbar-title>{{ titulo }}</v-toolbar-title>
        <widgets-Favorite ruta="/stock" formulario="Stock" />
        <v-divider class="mx-2" inset vertical></v-divider>

        <v-spacer></v-spacer>
        <v-text-field
          v-model="searchstock"
          append-icon="search"
          label="Busqueda"
          single-line
          hide-details
          v-if="permission.can_view"
        ></v-text-field>
      </v-toolbar>
      <v-tabs color="deep-purple accent-4" right>
        <v-tab>Lista</v-tab>
        <v-tab>General</v-tab>
        <v-tab>Avanzado</v-tab>

        <v-tab-item :key="1">
          <v-row dense>
            <v-spacer></v-spacer>
            <v-btn
              class="ma-2"
              text
              fab
              small
              color="red"
              outlined
              @click="generarPdf('simple')"
            >
              <v-icon>get_app</v-icon>
            </v-btn>
            <v-col cols="12">
              <v-data-table
                :loading="Vget_stock_load"
                :headers="headers"
                :items="Vget_stock_data"
                :search="searchstock"
                v-if="permission.can_view"
              >
                <template v-slot:[`item.cant`]="props">
                  <p class="text-end ma-2">
                    {{ toCurrency(props.item.cant) }}
                  </p>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item :key="2">
          <v-form ref="form">
            <v-row dense class="ma-2">
              <v-col>
                <v-autocomplete
                  :items="tipoReporte"
                  item-text="descripcion"
                  item-value="id"
                  :rules="validar"
                  autocomplete="off"
                  hide-details
                  label="Tipo Reporte"
                  id="input1"
                  @keyup.enter="next(1, data.tiporeporte)"
                  v-model="data.tiporeporte"
                ></v-autocomplete>
              </v-col>
              <v-col>
                <v-autocomplete
                  :search-input.sync="searchitem"
                  
                  v-if="data.tiporeporte === 'item'"
                  :items="Vget_item_data"
                  item-text="descitem"
                  item-value="iditem"
                  :rules="validar"
                  autocomplete="off"
                  hide-details
                  :loading="Vget_item_load"
                  label="Item"
                  id="input2"
                  @keyup.enter="next(2, data.idtiposeleccionado)"
                  v-model="data.idtiposeleccionado"
                ></v-autocomplete>
                <v-autocomplete
                  v-if="data.tiporeporte === 'deposito'"
                  :items="Vget_deposito_data"
                  item-text="desdeposito"
                  item-value="iddeposito"
                  :rules="validar"
                  autocomplete="off"
                  hide-details
                  :loading="Vget_deposito_load"
                  label="Deposito"
                  id="input2"
                  @keyup.enter="next(2, data.idtiposeleccionado)"
                  v-model="data.idtiposeleccionado"
                ></v-autocomplete>
              </v-col>
              <v-btn
                text
                outlined
                color="primary"
                small
                fab
                class="ma-2"
                @click="descargarDatos()"
              >
                <v-icon>cloud_download</v-icon>
              </v-btn>
              <v-btn
                v-if="datos != ''"
                class="ma-2"
                text
                fab
                small
                color="red"
                outlined
                @click="generarPdf('avanzado')"
              >
                <v-icon>get_app</v-icon>
              </v-btn>
              <v-col cols="12">
                <v-data-table
                  :loading="isLoading"
                  :headers="headers_reporte"
                  :items="datos"
                  :search="searchstock"
                  class="elevation-1"
                  v-if="permission.can_reporte"
                >
                  <template v-slot:[`item.value`]="props">
                    <p class="text-end ma-2">
                      {{ toCurrency(props.item.value) }}
                    </p>
                  </template>
                  <template v-slot:[`item.disponible`]="props">
                    <p class="text-end ma-2">
                      {{ toCurrency(props.item.disponible) }}
                    </p>
                  </template>
                  <template v-slot:[`item.despachar`]="props">
                    <p class="text-end ma-2">
                      {{ toCurrency(props.item.despachar) }}
                    </p>
                  </template>
                  <template v-slot:[`item.vendido_mes`]="props">
                    <p class="text-end ma-2">
                      {{ toCurrency(props.item.vendido_mes) }}
                    </p>
                  </template>
                  <template v-slot:[`item.vendido_year`]="props">
                    <p class="text-end ma-2">
                      {{ toCurrency(props.item.vendido_year) }}
                    </p>
                  </template>
                  <template v-slot:no-data>
                    <v-alert :value="true" color="info" icon="info" outlined
                      >No existe ningun registro</v-alert
                    >
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-form>
        </v-tab-item>
        <v-tab-item :key="3">
          <v-form ref="form2">
            <v-row dense class="ma-2">
              <v-col>
                <v-autocomplete
                  :items="Vget_deposito_data"
                  item-text="desdeposito"
                  item-value="iddeposito"
                  multiple
                  autocomplete="off"
                  hide-details
                  :loading="Vget_deposito_load"
                  label="Seleccione los depositos"
                  id="input1"
                  v-model="buscar.iddeposito"
                ></v-autocomplete>
              </v-col>
              <v-btn
                text
                outlined
                color="primary"
                small
                fab
                class="ma-2"
                @click="reporte_deposito()"
              >
                <v-icon>cloud_download</v-icon>
              </v-btn>
              <v-btn class="ma-2" text fab small color="red" outlined>
                <v-icon>get_app</v-icon>
              </v-btn>
            </v-row>
          </v-form>
          <v-data-table
            :items="Vget_deposito_reporte"
            :headers="headers_deposito"
            dense
            hide-default-footer
            :items-per-page="10000"
            show-expand
            @click:row="clickRow"
            :expanded="expanded"
            v-if="permission.can_reporte"
          >
            <template v-slot:[`expanded-item`]="{ item }">
              <td :colspan="headers_deposito.length">
                <v-row dense row>
                  <v-col cols="12">
                    <v-data-table
                      :headers="headers_deposito_detalle"
                      :items="item.detalle"
                      dense
                      hide-default-footer
                      :items-per-page="10000"
                    >
                      <template v-slot:[`item.value`]="{ item }">
                        <div class="text-end">
                          {{ toCurrency(item.value) }}
                        </div>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </td>
            </template>
          </v-data-table>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { db } from "@/services/db.service";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { mensaje } from "@/services/notify.service";
import { currency } from "@/services/util/number.service";
import exportAvanzado from "./Export";
import { STOCK } from "@/constants/forms";
import {
  PUEDE_AGREGAR,
  PUEDE_EDITAR,
  PUEDE_ELIMINAR,
  PUEDE_LISTAR,
  PUEDE_GENERAR_REPORTE,
} from "@/constants/permissions";

export default {
  data() {
    return {
      searchitem: "",
      awaitingSearch: false,
      formulario: {
        crud: false,
        search: "",
      },
      expanded: [],
      validar: [(v) => !!v || "Seleccion un campo"],
      searchstock: "",
      headers: [
        { text: "Item", align: "left", value: "desc" },
        { text: "Deposito", align: "left", value: "depo" },
        { text: "Cantidad", align: "right", value: "cant" },
      ],
      headers_reporte: [
        { text: "Item", align: "left", value: "name" },
        { text: "Deposito", align: "left", value: "deposito" },
        { text: "Cant Existencia", align: "right", value: "value" },
        { text: "Cant Disponible", align: "right", value: "disponible" },
        { text: "Cant Despachar", align: "right", value: "despachar" },
        { text: "Ventas Mes", align: "right", value: "vendido_mes" },
        { text: "Ventas Año", align: "right", value: "vendido_year" },
      ],
      headers_deposito: [
        { text: "Id", align: "left", value: "id" },
        { text: "Deposito", align: "left", value: "name" },
        { text: "", align: "left", value: "data-table-expand" },
      ],
      headers_deposito_detalle: [
        { text: "Id", align: "left", value: "id" },
        { text: "Item", align: "left", value: "name" },
        { text: "Cantidad", align: "right", value: "value" },
      ],
      titulo: STOCK,
      objeto: "",
      index: "",
      permission: {
        can_add: false,
        can_view: false,
        can_update: false,
        can_delete: false,
        can_reporte: false
      },
      tipoReporte: [
        {
          descripcion: "Item",
          id: "item",
        },
        {
          descripcion: "Deposito",
          id: "deposito",
        },
      ],
      data: {
        tiporeporte: "",
        idtiposeleccionado: "",
      },
      buscar: {
        iddeposito: [],
      },
      isLoading: false,
      datos: [],
    };
  },

  computed: {
    ...mapGetters("stock", ["Vget_stock_data", "Vget_stock_load"]),
    ...mapGetters("item", ["Vget_item_data", "Vget_item_load"]),
    ...mapGetters("deposito", [
      "Vget_deposito_data",
      "Vget_deposito_reporte",
      "Vget_deposito_load",
    ]),
    permission_alert() {
      return mensaje.permission(this.permission);
    },
  },
  watch: {
    $route(to) {
      if (to.path === "/stock") return this.Vact_stock_data();
    },
    searchitem(val) {
      this.$emit("sync-input", val);
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.obtain_item();
          this.awaitingSearch = false;
        }, 1000);
      }
      this.formulario.search = val;
      this.awaitingSearch = true;
    },
  },
  created() {
    this.$store.commit("SET_LAYOUT", "principal-layout");
    this.Vact_stock_data();
    const permission = getPermission(STOCK).permiso;
    this.permission = {
      can_add: permission[PUEDE_AGREGAR],
      can_view: permission[PUEDE_LISTAR],
      can_update: permission[PUEDE_EDITAR],
      can_delete: permission[PUEDE_ELIMINAR],
      can_reporte: permission[PUEDE_GENERAR_REPORTE],
    };
  },
  mounted() {
    this.Vact_deposito_data();
  },
  methods: {
    ...mapActions("stock", ["Vact_stock_data"]),
    ...mapActions("item", ["Vact_item_data"]),
    ...mapActions("deposito", ["Vact_deposito_data", "Vact_deposito_reporte"]),
    toCurrency(value) {
      return currency(value);
    },
    async descargarDatos() {
      if (!this.$refs.form.validate()) return null;
      this.isLoading = true;
      try {
        const tipo = this.data.tiporeporte;
        const id = this.data.idtiposeleccionado;
        this.datos = await db.get(`/apireportestock/${tipo}/${id}/`);
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
    generarPdf(mode) {
      let body;
      switch (mode) {
        case "avanzado":
          body = this.datos.map((x) => {
            return [
              x.name,
              x.deposito,
              this.toCurrency(x.value),
              this.toCurrency(x.disponible),
              this.toCurrency(x.despachar),
              this.toCurrency(x.vendido_mes),
              this.toCurrency(x.vendido_year),
            ];
          });
          exportAvanzado(body, this.headers_reporte);
          break;
        case "simple":
          body = this.Vget_stock_data.map((x) => {
            return [x.desc, x.depo, this.toCurrency(x.cant)];
          });
          exportAvanzado(body, this.headers);
          break;
      }
    },
    reporte_deposito() {
      let iddeposito = JSON.stringify(this.buscar.iddeposito)
        .replace("[", "")
        .replace("]", "")
        .replaceAll(",", "-");
      this.Vact_deposito_reporte(iddeposito);
    },
    clickRow(item, event) {
      if (event.isExpanded) {
        const index = this.expanded.findIndex((i) => i === item);
        this.expanded.splice(index, 1);
      } else {
        this.expanded.push(item);
      }
    },
    obtain_item() {
      if (this.searchitem != null && this.searchitem.length >= 3) {
        this.Vact_item_data({
          desc: this.searchitem,
        });
      }
    },
    search() {
      this.item.forEach((x) => {
        if (x.iditem === this.searchItemId) {
          this.$emit("input", x);
          this.$emit("change");
          this.$emit("resetSearch", null);
          return;
        }
      });
    },
  },
};
</script>