<template>
  <div>
    <v-container>
      <v-row no-gutters>
        <v-col cols="5">
          <v-select
            :items="tileType"
            v-model="tile.selected"
            dense
            append-icon
            item-value="value"
            item-text="text"
            id="mapaInput1"
            ref="input1"
            outlined
            @keyup.enter="
              next(1, tile.selected), ($refs.input1.isMenuActive = false)
            "
          ></v-select>
        </v-col>
        <v-col cols="7">
          <v-select
            :items="Vget_integralimages"
            v-model="tile.data"
            dense
            append-icon
            return-object
            item-text="generationdate"
            outlined
            id="mapaInput2"
            ref="input2"
            @keyup.enter="next(2, tile.data), ($refs.input2.isMenuActive = false)"
          ></v-select>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12"></v-col>
        <v-col cols="12" md="6">
          <c-text-date
            label="Desde"
            id="mapaInput4"
            @focus="setSelection('mapaInput4')"
            v-model="fechaInicio"
            @keyup.native.enter="next(4, fechaInicio)"
          ></c-text-date>
        </v-col>
        <v-col cols="12" md="6">
          <c-text-date
            label="Hasta"
            id="mapaInput5"
            @focus="setSelection('mapaInput5')"
            v-model="fechaFinal"
            @keyup.native.enter="next(5, fechaFinal)"
          ></c-text-date>
        </v-col>
        <v-col cols="12" md="12">
          <v-btn
            block
            color="primary"
            id="mapaInputaceptar"
            @click="filtrar($route.query)"
            >Filtrar</v-btn
          >
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {
  substract_days,
  current_date,
} from "@/services/util/date.service";
import { focus } from "@/services/focus.service";
import { mapActions, mapGetters } from "vuex";
export default {
  mounted() {
    this.filtrar(this.Vget_agromap_coordinates);
    this.next(0, "-");
  },
  watch: {
    tileSectected() {
      this.Vact_agromap_tile(this.tile);
    },
    tileData() {
      this.Vact_agromap_tile(this.tile);
    },
    Vget_agromap_coordinates(val) {
      if (val != null) {
        this.filtrar(val);
      }
    },
  },
  computed: {
    ...mapGetters("agromap", [
      "Vget_agromap_coordinates",
      "Vget_integralimages",
    ]),
    tileSectected() {
      return this.tile.selected;
    },
    tileData() {
      return this.tile.data;
    },
  },
  methods: {
    ...mapActions("agromap", ["Vact_agromap_tile", "Vact_integralimages"]),
    async next(id, data) {
      focus.nextidName("mapaInput", id, data);
    },
    setSelection(id) {
      setTimeout(() => {
        let input = document.getElementById(id);
        input.focus();
        input.setSelectionRange(0, 30);
      }, 20);
    },
    filtrar(val) {
      const fechaInicio = this.fechaInicio;
      const fechaFinal = this.fechaFinal;
      this.Vact_integralimages({
        fechaInicio,
        fechaFinal,
        key: val.id_polygon || val.polygon,
      })
    },
  },
  data() {
    return {
      satellite: [],
      mes: [],
      fechaInicio: substract_days(30),
      fechaFinal: current_date(),
      tile: {
        data: null,
        selected: "google",
      },
      tileType: [
        {
          value: "google",
          text: "Google",
        },
        {
          value: "contrasted_ndvi",
          text: "Contrasted NDVI",
        }
      ],
    };
  },
};
</script>

<style>
</style>