export default {
  contacto_create: (state) => {
    return state.contacto_create;
  },
  contacto_update: (state) => {
    return state.contacto_update;
  },
  contacto_reload: (state) => {
    return state.contacto_reload;
  },
  contacto_key: (state) => {
    return state.contacto_key;
  },
  contacto_show: (state) => {
    return state.contacto_show;
  },
  isLoadingContacto: (state) => state.isLoadingContacto,
  getContactoFirebase: (state) => state.contacto_firebase,
  Vget_contacto_data_cliente: (state) => {
    return state.contacto_data_cliente;
  },
  Vget_contacto_data_proveedor: (state) => {
    return state.contacto_data_proveedor;
  },
  Vget_contacto_data_transportadora: (state) => {
    return state.contacto_data_transportadora;
  },
  Vget_contacto_load: (state) => {
    return state.contacto_load;
  },
};
