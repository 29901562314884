
export default {


  Vget_gymtipo_data: (state) => {
    return state.gymtipo_data
  },
  Vget_gymtipo_update: (state) => {
    return state.gymtipo_update
  },
  Vget_gymtipo_load: (state) => {
    return state.gymtipo_load
  },
  Vget_gymtipo_save_load: (state) => {
    return state.gymtipo_save_load
  },
}
