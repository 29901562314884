
export default {
  Vget_transladobancocaja_data: (state) => {
    return state.transladobancocaja_data
  },
  Vget_transladobancocaja_load: (state) => {
    return state.transladobancocaja_load
  },
  Vget_transladobancocaja_save_load: (state) => {
    return state.transladobancocaja_save_load
  },
}
