<template>
  <div>
    <span class="ml-3 subtitle-2 font-weight-medium">Generar Reporte por</span>
    <v-form ref="form">
      <v-row dense class="mx-1">
        <v-col cols="12" sm="3">
          <AutocompleteTipoReporte v-model="tiporeporte" /> </v-col
      ></v-row>
      <v-row class="ma-1" dense>
        <v-col cols="12" sm="2" v-if="tiporeporte.value === 'animal'">
          <AutocompleteFilter label="Filtrar por grupo" v-model="band" />
        </v-col>
        <v-col cols="12" sm="3" v-if="tiporeporte.value === 'animal'">
          <AutocompleteAnimal
            action="fetchTodos"
            getter="getTodos"
            no-create
            rounded
            :rules="[]"
            filled
            :outlined="false"
            multiple
            v-model="data.seleccionado"
          />
        </v-col>
        <v-col cols="12" sm="3" v-if="tiporeporte.value === 'estado'">
          <AutocompleteEstadoAnimal
            multiple
            rounded
            filled
            :outlined="false"
            v-model="data.seleccionado"
          />
        </v-col>
        <v-col cols="12" sm="3" v-if="tiporeporte.value === 'raza'">
          <AutocompleteRaza
            multiple
            rounded
            filled
            :outlined="false"
            v-model="data.seleccionado"
          />
        </v-col>
        <v-col cols="12" sm="3" v-if="tiporeporte.value === 'lote'">
          <AutocompleteLote
            multiple
            rounded
            filled
            :outlined="false"
            v-model="data.seleccionado"
          />
        </v-col>
        <v-col cols="12" sm="3" v-if="tiporeporte.value === 'item'">
          <AutocompleteItem
            multiple
            rounded
            filled
            :outlined="false"
            v-model="data.seleccionado"
          />
        </v-col>
        <v-col cols="12" sm="2">
          <c-text-date
            rounded
            filled
            label="Fecha Desde"
            v-model="data.desde"
          ></c-text-date>
        </v-col>
        <v-col cols="12" sm="2">
          <c-text-date
            rounded
            filled
            label="Fecha Hasta"
            v-model="data.hasta"
          ></c-text-date>
        </v-col>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          fab
          small
          class="mt-3"
          outlined
          text
          @click="obtenerDatos()"
        >
          <v-icon>cloud_download</v-icon>
        </v-btn>
        <v-btn
          color="red"
          fab
          small
          class="ml-1 mt-3"
          outlined
          text
          @click="exportExcel()"
        >
          <v-icon>get_app</v-icon>
        </v-btn>
      </v-row>
    </v-form>

    <v-row dense class="mx-1">
      <v-col cols="12" sm="6">
        <v-select
          dense
          label="Ver"
          multiple
          outlined
          :items="mostrar"
          v-model="datatable.mostrar"
        ></v-select>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="getData"
      :loading="isLoading"
      :items-per-page="999999999"
      sort-by="fecha"
    >
      <template v-slot:[`item.tipo`]="{ item }">
        <div v-html="formatTipo(item)"></div>
      </template>
      <template v-slot:[`item.accion`]="{ item }">
        <v-btn
          elevation="1"
          class="my-1"
          fab
          text
          x-small
          @click="irFicha(item.idanimal)"
          ><v-icon>list_alt</v-icon></v-btn
        >
      </template>
    </v-data-table>
  </div>
</template>
<script>
import AutocompleteFilter from "../Animal/AutocompleteFilter";
import AutocompleteEstadoAnimal from "../Estadoanimal/Autocomplete";
import AutocompleteTipoReporte from "./TipoReporte";
import AutocompleteRaza from "../Raza/Autocomplete";
import AutocompleteLote from "../Lote/Autocomplete";
import AutocompleteItem from "@/views/Item/Item/AutocompleteItemVivo";
import { exportActividades, formatTipoHtml } from "./export";
import { mapActions, mapGetters } from "vuex";
import {
  dmy,
  current_first_date,
  current_last_date,
} from "@/services/util/date.service";
const TIPOS = [
  "Aborto",
  "Analisis",
  "Enfermedad",
  "Inseminacion",
  "Muerte",
  "Celo",
  "Parto",
  "Medicacion",
  "Palpacion",
  "Secado",
  "Preparto",
  "Venta",
];
export default {
  components: {
    AutocompleteEstadoAnimal,
    AutocompleteTipoReporte,
    AutocompleteFilter,
    AutocompleteRaza,
    AutocompleteItem,
    AutocompleteLote,
    AutocompleteAnimal: () =>
      import("@/views/Animal/Animal/AutocompleteAnimal"),
  },
  computed: {
    ...mapGetters("animal", ["getReporteAvanzado", "isLoading"]),
    getData() {
      let fecha = [];
      this.getReporteAvanzado.map((x) => {
        const row = {
          ...x,
          fecha: dmy(x.fecha),
          rp: x.rp,
          nombre: x.nombre,
        };
        if (this.datatable.mostrar.find((x) => x === row.operacion))
          fecha.push(row);
      });
      return fecha;
    },
  },
  methods: {
    ...mapActions("animal", ["fetchReporteAvanzado"]),
    exportExcel() {
      exportActividades(this.getData);
    },
    irFicha(idanimal) {
      this.$router.push({ path: "/reporteanimal", query: { idanimal } });
    },
    formatTipo(item) {
      return formatTipoHtml(item)
    },
    obtenerSeleccion() {
      const selects = this.data.seleccionado.reduce((acc, curr) => {
        if (acc === "") return (acc = curr);
        return (acc = acc + "-" + curr);
      }, "");
      return selects;
    },
    async obtenerDatos() {
      if (!this.$refs.form.validate()) return null;
      const tipo = this.tiporeporte.value;
      const select = this.obtenerSeleccion();
      const url =
        "band=" +
        this.band.band +
        "&inicio=" +
        this.data.desde +
        "&fin=" +
        this.data.hasta +
        "&por=" +
        tipo;
      const params = `${url}${
        select !== "" ? "&" + tipo + "=" + this.obtenerSeleccion() : ""
      }`;
      await this.fetchReporteAvanzado(params);
    },
  },
  data: () => ({
    mostrar: TIPOS,
    headers: [
      {
        text: "Fecha",
        align: "start",
        value: "fecha",
      },
      {
        text: "Animal",
        align: "start",
        sortable: false,
        value: "rpanimal",
      },
      { text: "Operacion", value: "operacion" },
      { text: "Tipo", value: "tipo" },
      { text: "Comentario", value: "comentario", align: "end" },
      { text: "Accion", value: "accion", sortable: false, align: "right" },
    ],
    tiporeporte: {
      text: "Animal",
      value: "animal",
    },
    band: {
      text: "Vivos",
      action: "fetchVivo",
      getter: "getVivo",
      band: "vivo",
    },
    datatable: {
      agrupar: "Fecha",
      mostrar: TIPOS,
    },
    data: {
      seleccionado: [],
      desde: current_first_date(),
      hasta: current_last_date(),
    },
  }),
};
</script>
