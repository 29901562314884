<template>
  <div>
    <div v-if="crearPuntoVenta">
      <Venta-PuntoVenta-create v-model="crearPuntoVenta" :editar="datos" @editar="datos = $event" />
    </div>
    <div v-if="verPuntoVenta">
        <Venta-PuntoVenta-show v-model="verPuntoVenta" :mostrar="datos" @mostrar="datos = $event" />
    </div>
    <v-card>
      <v-alert
        type="error"
        v-if="permission_alert"
      >Error!!! Este usuario no tiene permisos en esta ventana</v-alert>
      <v-toolbar flat>
        <v-toolbar-title>{{titulo}}</v-toolbar-title>
        <widgets-Favorite ruta="/puntoventa" formulario="Punto de Venta" />
        <v-divider class="mx-2" inset vertical></v-divider>

        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Busqueda"
          single-line
          hide-details
          v-if="permission.can_view"
        ></v-text-field>
        <v-btn
          color="primary"
          small
          fab
          dark
          @click="crearPuntoVenta = true"
          v-if="permission.can_add"
        >
          <v-icon dark>add</v-icon>
        </v-btn>
      </v-toolbar>
      <v-data-table
        :loading="Vget_puntoventa_load"
        :headers="headers"
        :items="Vget_puntoventa_data"
        :search="search"
        class="elevation-1"
        v-if="permission.can_view"
      >
        <template v-slot:[`item.accion`]="props">
          <v-icon class="mr-2" @click="rowselect(props.item)">visibility</v-icon>
          <v-icon class="mr-2" @click="mupdate(props.item)" v-if="permission.can_update">edit</v-icon>
          <v-icon small @click="meliminar(props.item)" v-if="permission.can_delete">delete</v-icon>
        </template>
        <template v-slot:no-data>
          <v-alert :value="true" color="info" icon="info" outlined>No existe ningun registro</v-alert>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="dialogoeliminar" persistent max-width="400">
      <v-card>
        <v-card-title>
          <v-icon left>info</v-icon>
          <span class="title font-weight-light">Ventana de Confirmacion</span>
        </v-card-title>
        <v-card-text>Desea eliminar el punto de venta <strong>{{ objeto.descpuntoventa }}</strong>?</v-card-text>
        <v-card-actions>
          <v-btn color="red" text @click="eliminarcancelar">
            <v-icon>clear</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="meliminarguardar">
            <v-icon>done</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
      <c-overlay :value="overlay"></c-overlay>
    </v-dialog>
  </div>
</template>
<script>
import { getPermission } from "@/services/storage/permissionStorage.service";
import { mensaje } from "@/services/notify.service";
import { mapActions, mapGetters } from "vuex";
import { PUNTO_VENTA } from "@/constants/forms";
import {
    PUEDE_AGREGAR,
    PUEDE_EDITAR,
    PUEDE_ELIMINAR,
    PUEDE_LISTAR,
} from "@/constants/permissions";

export default {
  data() {
    return {
      search: "",
      titulo: PUNTO_VENTA,
      overlay: false,
      dialogoeliminar: false,
      objeto: {
        descpuntoventa: "",
        idpuntoventa: ""
      },
      permission: {
        can_add: false,
        can_view: false,
        can_update: false,
        can_delete: false
      },
      headers: [
        { text: "#", align: "left", value: "idpuntoventa" },
        { text: "Punto de venta", align: "left", value: "descpuntoventa" },
        { text: "Caja", align: "left", value: "idcaja.descaja" },
        { text: "Accion", value: "accion", sortable: false, align: "right" }
      ],
      crearPuntoVenta: false,
      verPuntoVenta: false,
      datos: null
    };
  },

  computed: {
    ...mapGetters("puntoventa", [
      "Vget_puntoventa_data",
      "Vget_puntoventa_load"
    ]),
    permission_alert() {
      return mensaje.permission(this.permission);
    }
  },
  created() {
    this.$store.commit("SET_LAYOUT", "principal-layout");
   const permission = getPermission(PUNTO_VENTA).permiso;
this.permission = {
    can_add: permission[PUEDE_AGREGAR],
    can_view: permission[PUEDE_LISTAR],
    can_update: permission[PUEDE_EDITAR],
    can_delete: permission[PUEDE_ELIMINAR],
};
  },
  mounted() {
    this.Vact_puntoventa_data();
  },
  methods: {
    ...mapActions("puntoventa", ["Vact_puntoventa_data","Vact_puntoventa_del"]),
    meliminar(item) {
      this.objeto = Object.assign({}, item);
      this.dialogoeliminar = true;
    },
    rowselect(val) {
      this.datos = val;
      this.verPuntoVenta = true;
    },
    eliminarcancelar() {
      this.objeto = {
        descpuntoventa: ''
      };
      this.dialogoeliminar = false;
    },
    async meliminarguardar() {
      this.overlay = true;
      const id = this.objeto.idpuntoventa;
      const a = await this.Vact_puntoventa_del(id);
      if (a.estado == true) {
        this.$notify(a.info);
        this.dialogoeliminar = false;
        this.eliminarcancelar();
      } else {
        a.info.map(x => {
          this.$notify(mensaje.error(x));
        });
      }
      this.overlay = false;
    },
    mupdate(val) {
      this.datos = val;
      this.crearPuntoVenta = true;
    }
  }
};
</script>