<template>
  <v-btn color="primary" small dark @click="$emit('click')" :to="to">
    <v-icon dark>add</v-icon>
  </v-btn>
</template>

<script>
export default {
  props: {
    to: String,
  },
};
</script>
