<template>
  <div>
    <v-row dense>
      <v-col
        v-for="(item, i) of data"
        :key="i"
        :cols="item.cols[0]"
        :sm="item.cols[1]"
        :md="item.cols[2]"
      >
        <v-card elevation="1" tile>
          <v-toolbar flat dense :color="item.color">
            <div class="body-1 flex font-weight-black text-center">
              {{ item.type }}
            </div>
          </v-toolbar>
          <v-data-table
            dense
            hide-default-footer
            :items-per-page="10000"
            :headers="item.headers"
            :items="item.items"
            :mobile-breakpoint="0"
          >
            <template v-slot:[`item.monto`]="{ item }">
              <div>{{ toCurrency(item.monto) }}</div>
            </template>
            <template v-slot:[`item.total`]="{ item }">
              <div>{{ toCurrency(item.total) }}</div>
            </template>
            <template v-slot:[`item.kg`]="{ item }">
              <div>{{ toCurrency(item.kg) }}</div>
            </template>
            <template v-slot:[`item.viaticos`]="{ item }">
              <div>{{ toCurrency(item.viaticos) }}</div>
            </template>
            <template v-slot:[`item.cobrado`]="{ item }">
              <div>{{ toCurrency(item.cobrado) }}</div>
            </template>
            <template v-slot:[`item.gastos`]="{ item }">
              <div>{{ toCurrency(item.gastos) }}</div>
            </template>
            <template v-slot:[`item.saldo`]="{ item }">
              <div>{{ toCurrency(item.saldo) }}</div>
            </template>

            
            <template slot="body.append">
              <tr class="font-weight-black" v-if="item.type == `Transporte`">
                <th class="body-1">Total</th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th class="text-right body-1">{{ toCurrency(item.value) }}</th>
              </tr>
              <tr class="font-weight-black body-1" v-if="item.type == `Gastos`">
                <th class="body-1">Total</th>
                <th></th>
                <th></th>
                <th class="text-right body-1">{{ toCurrency(item.value) }}</th>
                <th></th>
              </tr>
              <tr class="font-weight-black body-1" v-if="item.type == `Gastos Viaje` || item.type == `Gastos Reembolsar`">
                <th class="body-1">Total</th>
                <th class="text-right body-1">{{ toCurrency(item.value) }}</th>
                <th></th>
              </tr>
              <tr class="font-weight-black body-1" v-if="item.type == `Haberes`">
                <th class="text-right body-1">{{ toCurrency(item.value) }}</th>
                <th></th>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { currency } from "@/services/util/number.service";
export default {
  props: {
    registro: {},
    type: Object,
  },
  data() {
    return {
      data: {
        transporte: {
          color: "blue",
          type: "Transporte",
          headers: [
            {
              text: "Fecha",
              value: "fecha",
              sortable: true,
            },
            {
              text: "Transportista",
              value: "transportista",
              sortable: true,
            },
            {
              text: "Chofer",
              value: "chofer",
              sortable: true,
            },
            {
              text: "Producto",
              value: "producto",
              sortable: true,
            },
            {
              text: "Origen",
              value: "origen",
              sortable: true,
            },
            {
              text: "Destino",
              value: "destino",
              sortable: true,
            },
            {
              text: "Kg",
              value: "kg",
              sortable: true,
            },
            {
              text: "Precio",
              value: "precio",
              sortable: true,
            },
            {
              text: "Total",
              value: "total",
              sortable: true,
              align: "right"
            },
          ],
          items: [],
          value: 0,
          cols: [12, 12, 12],
        },
        gastos: {
          color: "green",
          type: "Gastos",
          headers: [
            {
              text: "Fecha",
              value: "fecha",
              sortable: true,
            },
            {
              text: "Proveedor",
              value: "proveedor",
              sortable: true,
            },
            {
              text: "Concepto",
              value: "concepto",
              sortable: true,
            },
            {
              text: "Monto",
              value: "monto",
              sortable: true,
              align: "right"
            },
            {
              text: "Observacion",
              value: "observacion",
              sortable: true,
            },
          ],
          items: [],
          value: 0,
          cols: [12, 4, 4],
        },
        gastos_viaje: {
          color: "green",
          type: "Gastos Viaje",
          headers: [
            {
              text: "Fecha",
              value: "fecha",
              sortable: true,
            },
            {
              text: "Monto",
              value: "monto",
              sortable: true,
              align: "right"
            },
            {
              text: "Observacion",
              value: "observacion",
              sortable: true,
            },
          ],
          items: [],
          value: 0,
          cols: [12, 3, 3],
        },
        gastos_reembolsar: {
          color: "green",
          type: "Gastos Reembolsar",
          headers: [
            {
              text: "Fecha",
              value: "fecha",
              sortable: true,
            },
            {
              text: "Monto",
              value: "monto",
              sortable: true,
              align: "right"
            },
            {
              text: "Observacion",
              value: "observacion",
              sortable: true,
            },
          ],
          items: [],
          value: 0,
          cols: [12, 3, 3],
        },
        haberes: {
          color: "green",
          type: "Haberes",
          headers: [
            {
              text: "Monto",
              value: "monto",
              sortable: true,
              align: "right"
            },
            {
              text: "Observacion",
              value: "observacion",
              sortable: true,
            },
          ],
          items: [],
          value: 0,
          cols: [12, 2, 2],
        },
        liquidacion: {
          color: "red",
          type: "Liquidacion de Salario",
          headers: [
            {
              text: "Fecha",
              value: "fecha",
              sortable: true,
            },
            {
              text: "Nombre y Apellido",
              value: "name",
              sortable: true,
            },
            {
              text: "Saldo a Favor",
              value: "saldo",
              sortable: true,
            },
            {
              text: "Viaticos",
              value: "viaticos",
              sortable: true,
            },
            {
              text: "Gastos a Reembolsar",
              value: "gastos",
              sortable: true,
            },
            {
              text: "Saldo Cobrado",
              value: "cobrado",
              sortable: true,
            },
          ],
          items: [],
          value: 0,
          cols: [12, 12, 12],
        },
      },
    };
  },
  mounted() {
    this.data.gastos.items = this.$props.registro.data.gastos;
    this.data.gastos.value = this.$props.registro.value.gastos;
    this.data.gastos_reembolsar.items =
      this.$props.registro.data.gastos_reembolsar;
    this.data.gastos_reembolsar.value =
      this.$props.registro.value.gastos_reembolsar;
    this.data.gastos_viaje.items = this.$props.registro.data.gastos_viaje;
    this.data.gastos_viaje.value = this.$props.registro.value.gastos_viaje;
    this.data.transporte.items = this.$props.registro.data.transporte;
    this.data.transporte.value = this.$props.registro.value.transporte;
    this.data.haberes.items = this.$props.registro.data.haberes;
    this.data.haberes.value = this.$props.registro.value.haberes;
    this.data.liquidacion.items = this.$props.registro.data.salarios;
  },
  methods: {
    toCurrency(value) {
      if (!value) return 0;
      if (value) return currency(value);
    },
  }
};
</script>