
export default {

  tipoajuste_create: (state) => {
    return state.tipoajuste_create
  },
  tipoajuste_update: (state) => {
    return state.tipoajuste_update
  },
  tipoajuste_reload: (state) => {
    return state.tipoajuste_reload
  },
  tipoajuste_show: (state) => {
    return state.tipoajuste_show
  },
}
