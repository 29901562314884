<template>
  <v-row class="fill-height">
    <v-col>
      <v-sheet height="64">
        <v-toolbar flat>
          <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
            Hoy
          </v-btn>
          <v-btn fab text small color="grey darken-2" @click="prev">
            <v-icon small> chevron_left </v-icon>
          </v-btn>
          <v-toolbar-title v-if="$refs.calendar && type == 'category'">
            {{ $refs.calendar.lastStart.day }} de {{ $refs.calendar.title }}
          </v-toolbar-title>
          <v-toolbar-title v-if="$refs.calendar && type == 'month'">
            {{ $refs.calendar.title }}
          </v-toolbar-title>
          <v-btn fab text small color="grey darken-2" @click="next">
            <v-icon small> chevron_right </v-icon>
          </v-btn>
          <v-menu bottom right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                <span>{{ typeToLabel[type] }}</span>
                <v-icon right> arrow_drop_down </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="type = 'month'">
                <v-list-item-title>Mes</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'category'">
                <v-list-item-title>Dia</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-spacer></v-spacer>
          <v-divider class="mx-1" inset vertical></v-divider>
          <IncludeCH :url="'/apiinformegantasistencia/'" :name="'Calendario'" />
        </v-toolbar>
      </v-sheet>
      <v-sheet height="600">
        <v-calendar
          ref="calendar"
          locale="es-es"
          v-model="focus"
          color="primary"
          :type="type"
          category-hide-dynamic
          :categories="categories"
          :events="events"
          :event-color="getEventColor"
          @change="fetchEvents"
          @click:date="viewDay"
        ></v-calendar>
      </v-sheet>
    </v-col>
  </v-row>
</template>
<script>
import { db } from "@/services/db.service";
import IncludeCH from "@/components/widgets/IncludeCH.vue";
export default {
  components: {
    IncludeCH,
  },
  data: () => ({
    type: "month",
    typeToLabel: {
      month: "Mes",
      category: "Dia",
    },
    focus: "",
    events: [],
    categories: [],
  }),
  mounted() {
    this.$refs.calendar.checkChange();
  },
  methods: {
    viewDay({ date }) {
      this.focus = date;
      this.type = "category";
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    async fetchEvents({ start, end }) {
      try {
        const response = await db.get(
          `/apiinformegantasistencia/?fecha=${start.date}&end=${end.date}`
        );
        this.categories = response.categories;
        this.events = response.data;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>