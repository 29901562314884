<template>
  <div>
    <v-dialog :value="value" persistent max-width="900" @keydown.esc="cancelar()">
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>Crear Nota de Credito Compra</v-toolbar-title>
        </v-toolbar>
        <v-container>
          <v-form ref="form1">
            <v-row dense>
              <v-col cols="12" md="2" sm="3">
                <c-text-date v-model="data.fecha" :rules="[v=>!!v || 'Campo Obligatorio']" outlined dense id="input1" @keyup.native.enter="nextFocus(1,data.fecha)"></c-text-date>
              </v-col>
              <v-col cols="12" md="2" sm="3">
                <vue-text-field
                  label="Numero Nota Credito"
                  id="input2"
                  v-mask="mask_factura"
                  v-model="data.numnotacreditocompra"
                  @keyup.native.enter="nextFocus(2,data.numnotacreditocompra)"
                ></vue-text-field>
              </v-col>
              <v-col cols="12" md="5" sm="6">
                <vue-autocomplete-contacto-proveedor
                  label="Proveedor"
                  id="input3"
                  :create="false"
                  v-model="data.idcontacto.idcontacto"
                  @keyup.native.enter="nextFocus(3,data.idcontacto.idcontacto)"
                />
              </v-col>
              <v-col cols="12" md="2" sm="3">
                <vue-text-field
                  v-mask="mask_factura"
                  id="input4"
                  label="Factura que modifica"
                  v-model="factura"
                  @keyup.native.enter="nextFocus(4,factura)"
                ></vue-text-field>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="1">
                <v-btn color="red" id="input5" text outlined @click="obtenerCompra()">
                  <v-icon>cloud_download</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
          <v-form ref="formDet" v-show="response.length > 0">
            <v-row dense>
              <v-col cols="12" sm="4" md="5">
                <v-autocomplete
                  v-if="isCompra"
                  dense
                  v-model="detalle.iditem"
                  height="12px"
                  outlined
                  id="input6"
                  :items="items"
                  label="Item"
                  item-text="descitem"
                  item-value="iditem"
                  return-object
                  :rules="[v => !!v || 'Seleccione un Item']"
                  required
                  autocomplete="off"
                  :no-data-text="`No existe ninguna concidencia`"
                  small-chips
                  @change="asignarPrecio()"
                  @keyup.enter="nextFocus(6,detalle.iditem.iditem)"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>No existe concidencia</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
                <v-autocomplete
                  v-if="!isCompra"
                  dense
                  v-model="detalle.idconcepto"
                  height="12px"
                  outlined
                  id="input6"
                  :items="conceptos"
                  label="Concepto"
                  item-text="desconcepto"
                  item-value="idconcepto"
                  return-object
                  :rules="[v => !!v || 'Seleccione un Concepto']"
                  required
                  autocomplete="off"
                  :no-data-text="`No existe ninguna concidencia`"
                  small-chips
                  @change="asignarPrecio()"
                  @keyup.enter="nextFocus(6,detalle.iditem.idconcepto)"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>No existe concidencia</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="2" sm="3">
                <vue-text-currency
                  v-model="detalle.precio"
                  label="Precio"
                  :rules="true"
                  id="input7"
                  @keyup.native.enter="nextFocus(7,detalle.precio)"
                ></vue-text-currency>
              </v-col>
              <v-col cols="8" md="2" sm="3">
                <vue-text-currency
                  v-model="detalle.cantidad"
                  label="Cantidad"
                  id="input8"
                  :rules="true"
                  @keyup.native.enter="nextFocus(8,detalle.cantidad)"
                ></vue-text-currency>
              </v-col>
              <v-col cols="1" md="2"></v-col>
              <v-col cols="1" sm="1">
                <v-btn outlined color="primary" id="input9" fab small @click="addTable()">
                  <v-icon>add</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="12">
                <v-data-table
                  v-if="isCompra"
                  :headers="headers"
                  :items="data.notacredito"
                  :loading="overlay"
                  :items-per-page="1000"
                  hide-default-footer
                >
                  <template v-slot:item.actions="props">
                    <v-icon small class="mr-1" @click="editarDetalle(props.item)">edit</v-icon>
                    <v-icon small class="mr-1" @click="eliminarDetalle(props.item)">delete</v-icon>
                  </template>
                </v-data-table>
                <v-data-table
                  v-if="!isCompra"
                  :headers="headers_concepto"
                  :items="data.notaconcepto"
                  :loading="overlay"
                  :items-per-page="1000"
                  hide-default-footer
                >
                  <template v-slot:item.actions="props">
                    <v-icon small class="mr-1" @click="editarDetalle(props.item)">edit</v-icon>
                    <v-icon small class="mr-1" @click="eliminarDetalle(props.item)">delete</v-icon>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-form>
          <v-alert dense type="info" v-show="response.length === 0">
            No se encuentra ninguna factura de
            <strong>compra</strong> con estos parametros
          </v-alert>
          <v-form ref="form2" @submit.prevent="nextFocus(10,data.comentario)">
            <v-row dense>
              <v-col cols="12">
                <vue-text-field label="Descripcion de la operacion" v-model="data.comentario"></vue-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="red" text @click="cancelar()">
            <v-icon>clear</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" id="aceptar" text @click="guardar()">
            <v-icon>done</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
      <c-overlay :value="Vget_notacreditocompra_save_load"></c-overlay>
    </v-dialog>
  </div>
</template>

<script>
import { mask } from "vue-the-mask";
import { current_date } from "@/services/util/date.service";
import { focus } from "@/services/focus.service";
import { db } from "@/services/db.service";
import { mensaje } from "@/services/notify.service";
import { mapActions, mapGetters } from "vuex";
export default {
  directives: {
    mask
  },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters("notacreditocompra", ["Vget_notacreditocompra_save_load"])
  },
  mounted() {
    setTimeout(() => this.nextFocus(0, "-"), 100);
  },
  methods: {
    ...mapActions("notacreditocompra", [
      "Vact_notacreditocompra_save",
      "Vact_notacreditocompra_data"
    ]),
    asignarPrecio() {
      switch (this.isCompra) {
        case true:
          if (this.detalle.iditem != undefined)
            return (this.detalle.precio = this.detalle.iditem.precio);
          break;
        case false:
          if (this.detalle.idconcepto != undefined)
            return (this.detalle.precio = this.detalle.idconcepto.precio);
          break;
      }
    },
    isRepeat() {
      let repeat = false;
      this.data.notacredito.forEach(x => {
        if (x.iditem.iditem === this.detalle.iditem.iditem)
          return (repeat = true);
      });
      return repeat;
    },
    addTable() {
      if (!this.$refs.formDet.validate() || this.isRepeat())
        return this.$notify(mensaje.error("Verifique los datos ingresados"));
      if (this.isCompra) this.addDetalle(this.detalle);
      if (!this.isCompra) this.addDetalleConcepto(this.detalle);
      this.detalle = JSON.parse(JSON.stringify(this.detalle_default));
      this.$refs.formDet.resetValidation();
      this.nextFocus(5, "-");
    },
    cancelar() {
      this.$emit("input", false);
      this.Vact_notacreditocompra_data();
    },
    nextFocus(id, data) {
      focus.nextid(id, data);
    },
    addDetalle(item) {
      switch (item.iditem.idcategoriaitem.idiva.idiva) {
        case 3:
          item.iva10 = Number(item.cantidad) * Number(item.precio);
          break;
        case 2:
          item.iva5 = Number(item.cantidad) * Number(item.precio);
          break;
        case 1:
          item.exenta = Number(item.cantidad) * Number(item.precio);
          break;
        default:
          break;
      }
      this.data.notacredito.push(item);
    },
    addDetalleConcepto(concepto) {
      switch (concepto.idconcepto.idcategoriaitem.idiva.idiva) {
        case 3:
          concepto.iva10 =
            Number(concepto.cantidad) * Number(concepto.precio);
          break;
        case 2:
          concepto.iva5 =
            Number(concepto.cantidad) * Number(concepto.precio);
          break;
        case 1:
          concepto.exenta =
            Number(concepto.cantidad) * Number(concepto.precio);
          break;
        default:
          break;
      }
      this.data.notaconcepto.push(concepto);
    },

    async obtenerCompra() {
      this.overlay = true;
      const proveedor = this.data.idcontacto.idcontacto.idcontacto;
      if (proveedor != undefined || this.factura != "") {
        try {
          this.response = await db.get(
            `/apicompra/?proveedor=${proveedor}&factura=${this.factura}`
          );
          if (this.response.length === 0)
            throw new Error("No existe ninguna factura");
          if (this.response[0].compra.length > 0) {
            this.isCompra = true;
            this.data.notacredito = [];
            this.items = [];
            this.response[0].compra.forEach(item => {
              item.precio = item.costdetcompra;
              item.cantidad = item.cantdetcompra;
              item.iditem.precio = item.costdetcompra;
              delete item.costdetcompra;
              delete item.cantdetcompra;
              delete item.iditem.precitem;
              this.items.push(item.iditem);
              this.addDetalle(item);
            });
          }
          if (this.response[0].compraconcepto.length > 0) {
            this.isCompra = false;
            this.data.notaconcepto = [];
            this.conceptos = [];
            this.response[0].compraconcepto.forEach(concepto => {
              concepto.precio = concepto.costconcepto;
              concepto.cantidad = concepto.cantdetconcepto;
              concepto.idconcepto.precio = concepto.costconceptoo;
              delete concepto.costconcepto;
              delete concepto.cantdetconcepto;
              delete concepto.idconcepto.precconcepto;
              this.conceptos.push(concepto.idconcepto);
              this.addDetalleConcepto(concepto);
            });
          }
          this.data.idcompra = this.response[0];
          this.data.iddeposito = this.response[0].iddeposito;
          setTimeout(() => this.nextFocus(5, "-"), 100);
          this.$refs.formDet.resetValidation();
        } catch (error) {
          this.$notify(mensaje.error(error.message));
        }
      }
      this.overlay = false;
    },
    editarDetalle(val) {
      this.eliminarDetalle(val);
      this.detalle = JSON.parse(JSON.stringify(val));
      this.isEdit = true;
    },
    eliminarDetalle(val) {
      let index;
      if (this.isCompra) {
        index = this.data.notacredito.indexOf(val);
        this.data.notacredito.splice(index, 1);
      } else {
        index = this.data.notaconcepto.indexOf(val);
        this.data.notaconcepto.splice(index, 1);
      }
    },
    async guardar() {
      if (!this.$refs.form1.validate() || !this.$refs.form2.validate())
        return null;
      const a = await this.Vact_notacreditocompra_save(this.data);
      if (!a.estado) {
        return a.info.map(x => {
          this.$notify(mensaje.error(x));
        });
      }
      this.$notify(a.info);
      this.data = JSON.parse(JSON.stringify(this.default));
      this.detalle = JSON.parse(JSON.stringify(this.detalle_default));
      this.$refs.formDet.resetValidation();
      this.$refs.form1.resetValidation();
      this.$refs.form2.resetValidation();
      setTimeout(() => this.nextFocus(0, "-"), 100);
    }
  },
  data() {
    return {
      mask_factura: "###-###-#######",
      isCompra: true,
      factura: "",
      items: [],
      conceptos: [],
      overlay: false,
      response: [],
      headers: [
        { text: "Cantidad", value: "cantidad", sortable: false },
        { text: "Item", value: "iditem.descitem", sortable: false },
        { text: "Precio Unitario", value: "precio", sortable: false },
        { text: "Exenta", value: "exenta", sortable: false },
        { text: "Iva 5%", value: "iva5", sortable: false },
        { text: "Iva 10%", value: "iva10", sortable: false },
        { text: "Accion", value: "actions", sortable: false }
      ],
      headers_concepto: [
        { text: "Cantidad", value: "cantidad", sortable: false },
        { text: "Concepto", value: "idconcepto.desconcepto", sortable: false },
        { text: "Precio Unitario", value: "precio", sortable: false },
        { text: "Exenta", value: "exenta", sortable: false },
        { text: "Iva 5%", value: "iva5", sortable: false },
        { text: "Iva 10%", value: "iva10", sortable: false },
        { text: "Accion", value: "actions", sortable: false }
      ],
      data: {
        idnotacreditocompra: 0,
        comentario: "",
        fecha: current_date(),
        idcompra: {
          idcompra: ""
        },
        iddeposito: {
          iddeposito: ""
        },
        idcontacto: {
          idcontacto: ""
        },
        numnotacreditocompra: "",
        notacredito: [],
        notaconcepto: [],
        anulnota: false
      },

      default: {
        idnotacreditocompra: 0,
        comentario: "",
        fecha: current_date(),
        idcompra: {
          idcompra: ""
        },
        iddeposito: {
          iddeposito: ""
        },
        idcontacto: {
          idcontacto: ""
        },
        numnotacreditocompra: "",
        notacredito: [],
        notaconcepto: [],
        anulnota: false
      },
      detalle: {
        idconcepto: "",
        iditem: "",
        precio: "",
        cantidad: "",
        cantdetconcepto: "",
        costconcepto: ""
      },
      detalle_default: {
        idconcepto: "",
        iditem: "",
        precio: "",
        cantidad: "",
        cantdetconcepto: "",
        costconcepto: ""
      }
    };
  }
};
</script>

<style>
</style>