<template>
  <div>
    <v-dialog :value="value" max-width="800px" persistent @keydown.esc="cancelar()">
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>{{'Realizar translado Banco a Banco'}}</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-form ref="form">
            <v-row dense>
              <v-col cols="12" md="6" sm="6">
                <c-text-date
                  label="Fecha"
                  outlined
                  dense
                  id="transBBInput1"
                  v-model="data.fechtransladocaja"
                  @keyup.native.enter="next(1,data.fechtransladocaja)"
                />
              </v-col>
              <v-col cols="12" md="6" sm="6">
                <vue-text-field
                  label="Numero de Operacion"
                  id="transBBInput2"
                  :rules="true"
                  v-model="data.nrotranslado"
                  @keyup.native.enter="next(2,data.nrotranslado)"
                ></vue-text-field>
              </v-col>

              <v-col cols="12" md="6" sm="6">
                <v-autocomplete
                  :items="Vget_banco_data"
                  item-text="descbanco"
                  id="transBBInput3"
                  return-object
                  autocomplete="off"
                  :rules="[v => !!v.idbanco || 'Seleccione un Banco']"
                  outlined
                  dense
                  :loading="Vget_banco_load"
                  label="Banco Origen"
                  v-model="data.idbancoorigen"
                  @change="addCuentaOrigen()"
                  @keyup.native.enter="next(3,data.idbancoorigen.idbanco)"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="6" sm="6">
                <v-autocomplete
                  :items="cuentabanco_origen"
                  item-text="desccuentabanco"
                  item-value="idcuentabanco"
                  return-object
                  autocomplete="off"
                  :rules="[v => !!v.idcuentabanco || 'Seleccione una Cuenta']"
                  outlined
                  dense
                  :loading="Vget_cuentabanco_load"
                  label="Cuenta de Origen"
                  id="transBBInput4"
                  v-model="data.idcuentabancoorigen"
                  :messages="monedaOrigen"
                  @change="verificarMoneda()"
                  @keyup.native.enter="next(4,data.idcuentabancoorigen.idcuentabanco)"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12">
                <vue-text-currency
                  label="Monto"
                  id="transBBInput5"
                  :rules="true"
                  v-model="data.monto"
                  @keyup.native.enter="next(5,data.monto)"
                ></vue-text-currency>
              </v-col>
              <v-col cols="12" md="6" sm="6">
                <v-autocomplete
                  :items="Vget_banco_data"
                  item-text="descbanco"
                  return-object
                  autocomplete="off"
                  :rules="[v => !!v.idbanco || 'Seleccione un Banco']"
                  outlined
                  dense
                  :loading="Vget_banco_load"
                  label="Banco Destino"
                  id="transBBInput6"
                  v-model="data.idbancodestino"
                  @change="addCuentaDestino()"
                  @keyup.native.enter="next(6,data.idbancodestino.idbanco)"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="6" sm="6">
                <v-autocomplete
                  :items="cuentabanco_destino"
                  item-text="desccuentabanco"
                  return-object
                  autocomplete="off"
                  :rules="[v => !!v.idcuentabanco || 'Seleccione una Cuenta']"
                  outlined
                  dense
                  id="transBBInput7"
                  :loading="Vget_cuentabanco_load"
                  label="Cuenta de Destino"
                  v-model="data.idcuentabancodestino"
                  :messages="monedaDestino"
                  @change="verificarMoneda()"
                  @keyup.native.enter="next(data.convertir ? 7 : 8,data.idcuentabancodestino.idcuentabanco)"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" v-if="data.convertir">
                <vue-text-currency
                  :label="'Cotizacion en ' + monedaDestinoDesc"
                  id="transBBInput8"
                  :rules="true"
                  v-model="data.cotizacion"
                  @keyup.native="calcularMontoRecibir()"
                  @keyup.native.enter="next(8,data.cotizacion)"
                ></vue-text-currency>
              </v-col>
              <v-col cols="12" sm="6" v-if="data.convertir">
                <vue-text-currency
                  id="transBCInput9"
                  label="Monto a Recibir"
                  v-model="data.montorecibir"
                  :rules="true"
                  @keyup.native="calcularCotizacion()"
                  @keyup.native.enter="next(9,data.montorecibir)"
                ></vue-text-currency>
              </v-col>
              <v-col>
                <vue-text-field
                  label="Motivo Translado"
                  id="transBBInput9"
                  v-model="data.motivotranslado"
                  @keyup.native.enter="next(9,data.motivotranslado)"
                ></vue-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-btn color="red" text @click="cancelar">
            <v-icon dark>close</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn id="transBBInputaceptar" color="primary" text @click="guardar()">
            <v-icon dark>done</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
      <c-overlay :value="Vget_transladobancobanco_save_load"></c-overlay>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { mensaje } from "@/services/notify.service";
import { current_date } from "@/services/util/date.service";
import { focus } from "@/services/focus.service";
export default {
  props: {
    value: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      cuentabanco_origen: [],
      cuentabanco_destino: [],
      operacion: true,
      data: {
        idtransladobab: 0,
        cotizacion: "",
        montorecibir: "",
        idbancoorigen: {
          idbanco: "",
        },
        idbancodestino: {
          idbanco: "",
        },
        idcuentabancoorigen: {
          idcuentabanco: "",
        },
        idcuentabancodestino: {
          idcuentabanco: "",
        },
        fechtransladocaja: current_date(),
        monto: "",
        motivotranslado: "",
        nrotranslado: "",
        estado: false,
      },
      default: {
        idtransladobab: 0,
        cotizacion: "",
        montorecibir: "",
        idbancoorigen: {
          idbanco: "",
        },
        idbancodestino: {
          idbanco: "",
        },
        idcuentabancoorigen: {
          idcuentabanco: "",
        },
        idcuentabancodestino: {
          idcuentabanco: "",
        },
        fechtransladocaja: current_date(),
        monto: "",
        motivotranslado: "",
        nrotranslado: "",
        estado: false,
      },
    };
  },
  watch: {
    $route(to, from) {
      if (from.path === "/transladobancobanco") return this.cancelar();
    },
  },
  mounted() {
    setTimeout(() => this.next(0, "-"), 200);
    this.Vact_banco_data();
    this.Vact_cuentabanco_data();
  },
  computed: {
    ...mapGetters("transladobancobanco", [
      "Vget_transladobancobanco_save_load",
    ]),
    ...mapGetters("banco", ["Vget_banco_data", "Vget_banco_load"]),
    ...mapGetters("cuentabanco", [
      "Vget_cuentabanco_data",
      "Vget_cuentabanco_load",
    ]),
    monedaOrigen() {
      return this.data.idcuentabancoorigen.monto
        ? "Saldo " +
            this.data.idcuentabancoorigen.idmoneda.descmoneda +
            ": " +
            new Intl.NumberFormat().format(this.data.idcuentabancoorigen.monto)
        : "";
    },
    monedaDestino() {
      return this.data.idcuentabancodestino.monto
        ? "Saldo " +
            this.data.idcuentabancodestino.idmoneda.descmoneda +
            ": " +
            new Intl.NumberFormat().format(this.data.idcuentabancodestino.monto)
        : "";
    },
    monedaDestinoDesc() {
      return this.data.idcuentabancodestino.monto
        ? this.data.idcuentabancodestino.idmoneda.descmoneda
        : "";
    },
  },
  methods: {
    ...mapActions("transladobancobanco", [
      "Vact_transladobancobanco_data",
      "Vact_transladobancobanco_save",
    ]),
    ...mapActions("banco", ["Vact_banco_data"]),
    ...mapActions("cuentabanco", ["Vact_cuentabanco_data"]),
    calcularMontoRecibir() {
      if (this.data.cotizacion === "" || this.data.monto === "") return null;
      this.data.montorecibir =
        Number(this.data.cotizacion) * Number(this.data.monto);
    },
    calcularCotizacion() {
      if (this.data.montorecibir === "" || this.data.monto === "") return null;
      this.data.cotizacion =
        Number(this.data.montorecibir) / Number(this.data.monto);
    },
    addCuentaOrigen() {
      this.cuentabanco_origen = [];
      this.data.idcuentabancoorigen = "";
      this.verificarMoneda();
      this.Vget_cuentabanco_data.forEach((cuenta) => {
        if (cuenta.idbanco.idbanco === this.data.idbancoorigen.idbanco) {
          this.cuentabanco_origen.push(cuenta);
        }
      });
    },
    addCuentaDestino() {
      this.cuentabanco_destino = [];
      this.data.idcuentabancodestino = "";
      this.verificarMoneda();
      this.Vget_cuentabanco_data.forEach((cuenta) => {
        if (cuenta.idbanco.idbanco === this.data.idbancodestino.idbanco) {
          this.cuentabanco_destino.push(cuenta);
        }
      });
    },
    next(index, data) {
      focus.nextidName("transBBInput", index, data);
    },
    verificarMoneda() {
      this.data.convertir = false;
      this.data.cotizacion = null;
      if (
        !this.data.idcuentabancoorigen.idmoneda ||
        !this.data.idcuentabancodestino.idmoneda
      )
        return null;
      if (
        this.data.idcuentabancoorigen.idmoneda.idmoneda !=
        this.data.idcuentabancodestino.idmoneda.idmoneda
      )
        return (this.data.convertir = true);
    },
    cancelar() {
      this.$emit("input", false);
      this.data = JSON.parse(JSON.stringify(this.default));
      this.Vact_transladobancobanco_data();
      this.operacion = true;
    },
    async guardar_finalizar() {
      let a = await this.Vact_transladobancobanco_save(this.data);
      if (a.estado == true) {
        this.data = JSON.parse(JSON.stringify(this.default));
        this.$notify(a.info);
        this.$refs.form.resetValidation();
        this.Vact_cuentabanco_data();
        setTimeout(() => this.next(0, "-"), 200);
      } else {
        a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
    },
    guardar() {
      if (!this.$refs.form.validate()) return this.$notify(mensaje.completar());
      if (this.operacion === true) return this.guardar_finalizar();
    },
  },
};
</script>