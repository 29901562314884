export default {
  getRacionDisponible: (state) => {
    return state.racion_data.filter((x) => x.estado);
  },
  Vget_racion_data: (state) => {
    return state.racion_data;
  },
  Vget_racion_data_all: (state) => {
    return state.racion_data_all;
  },
  Vget_racion_data_anho: (state) => {
    return state.racion_data_anho;
  },
  Vget_racion_data_byLote: (state) => {
    return state.racion_data_byLote;
  },
  Vget_racion_load: (state) => {
    return state.racion_load;
  },
  Vget_racion_save_load: (state) => {
    return state.racion_save_load;
  },
};
