<template>
  <div>
    <v-alert type="error" v-if="permission_alert"
      >Error!!! Este usuario no tiene permisos en esta ventana</v-alert
    >
    <v-data-table
      :headers="headers"
      :search="search"
      :loading="isNacionalidadLoading"
      :items="getNacionalidad"
      :items-per-page="15"
    >
      <template v-slot:top>
        <v-toolbar flat dense>
          <v-toolbar-title>{{ $route.name }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <c-text-table-search v-model="search"></c-text-table-search>
          <v-toolbar-items>
            <c-btn-table-reload
              @click="fetchNacionalidad()"
            ></c-btn-table-reload>
          </v-toolbar-items>
        </v-toolbar>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { mensaje } from "@/services/notify.service";

export default {
  data() {
    return {
      search: "",
      headers: [
        {
          text: "Codigo",
          align: "start",
          value: "idnacionalidad",
        },
        { text: "Descripcion", value: "descnacionalidad" },
      ],
      permission: {
        can_add: true,
        can_view: true,
        can_update: true,
        can_delete: true,
      },
    };
  },
  created() {
    this.$store.commit("SET_LAYOUT", "principal-layout");
    this.fetchNacionalidad();
  },
  computed: {
    ...mapGetters("nacionalidad", ["getNacionalidad", "isNacionalidadLoading"]),
    permission_alert() {
      return mensaje.permission(this.permission);
    },
  },

  methods: {
    ...mapActions("nacionalidad", ["fetchNacionalidad"]),
  },
};
</script>
