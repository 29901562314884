export default {

  establecimiento_data(state, request) {
    state.establecimiento_data = request;
  }, 
  establecimiento_load(state, request) {
    state.establecimiento_load = request;
  }, 
  establecimiento_save_load(state, request) {
    state.establecimiento_save_load = request;
  }, 
}