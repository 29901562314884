<template>
  <v-dialog persistent max-width="900" :value="value">
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
      </v-toolbar>
      <v-container>
        <v-form ref="form" @submit.prevent="next(1, data.descripcion)">
          <v-row dense>
            <v-col cols="12" sm="6">
              <c-text-field
                label="Nombre"
                ref="user1"
                :rules="rules"
                v-model="data.first_name"
                placeholder="Ingrese el nombre del usuario"
                @keyup.native.enter="next(1, data.first_name)"
              ></c-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <c-text-field
                label="Apellido"
                ref="user2"
                :rules="rules"
                v-model="data.last_name"
                placeholder="Ingrese el apellido del usuario"
                @keyup.native.enter="next(2, data.last_name)"
              ></c-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <c-text-field
                label="Usuario"
                ref="user3"
                :rules="rules"
                v-model="data.username"
                placeholder="El nick se usa para iniciar sesion"
                @keyup.native.enter="next(3, data.username)"
              ></c-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <c-text-field
                label="Email"
                ref="user4"
                :rules="rules"
                v-model="data.email"
                placeholder="Email del Usuario"
                @keyup.native.enter="next(4, data.username)"
              ></c-text-field>
            </v-col>
            <v-col cols="12" v-if="!editable" sm="6">
              <c-text-field
                label="Contraseña"
                ref="user5"
                :rules="rules"
                v-model="data.password"
                placeholder="Ingrese la contraseña"
                @keyup.native.enter="next(5, data.password)"
              ></c-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-autocomplete
                :items="getModulosFormat"
                :loading="isModulosLoading"
                ref="user6"
                item-value="idrol_usuario.idrol_usuario"
                item-text="idrol_usuario.descripcion"
                return-object
                multiple
                chips
                small-chips
                label="Nivel del Usuario"
                v-model="data.usuario_det"
                @change="(event) => updatePermisos(event)"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6">
              <v-autocomplete
                :items="Vget_empresa_data"
                :loading="Vget_empresa_load"
                ref="user7"
                item-value="idempresa"
                item-text="descempresa"
                return-object
                multiple
                chips
                small-chips
                v-model="data.idempresa"
                placeholder="Empresas a las que tendra acceso el usuario"
                label="Empresa"
                @change="(event) => updateEmpresas(event)"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6">
              <v-autocomplete
                :items="getSucursales"
                :loading="Vget_tambo_load"
                ref="user8"
                item-text="nombtambo"
                item-value="idtambo"
                return-object
                multiple
                v-model="data.usuario"
                chips
                small-chips
                label="Sucursal"
                placeholder="Sucursales a las que tendra acceso el usuario"
                @change="(event) => updateSucursales(event)"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <div v-if="incluye.deposito">
            <span class="title ml-2 orange--text text--darken-2">
              Asignar Deposito
            </span>
            <v-tooltip right color="info" max-width="300">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" color="orange darken-2" class="ml-2">
                  help_outline
                </v-icon>
              </template>
              <span class="text-center">
                <p class="text-justify">
                  Cuando el nivel de usuario tenga el permiso de operar con
                  deposito, este le permitira filtrar los depositos con los
                  cuales el usuario podra operar
                </p>
              </span>
            </v-tooltip>
            <v-row dense>
              <v-col
                cols="12"
                v-for="(item, index) in mostrardeposito"
                :key="index"
              >
                <v-card-actions>
                  <div class="title">{{ item.name }}</div>
                  <v-divider></v-divider>
                </v-card-actions>
                <v-row dense>
                  <v-col
                    cols="12"
                    sm="3"
                    v-for="(item2, index2) in item.detalle"
                    :key="index2"
                  >
                    <v-checkbox
                      color="orange"
                      :label="item2.desdeposito"
                      v-model="depositos"
                      :value="item2"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
          <div v-if="incluye.lotegrupo">
            <span class="title ml-2 success--text">Asignar Zonas</span>
            <v-tooltip right color="info" max-width="300">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" color="success" class="ml-2">
                  help_outline
                </v-icon>
              </template>
              <span class="text-center">
                <p class="text-justify">
                  Cuando el nivel de usuario tenga el permiso de operar con
                  Zonas, este le permitira filtrar las zonas con los cuales el
                  usuario podra operar
                </p>
              </span>
            </v-tooltip>
            <v-row dense>
              <v-col
                cols="12"
                v-for="(item, index) in mostrarzona"
                :key="index"
              >
                <v-card-actions>
                  <div class="title">{{ item.name }}</div>
                  <v-divider></v-divider>
                </v-card-actions>
                <v-row dense>
                  <v-col
                    cols="12"
                    sm="3"
                    v-for="(item2, index2) in item.detalle"
                    :key="index2"
                  >
                    <v-checkbox
                      color="success"
                      :label="item2.descripcion"
                      v-model="lotesgrupos"
                      :value="item2"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
        </v-form>
      </v-container>
      <v-divider></v-divider>
      <v-card-actions>
        <c-btn-crud-close @click="cancel()"></c-btn-crud-close>
        <v-spacer></v-spacer>
        <c-btn-crud-done @click="save()"></c-btn-crud-done>
      </v-card-actions>
      <c-overlay :value="isUsersLoading"></c-overlay>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    props: {
      type: Object,
    },
  },

  mounted() {
    if (this.editable) {
      this.data = JSON.parse(JSON.stringify(this.props));
      this.getEmpresas();
    }
    setTimeout(() => this.next(0, "-"), 20);
    this.Vact_empresa_data();
    this.Vact_tambo_data();
    this.updatePermisos(this.data.usuario_det);
    this.fetchModulos();
  },
  activated() {},
  deactivated() {},
  computed: {
    ...mapGetters("users", ["isUsersLoading"]),
    ...mapGetters("modulos", ["getModulos", "isModulosLoading"]),
    ...mapGetters("empresa", ["Vget_empresa_data", "Vget_empresa_load"]),
    ...mapGetters("tambo", ["Vget_tambo_data", "Vget_tambo_load"]),
    ...mapGetters("deposito", ["Vget_deposito_data"]),
    ...mapGetters("lotegrupo", ["Vget_lotegrupo_data"]),
    title() {
      return this.editable ? "Modificar Usuario" : "Registrar Usuario";
    },
    getModulosFormat() {
      return this.getModulos.map((x) => {
        return { idrol_usuario: x };
      });
    },
    getSucursales() {
      var sucursales = [];
      this.data.idempresa.map((emp) => {
        const suc = this.Vget_tambo_data.filter(
          (suc) => suc.idempresa.idempresa === emp.idempresa
        );
        if (suc.length > 0) sucursales = sucursales.concat(suc);
      });
      return sucursales;
    },
  },
  watch: {
    Vget_deposito_data(val) {
      let existe = false;
      this.mostrardeposito.forEach((x) => {
        if (
          x.idempresa === this.seleccionado.idempresa &&
          x.idtambo === this.seleccionado.idtambo
        ) {
          existe = true;
        }
      });
      if (!existe) {
        this.seleccionado.detalle = JSON.parse(JSON.stringify(val));
        this.mostrardeposito.push(
          JSON.parse(JSON.stringify(this.seleccionado))
        );
      }
      return val;
    },
    Vget_lotegrupo_data(val) {
      let existe = false;
      this.mostrarzona.forEach((x) => {
        if (x.idempresa === this.select_zona.idempresa) {
          existe = true;
        }
      });
      if (!existe) {
        this.select_zona.detalle = JSON.parse(JSON.stringify(val));
        this.mostrarzona.push(JSON.parse(JSON.stringify(this.select_zona)));
      }
      return val;
    },
  },
  methods: {
    ...mapActions("users", ["setUsers", "fetchUsersActive", "setUsersUpdate"]),
    ...mapActions("empresa", ["Vact_empresa_data"]),
    ...mapActions("tambo", ["Vact_tambo_data"]),
    ...mapActions("deposito", ["Vact_deposito_sucursal_data"]),
    ...mapActions("modulos", ["fetchModulos"]),
    ...mapActions("lotegrupo", ["Vact_lotegrupo_empresa_data"]),
    cancel() {
      this.$emit("input", false);
    },
    getEmpresas() {
      let emp = [];
      let sucs = [];
      this.data.usuario.map((suc) => {
        if (!emp.find((x) => x.idempresa === suc.idempresa.idempresa))
          emp.push(suc.idempresa);
        sucs.push(suc.idtambo);
      });
      this.data.idempresa = JSON.parse(JSON.stringify(emp));
      this.data.usuario = JSON.parse(JSON.stringify(sucs));
      this.updateEmpresas(emp);
      this.updateSucursales(sucs);
      this.reasignarEditar();
    },
    reasignarEditar() {
      this.data.permisodeposito.forEach((x) => {
        this.depositos.push(x.iddeposito);
      });
      this.data.permisolotegrupo.forEach((y) => {
        this.lotesgrupos.push(y.idlote_grupo);
      });
    },
    next(id, data) {
      if (data != "")
        try {
          this.$refs[`user${id + 1}`].focus();
        } catch (error) {
          this.$refs[`user${id + 1}`].$el.focus();
        }
    },
    async save() {
      if (!this.$refs.form.validate()) return null;
      if (this.editable) {
        //this.data.password = null;
        delete this.data.password;
      }
      this.data.permisodeposito = [];
      this.data.permisolotegrupo = [];
      this.depositos.forEach((x) => {
        this.data.permisodeposito.push({
          iddeposito: x,
        });
      });
      this.lotesgrupos.forEach((y) => {
        this.data.permisolotegrupo.push({
          idlote_grupo: y,
        });
      });
      const response = this.editable
        ? await this.setUsersUpdate({
            data: this.data,
            id: this.data.id,
          })
        : await this.setUsers(this.data);
      if (response.success) {
        this.data = JSON.parse(JSON.stringify(this.default));
        this.$refs.form.resetValidation();
        this.fetchUsersActive();
        if (this.editable) return this.cancel();
        setTimeout(() => this.next(0, "-"), 20);
      }
    },
    updatePermisos(event) {
      let adeposito = false;
      let alotegrupo = false;
      event.forEach((x) => {
        x.idrol_usuario.detalle.forEach((y) => {
          if (y.idformulario.descformulario === "Deposito") {
            adeposito = true;
          } else if (y.idformulario.descformulario === "Zonas de Cultivo") {
            alotegrupo = true;
          }
        });
      });
      this.incluye.deposito = adeposito;
      this.incluye.lotegrupo = alotegrupo;
    },
    async updateEmpresas(event) {
      for (const x of event) {
        this.select_zona = JSON.parse(JSON.stringify(this.reset_select_zona));
        this.select_zona.idempresa = x.idempresa;
        this.select_zona.name = x.descempresa;
        await this.Vact_lotegrupo_empresa_data(x.idempresa);
      }
    },
    async updateSucursales(event) {
      for (const x of event) {
        this.seleccionado = JSON.parse(JSON.stringify(this.resetseleccionado));
        this.seleccionado.idempresa = x.idempresa.idempresa;
        this.seleccionado.idtambo = x.idtambo;
        this.seleccionado.name = x.nombtambo;
        await this.Vact_deposito_sucursal_data(x.idtambo);
      }
    },
  },

  data: () => ({
    rules: [(v) => !!v || "Este campo es obligatorio"],
    incluye: {
      deposito: false,
      lotegrupo: false,
    },
    select_zona: {
      idempresa: null,
      name: null,
      detalle: [],
    },
    reset_select_zona: {
      idempresa: null,
      name: null,
      detalle: [],
    },
    seleccionado: {
      idempresa: null,
      idtambo: null,
      name: null,
      detalle: [],
    },
    resetseleccionado: {
      idempresa: null,
      idtambo: null,
      name: null,
      detalle: [],
    },
    item1: [],
    item2: [],
    depositos: [],
    lotesgrupos: [],
    data: {
      id: 0,
      date_joined: "",
      last_login: "",
      first_name: "",
      last_name: "",
      username: "",
      password: "",
      usuario_det: [],
      idempresa: [],
      usuario: [],
      permisodeposito: [],
      permisolotegrupo: [],
    },
    default: {
      id: 0,
      date_joined: "",
      last_login: "",
      firstname: "",
      lastname: "",
      username: "",
      password: "",
      usuario_det: [],
      idempresa: [],
      usuario: [],
      permisodeposito: [],
      permisolotegrupo: [],
    },
    mostrardeposito: [],
    mostrarzona: [],
  }),
};
</script>

<style>
</style>