import { current_date, dmy } from "@/services/util/date.service";
import { Workbook } from "exceljs";
import * as fs from "file-saver";

const formatTipo = (item) => {
  if (item.operacion === "Inseminacion")
    return `Toro: ${item.tipo.split("-")[0]}, Servicio: ${
      item.tipo.split("-")[1]
    }`;
  if (item.operacion === "Parto")
    return `Parto: ${item.tipo.split("-")[0]}, Cria: ${
      item.tipo.split("-")[1]
    }, Inseminaciones: ${item.tipo.split("-")[2]}`;
  return item.tipo;
};

export const formatTipoHtml = (item) => {
  if (item.operacion === "Inseminacion")
    return `Toro: <strong>${
      item.tipo.split("-")[0]
    }</strong>, Servicio: <strong>${item.tipo.split("-")[1]}</strong>`;
  if (item.operacion === "Parto")
    return `Parto: <strong>${item.tipo.split("-")[0]}</strong>, Cria: <strong>${
      item.tipo.split("-")[1]
    }</strong>, Inseminaciones: <strong>${item.tipo.split("-")[2]}</strong>`;
  return item.tipo;
};

export const exportFicha = (datos, head) => {
  let workbook = new Workbook();
  if (datos.length === 0) workbook.addWorksheet("HOJA 1");
  const header = ["Operacion", "Fecha", "Tipo", "Comentario"];

  let worksheet = workbook.addWorksheet("Hoja 1");

  let dataTemp = datos.map((x) => {
    return [x.operacion, dmy(x.fecha), formatTipo(x), x.comentario || ""];
  });
  const data = dataTemp;
  const titles = [
    [`Nombre: ${head.nombra}`],
    [`RP: ${head.rp}`],
    [`Sexo: ${head.sexo}`],
    [`Nacimiento: ${dmy(head.fecha)}`],
    [`Raza: ${head.raza}`],
    [`Procedencia: ${head.procedencia}`],
  ];
  worksheet.addRow(["Ficha de Animal"]).font = {
    name: "Arial",
    family: 4,
    size: 12,
    bold: true,
  };
  worksheet.mergeCells(`A1:D1`);
  worksheet.getCell("A1").alignment = {
    vertical: "center",
    horizontal: "center",
  };
  titles.map((t) => {
    let row = worksheet.addRow(t);
    row.font = {
      name: "Arial",
      family: 4,
      size: 11,
    };
    const rowNumber = row._number;
    worksheet.mergeCells(`A${rowNumber}:B${rowNumber}`);
  });
  let headerRow = worksheet.addRow(header);

  headerRow.eachCell((cell) => {
    cell.alignment = {
      vertical: "middle",
      horizontal: "center",
    };
    cell.border = {
      top: { style: "medium" },
      left: { style: "medium" },
      right: { style: "medium" },
      bottom: { style: "medium" },
    };
    cell.font = {
      name: "Arial",
      family: 4,
      size: 11,
    };
  });

  data.forEach((x) => {
    let row = worksheet.addRow(x);
    row.eachCell((cell) => {
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
        top: { style: "thin" },
      };
      cell.font = {
        name: "Arial",
        family: 4,
        size: 11,
      };
    });
  });

  worksheet.columns.map((column) => {
    let widthMax = 0;
    column.values.map((x, i) => {
      if (x)
        if (x.length) if (i > 7) if (x.length > widthMax) widthMax = x.length;
    });
    column.width = widthMax < 10 ? 13 : widthMax;
  });

  workbook.xlsx.writeBuffer().then((data) => {
    let blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    fs.saveAs(blob, `FICHA ANIMAL ${current_date()}.xlsx`);
  });
};

export const exportActividades = (datos) => {
  let workbook = new Workbook();
  if (datos.length === 0) workbook.addWorksheet("HOJA 1");
  const header = ["Fecha", "Animal", "Operacion", "Tipo", "Comentario"];
  let worksheet = workbook.addWorksheet("Hoja 1");
  let dataTemp = datos.map((x) => {
    return [
      x.fecha,
      x.rpanimal,
      x.operacion,
      formatTipo(x),
      x.comentario || "",
    ];
  });
  const data = dataTemp;

  worksheet.addRow(["Ficha de Actividades"]).font = {
    name: "Arial",
    family: 4,
    size: 13,
    bold: true,
  };
  worksheet.mergeCells(`A1:E1`);
  worksheet.getCell("A1").alignment = {
    vertical: "center",
    horizontal: "center",
  };

  let headerRow = worksheet.addRow(header);

  headerRow.eachCell((cell) => {
    cell.alignment = {
      vertical: "middle",
      horizontal: "center",
    };
    cell.border = {
      top: { style: "medium" },
      left: { style: "medium" },
      right: { style: "medium" },
      bottom: { style: "medium" },
    };
    cell.font = {
      name: "Arial",
      family: 4,
      size: 12,
    };
  });

  data.forEach((x) => {
    let row = worksheet.addRow(x);
    row.eachCell((cell) => {
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
        top: { style: "thin" },
      };
      cell.font = {
        name: "Arial",
        family: 4,
        size: 11,
      };
    });
  });

  worksheet.columns.map((column) => {
    let widthMax = 0;
    column.values.map((x, i) => {
      if (x)
        if (x.length)
          if (i > 2) if (x.length > widthMax) widthMax = x.length + 3;
    });
    column.width = widthMax <= 12 ? 20 : widthMax;
  });

  workbook.xlsx.writeBuffer().then((data) => {
    let blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    fs.saveAs(blob, `ACTIVIDADES ANIMAL ${current_date()}.xlsx`);
  });
};

export const exportReproducciones = (datos) => {
  if(datos.length === 0) return null;
  let workbook = new Workbook();
  if (datos.length === 0) workbook.addWorksheet("HOJA 1");
  const header = ["Padre", "Madre", "Animal", "Raza","Ultima I.A.","Toro","A.I", "Parto","Cria","Sexo Cria","I.E.P","Nro Parto",  "Dias Abiertos","Dias Lact."];
  let worksheet = workbook.addWorksheet("Hoja 1");
  let dataTemp = datos.map((x) => {
    return [
      x["PADRE"] || "",
      x["MADRE"] || "",
      x["VACA"] || "",
      x["RAZA"] || "",
      dmy(x["ULTIMA FECHA I.A."]) || "",
      x["TORO"] || "",
      x["A.I"] || "",
      dmy(x["FECHA DE PARTO"]) || "",
      x["NRO. CRIA"] || "",
      x["SEXO CRIA"] || "",
      x["I.E.P meses"] || "",
      x["# DE PARTO"] || "",
      x["DIAS ABIERT."] || "",
      x["LACTACION TOTAL"] || ""
    ];
  });
  const data = dataTemp;
  worksheet.addRow([])
  worksheet.addRow(["Reporte de Reproduccion y Lactancia"]).font = {
    name: "Arial",
    family: 4,
    size: 16,
    bold: true,
  };
  worksheet.mergeCells(`A2:N2`);
  worksheet.addRow([])
  worksheet.getCell("A2").alignment = {
    vertical: "center",
    horizontal: "center",
  };

  let headerRow = worksheet.addRow(header);

  headerRow.eachCell((cell) => {
    cell.alignment = {
      vertical: "middle",
      horizontal: "center",
    };
    cell.border = {
      top: { style: "medium" },
      left: { style: "medium" },
      right: { style: "medium" },
      bottom: { style: "medium" },
    };
    cell.font = {
      name: "Arial",
      family: 4,
      size: 13,
    };
  });
  
  data.forEach((x) => {
    let row = worksheet.addRow(x);
    row.eachCell((cell) => {
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
        top: { style: "thin" },
      };
      cell.font = {
        name: "Arial",
        family: 4,
        size: 11,
      };
    });
  });

  worksheet.columns.map((column) => {
    let widthMax = 0;
    column.values.map((x, i) => {
      if (x)
        if (x.length)
          if (i > 2) if (x.length > widthMax) widthMax = x.length + 3;
    });
    column.width = widthMax <= 12 ? 17 : widthMax;
  });

  workbook.xlsx.writeBuffer().then((data) => {
    let blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    fs.saveAs(blob, `Reproduccion y Lactancia ${current_date()}.xlsx`);
  });
};

