<template>
  <div>
    <v-row dense align="center" justify="center">
      <v-img :src="require('@/assets/help/agro/mainopen.png')" max-width="240"></v-img>
      <h4>Secciones</h4>
      <v-col cols="12">
        <ol>
          <li>
            Seccion
            <a @click="show = 'EditarCampo'">Editar</a>
          </li>
          <li>
            Seccion
            <a @click="show = 'EliminarCampo'">Eliminar</a>
          </li>
          <li>
            Seccion
            <a @click="show = 'Regcampana'">Registro de Campaña</a>
          </li>
          <li>
            Seccion
            <a @click="show = 'MapaCampo'">Mapa</a>
          </li>
          <li>
            Seccion
            <a @click="show = 'Clima'">Clima</a>
          </li>
          <li>
            Seccion
            <a @click="show = 'Ndvi'">Indice NDVI</a>
          </li>
        </ol>
      </v-col>
    </v-row>
    <v-progress-linear rounded height="3" value="100"></v-progress-linear>
    <component :is="show"></component>
    <div id="mapa"></div>
    <div id="clima"></div>
    <div id="ndvi"></div>
  </div>
</template>

<script>
export default {
  components: {
    Regcampana: () =>
      import(/* webpackPrefetch: true */ "@/views/Help/Agro/Detail/Regcampana.vue"),
    EliminarCampo: () =>
      import(/* webpackPrefetch: true */ "@/views/Help/Agro/Detail/EliminarCampo.vue"),
    EditarCampo: () =>
      import(/* webpackPrefetch: true */ "@/views/Help/Agro/Detail/EditarCampo.vue"),
    MapaCampo: () =>
      import(/* webpackPrefetch: true */ "@/views/Help/Agro/Detail/MapaCampo.vue"),
    Clima: () =>
      import(/* webpackPrefetch: true */ "@/views/Help/Agro/Detail/Clima.vue"),
    Ndvi: () => import(/* webpackPrefetch: true */ "@/views/Help/Agro/Detail/Ndvi.vue")
  },
  data() {
    return {
      show: ""
    };
  }
};
</script>

<style>
</style>