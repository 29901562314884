<template>
  <div>
    <v-dialog :width="verClima ? '350' : '700'" :value="true" persistent>
      <v-card>
        <div v-if="verClima">
          <v-toolbar flat>
            <v-toolbar-title>Clima</v-toolbar-title>
            <v-divider class="mx-2" inset vertical></v-divider>

            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <v-row align="center" row dense>
              <v-spacer></v-spacer>
              <v-col class="display-1" cols="6">{{clima.main.temp}}&deg;C</v-col>
              <v-col cols="3">
                <v-img
                  :src="'https://openweathermap.org/img/wn/'+clima.weather[0].icon+'@2x.png'"
                  alt="Sunny image"
                  width="50"
                ></v-img>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-subtitle
            class="title text-uppercase"
            align="center"
          >{{clima.weather[0].description}}</v-card-subtitle>
          <v-card-text>
            <table>
              <tr>
                <th>Viento</th>
                <td>{{(clima.wind.speed * 3.6).toFixed(2)}} km/h</td>
              </tr>
              <tr>
                <th>Presion</th>
                <td>{{clima.main.pressure}} hPa</td>
              </tr>
              <tr>
                <th>Humedad</th>
                <td>{{clima.main.humidity}} %</td>
              </tr>
              <tr>
                <th>Indice UVI</th>
                <td>{{clima.uvi.uvi}}</td>
              </tr>
              <tr>
                <th>Nubes</th>
                <td>{{clima.clouds.all}} %</td>
              </tr>
            </table>
          </v-card-text>
          <v-divider></v-divider>
        </div>
        <div v-else>
          <v-toolbar flat>
            <v-toolbar-title>Pronostico</v-toolbar-title>
            <v-divider class="mx-2" inset vertical></v-divider>

            <v-spacer></v-spacer>

            <v-switch
              v-model="pronostico_modo"
              inset
              :label="`Pronostico: ${pronostico}`"
              class="mt-5"
            ></v-switch>
          </v-toolbar>

          <v-data-table
            :headers="headers"
            :loading="Vget_campo_load"
            :items="data"
            hide-default-header
            hide-default-footer
            :items-per-page="100"
            v-show="!pronostico_modo"
          >
            <template v-slot:[`item.diaMes`]="{item}">
              <v-row dense>
                <v-col cols="10" md="4" sm="3" class="mt-3">
                  <div class="mt-2">{{item.diaMes}}</div>
                </v-col>
                <v-col cols="2" md="2" sm="2" class="mt-3">
                  <v-img
                    :src="'https://openweathermap.org/img/wn/'+item.weather.icon+'@2x.png'"
                    alt="Sunny image"
                    width="35"
                  ></v-img>
                </v-col>
                <v-col cols="12" md="2" sm="3"></v-col>
                <v-col cols="12" md="4" sm="4">
                  <v-chip x-small color="primary">{{item.temp_max}}</v-chip>
                  <v-chip x-small color="secondary">{{item.temp_min}}</v-chip>
                  <v-chip x-small>
                    <div class="overline">{{item.weather.description}}</div>
                  </v-chip>
                  <div class="caption">{{item.vien_kmh}} km/h</div>
                  <div class="caption">Nubes {{item.nube}} %, {{item.hPa}} hPa</div>
                </v-col>
              </v-row>
            </template>
          </v-data-table>
          <v-data-table
            v-show="pronostico_modo"
            :headers="headers_dia"
            hide-default-footer
            hide-default-header
            :loading="Vget_campo_load"
            :items="data"
            :expanded.sync="expanded"
            show-expand
            item-key="diaMes"
            :items-per-page="100"
          >
            <template v-slot:expanded-item="{ item }">
              <td :colspan="3">
                <v-card>
                  <v-data-table
                    :headers="headers"
                    :items="item.detalle"
                    hide-default-header
                    hide-default-footer
                    :items-per-page="100"
                  >
                    <template v-slot:[`item.diaMes`]="{item}">
                      <v-row dense>
                        <v-col cols="10" md="3" sm="3" class="mt-3">
                          <div class="mt-2">{{item.hora}}</div>
                        </v-col>
                        <v-col cols="2" md="2" sm="2" class="mt-3">
                          <v-img
                            :src="'https://openweathermap.org/img/wn/'+item.weather[0].icon+'@2x.png'"
                            alt="Sunny image"
                            width="35"
                          ></v-img>
                        </v-col>
                        <v-col cols="12" md="3" sm="3"></v-col>
                        <v-col cols="12" md="4" sm="4">
                          <v-chip x-small color="primary">{{item.main.temp}}</v-chip>
                          <v-chip x-small>
                            <div class="overline">{{item.weather[0].description}}</div>
                          </v-chip>
                          <div class="caption">{{(item.wind.speed * 3.6).toFixed(2)}} km/h</div>
                          <div
                            class="caption"
                          >Nubes {{item.clouds.all}} %, {{item.main.pressure}} hPa</div>
                        </v-col>
                      </v-row>
                    </template>
                  </v-data-table>
                </v-card>
              </td>
            </template>
          </v-data-table>
        </div>
        <v-card-actions>
          <v-btn
            color="primary"
            text
            @click="verClima ? verClima = false : verClima = true"
          >{{verClima ? 'Ver Pronosticos' : 'Ver Clima'}}</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="$emit('input', 1)">
            <v-icon>clear</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { unixDiaMes, unixDia, unixHora } from "@/services/util/date.service";
export default {
  props: {
    value: Number
  },
  computed: {
    ...mapGetters("campo", ["Vget_campo_pronostico", "Vget_campo_load"]),
    ...mapGetters("campo", ["Vget_campo_clima"]),
    pronostico() {
      if (this.pronostico_modo) return "Hora";
      return "Diario";
    }
  },
  watch: {
    Vget_campo_pronostico(val) {
      this.filtrarDatos(val);
    }
  },
  mounted() {
    this.Vact_campo_pronostico(this.$route.query.polygon);
    this.clima = this.Vget_campo_clima;
  },
  methods: {
    ...mapActions("campo", ["Vact_campo_pronostico"]),
    filtrarDatos(val) {
      let objeto_temp = {};
      let data = [];
      val.list.forEach(x => {
        x.diaMes = unixDiaMes(x.dt);
        x.dia = unixDia(x.dt);
        x.hora = unixHora(x.dt);
        if (!Object.prototype.hasOwnProperty.call(objeto_temp, x.dia)) {
          objeto_temp[x.dia] = {
            detalle: []
          };
        }
        objeto_temp[x.dia].detalle.push(x);
      });
      Object.entries(objeto_temp).forEach(value => {
        const array_length = value[1].detalle.length;
        let weather_repet = {};
        let weather = { cant: 0 };
        let viento_promedio_mS = 0;
        let viento_promedio_kmH = 0;
        let hPa_promedio = 0;
        let nube_promedio = 0;
        let temp_min = 1000;
        let temp_max = 0;
        let diaMes;
        value[1].detalle.forEach(x => {
          if (weather_repet[x.weather[0].id] === undefined) {
            weather_repet[x.weather[0].id] = { cant: 0 };
          }
          weather_repet[x.weather[0].id] = {
            cant: (weather_repet[x.weather[0].id].cant || 0) + 1,
            description: x.weather[0].description,
            icon: x.weather[0].icon
          };
          if (x.main.temp < temp_min) temp_min = x.main.temp;
          if (x.main.temp > temp_max) temp_max = x.main.temp;
          viento_promedio_mS = viento_promedio_mS + Number(x.wind.speed);
          nube_promedio = nube_promedio + Number(x.clouds.all);
          hPa_promedio = hPa_promedio + Number(x.main.pressure);
          diaMes = x.diaMes;
        });
        Object.entries(weather_repet).forEach(v => {
          if (v[1].cant > weather.cant) weather = v[1];
        });
        viento_promedio_mS = viento_promedio_mS / array_length;
        viento_promedio_kmH = (viento_promedio_mS * 3.6).toFixed(2);
        nube_promedio = (nube_promedio / array_length).toFixed(2);
        hPa_promedio = (hPa_promedio / array_length).toFixed(0);
        data.push({
          weather: weather,
          temp_min: temp_min,
          temp_max: temp_max,
          vien_kmh: Number(viento_promedio_kmH),
          nube: Number(nube_promedio),
          hPa: Number(hPa_promedio),
          diaMes: diaMes,
          detalle: value[1].detalle
        });
      });
      this.data = data;
    }
  },
  data() {
    return {
      verClima: true,
      data: [],
      clima: {
        uvi: {
          uvi: ""
        },
        weather: [
          {
            icon: ""
          }
        ],
        wind: {
          speed: ""
        },
        main: {
          temp: ""
        },
        clouds: {
          all: ""
        }
      },
      pronostico_modo: false,
      expanded: [],
      headers: [
        {
          align: "start",
          value: "diaMes",
          sortable: false
        }
      ],
      headers_dia: [
        {
          align: "start",
          value: "diaMes",
          sortable: false
        },
        { text: "", value: "data-table-expand", align: "right" }
      ]
    };
  }
};
</script>
<style lang="css" scoped>
table {
  border-collapse: collapse;
  width: 100%;
}

th,
td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}
.position-dialog {
  width: 330px;
}
</style>
