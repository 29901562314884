<template>
  <v-dialog :value="value" max-width="800px" persistent @keydown.esc="close()">
    <v-card>
      <v-form ref="form" lazy-validation @submit.prevent>
        <v-toolbar flat dense>
          <v-toolbar-title>Agregar Animal</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-avatar color="primary lighten-2" class="subheading white--text" size="24" v-text="step"></v-avatar>
        </v-toolbar>
        <v-window v-model="step">
          <v-window-item :value="1">
            <v-container>
              <v-row dense>
                <v-col cols="12" sm="6">
                  <c-text-field label="Nombre" id="animalInput1" :rules="[]" outlined dense v-model="data.nombanimal"
                    @keyup.native.enter="next(1, '0')" />
                </v-col>
                <v-col cols="12" sm="6">
                  <c-text-field label="RP Animal" id="animalInput2" outlined dense v-model="data.rpanimal"
                    @keyup.native.enter="next(2, data.rpanimal)" />
                </v-col>
                <v-col cols="12" sm="6">
                  <c-text-date label="Fecha de nacimiento" id="animalInput3" outlined dense v-model="data.fechanimal"
                    @keyup.native.enter="next(3, data.fechanimal)" />
                </v-col>
                <v-col cols="12" sm="6">
                  <c-text-currency label="Valor del animal" id="animalInput4" outlined dense
                    :rules="[(v) => !!v || 'Obligatorio']" v-model="data.montanimal"
                    @keyup.native.enter="next(4, data.montanimal)" />
                </v-col>
                <v-col :cols="isMacho ? 9 : 12" :sm="isMacho ? 4 : 6">
                  <vue-autocomplete-generic id="animalInput5" action="sexocria/fetchSexoCria"
                    getter="sexocria/getSexoCria" load="sexocria/isLoading" pk="idsexo" label="Seleccione Sexo"
                    title="descsexocria" permission="Sexo Cria" v-model="data.idsexo.idsexo"
                    @keyup.native.enter="next(5, data.idsexo.idsexo)" />
                </v-col>
                <v-col cols="3" sm="2" class="mt-n3" v-if="isMacho">
                  <v-checkbox v-model="data.issemen" label="Semen?"></v-checkbox>
                </v-col>
                <v-col cols="12" sm="6">
                  <AutocompleteEstadoAnimal id="animalInput6" label="Seleccione estado animal"
                    v-model="data.idestadoanimal.idestadoanimal" @keyup.native.enter="
                      next(6, data.idestadoanimal.idestadoanimal)
                      " />
                </v-col>

                <v-col cols="12" sm="6">
                  <AutocompleteRaza id="animalInput7" v-model="data.idraza.idraza" @nextfocus="next(7, '-')"
                    @keyup.native.enter="next(7, data.idraza.idraza)" />
                </v-col>

                <v-col cols="12" sm="6">
                  <AutocompleteItemVivo id="animalInput8" v-model="data.iditem.iditem" @nextfocus="next(8, '-')"
                    @keyup.native.enter="next(8, data.iditem.iditem)" />
                </v-col>
                <v-col cols="12" sm="6">
                  <vue-autocomplete-generic id="animalInput9" action="deposito/Vact_deposito_data"
                    getter="deposito/Vget_deposito_data" load="deposito/Vget_deposito_load" pk="iddeposito"
                    label="Deposito" title="desdeposito" :create="true" @create="formularios.deposito = $event"
                    permission="Deposito" v-model="data.iddeposito.iddeposito" @sync-input="formularios.search = $event"
                    @keyup.native.enter="next(9, data.iddeposito.iddeposito)" />
                  <div v-if="formularios.deposito">
                    <Item-Deposito-create v-model="formularios.deposito" :sync-input="formularios.search" @sync-input="
                      (data.iddeposito = $event),
                      next(9, data.iddeposito.iddeposito)
                      " />
                  </div>
                </v-col>
                <v-col cols="12" sm="6">
                  <AutocompleteLote id="animalInput10" v-model="data.idlote.idlote" @nextfocus="next(10, '-')"
                    @keyup.native.enter="next(10, data.idlote.idlote)" />
                </v-col>

                <v-col cols="12">
                  <vue-autocomplete-generic label="Seleccione procedencia" action="procedencia/Vact_procedencia_data"
                    getter="procedencia/Vget_procedencia_data" load="procedencia/Vget_procedencia_load"
                    pk="idprocedencia" id="animalInput11" title="descprocedencia" permission="Procedencia"
                    @create="formularios.procedencia = $event" :create="true" v-model="data.idprocedencia.idprocedencia"
                    @sync-input="formularios.search = $event" @keyup.native.enter="
                      next(11, data.idprocedencia.idprocedencia)
                      " />
                  <div v-if="formularios.procedencia">
                    <Animal-procedencia-create v-model="formularios.procedencia" :sync-input="formularios.search"
                      @sync-input="
                        (data.idprocedencia = $event),
                        next(11, data.idprocedencia.idprocedencia)
                        " />
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-window-item>

          <v-window-item :value="2">
            <v-card-text>
              <div v-if="data.idestadoanimal.idestadoanimal === 3">
                <CeloForm ref="celoForm" :modo="false" />
              </div>
              <div v-if="data.idestadoanimal.idestadoanimal === 4">
                <InseminacionForm ref="inseminacionForm" :modo="false" />
              </div>
              <div v-if="data.idestadoanimal.idestadoanimal === 6">
                <Animal-Secado-CreateRef ref="secadoForm" :modo="false" />
              </div>
              <div v-if="data.idestadoanimal.idestadoanimal === 8">
                <EnfermedadForm ref="enfermedadForm" :modo="false" />
              </div>
              <div v-if="data.idestadoanimal.idestadoanimal === 9">
                <MuerteForm ref="muerteForm" :modo="false" />
              </div>
              <div v-if="data.idsexo.idsexo === 1 && data.issemen == true && data.idestadoanimal.idestadoanimal === 2">
                <SemenForm ref="semenArbolForm"/>
              </div>
            </v-card-text>
          </v-window-item>
        </v-window>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="blue darken-1" :disabled="step === 1" text @click="step--">
            <v-icon dark>arrow_back_ios</v-icon>
          </v-btn>
          <v-btn color="red" text @click="close">
            <v-icon dark>close</v-icon>
          </v-btn>
          <v-spacer></v-spacer>

          <v-btn color="blue darken-1" text v-if="step_extra && step === 1" @click="step++" id="animalInput12">
            <v-icon dark>arrow_forward_ios</v-icon>
          </v-btn>
          <v-btn v-if="!step_extra || step === 2" id="animalInputaceptar" color="blue darken-1" text
            @click="validarcampos()">
            <v-icon dark>check</v-icon>
          </v-btn>
        </v-card-actions>
      </v-form>
      <c-overlay :value="isLoading"></c-overlay>
      <Nomina @reset="formularios.datos = null" :props="formularios.datos" v-model="formularios.nomina"
        v-if="formularios.nomina" />
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { focus } from "@/services/focus.service";
import CeloForm from "@/views/Analisis/Celo/CreateRef";
import { current_date } from "@/services/util/date.service";
import Nomina from "./Nomina";
import AutocompleteItemVivo from "@/views/Item/Item/AutocompleteItemVivo";
import AutocompleteEstadoAnimal from "../Estadoanimal/Autocomplete";
import AutocompleteRaza from "../Raza/Autocomplete";
import AutocompleteLote from "../Lote/Autocomplete";
export default {
  components: {
    CeloForm,
    Nomina,
    InseminacionForm: () => import("@/views/Analisis/Inseminacion/CreateRef"),
    MuerteForm: () => import("@/views/Enfermedad/Muerte/CreateRef"),
    EnfermedadForm: () => import("@/views/Enfermedad/Enfermedad/CreateRef"),
    SemenForm: () => import("@/views/Animal/Animal/SemenRef"),
    AutocompleteLote,
    AutocompleteRaza,
    AutocompleteItemVivo,
    AutocompleteEstadoAnimal,
  },
  props: {
    value: Boolean,
    datos: {
      type: [Object, Array],
      default: null,
    },
    "sync-input": {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      sync: null,
      step: 1,
      data: {
        idanimal: 0,
        fechanimal: current_date(),
        nombanimal: "",
        rpanimal: "",
        issemen: false,
        idestadoanimal: {
          idestadoanimal: "",
        },
        idprocedencia: {
          idprocedencia: "",
        },
        idraza: {
          idraza: "",
        },
        idlote: {
          idlote: "",
        },
        iddeposito: {
          iddeposito: "",
        },
        iditem: {
          iditem: "",
        },
        idsexo: {
          idsexo: "",
        },
        montanimal: "",
        band: "celo",
        idcelo: {},
        idinseminacion: {},
        idsecado: {},
        idenfermedad: {},
        idmuerte: {},
        arbolsemen: []
      },
      default: {
        idanimal: 0,
        fechanimal: current_date(),
        nombanimal: "",
        rpanimal: "",
        issemen: false,
        idestadoanimal: {
          idestadoanimal: "",
        },
        idprocedencia: {
          idprocedencia: "",
        },
        idraza: {
          idraza: "",
        },
        idlote: {
          idlote: "",
        },
        iddeposito: {
          iddeposito: "",
        },
        iditem: {
          iditem: "",
        },
        idsexo: {
          idsexo: "",
        },
        montanimal: "",
        band: "",
        idcelo: {},
        idinseminacion: {},
        idsecado: {},
        idenfermedad: {},
        idmuerte: {},
        arbolsemen: []
      },
      operacion: true,
      formularios: {
        datos: null,
        nomina: false,
        raza: false,
        lote: false,
        procedencia: false,
        deposito: false,
        search: "",
      },
    };
  },

  watch: {
    $route() {
      return this.close();
    }
  },
  computed: {
    ...mapGetters("animal", ["isLoading"]),
    isMacho() {
      return this.data.idsexo.idsexo === 1 ? true : false;
    },
    step_extra() {
      const semen = this.data.issemen
      const sexo = this.data.idsexo.idsexo
      const estado = this.data.idestadoanimal.idestadoanimal;
      const permitido = [1, 2, 5, 7, 10, 11]
      if (estado != "") {
        if (sexo == 1) {
          if (estado == 2 && semen) {
            return true
          }
        } else if (permitido.includes(estado)) {
          return false
        } else {
          return true
        }
      }
      return false
    },
  },
  mounted() {
    setTimeout(() => this.next(0, "-"), 500);
    if (this.syncInput != null) {
      this.data.nombanimal = this.syncInput;
      this.sync = this.syncInput;
    }
  },
  methods: {
    ...mapActions("animal", ["setAnimal"]),
    ...mapActions("stock", ["Vact_stock_data"]),
    next(id, data) {
      focus.nextidName("animalInput", id, data);
    },
    async obtenerdatos() {
      switch (this.data.idestadoanimal.idestadoanimal) {
        case 2: {
          let semen = this.data.issemen
          let sexo = this.data.idsexo.idsexo
          if (semen && sexo === 1) {
            this.data.arbolsemen = JSON.parse(
              JSON.stringify(await this.$refs.semenArbolForm.obtenerDatos())
            )
          }
          return true
        }
        case 3: {
          this.data.idcelo = JSON.parse(
            JSON.stringify(await this.$refs.celoForm.obtenerDatos())
          );
          return true;
        }
        case 4: {
          this.data.idinseminacion = JSON.parse(
            JSON.stringify(await this.$refs.inseminacionForm.obtenerDatos())
          );
          return true;
        }
        case 6: {
          this.data.idsecado = JSON.parse(
            JSON.stringify(await this.$refs.secadoForm.obtenerDatos())
          );
          return true;
        }
        case 8: {
          this.data.idenfermedad = JSON.parse(
            JSON.stringify(await this.$refs.enfermedadForm.obtenerDatos())
          );
          return true;
        }
        case 9: {
          this.data.idmuerte = JSON.parse(
            JSON.stringify(await this.$refs.muerteForm.obtenerDatos())
          );
          return true;
        }
        default: {
          return true;
        }
      }
    },
    validarcampos() {
      if (this.$refs.form.validate()) {
        switch (this.data.idestadoanimal.idestadoanimal) {
          case 3: {
            if (!this.$refs.celoForm.validate()) return null;
            this.data.band = "celo";
            this.guardarA();
            break;
          }
          case 4: {
            if (!this.$refs.inseminacionForm.validate()) return null;
            this.data.band = "inseminada";
            this.guardarA();
            break;
          }
          case 6: {
            if (!this.$refs.secadoForm.validate()) return null;
            this.data.band = "secado";
            this.guardarA();
            break;
          }
          case 8: {
            if (!this.$refs.enfermedadForm.validate()) return null;
            this.data.band = "enfermedad";
            this.guardarA();
            break;
          }
          case 9: {
            if (!this.$refs.muerteForm.validate()) return null;
            this.data.band = "muerte";
            this.guardarA();
            break;
          }
          default: {
            this.data.band = "";
            this.guardarA();
            break;
          }
        }
      }
    },
    vaciarcampos() {
      this.data = JSON.parse(JSON.stringify(this.default));
    },
    close() {
      this.data = JSON.parse(JSON.stringify(this.default));
      this.$emit("input", false);
      this.$emit("datos", null);
      this.Vact_stock_data();
      this.operacion = true;
    },
    async guardarA_finalizar() {
      if (this.data.nombanimal === "") {
        this.data.nombanimal = null;
      }
      const response = await this.setAnimal(this.data);
      if (response.success) {
        if (this.sync != null) {
          this.$emit("sync-input", response.data.data);
          return this.close();
        }
        if (this.data.idsexo.idsexo === 2) {
          this.formularios.datos = JSON.parse(
            JSON.stringify(response.data.data)
          );
          this.formularios.nomina = true;
        }
        this.vaciarcampos();
        this.$refs.form.resetValidation();
        this.step = 1;
        setTimeout(() => this.next(0, "-"), 100);
      }
    },
    async guardarA() {
      if (!(await this.obtenerdatos())) return null
      if (this.operacion) return this.guardarA_finalizar();
    },
  },
};
</script>
