<template>
  <c-dialog-confirmation
    :value="value"
    @cancel="close()"
    @done="save()"
    :loading="isContratoFuncionarioLoading"
    >Deseas dar de baja el contrato?</c-dialog-confirmation
  >
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    value: Boolean,
    props: Object,
  },
  computed: {
    ...mapGetters("contratofuncionario", ["isContratoFuncionarioLoading"]),
  },
  watch: {
    $route() {
      return this.close();
    },
  },
  methods: {
    ...mapActions("contratofuncionario", ["setContratoFuncionarioDelete"]),
    async save() {
        console.log(this.value)
      const id = this.props.idcontrato
      const response = await this.setContratoFuncionarioDelete(id);
      if (!response.success) return null;
      this.close();
      this.$emit('filter');
    },
    close() {
      this.$emit("datos", null);
      this.$emit("input", false);
    },
  },
};
</script>

<style>
</style>