import { db } from "@/services/db.service";
import { utilidad } from "@/services/compra_utilidad.service";
export default {
  async Vact_cobrocuenta_save({ commit }, data) {
    commit("cobrocuenta_load", true);
    try {
      const resp = await db.save("/apireciboventa/", data);
      commit("cobrocuenta_load", false);
      return resp;
    } catch (e) {
      commit("cobrocuenta_load", false);
      return false;
    }
  },
  async Vact_cobrocuenta_data({ commit }, search) {
    commit("cobrocuenta_load", true);
    try {
      let data = [];
      if (search) {
        data = await db.get("/apireciboventa/?busqueda="+search);
      } else {
        data = await db.get("/apireciboventa/?limit=10");
      }

      commit("cobrocuenta_data", data);
      commit("cobrocuenta_load", false);
      return true;
    } catch (e) {
      commit("cobrocuenta_load", false);
      return false;
    }
  },
  async Vact_cobrocuenta_data_id({ commit }, id) {
    commit("cobrocuenta_load", true);
    try {
      let data = await db.get("/apireciboventashow/" + id + "/");
      data.detrecibo.forEach((x, i) => {
        const est = x.idventa.idtimbrado.idestablecimiento.establecimiento;
        const pun = x.idventa.idtimbrado.idpuntoexpedicion.puntoexpedicion;
        const num = utilidad.completar_nrof(x.idventa.numfventa.toString());
        data.detrecibo[i].factura = est + "-" + pun + "-" + num;
      });
      commit("cobrocuenta_data_id", data);
      commit("cobrocuenta_load", false);
      return true;
    } catch (e) {
      commit("cobrocuenta_load", false);
      return false;
    }
  },
  async Vact_cobrocuenta_del({ dispatch }, id) {
    try {
      const data = await db.delete("/apireciboventa/" + id + "/");
      dispatch("Vact_cobrocuenta_data");
      return data;
    } catch (e) {
      return false;
    }
  },
  async Vact_cobrocuenta_by_id({ commit }, { id, idmoneda }) {
    commit("cobrocuenta_load", true);
    try {
      var data = await db.get(
        "/apicuentaventa/?cliente=" + id + "&money=" + idmoneda
      );
      data.forEach((x, i) => {
        const est = x.idventa.idtimbrado.idestablecimiento.establecimiento;
        const pun = x.idventa.idtimbrado.idpuntoexpedicion.puntoexpedicion;
        const num = utilidad.completar_nrof(x.idventa.numfventa.toString());
        data[i].factura = est + "-" + pun + "-" + num;
      });
      commit("cobrocuenta_by_id", data);
      commit("cobrocuenta_load", false);
      return null;
    } catch (e) {
      commit("cobrocuenta_by_id", []);
      commit("cobrocuenta_load", false);
      return e.message;
    }
  },

  async Vact_cobrocuenta_num_rec({ commit }) {
    commit("cobrocuenta_num_rec_load", true);
    try {
      const resp = await db.get("/apinrorecibo/");
      commit("cobrocuenta_num_rec", resp[0]);
      commit("cobrocuenta_num_rec_load", false);
      return true;
    } catch (e) {
      commit("cobrocuenta_num_rec_load", false);
      return false;
    }
  },
};
