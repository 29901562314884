<template>
    <c-dialog-confirmation
        :value="value"
        @cancel="close()"
        @done="save()"
        :loading="isLoadingMaquinariaSalario"
    >
        Desea eliminar el salario de 
        <strong>{{ props.idmaquinaria_asignacion.idmaquinaria.descripcion }}</strong>
    </c-dialog-confirmation>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
    props: {
        value: Boolean,
        props: Object,
    },
    computed: {
        ...mapGetters("maquinaria_salario", ["isLoadingMaquinariaSalario"]),
    },
    watch: {
        $route() {
            return this.close();
        },
    },
    methods: {
        ...mapActions("maquinaria_salario", [
            "setMaquinariaSalarioDelete",
            "fetchMaquinariaSalario",
        ]),
        async save() {
            const id = this.props.idmaquinaria_salario;
            const response = await this.setMaquinariaSalarioDelete(id);
            if (!response.success) return null;
            this.close();
        },
        close() {
            this.$emit("datos", null);
            this.$emit("input", false);
            this.fetchMaquinariaSalario();
        }
    }
}
</script>