export default {

  ajuste_update(state, request) {
    state.ajuste_update = request;
  },
  ajuste_data(state, request) {
    state.ajuste_data = request;
  },   
  ajuste_load(state, request) {
    state.ajuste_load = request;
  }, 
  ajuste_save_load(state, request) {
    state.ajuste_save_load = request;
  }, 
}