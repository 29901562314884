
export default {

  Vget_veterinario_data: (state) => {
    return state.veterinario_data
  },
  Vget_veterinario_load: (state) => {
    return state.veterinario_load
  },
  Vget_veterinario_save_load: (state) => {
    return state.veterinario_save_load
  },
}
