export const getTotal = (crudMode, data) => {
  if (crudMode === "Compra")
    return data.compra.reduce(
      (acc, curr) => (acc = acc + curr.cantdetcompra * curr.costdetcompra),
      0
    );
  return data.compraconcepto.reduce(
    (acc, curr) => (acc = acc + curr.cantdetconcepto * curr.costconcepto),
    0
  );
};

export const getIva5 = (crudMode, data) => {
  if (crudMode === "Compra")
    return data.compra.reduce((acc, curr) => acc + curr.iva5, 0) / 21;
  return data.compraconcepto.reduce((acc, curr) => acc + curr.iva5, 0) / 21;
};

export const getIva10 = (crudMode, data) => {
  if (crudMode === "Compra")
    return data.compra.reduce((acc, curr) => acc + curr.iva10, 0) / 11;
  return data.compraconcepto.reduce((acc, curr) => acc + curr.iva10, 0) / 11;
};
