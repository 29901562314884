<template>
  <div>
    <div v-if="crud.add">
      <Apertura-Banco-create
        v-model="crud.add"
        :datos="crud.datos"
        @datos="crud.datos = $event"
      />
    </div>
    <div v-if="crud.view">
      <Apertura-Banco-show
        v-model="crud.view"
        :datos="crud.datos"
        @datos="crud.datos = $event"
      />
    </div>
    <v-alert type="error" v-if="permission_alert"
      >Error!!! Este usuario no tiene permisos en esta ventana</v-alert
    >
    <v-toolbar flat dense>
      <v-toolbar-title>{{ titulo }}</v-toolbar-title>
      <widgets-Favorite ruta="/banco" formulario="Banco" />
      <v-divider class="mx-2" inset vertical></v-divider>

      <v-spacer></v-spacer>

      <c-text-table-search
        v-if="permission.can_view"
        v-model="search"
      ></c-text-table-search>
      <v-toolbar-items>
        <c-btn-table-add
          @click="crud.add = true"
          v-if="permission.can_add"
        ></c-btn-table-add>
      </v-toolbar-items>
    </v-toolbar>
    <v-data-table
      :loading="Vget_banco_load"
      :headers="headers"
      :items="Vget_banco_data"
      :search="search"
      v-if="permission.can_view"
    >
      <template v-slot:item.accion="props">
        <v-icon class="mr-2" @click="rowselect(props.item)">visibility</v-icon>
        <v-icon
          class="mr-2"
          @click="mupdate(props.item)"
          v-if="permission.can_update"
          >edit</v-icon
        >
        <v-icon
          small
          @click="meliminar(props.item)"
          v-if="permission.can_delete"
          >delete</v-icon
        >
      </template>
      <template v-slot:no-data>
        <v-alert :value="true" color="info" icon="info" outlined
          >No existe ningun registro</v-alert
        >
      </template>
    </v-data-table>
    <v-dialog v-model="crud.delete" persistent max-width="350">
      <v-card>
        <v-card-title>
          <v-icon left>info</v-icon>
          <span class="title font-weight-light">Ventana de Confirmacion</span>
        </v-card-title>
        <v-card-text>Desea eliminar {{ objeto.descbanco }}?</v-card-text>
        <v-card-actions>
          <v-btn color="red" text @click="eliminarcancelar">
            <v-icon>clear</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="meliminarguardar">
            <v-icon>done</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
      <c-overlay :value="overlay"></c-overlay>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { mensaje } from "@/services/notify.service";
import { BANCO } from "@/constants/forms";
import {
  PUEDE_AGREGAR,
  PUEDE_EDITAR,
  PUEDE_ELIMINAR,
  PUEDE_LISTAR,
} from "@/constants/permissions";

export default {
  data() {
    return {
      overlay: false,
      search: "",
      headers: [
        { text: "Codigo", align: "left", value: "idbanco" },
        { text: "Banco", align: "left", value: "descbanco" },
        { text: "Accion", value: "accion", sortable: false, align: "right" },
      ],
      titulo: BANCO,
      objeto: { descbanco: "" },
      permission: {
        can_add: false,
        can_view: false,
        can_update: false,
        can_delete: false,
      },
      crud: {
        add: false,
        view: false,
        delete: false,
        datos: null,
        readonly: true,
      },
    };
  },

  created() {
    this.$store.commit("SET_LAYOUT", "principal-layout");
    this.Vact_banco_data();
    const permission = getPermission(BANCO).permiso;
    this.permission = {
      can_add: permission[PUEDE_AGREGAR],
      can_view: permission[PUEDE_LISTAR],
      can_update: permission[PUEDE_EDITAR],
      can_delete: permission[PUEDE_ELIMINAR],
    };
  },
  computed: {
    ...mapGetters("banco", ["Vget_banco_load", "Vget_banco_data"]),
    permission_alert() {
      return mensaje.permission(this.permission);
    },
  },

  methods: {
    ...mapActions("banco", ["Vact_banco_data", "Vact_banco_del"]),
    rowselect(val) {
      this.crud.datos = JSON.parse(JSON.stringify(val));
      this.crud.view = true;
    },
    meliminar(item) {
      this.objeto = Object.assign({}, item);
      this.crud.delete = true;
    },

    eliminarcancelar() {
      this.objeto = "";
      this.crud.delete = false;
    },
    async meliminarguardar() {
      this.overlay = true;
      const id = this.objeto.idbanco;
      const a = await this.Vact_banco_del(id);
      if (a.estado === true) {
        this.$notify(a.info);
        this.crud.delete = false;
        this.eliminarcancelar();
      } else {
        a.info.map((element) => {
          this.$notify(mensaje.error(element));
        });
      }
      this.overlay = false;
    },
    mupdate(val) {
      this.crud.datos = JSON.parse(JSON.stringify(val));
      this.crud.add = true;
    },
  },
};
</script>