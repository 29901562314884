<template>
  <v-autocomplete
    :value="value"
    @input="$emit('input', $event)"
    :items="getNacionalidad"
    ref="input"
    :loading="isNacionalidadLoading"
    item-value="idnacionalidad"
    :rules="rules"
    return-object
    item-text="descnacionalidad"
    :outlined="outlined"
    :dense="dense"
    :disabled="disabled"
    :chips="chips"
    :small-chips="smallChips"
    :label="label"
  ></v-autocomplete>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: {
    value: [Object, Number, String],
    outlined: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    chips: {
      type: Boolean,
      default: false,
    },
    "small-chips": {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "Nacionalidad",
    },
    rules: {
      type: Array,
      default: function () {
        return [(v) => !!v.idnacionalidad || "Este campo es obligatorio"];
      },
      
    },
    disabled: {
        type: Boolean,
        default: false,
      },
  },
  created() {
    this.fetchNacionalidad();
  },
  computed: {
    ...mapGetters("nacionalidad", ["getNacionalidad", "isNacionalidadLoading"]),
  },
  methods: {
    ...mapActions("nacionalidad", ["fetchNacionalidad"]),
    focus() {
      this.$refs.input.focus();
    },
    isMenuActive() {
      this.$refs.input.isMenuActive = false;
    },
  },
};
</script>

<style>
</style>