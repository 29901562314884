<template>
  <div>
    <v-dialog :value="value" max-width="700px" persistent @keydown.esc="cancelar()">
      <v-card>
        <v-form ref="form" lazy-validation>
          <v-toolbar flat>
            <v-toolbar-title>{{operacion ? 'Registrar Caja' : 'Modificar Caja'}}</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-row dense>
              <v-col cols="12">
                <c-text-field
                  label="Descripcion"
                  outlined
                  dense
                  id="cajaInput0"
                  v-model="data.descaja"
                  @keyup.native.enter="next(0,data.descaja)"
                />
              </v-col>
            </v-row>
              <v-row dense cols="10" v-for="(item, i) in data.cajadet" :key="i">
                <v-col>
                  <v-autocomplete
                    :readonly="data.cajadet[i].idcaja != 0"
                    v-model="data.cajadet[i].idmoneda.idmoneda"
                    height="12px"
                    dense
                    :id="`cajaInput${i+1}`"
                    v-bind:items="moneda"
                    label="Moneda"
                    item-text="fkdes"
                    item-value="id"
                    :loading="loadmoneda"
                    required
                    outlined
                    autocomplete="off"
                    :no-data-text="`No existe ninguna concidencia`"
                    :small-chips="data.cajadet[i].idcaja === 0"
                    @keyup.enter="next(i+1,data.cajadet[i].idmoneda.idmoneda)"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="1" v-if="ultimoindex != 0">
                  <v-btn class="ma-2" text small color="primary" @click="deleteuser(i,data.cajadet[i].idmoneda.idmoneda)">
                    <v-icon>clear</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="1" v-if="i === ultimoindex && data.cajadet[i].idmoneda.idmoneda != ''">
                  <v-btn class="ma-2" text small color="primary" @click="adduser">
                    <v-icon>add</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
          </v-card-text>

          <v-card-actions>
            <v-btn color="red" text @click="cancelar">
              <v-icon dark>close</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn id="cajaInputaceptar" color="primary" text @click="guardar">
              <v-icon dark>done</v-icon>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { db } from "@/services/db.service";
import { mensaje } from "@/services/notify.service";
import { focus } from "@/services/focus.service";

export default {
  props:{
    value: {
      type: Boolean,
      default: null
    },
    editar:{
      type: [Object,Array],
      default: null
    },
    "sync-input": {
      type: [String, Number],
      default: null
    }
  },
  data() {
    return {
      overlay: false,
      noData: true,
      data: {
        idcaja: 0,
        descaja: "",
        eliminar:[],
        cajadet: [
          {
            idcaja: 0,
            idmoneda: {
              idmoneda: ""
            },
            monto: 0
          }
        ]
      },
      default: {
        idcaja: 0,
        descaja: "",
        eliminar:[],
        cajadet: [
          {
            idcaja: 0,
            idmoneda: {
              idmoneda: ""
            },
            monto: 0
          }
        ]
      },
      validar: [v => !!v || "Campo requerido"],
      validars: [v => !!v || "Seleccione un campo"],
      reload: 0,
      operacion: true,
      loadmoneda: true,
      moneda: []
    };
  },
  watch: {
    $route(to, from) {
      if (from.path === "/caja") return this.cancelar();
    },
  },
  computed: {

    ultimoindex() {
      return this.data.cajadet.length - 1;
    }
  },
  created() {
    this.nuevo(this.editar);
  },
  mounted() {
    this.next(-1,'-');
    if (this.syncInput != null) this.data.descaja = this.syncInput;
  },
  methods: {
    ...mapActions("caja", ["Vact_caja_data","Vact_caja_cajacuenta","Vact_caja_save","Vact_caja_update"]),

    async next(id, data) {
      focus.nextidName("cajaInput", id, data);
    },
    deleteuser(index,item) {
      if(this.data.cajadet[index].idcaja != 0){
        this.data.eliminar.push(item);
      }
      this.data.cajadet.splice(index, 1);
    },
    adduser() {
      this.data.cajadet.push({
        idcaja: 0,
        idmoneda: {
          idmoneda: ""
        },
        monto: 0
      });
    },


    cancelar() {
      this.$emit('input', false);
      this.$emit('editar', null);
      this.data = JSON.parse(JSON.stringify(this.default));
      this.Vact_caja_data();
      this.Vact_caja_cajacuenta();
      this.operacion = true;
    },
    async guardar_finalizar() {
      const sync =  this.syncInput;
      let a = await this.Vact_caja_save(this.data);
      if (a.estado == true) {
         if (sync != null) {
          this.cancelar();
          return this.$emit('sync-input',a.data.data.id);
        }
        this.data = JSON.parse(JSON.stringify(this.default));
        this.$notify(a.info); 
        this.$refs.form.resetValidation();
      } else {
        a.info.map(x => {
          this.$notify(mensaje.error(x));
        });
      }
      this.overlay = false;
    },
    async editar_finalizar() {
      const id = this.editar.idcaja;
      let a = await this.Vact_caja_update({id, data: this.data});
      if (a.estado == true) {
        this.$notify(a.info);
        this.cancelar();
      } else {
        a.info.map(x => {
          this.$notify(mensaje.error(x));
        });
      }
      this.overlay = false;
    },
    guardar() {
      if (!this.$refs.form.validate()) return this.$notify(mensaje.completar());
      this.overlay = true;
      if (this.operacion === true) return this.guardar_finalizar();
      if (this.operacion === false) return this.editar_finalizar();
    },

    nuevo_moneda() {
      db.get( "/apimoneda/" ).then(a => {
        a.map(element => {
          this.moneda.push({
            id: element.idmoneda,
            fkdes: element.descmoneda
          });
        });
      });
      this.loadmoneda = false;
    },
    nuevo(val) {
      this.nuevo_moneda();
      if (val !== null) {
        this.data = val;
        if(val.cajadet[0]=== undefined){
          this.data.cajadet = this.default.cajadet;
        }
        this.data = Object.assign(this.data, {eliminar: []});
        this.operacion = false;
      }
    }
  }
};
</script>