<template>
  <v-card>
    <v-toolbar flat dense>
      <v-toolbar-title>{{ $route.name }}</v-toolbar-title>
      <widgets-Favorite :ruta="$route.path" :formulario="$route.name" />
      <v-divider class="mx-4" inset vertical></v-divider>
      <v-spacer></v-spacer>
      <v-text-field
          class="mr-1"
          v-model="search"
          append-icon="search"
          label="Busqueda"
          single-line
          hide-details
          v-if="permission.can_view"
        ></v-text-field>
      <v-toolbar-items>
        <c-btn-table-add
          class="mr-1"
          @click="crud.add = true"
        ></c-btn-table-add>
        <c-btn-table-reload @click="fetchTermoSemen()"></c-btn-table-reload>
      </v-toolbar-items>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="getTermoSemen"
      :search="search"
      :loading="isLoading"
      v-if="permission.can_view"
    >
      <template v-slot:[`item.accion`]="{ item }">
        <c-btn-table-edit @click="editar(item)"></c-btn-table-edit>
        <c-btn-table-delete @click="eliminar(item)"></c-btn-table-delete>
      </template>
    </v-data-table>
    <Crud
      v-model="crud.add"
      v-if="crud.add"
      :props="crud.datos"
      @reset="crud.datos = $event"
    />
    <Delete v-model="crud.delete" v-if="crud.delete" :props="crud.datos" />
  </v-card>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { TERMO_SEMEN } from "@/constants/forms";
import { getPermission } from "@/services/storage/permissionStorage.service";
import Crud from "./Crud";
import Delete from "./Delete";
import {
  PUEDE_AGREGAR,
  PUEDE_EDITAR,
  PUEDE_ELIMINAR,
  PUEDE_LISTAR,
} from "@/constants/permissions";
export default {
  components: {
    Crud,
    Delete,
  },
  watch:{
    $route(to){
      if(to.path === '/termosemen') this.fetchTermoSemen();
    }
  },
  data: () => ({
    search: "",
    headers: [
      { text: "Descripcion", align: "left", value: "descripcion" },
      { text: "Capacidad", value: "cantidad" },
      {
        text: "Cantidad de Pajuelas Actual",
        value: "detcantidad",
        align: "end",
      },
      { text: "Accion", value: "accion", sortable: false, align: "right" },
    ],
    permission: {
      can_add: false,
      can_view: false,
      can_update: false,
      can_delete: false,
    },
    crud: {
      add: false,
      delete: false,
      datos: null,
    },
  }),

  mounted() {
    this.$store.commit("SET_LAYOUT", "principal-layout");
    this.fetchTermoSemen();
    const permission = getPermission(TERMO_SEMEN).permiso;
    this.permission = {
      can_add: permission[PUEDE_AGREGAR],
      can_view: permission[PUEDE_LISTAR],
      can_update: permission[PUEDE_EDITAR],
      can_delete: permission[PUEDE_ELIMINAR],
    };
  },
  computed: {
    ...mapGetters("termosemen", ["getTermoSemen", "isLoading"]),
  },
  methods: {
    ...mapActions("termosemen", ["fetchTermoSemen"]),
    editar(item) {
      this.crud.datos = JSON.parse(JSON.stringify(item));
      this.crud.add = true;
    },
    eliminar(item) {
      this.crud.datos = JSON.parse(JSON.stringify(item));
      this.crud.delete = true;
    },
  },
};
</script>
