import { db } from "@/services/db.service";
import { get} from "@/services/api/api.service";
import { SET_LOADING, SET_TIPO_FACTURA, url } from './constants'
export default {
    async Vact_tipofactura_data({commit}){
        commit('tipofactura_load',true);
        try {
            const data = await db.get('/apitipofactura/');
            commit('tipofactura_data',data);
            commit('tipofactura_load',false);
            return true
        } catch (e) {
            commit('tipofactura_load',false);
            return false
        } 
    },
    async fetchTipoFactura({ commit, dispatch }) {
        commit(SET_LOADING, true);
        try {
            const response = await get(url);
            commit(SET_TIPO_FACTURA, response);
        } catch (e) {
            dispatch('snackbar/setNotification', e, { root: true });
            commit(SET_LOADING, false);
            throw e
        }
        commit(SET_LOADING, false);
    }
}
