<template>
  <div>
    <v-dialog
      :value="value"
      max-width="700px"
      persistent
      @keydown.esc="cancelar()"
    >
      <v-card>
        <v-form ref="form" lazy-validation>
          <v-toolbar dense flat>
            <v-toolbar-title>{{
              operacion ? "Agregar Categoria" : "Editar Categoria"
            }}</v-toolbar-title>
          </v-toolbar>

          <v-card-text>
            <v-row row dense>
              <v-col cols="12">
                <v-text-field
                  dense
                  type="text"
                  :rules="validar"
                  ref="input1"
                  v-model="data.desccategoriaitem"
                  label="Descripcion"
                  required
                  outlined
                  autocomplete="off"
                  @keyup.enter="nextfocus(1)"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-autocomplete
                  dense
                  v-model="data.idiva.idiva"
                  ref="input2"
                  :rules="validars"
                  :loading="loadiva"
                  v-bind:items="iva"
                  fkdes="item"
                  label="Seleccione iva"
                  item-text="fkdes"
                  item-value="id"
                  autocomplete="off"
                  required
                  outlined
                  @keyup.enter="nextfocus(2)"
                  @change="distribuirIva()"
                  :no-data-text="`No existe ninguna concidencia`"
                ></v-autocomplete>
              </v-col>

              <v-btn
                text
                color="primary"
                x-small
                @click="distribuir = !distribuir"
                >Opciones Avanzadas</v-btn
              >
            </v-row>
            <v-row dense v-if="distribuir">
              <v-col cols="12" sm="4">
                <c-text-currency
                  label="Distribucion Exenta"
                  suffix="%"
                  v-model="data.dis_exenta"
                ></c-text-currency>
              </v-col>
              <v-col cols="12" sm="4">
                <c-text-currency
                  label="Distribucion Iva 10"
                  suffix="%"
                  v-model="data.dis_iva10"
                ></c-text-currency>
              </v-col>
              <v-col cols="12" sm="4">
                <c-text-currency
                  label="Distribucion Iva 5"
                  suffix="%"
                  v-model="data.dis_iva5"
                ></c-text-currency>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn color="red" text @click="cancelar">
              <v-icon>clear</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn ref="aceptar" color="primary" text @click="guardar">
              <v-icon>done</v-icon>
            </v-btn>
          </v-card-actions>
        </v-form>
        <c-overlay :value="Vget_categoria_save_load"></c-overlay>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { db } from "@/services/db.service";
import { mensaje } from "@/services/notify.service";
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    "sync-input": {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      data: {
        desccategoriaitem: "",
        idiva: {
          idiva: "",
        },
        dis_exenta: 0,
        dis_iva5: 0,
        dis_iva10: 0,
      },
      default: {
        desccategoriaitem: "",
        idiva: {
          idiva: "",
        },
        dis_exenta: 0,
        dis_iva5: 0,
        dis_iva10: 0,
      },
      validar: [(v) => !!v || "Campo requerido"],
      validars: [(v) => !!v || "Seleccione un campo"],
      operacion: true,
      loadiva: false,
      distribuir: false,
      iva: [],
    };
  },
  computed: {
    ...mapGetters("categoria", {
      getupdate: "categoria_update",
    }),
    ...mapGetters("categoria", ["Vget_categoria_save_load"]),
  },
  created() {
    this.nuevo(this.getupdate);
  },
  mounted() {
    setTimeout(() => this.$refs.input1.focus(), 500);
    if (this.syncInput != null) this.data.desccategoriaitem = this.syncInput;
  },
  watch: {
    $route(to, from) {
      if (from.path === "/categoria") return this.cancelar();
    },
  },
  methods: {
    ...mapActions("categoria", [
      "categoria_update",
      "Vact_categoria_data",
      "Vact_categoria_save",
      "Vact_categoria_update",
    ]),

    nextfocus(x) {
      switch (x) {
        case 1: {
          if (this.data.desccategoriaitem != "") {
            this.$refs.input2.focus();
          }
          break;
        }
        case 2: {
          if (this.data.idiva.idiva != "") {
            this.$refs.aceptar.$el.focus();
          }
          break;
        }
      }
    },
    distribuirIva() {
      this.data.dis_exenta = 0;
      this.data.dis_iva5 = 0;
      this.data.dis_iva10 = 0;
      if (this.data.idiva.idiva === 1) this.data.dis_exenta = 100;
      if (this.data.idiva.idiva === 2) this.data.dis_iva5 = 100;
      if (this.data.idiva.idiva === 3) this.data.dis_iva10 = 100;
    },
    cancelar() {
      this.data = JSON.parse(JSON.stringify(this.default));
      this.categoria_update(null);
      this.Vact_categoria_data();
      this.operacion = true;
      this.$emit("input", false);
    },
    async guardar_finalizar() {
      const sync = this.syncInput;
      let a = await this.Vact_categoria_save(this.data);
      if (a.estado == true) {
        if (sync != null) {
          this.cancelar();
          this.$emit("sync-input", a.data.data.id);
        }
        this.data = JSON.parse(JSON.stringify(this.default));
        this.$notify(a.info);
        this.$refs.form.resetValidation();
        this.$refs.input1.focus();
      } else {
        a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
    },
    async editar_finalizar() {
      const id = this.getupdate.idcategoriaitem;
      const data = this.data;
      let a = await this.Vact_categoria_update({ id, data });
      if (a.estado == true) {
        this.$notify(a.info);
        this.cancelar();
      } else {
        a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
    },
    guardar() {
      if (this.$refs.form.validate()) {
        this.distribuirIva();
        if (this.operacion === true) return this.guardar_finalizar();
        if (this.operacion === false) return this.editar_finalizar();
      } else {
        this.$notify(mensaje.completar());
      }
    },
    nuevo(val) {
      var iva = [];
      this.loadiva = true;
      db.get("/apiiva/").then((b) => {
        b.forEach(function(element) {
          iva.push({ id: element.idiva, fkdes: element.desciva });
        });
        this.loadiva = false;
      });
      this.iva = iva;
      if (val !== null) {
        this.data = val;
        this.operacion = false;
      }
    },
  },
};
</script>
