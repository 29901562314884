<template>
  <div>
    <div class="d-md-flex flex-row justify-space-between hidden-sm-and-down">
      <div class="title hidden-sm-and-down">Gimnasio</div>
      <v-sheet class="d-flex justify-end mb-2">
        <v-slide-group multiple show-arrows>
          <v-slide-item>
              <v-btn active-class="deep-purple--text accent-4" exact text tile to="/Gimnasio/planillagimnasio">
              Planilla Gimnasio
              </v-btn>
          </v-slide-item>
          <v-slide-item>
            <v-menu bottom left offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  tile
                  class="align-self-center"
                  v-bind="attrs"
                  v-on="on"
                >
                  Mantenimientos
                  <v-icon right>arrow_drop_down</v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item
                  v-for="(sub, subkey) in menu.mantenimientos"
                  :key="subkey"
                  :to="sub.route"
                  active-class="deep-purple--text accent-4"
                  >{{ sub.title }}</v-list-item
                >
              </v-list>
            </v-menu>
          </v-slide-item>
        </v-slide-group>
      </v-sheet>
    </div>
    <v-divider></v-divider>
    <transition>
      <keep-alive>
        <router-view></router-view>
      </keep-alive>
    </transition>
  </div>
</template>
<script>
//import { getPermission } from "@/services/storage/permissionStorage.service";
//import { mensaje } from "@/services/notify.service";
export default {
  data() {
    return {
      permission: {
        Saldos: false,
      },
      menu: {
        mantenimientos: [
          {
            title: "Tipo Gimnasio",
            route: "/Gimnasio/mantenimiento/tipogimnasio",
          },
        ],
      },
    };
  },
  created() {
    this.$store.commit("SET_LAYOUT", "principal-layout");
  },
  mounted() {},
  computed: {},
};
</script>
