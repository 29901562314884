<template>
  <div>
    <v-dialog :value="value" max-width="700px" persistent @keydown.esc="cancelar()">
      <v-card>
        <v-form ref="form" lazy-validation>
          <v-toolbar flat>
            <v-toolbar-title>{{'Realizar un ajuste de Caja'}}</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-row dense>
              <v-col cols="12">
                <vue-text-field
                  label="Observacion"
                  id="ajustecajaInput1"
                  v-model="data.observacion"
                  @keyup.native.enter="next(1,data.observacion)"
                />
              </v-col>
              <v-col cols="12">
                <vue-text-currency
                  label="Monto"
                  :rules="true"
                  id="ajustecajaInput2"
                  v-model="data.monto"
                  @keyup.native.enter="next(2,data.monto)"
                />
              </v-col>
              <v-col cols="12">
                <vue-autocomplete-generic
                  id="ajustecajaInput3"
                  action="caja/Vact_caja_data"
                  getter="caja/Vget_caja_data"
                  load="caja/Vget_caja_load"
                  pk="idcaja"
                  label="Seleccione Caja"
                  title="descaja"
                  @sync-input="search = $event"
                  permission="Caja"
                  v-model="data.idcaja.idcaja"
                  @keyup.native.enter="next(3,data.idcaja.idcaja)"
                />
              </v-col>
              <v-col cols="12">
                <vue-autocomplete-generic
                  id="ajustecajaInput4"
                  action="motivoajuste/Vact_motivoajuste_data"
                  getter="motivoajuste/Vget_motivoajuste_data"
                  load="motivoajuste/Vget_motivoajuste_load"
                  pk="idmotivoajuste"
                  label="Seleccione el Motivo"
                  title="descmotivoajuste"
                  @sync-input="search = $event"
                  permission="Motivo Ajuste"
                  v-model="data.idmotivoajuste.idmotivoajuste"
                  @keyup.native.enter="next(4,data.idmotivoajuste.idmotivoajuste)"
                />
              </v-col>
              <v-col cols="12">
                <vue-autocomplete-generic
                  id="ajustecajaInput5"
                  action="moneda/Vact_moneda_data"
                  getter="moneda/Vget_moneda_data"
                  load="moneda/Vget_moneda_load"
                  pk="idmoneda"
                  label="Seleccione Moneda"
                  title="descmoneda"
                  permission="Moneda"
                  v-model="data.idmoneda.idmoneda"
                  @keyup.native.enter="next(5,data.idmoneda.idmoneda)"
                />
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-btn color="red" text @click="cancelar">
              <v-icon dark>close</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn id="ajustecajaInputaceptar" color="primary" text @click="guardar">
              <v-icon dark>done</v-icon>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
      <c-overlay :value="Vget_ajustecaja_save_load"></c-overlay>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { mensaje } from "@/services/notify.service";
import { focus } from "@/services/focus.service";
export default {
  props: {
    value: {
      type: Boolean,
      default: null
    }
  },
  data() {
    return {
      data: {
        idajustecaja: 0,
        observacion: "",
        monto: "",
        idcaja: {
          idcaja: ""
        },
        fecha: '2000-01-01',
        idmotivoajuste: {
          idmotivoajuste: ""
        },
        idmoneda: {
          idmoneda: ""
        }
      },
      default: {
        idajustecaja: 0,
        observacion: "",
        monto: "",
        idcaja: {
          idcaja: ""
        },
        fecha: '2000-01-01',
        idmotivoajuste: {
          idmotivoajuste: ""
        },
        idmoneda: {
          idmoneda: ""
        }
      },
      operacion: true
    };
  },
  watch: {
    $route(to, from) {
      if (from.path === "/ajustecaja") return this.cancelar();
    }
  },
  mounted() {
    setTimeout(()=>this.next(0,'-'),200);
  },
  computed: {
    ...mapGetters("ajustecaja", ["Vget_ajustecaja_save_load"])
  },
  methods: {
    ...mapActions("ajustecaja", [
      "Vact_ajustecaja_data",
      "Vact_ajustecaja_save"
    ]),
    next(index, data) {
      focus.nextidName("ajustecajaInput", index, data);
    },
    cancelar() {
      this.$emit("input", false);
      this.data = JSON.parse(JSON.stringify(this.default));
      this.Vact_ajustecaja_data();
      this.operacion = true;
    },
    async guardar_finalizar() {
      let a = await this.Vact_ajustecaja_save(this.data);
      if (a.estado == true) {
        this.data = JSON.parse(JSON.stringify(this.default));
        this.$notify(a.info);
        this.$refs.form.resetValidation();
      } else {
        a.info.map(x => {
          this.$notify(mensaje.error(x));
        });
      }
    },
    guardar() {
      if (!this.$refs.form.validate()) return this.$notify(mensaje.completar());
      if (this.operacion === true) return this.guardar_finalizar();
    }
  }
};
</script>