const utilidad = {
  next(index, data) {
    if (data === null || data === "" && index != 10) return document.getElementById(`input` + index).focus();
    if (index === 1) {
      if (data === 2) {
        document.getElementById(`input` + 2).focus();
      } else {
        document.getElementById(`input` + 3).focus();
      }
    } else {
      try {
        index = index + 1;
        document.getElementById(`input` + index).focus();
      } catch (e) {
        document.getElementById(`aceptar`).focus();
      }
    }
  },
  completar_nrof(factura) {
    let factura_length = factura.length;
    let factura_falta = 7 - factura_length;
    switch (factura_falta) {
      case 0:{
        return factura;
      }
      case 1: {
        return `0${factura}`;
      }
      case 2: {
        return `00${factura}`;
      }
      case 3: {
        return `000${factura}`;
      }
      case 4: {
        return `0000${factura}`;
      }
      case 5: {
        return `00000${factura}`;
      }
      case 6: {
        return `000000${factura}`;
      }
    }
  },
}

export {
  utilidad
}