import { db } from "@/services/db.service";
import { get  } from "@/services/api/api.service";
import dbCliente from "@/db/dbCliente";
import { TokenService } from "@/services/auth/storage.service";
import { SET_CONTACTO_FIREBASE, SET_LOADING } from "./constans";

export default {
  async contacto_create({ commit }, request) {
    commit("contacto_create", request);
  },
  async contacto_update({ commit }, request) {
    commit("contacto_update", request);
  },
  async contacto_reload({ commit }, request) {
    commit("contacto_reload", request);
  },
  async contacto_key({ commit }, request) {
    commit("contacto_key", request);
  },
  async contacto_show({ commit }, request) {
    commit("contacto_show", request);
  },

  async Vact_contacto_data_cliente({ commit }) {
    const xidusuario = TokenService.getToken();
    commit("contacto_load", true);
    try {
      const data = await db.get(`/apicontacto/?tipo=cliente`);
      dbCliente
        .transaction("rw", dbCliente.cliente, () => {
          dbCliente.cliente.clear();
          data.map((x) => {
            let cliente = { ...x, ...{ xidusuario: xidusuario } };
            dbCliente.cliente.add(cliente);
          });
          dbCliente.cliente.toArray().then((x) => {
            commit("contacto_data_cliente", x);
          });
        })
        .catch(function(e) {
          console.log(e);
        });
      commit("contacto_load", false);
      return true;
    } catch (e) {
      dbCliente.cliente
        .where("xidusuario")
        .equals(xidusuario)
        .toArray()
        .then((x) => {
          commit("contacto_data_cliente", x);
        });
      commit("contacto_load", false);
      return false;
    }
  },

  async Vact_contacto_data_proveedor({ commit }, search) {
    commit("contacto_load", true);
    try {
      const data = await db.get(
        `/apicontacto/?tipo=proveedor&ruccontacto=${search || ""}`
      );
      commit("contacto_data_proveedor", data);
      commit("contacto_load", false);
      if (data.length > 0) {
        return data[0];
      } else {
        return true;
      }
    } catch (e) {
      commit("contacto_load", false);
      return false;
    }
  },

  async Vact_contacto_data_transportadora({ commit }, search) {
    commit("contacto_load", true);
    try {
      const data = await db.get(
        `/apicontacto/?tipo=transportadora&ruccontacto=${search || ""}`
      );
      commit("contacto_data_transportadora", data);
      commit("contacto_load", false);
      if (data.length > 0) {
        return data[0];
      } else {
        return true;
      }
    } catch (e) {
      commit("contacto_load", false);
      return false;
    }
  },

  async fetchContactoFirebase({ commit }, ruc) {
    commit(SET_LOADING, true);
    try {
      const response = await get("/apicontacto/?ruccontacto=" + ruc);
      if (response != "") {
        commit(SET_CONTACTO_FIREBASE, { origin: "db", data: response[0] });
      } else {
        const firebase = await get("/apicontactofire/?ruccontacto=" + ruc);
        firebase.error != 0
          ? commit(SET_CONTACTO_FIREBASE, {
              origin: "firebase",
              data: {
                docucontactos: firebase.dv,
                desccontacto: firebase.razonsocial,
              },
            })
          : commit(SET_CONTACTO_FIREBASE, {
              origin: "backend",
              data: { docucontactos: firebase.calc },
            });
      }
    } catch (error) {
      commit(SET_CONTACTO_FIREBASE, null);
      console.log(error);
    }
    commit(SET_LOADING, false);
  },
};
