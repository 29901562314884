<template>
  <div>
    <v-dialog :value="value" max-width="800px" persistent :key="reload">
      <v-card>
        <v-card-title>
          <span class="headline">Detalles</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row row dense>
            <v-col cols="12" sm="6">
              <v-text-field
                dense
                v-model="data.idbanco.descbanco"
                label="Banco"
                persistent-hint
                outlined
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                dense
                v-model="data.idtipocuentabanco.descripcion"
                label="Tipo Cuenta"
                persistent-hint
                outlined
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                dense
                v-model="data.desccuentabanco"
                label="Descripcion"
                persistent-hint
                outlined
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                dense
                v-model="data.numrcuenta"
                label="Numero Cuenta"
                persistent-hint
                outlined
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                dense
                v-model="data.nomtitular"
                label="Titular"
                persistent-hint
                outlined
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <vue-text-currency
                label="Linea de Sobregiro"
                v-model="data.sobregiro"
                :readonly="true"
              />
            </v-col>
          </v-row>
          <v-alert outlined color="blue lighten-4" text>
            <v-row row dense>
              <v-col cols="12" sm="4">
                <vue-text-currency label="Saldo en Cuenta" v-model="data.monto" :readonly="true" />
              </v-col>

              <v-col cols="12" sm="4">
                <vue-text-currency
                  label="Linea de sobregiro disponible"
                  :value="data.sobregiro - data.sobregirousado"
                  :readonly="true"
                />
              </v-col>
              <v-col cols="12" sm="4">
                <vue-text-currency
                  :error="data.sobregirousado != 0 ? true : false"
                  label="Sobregiro Usado"
                  v-model="data.sobregirousado"
                  :readonly="true"
                />
              </v-col>
            </v-row>
          </v-alert>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="cancelar">
            <v-icon>clear</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    datos: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      dateFormatted: "",
      data: {
        idcuentabanco: 0,
        numrcuenta: "",
        desccuentabanco: "",
        nomtitular: "",
        idbanco: {
          idbanco: 0,
        },
        idtipocuentabanco: {
          idtipocuentabanco: 0,
        },
      },
      default: {
        idcuentabanco: 0,
        numrcuenta: "",
        desccuentabanco: "",
        nomtitular: "",
        idbanco: {
          idbanco: 0,
        },
        idtipocuentabanco: {
          idtipocuentabanco: 0,
        },
      },
      reload: 0,
    };
  },
  created() {
    this.data = JSON.parse(JSON.stringify(this.datos));
  },
  watch: {
    $route(to, from) {
      if (from.path === "/cuentabanco") return this.cancelar();
    },
  },
  methods: {
    cancelar() {
      this.data = this.default;
      this.$emit("input", false);
      this.$emit("datos", null);
      this.reload++;
    },
  },
};
</script>
