<template>
    <v-container>
        <v-timeline dense>
            <div v-for="(element, i) in content" :key="i">
            <v-timeline-item v-if="element.formulario == 'Todos' 
                || (element.formulario == 'Zafra' && permission.zafra == true)
                || (element.formulario == 'Animal' && permission.animal == true)"
                :icon="element.icon"
                :color="element.color">
                <v-card class="elevation-2">
                    <v-divider></v-divider>
                    <v-card-actions>
                        <span class="title ml-2">{{ element.title }}</span>
                        <v-chip class="ma-2" color="green darken-3" outlined v-if="element.new">
                            <v-icon left>
                                mdi-fire
                            </v-icon>
                            New
                        </v-chip>
                    </v-card-actions>
                    <v-card-text>
                        <iframe v-if="element.url" width="560" height="315" style="max-width: 100%; max-height: 100%;"
                            :src="element.url" title="YouTube video player" frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        <p class="text">
                            {{ element.subtitle }}
                        </p>
                        <p class="text" v-if="element.detail">
                            {{ element.detail }}
                        </p>
                    </v-card-text>
                </v-card>
            </v-timeline-item>
        </div>
        </v-timeline>
    </v-container>
</template>
<script>
import { getPermission } from "@/services/storage/permissionStorage.service";
import { ANIMAL, ZAFRA, FUNCIONARIO } from '@/constants/forms';
import { PUEDE_LISTAR } from '@/constants/permissions';
export default {
    created() {
        const animal = getPermission(ANIMAL).permiso
        this.permission.animal = animal[PUEDE_LISTAR]
        const zafra = getPermission(ZAFRA).permiso
        this.permission.zafra = zafra[PUEDE_LISTAR]
        const funcionario = getPermission(FUNCIONARIO).permiso
        this.permission.funcionario = funcionario[PUEDE_LISTAR]
    },
    data: () => ({
        permission: {
            animal: false,
            zafra: false,
            funcionario: false,
        },
        content: [
            {
                formulario: "Animal",
                icon: "upgrade",
                color: "green darken-3",
                new: true,
                title: "Historicos",
                url: null,
                subtitle: "Historico de Animales",
                detail: "Al marcar Historico en los formularios, puede registrar datos anteriores sin afectar el ciclo actual."
            },
            {
                formulario: "Todos",
                icon: "upgrade",
                color: "green darken-3",
                new: true,
                title: "General",
                url: null,
                subtitle: "Mejoras en Ultimas Novedades",
                detail: "Se aplicaron mejoras en interfaz de Ultimas Novedades."
            },
            {
                formulario: "Todos",
                icon: "upgrade",
                color: "green darken-3",
                new: true,
                title: "General",
                url: null,
                subtitle: "Mejoras en Dashboard",
                detail: "Ahora incluimos Ultimas Novedades y Configuraciones en un solo lugar."
            },
            {
                formulario: "Todos",
                icon: "bug_report",
                color: "yellow darken-3",
                new: false,
                title: "General",
                url: null,
                subtitle: "Corrección de errores",
                detail: "Corrección de errores conocidos."
            },
            {
                formulario: "Zafra",
                icon: "upgrade",
                color: "green darken-3",
                new: false,
                title: "Reporte Avanzado",
                url: "https://www.youtube.com/embed/UWRxEuf1GGE?si=pNQe0fWDJnwVBG5i",
                subtitle: "Reporte por Campaña",
                detail: "Filtro de lotes para campañas."
            },
            {
                formulario: "Zafra",
                icon: "upgrade",
                color: "green darken-3",
                new: false,
                title: "Reajuste",
                url: "https://www.youtube.com/embed/BbQqr53QqZ0?si=4yCOUr9AQ4QSWdNS",
                subtitle: "Reajuste de depósito",
                detail: "Nuevo formulario para registrar los ajustes en depósitos."
            },
            {
                formulario: "Animal",
                icon: "upgrade",
                color: "green darken-3",
                new: false,
                title: "Campos Personalizados",
                url: "https://www.youtube.com/embed/vrOE9BN5jqo?si=r5xLWyeDrMZzqGvF",
                subtitle: "Campos personalizados en Ganadería",
                detail: "Nuevo campo para mostrar la cantidad de inseminaciones."
            },
            {
                formulario: "Animal",
                icon: "upgrade",
                color: "green darken-3",
                new: false,
                title: "Registro Genealógico",
                url: "https://www.youtube.com/embed/Cy9ITbPfCHc?si=0_kvwQxe5Zkh4zed",
                subtitle: "Registro Genealógico en animal",
                detail: "Registre el árbol genealógico en animal."
            },
            {
                formulario: "Animal",
                icon: "bug_report",
                color: "yellow darken-3",
                new: false,
                title: "Configuraciones",
                url: null,
                subtitle: "Corrección",
                detail: "Corrección de desorden de tarjetas que ocurria al moverlas."
            },
            {
                formulario: "Animal",
                icon: "upgrade",
                color: "green darken-3",
                new: false,
                title: "Palpacion",
                url: "https://www.youtube.com/embed/2bhTAVxA_3c?si=dFfyPFGxRCzPvANF",
                subtitle: "Palpacion en animales secos",
                detail: "En palpacion, marque Palpar secada para listar animales secados."
            },
            {
                formulario: "Animal",
                icon: "upgrade",
                color: "green darken-3",
                new: false,
                title: "Animal",
                url: "https://www.youtube.com/embed/HAHoUhp7n0g?si=mM4Dsz1G4Y_tQU7n",
                subtitle: "Termo en Semen",
                detail: "En Animal, al listar el Termo en Semen, puede agregar la columna 'Pajuela', donde puede ver la cantidad de pajuelas disponibles. También puede agregar la columna 'Información', para ver la cantidad de pajuelas utilizadas."
            },
            {
                formulario: "Animal",
                icon: "upgrade",
                color: "green darken-3",
                new: false,
                title: "Inseminacion",
                url: null,
                subtitle: "Termo de Semen",
                detail: "Al seleccionar el termo, puede ver la cantidad de pajuelas que hay en existencia."
            },
            {
                formulario: "Zafra",
                icon: "upgrade",
                color: "green darken-3",
                new: false,
                title: "Compras",
                url: "https://www.youtube.com/embed/c1y_ipgyyv0?si=OrJ52NOCCOJOFUC8",
                subtitle: "Items de Agro",
                detail: "Activar Items de Agro desde compras."
            },
            {
                formulario: "Animal",
                icon: "upgrade",
                color: "green darken-3",
                new: false,
                title: "Campos personalizados",
                url: "https://www.youtube.com/embed/9VOMKkvER4M?si=G6RlmUtwqVZ0y9MF",
                subtitle: "Campos personalizados en Ganaderia",
                detail: "Nuevo campo para mostrar la cantidad de partos."
            },
            {
                formulario: "Zafra",
                icon: "upgrade",
                color: "green darken-3",
                new: false,
                title: "Predicciones",
                url: "https://www.youtube.com/embed/di72jxuZmzo?si=EoWfG92KGkZ6EwCW",
                subtitle: "Agregamos predicciones",
                detail: "Usando imágenes satelitales, predecimos cuántos Kg x ha puede producir cada lote."
            },
            {
                formulario: "Zafra",
                icon: "upgrade",
                color: "green darken-3",
                new: false,
                title: "Bonificaciones",
                url: "https://www.youtube.com/embed/f4SE3I7Kjns?si=NHoKKtDoE-YIIrvL",
                subtitle: "Agregamos una nueva funcion en agro para registrar las bonificaciones.",
                detail: null
            },
            {
                formulario: "Zafra",
                icon: "upgrade",
                color: "green darken-3",
                new: false,
                title: "Imagenes NDVI",
                url: "https://www.youtube.com/embed/nHHRuSKo8Qc?si=3iZJA_pEF61oK7KM",
                subtitle: "Habilitamos el proceso de imagenes satelitales.",
                detail: null
            },
        ]
    })
}
</script>