<template>
  <c-dialog-confirmation
    :value="value"
    @cancel="close()"
    @done="save()"
    :loading="isObjetivoLoading"
    >Desea eliminar el objetivo <strong>{{ props.descripcion }}</strong
    >?</c-dialog-confirmation
  >
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    value: Boolean,
    props: Object,
  },
  computed:{
    ...mapGetters('objetivo',["isObjetivoLoading"])
  },
    watch: {
    $route() {
      return this.close();
    },
  },
  methods: {
    ...mapActions("objetivo", ["setObjetivoDelete","fetchObjetivo"]),

    async save() {
      const id = this.props.idobjetivo;
      const response = await this.setObjetivoDelete(id);
      if (!response.success) return null;
      this.close();
    },
    close() {
      this.$emit('datos',null);
      this.$emit("input", false);
      this.fetchObjetivo();
    },
  }
};
</script>

<style>
</style>