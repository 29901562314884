import { db } from "@/services/db.service";
export default {

    async Vact_magnitud_data({commit}){
        commit('magnitud_load',true);
        try {
            const data = await db.get('/apimagnitud/');
            commit('magnitud_data',data);
            commit('magnitud_load',false);
            return true
        } catch (e) {
            commit('magnitud_load',false);
            return false
        } 
    },
}
