<template>
  <div>
    <div v-if="crud.view">
      <Stock-Transferencia-show
        v-model="crud.view"
        :datos="crud.datos"
        @datos="crud.datos = $event"
      />
    </div>
    <div v-if="crud.add">
      <Stock-Transferencia-create
        v-model="crud.add"
        :datos="crud.datos"
        @datos="crud.datos = $event"
      />
    </div>
    <v-card>
      <v-alert type="error" v-if="permission_alert">
        Error!!! Este usuario no tiene permisos en esta ventana
      </v-alert>
      <v-toolbar flat dense>
        <v-toolbar-title>Transferencia</v-toolbar-title>
        <widgets-Favorite ruta="/transferencia" formulario="Transferencia" />
        <v-divider class="mx-2" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="search"
          placeholder="Buscar"
          autocomplete="off"
          label="Busqueda"
          class="mr-1"
          single-line
          hide-details
          v-if="permission.can_view"
        ></v-text-field>
        <v-toolbar-items>
          <v-speed-dial v-model="opcion" direction="left" class="mr-1">
            <template v-slot:activator>
              <v-btn v-model="opcion" height="100%" small color="red" tile dark>
                <v-icon v-if="opcion">keyboard_arrow_up</v-icon>
                <v-icon v-else>keyboard_arrow_down</v-icon>
              </v-btn>
            </template>
            <v-btn fab dark small color="indigo" @click="searchadvanced = true">
              <v-icon>zoom_in</v-icon>
            </v-btn>
            <v-btn fab dark small color="green" @click="generarCsv()">
              <v-icon>grid_on</v-icon>
            </v-btn>
            <v-btn fab dark small color="red" @click="generarPdf()">
              <v-icon>get_app</v-icon>
            </v-btn>
          </v-speed-dial>
          <c-btn-table-add @click="crud.add=true" class="mr-1"></c-btn-table-add>
          <c-btn-table-reload @click="Vact_transferencia_data()" class="mr-1"></c-btn-table-reload>
        </v-toolbar-items>
      </v-toolbar>
      <v-data-table
        :loading="Vget_transferencia_load"
        :headers="headers"
        :items="datos"
        :search="search"
        class="elevation-1"
        v-if="permission.can_view"
      >
        <template v-slot:[`item.accion`]="props">
          <c-btn-table-view 
            v-if="permission.can_view"
            @click="rowselect(props.item)"
          ></c-btn-table-view>
          <c-btn-table-delete
            v-if="permission.can_delete"
            @click="meliminar(props.item)"
          ></c-btn-table-delete>
        </template>
      </v-data-table>
    </v-card>
    <Delete
      v-if="crud.delete"
      v-model="crud.delete"
      :props="crud.datos"
      @datos="crud.datos = $event"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { mensaje } from "@/services/notify.service";
import { TRANSFERENCIA } from "@/constants/forms";
import { exportTo } from "@/services/export.service";
import Delete from "./Delete";
import {
  PUEDE_AGREGAR,
  PUEDE_EDITAR,
  PUEDE_ELIMINAR,
  PUEDE_LISTAR,
  PUEDE_EDITAR_NUNFACTURA,
} from "@/constants/permissions";
export default {
  components: {
    Delete,
  },
  data() {
    return {
      opcion: false,
      search: "",
      datos: [],
      crud: {
        add: false,
        view: false,
        delete: false,
        datos: null,
      },
      permission: {
        can_add: false,
        can_view: false,
        can_update: false,
        can_delete: false,
      },
      headers: [
        {
          text: "Fecha",
          align: "left",
          value: "fecha",
        },
        {
          text: "Comentario",
          align: "left",
          value: "comentario",
        },
        {
          text: "Item",
          align: "left",
          value: "idstock.iditem.descitem",
        },
        {
          text: "Deposito",
          align: "left",
          value: "idstock.iddeposito.desdeposito",
        },
        {
          text: "Accion",
          value: "accion",
          sortable: false,
          align: "end",
        },
      ],
    };
  },
  watch: {
    Vget_transferencia_data(val) {
      if (val === null) return;
      this.datos = JSON.parse(JSON.stringify(val))
    }
  },
  created() {
    this.$store.commit("SET_LAYOUT", "principal-layout");
    const permission = getPermission(TRANSFERENCIA).permiso;
    this.permission = {
      can_add: permission[PUEDE_AGREGAR],
      can_view: permission[PUEDE_LISTAR],
      can_update: permission[PUEDE_EDITAR],
      can_delete: permission[PUEDE_ELIMINAR],
      can_editNumFactura: permission[PUEDE_EDITAR_NUNFACTURA]
    };
    this.Vact_transferencia_data();
  },
  computed: {
    ...mapGetters("transferencia", [
      "Vget_transferencia_data",
      "Vget_transferencia_load",
    ]),
    permission_alert() {
      return mensaje.permission(this.permission);
    },
  },
  methods: {
    ...mapActions("transferencia", ["Vact_transferencia_data"]),
    meliminar(item) {
      this.crud.datos = JSON.parse(JSON.stringify(item));
      this.crud.delete = true;
    },
    rowselect(val) {
      this.crud.datos = JSON.parse(JSON.stringify(val));
      this.crud.view = true;
    },
    generarPdf() {
        let head = [
            ["Fecha", "Comentario", "Item", "Deposito"],
        ];
        let body = [];
        this.datos.map((x) => {
            body.push([
                x.fecha,
                x.comentario,
                x.idstock.iditem.descitem,
                x.idstock.iddeposito.desdeposito
            ]);
        });
        exportTo.pdftable(head, body, "Lista de Transferencias");
    },
    generarCsv() {
        let data = [];
        this.datos.map((x) => {
            data.push({
                Fecha: x.fecha,
                Cliente: x.comentario,
                Item: x.idstock.iditem.descitem,
                Deposito: x.idstock.iddeposito.desdeposito
            });
        });
        exportTo.xlsx(data)
    }
  },
};
</script>