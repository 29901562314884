<template>
  <v-text-field
    :label="label"
    autocomplete="off"
    :loading="isLoadingContacto"
    :disabled="disabled"
    :outlined="outlined"
    :dense="dense"
    ref="input"
    type="number"
    :rules="rules"
    @input="$emit('input', $event)"
    :value="value"
    :hint="
      !disabled
        ? hintSearch
          ? 'Presione en la lupa para buscar el contacto'
          : ''
        : ''
    "
    :append-outer-icon="!disabled ? (hintSearch ? 'search' : '') : ''"
    @click:append-outer="searchFirebase(value)"
    @keyup.enter="hintSearch ? searchFirebase(value) : ''"
  ></v-text-field>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    value: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [(v) => !!v || "Este campo es obligatorio"],
    },
    label: {
      type: String,
      default: "Ruc",
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    data: {
      nombre: "",
      apellido: "",
      idcontacto: "",
    },
  }),
  watch: {
    getContactoFirebase(val) {
      if (!val.data.desccontacto) return null;
      if (val != null) this.asignarContactoDesdeOrigen(val);
    },
  },
  computed: {
    ...mapGetters("contacto", ["isLoadingContacto", "getContactoFirebase"]),
    hintSearch() {
      if (this.value) return this.value.toString().length > 4 ? true : false;
      return false;
    },
  },
  methods: {
    ...mapActions("contacto", ["fetchContactoFirebase"]),
    focus() {
      this.$refs.input.focus();
    },
    searchFirebase(ruc) {
      if (isNaN(ruc) || ruc.toString().length < 5) return null;
      this.fetchContactoFirebase(ruc);
    },
    asignarContactoDesdeOrigen(item) {
      let desccontacto = item.data.desccontacto.split(" ");
      const mitad = Math.floor(desccontacto.length / 2);
      desccontacto.map((x, i) => {
        if (i < mitad) {
          item.data.nombre = item.data.nombre || "" + " " + x;
        } else {
          item.data.apellido = item.data.apellido || "" + " " + x;
        }
      });
      this.data.nombre = item.data.nombre;
      this.data.apellido = item.data.apellido;
      switch (item.origin) {
        case "db":
          this.data.idcontacto = JSON.parse(JSON.stringify(item.data));
          this.$emit('fetch',item.data)
          break;
        case "firebase":
          this.data.idcontacto = { ...this.data.idcontacto, ...item.data };
          this.$emit('fetch',this.data.idcontacto)
          break;
        default:
          console.log("Caso desconocido");
          break;
      }
    },
  },
};
</script>
