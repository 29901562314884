<template>
  <div v-if="$route.path === '/agricultura/campo'">
    <v-row dense justify="center">
      <v-img :src="require('@/assets/help/agro/maincampo.png')" max-width="250"></v-img>
      <h3>Controles</h3>
      <v-col cols="12">
        <ol>
          <li>Para regresar atras</li>
          <li>Para agregar un nuevo Campo</li>
          <li>Para minimizar/maximizar el formulario</li>
          <li>Para abrir un campo creado</li>
          <li>Para buscar entre los Campos</li>
        </ol>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>