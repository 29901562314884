
export default {
  Vget_notacreditocompra_data: (state) => {
    return state.notacreditocompra_data
  },
  Vget_notacreditocompra_load: (state) => {
    return state.notacreditocompra_load
  },
  Vget_notacreditocompra_save_load: (state) => {
    return state.notacreditocompra_save_load
  },
}
