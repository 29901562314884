import {SET_LOADING, SET_CONCEPTO_SALARIO, SET_CONCEPTO_CONTRATO,SET_CONCEPTO_PAGO_SALARIO} from './constants'
export default {
  [SET_CONCEPTO_SALARIO](state, request) {
    state.conceptosalario = request;
  },
  [SET_CONCEPTO_CONTRATO](state,request){
    state.conceptocontrato = request;
  },
  [SET_CONCEPTO_PAGO_SALARIO](state,request){
    state.conceptopagosalario = request;
  },
  [SET_LOADING](state, request) {
    state.isLoading = request;
  },
}