export default {
  getFuncionario: (state) => state.funcionario,
  getFuncionarioInactive: (state) => state.funcionario_inactive,
  getDocumentos: (state) => state.documentos,
  getAnhos: (state) => state.anhos,
  getEmpleadoObrero: (state) => state.empleados_obreros,
  getSueldosJornales: (state) => state.sueldos_jornales,
  getCargo: (state) => state.cargo,
  getSaldos: (state) => state.saldos,
  getResumenGeneral: (state) => state.resumen_general,
  getPlanillaIps: (state) => state.planilla_sueldos_ips,
  isFuncionarioLoading: (state) => state.isLoading,
  isReporteLoading: (state) => state.isReporteLoading,
};
