export default {
    async tipocuenta_create({commit},request){
        commit('tipocuenta_create',request);
    },
    async tipocuenta_update({commit},request){
        commit('tipocuenta_update',request);
    },
    async tipocuenta_reload({commit},request){
        commit('tipocuenta_reload',request);
    },
    async tipocuenta_show({commit},request){
        commit('tipocuenta_show',request);
    },
}
