<template>
  <div>
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="200"
      offset-x
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" icon fab small>
          <v-icon>settings</v-icon>
        </v-btn>
      </template>
      <v-card min-width="400" width="500">
        <v-form ref="form">
          <v-list dense @submit.prevent="">
            <v-list-item>
              <v-list-item-avatar>
                <v-icon>settings</v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title
                  >Seleccione las columnas que desea
                  visualizar</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-divider></v-divider>
          <v-autocomplete
            class="mx-1"
            multiple
            :items="headers"
            item-text="text"
            item-value="text"
            v-model="value"
            :loading="isLoading"
          >
          </v-autocomplete>

          <v-card-actions>
            <v-btn color="red" text @click="menu = false">
              <v-icon>clear</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="guardarConsulta()">
              <v-icon>done</v-icon>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { headers } from "./constants";
export default {
  props: {
    band: {type :String}
  },
  data() {
    return {
      value: [],
      headers: headers,
      menu: false,
    };
  },
  mounted() {
    this.fetchConfigGanaderia();
  },
  watch: {
    getColumnsAnimal(val) {
      this.value = JSON.parse(JSON.stringify(val));
    },
  },
  computed: {
    ...mapState("configganaderia", {
      getData: "data",
      isLoading: "loading",
    }),
    getColumnsAnimal() {
      return this.$store.getters["configganaderia/getColumnsAnimal"][this.band];
    },
  },
  methods: {
    ...mapActions("configganaderia", [
      "fetchConfigGanaderia",
      "setConfigGanaderia",
    ]),

    async guardarConsulta() {
      if (!this.$refs.form.validate()) return null;
      let name = this.band;
      const response = await this.setConfigGanaderia({
        ...this.getData[0],
        lista_animal: this.value,
        lista_anim: {name: this.value},
        desde: name,
      });
      if (response.success) {
        this.fetchConfigGanaderia();
      }
    },
  },
};
</script>

<style></style>
