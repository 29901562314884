
export default {

  tiporesponsable_create: (state) => {
    return state.tiporesponsable_create
  },
  tiporesponsable_update: (state) => {
    return state.tiporesponsable_update
  },
  tiporesponsable_reload: (state) => {
    return state.tiporesponsable_reload
  },
  tiporesponsable_show: (state) => {
    return state.tiporesponsable_show
  },
}
