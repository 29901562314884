<template>
  <div>
    <v-dialog :value="value" max-width="650" persistent>
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>{{
            !editable ? "Agregar Parcela" : "Modificar Parcela"
          }}</v-toolbar-title>
        </v-toolbar>
        <v-form
          ref="form"
          @submit.prevent="next(1, data.descripcion)"
          class="mx-3"
        >
          <v-row dense>
            <v-col cols="12">
              <c-text-field
                label="Parcela"
                dense
                ref="parcela1"
                outlined
                v-model="data.descripcion"
                @keyup.native.enter="next(1, data.descripcion)"
              ></c-text-field>
            </v-col>
            <v-col cols="12">
              <AutocompleteCampana
                outlined
                ref="parcela2"
                dense
                label="Campaña"
                v-model="data.idcampana"
                return-object
                @change="data.idlote_agri = ''"
                @keyup.native.enter="
                  next(2, data.idcampana.idcampana),
                    $refs.parcela3.isMenuActive()
                "
              />
            </v-col>
            <v-col cols="12">
              <AutocompleteLote
                outlined
                :items="itemsLotes"
                ref="parcela3"
                dense
                label="Lote"
                v-model="data.idlote_agri"
                @keyup.native.enter="
                  next(3, data.idlote_agri.idlote_agri),
                    $refs.parcela2.isMenuActive()
                "
              />
            </v-col>
            <v-col>
              <c-text-currency
                label="Hectareas de la parcela"
                ref="parcela4"
                dense
                :rules="[(v) => !!v || 'Campo requerido']"
                outlined
                v-model="data.hectarea"
                @keyup.native.enter="next(4, data.hectarea)"
              ></c-text-currency>
            </v-col>
          </v-row>
        </v-form>
        <v-divider></v-divider>
        <v-card-actions>
          <c-btn-crud-close @click="cancelar()"></c-btn-crud-close>
          <v-spacer></v-spacer>
          <c-btn-crud-done ref="parcela5" @click="guardar()"></c-btn-crud-done>
        </v-card-actions>
        <c-overlay :value="isParcelaLoading"></c-overlay>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import AutocompleteCampana from "../../Campana/autocomplete";
import AutocompleteLote from "../../Campo/autocomplete";
export default {
  components: {
    AutocompleteCampana,
    AutocompleteLote,
  },
  props: {
    idlote: String,
    idcampana: Object,
    value: {
      type: Boolean,
      default: false,
    },
    props: {
      type: Object,
    },
    "sync-input": {
      type: [String, Number],
      default: null,
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sync: null,
      data: {
        idparcelas: "",
        descripcion: "",
        idcampana: {
          idcampana: "",
        },
        idlote_agri: {
          idlote_agri: "",
        },
        hectarea: "",
      },
      default: {
        idparcelas: "",
        descripcion: "",
        idcampana: {
          idcampana: "",
        },
        idlote_agri: {
          idlote_agri: "",
        },
        hectarea: "",
      },
    };
  },
  watch: {
    $route() {
      return this.cancelar();
    },
  },
  computed: {
    ...mapGetters("parcela", ["isParcelaLoading"]),
    itemsLotes() {
      if (this.data.idcampana.campanadet)
        return this.data.idcampana.campanadet.map((x) => x.idlote_agri);
      return [];
    },
  },
  mounted() {
    if (this.editable) this.data = JSON.parse(JSON.stringify(this.props));
    setTimeout(() => this.next(0, "-"), 200);
    if (this.idlote) this.data.idlote_agri.idlote_agri = this.idlote;
    if (this.idcampana) this.data.idcampana = JSON.parse(JSON.stringify(this.idcampana));
    if (this.syncInput != null) {
      this.data.descripcion = this.syncInput;
      this.sync = this.syncInput;
    }
  },
  methods: {
    ...mapActions("parcela", [
      "setParcela",
      "setParcelaUpdate",
      "fetchParcela",
    ]),
    next(id, data) {
      if (data != "")
        try {
          this.$refs[`parcela${id + 1}`].focus();
        } catch (error) {
          this.$refs[`parcela${id + 1}`].$el.focus();
        }
    },
    cancelar() {
      this.$emit("input", false);
      this.$emit("datos", null);
    },

    async guardar() {
      if (!this.$refs.form.validate()) return null;
      const response = this.editable
        ? await this.setParcelaUpdate({
            data: this.data,
            id: this.data.idparcelas,
          })
        : await this.setParcela(this.data);
      if (response.success) {
        this.fetchParcela();
        if (this.sync != null) {
          this.$emit("sync-input", response.data.id);
          this.$emit("next-focus");
          return this.cancelar();
        }
        this.data = JSON.parse(JSON.stringify(this.default));
        this.$refs.form.resetValidation();
        if (this.editable) return this.cancelar();
        this.next(0, "-");
      }
    },
  },
};
</script>
