import { db } from "@/services/db.service";
export default {

    async Vact_tarjetacredito_data({commit}){
        commit('tarjetacredito_load',true);
        try {
            const data = await db.get('/apitarjeta/');
            commit('tarjetacredito_data',data);
            commit('tarjetacredito_load',false);
            return true
        } catch (e) {
            commit('tarjetacredito_load',false);
            return false
        } 
    },
    async Vact_tarjetacredito_save({commit},data){
        commit('tarjetacredito_save_load',true);
        try {
            const resp = await db.save('/apitarjeta/',data);
            commit('tarjetacredito_save_load',false);
            return resp;
        } catch (e) {
            commit('tarjetacredito_save_load',false);
            return e
        } 
    },
    async Vact_tarjetacredito_del({dispatch},id){
        try {
            const data = await db.delete('/apitarjeta/'+id);
            dispatch('Vact_tarjetacredito_data');
            return data
        } catch (e) {
            return false
        } 
    },
    async Vact_tarjetacredito_update({commit},{id, data}){
        commit('tarjetacredito_save_load',true);
        try {
            const resp = await db.update('/apitarjeta/'+id+'/',data);
            commit('tarjetacredito_save_load',false);
            return resp;
        } catch (e) {
            commit('tarjetacredito_save_load',false);
            return e
        } 
    },
}
