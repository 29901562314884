
export default {

  Vget_establecimiento_data: (state) => {
    return state.establecimiento_data
  },
  Vget_establecimiento_load: (state) => {
    return state.establecimiento_load
  },
  Vget_establecimiento_save_load: (state) => {
    return state.establecimiento_save_load
  },
}
