import { db } from "@/services/db.service";
export default {

    async Vact_timbrado_data({commit}){
        commit('timbrado_load',true);
        try {
            const data = await db.get('/apitimbrado/');
            commit('timbrado_data',data);
            commit('timbrado_load',false);
            return true
        } catch (e) {
            commit('timbrado_load',false);
            return false
        } 
    },
    async Vact_timbrado_del({dispatch},id){
        try {
            const data = await db.delete('/apitimbrado/'+id);
            dispatch('Vact_timbrado_data');
            return data
        } catch (e) {
            return false
        } 
    },
    async Vact_timbrado_save({commit},data){
        commit('timbrado_save_load',true);
        try {
            const resp = await db.save('/apitimbrado/',data);
            commit('timbrado_save_load',false);
            return resp;
        } catch (e) {
            commit('timbrado_save_load',false);
            return e
        } 
    },
    async Vact_timbrado_update({commit},{id, data}){
        commit('timbrado_save_load',true);
        try {
            const resp = await db.update('/apitimbrado/'+id+'/',data);
            commit('timbrado_save_load',false);
            return resp;
        } catch (e) {
            commit('timbrado_save_load',false);
            return e
        } 
    },
}
