import jsPDF from 'jspdf'
import 'jspdf-autotable'
import store from '@/store'
const exportAvanzado = (body, headers) => {
    var doc = new jsPDF();
    doc.setFontSize(10);
    store.getters['empresa/Vget_empresa_active'].forEach(empresa => {
        if (empresa.est_emp_tam_usu)
            return doc.text(empresa.idempresa.descempresa, 15, 20);

    })
    doc.setFontSize(14);
    doc.text('Stock', doc.internal.pageSize.width / 2, 30, { align: 'center' });


    doc.setLineWidth(1);
    doc.line(13, 37, doc.internal.pageSize.width - 12, 37);

    doc.autoTable({
        theme: 'grid',
        head: [headers.map(x => {
            return x.text
        })],
        body: body,
        headStyles: {
            textColor: [0, 0, 0],
            fillColor: [255, 255, 255],
            fontSize: 8,
        },
        startY: 40,
        showHead: 'firstPage',
        styles: { overflow: 'hidden' },
    })


    doc.save('Inventario Stock.pdf');
}
export default exportAvanzado;
