<template>
  <v-dialog persistent max-width="500" :value="value">
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
      </v-toolbar>
      <v-container>
        <v-form ref="form" @submit.prevent="next(1, data.descripcion)">
          <v-row dense>
            <v-col cols="12">
              <c-text-field
                label="Descripcion"
                ref="estadoasistencia1"
                v-model="data.descripcion"
                placeholder="Ingrese una descripcion"
                @keyup.native.enter="next(1, data.descripcion)"
              ></c-text-field>
            </v-col>
            <v-col cols="12">
              <c-text-field
                label="Codigo de Estado"
                ref="estadoasistencia2"
                v-model="data.clave"
                placeholder="Ingrese un codigo para el estado"
                @keyup.native.enter="next(2, data.calve)"
              ></c-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
      <v-divider></v-divider>
      <v-card-actions>
        <c-btn-crud-close @click="cancel()"></c-btn-crud-close>
        <v-spacer></v-spacer>
        <c-btn-crud-done ref="estadoasistencia3" @click="save()"></c-btn-crud-done>
      </v-card-actions>
      <c-overlay :value="isEstadoAsistenciaLoading"></c-overlay>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    props: {
      type: Object,
    },
  },

  mounted() {
    if (this.editable) this.data = JSON.parse(JSON.stringify(this.props));
    setTimeout(() => this.next(0, "-"), 20);
  },
  computed: {
    ...mapGetters("estadoasistencia", ["isEstadoAsistenciaLoading"]),
    title() {
      return this.editable ? "Modificar Estado" : "Registrar Estado";
    },
  },
  methods: {
    ...mapActions("estadoasistencia", ["setEstadoAsistencia", "fetchEstadoAsistencia", "setEstadoAsistenciaUpdate"]),
    cancel() {
      this.$emit("input", false);
    },
    next(id, data) {
      if (data != "")
        try {
          this.$refs[`estadoasistencia${id + 1}`].focus();
        } catch (error) {
          this.$refs[`estadoasistencia${id + 1}`].$el.focus();
        }
    },
    async save() {
      if (!this.$refs.form.validate()) return null;
      const response = this.editable
        ? await this.setEstadoAsistenciaUpdate({
            data: this.data,
            id: this.data.idioestado,
          })
        : await this.setEstadoAsistencia(this.data);
      if (response.success) {
        this.data = JSON.parse(JSON.stringify(this.default));
        this.$refs.form.resetValidation();
        this.fetchEstadoAsistencia();
        this.next(0, "-");
        if (this.editable) this.cancel();
      }
    },
  },
  data: () => ({
    data: {
      idioestado: 0,
      descripcion: "",
      clave: ''
    },
    default: {
      idioestado: 0,
      descripcion: "",
      clave: ''
    },
  }),
};
</script>

<style>
</style>