<template>
  <div>
    <v-dialog :value="value" max-width="700px" persistent @keydown.esc="cancelar()">
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>Detalle de Concepto</v-toolbar-title>
        </v-toolbar>
        <v-container>
        <v-row row dense>
          <v-col cols="12">
            <v-text-field
              dense
              type="text"
              v-model="data.desconcepto"
              label="Descripcion"
              outlined
              readonly
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              dense
              v-model="data.idcategoriaitem.desccategoriaitem"
              label="Categoria"
              persistent-hint
              outlined
              readonly
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field
              dense
              v-model="data.idcuenta.desccuenta"
              label="Cuenta"
              persistent-hint
              outlined
              readonly
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <vue-text-currency v-model="data.precconcepto" label="Precio" readonly></vue-text-currency>
          </v-col>
        </v-row>
        </v-container>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="red" text @click="cancelar">
            <v-icon>clear</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    datos: {
      type: [Object, Array],
      default: null,
    },
  },
  data() {
    return {
      data: {
        desconcepto: "",
        idcategoriaitem: {
          idcategoriaitem: "",
          desccategoriaitem: "",
        },
        idcuenta: {
          idcuenta: "",
          desccuenta: "",
        },
        precconcepto: "",
      },
      default: {
        desconcepto: "",
        idcategoriaitem: {
          idcategoriaitem: "",
          desccategoriaitem: "",
        },
        idcuenta: {
          idcuenta: "",
          desccuenta: "",
        },
        precconcepto: "",
      },
      precconcepto_fake: "",
      reload: 0,
    };
  },
  watch: {
    $route(to, from) {
      if (from.path === "/concepto") return this.cancelar();
    },
  },
  created() {
    this.show(this.datos);
  },
  methods: {
    cancelar() {
      this.data = JSON.parse(JSON.stringify(this.default));
      this.$emit("input", false);
      this.$emit("datos", null);
    },

    show(val) {
      this.data = JSON.parse(JSON.stringify(val));
    },
  },
};
</script>
