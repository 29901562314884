export default {
  transladocaja_create: false,
  transladocaja_update: null,
  transladocaja_reload: false,
  transladocaja_show: false,
  transladocaja_key: 0,

  transladocaja_data: [],
  transladocaja_load: false,
  transladocaja_save_load: false
}
