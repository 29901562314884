<template>
  <div  class="mx-2">
    <v-row dense outlined>
      <v-col cols="12" sm="6">
        <c-text-date
          label="Fecha Desde"
          v-model="data.desde"
        ></c-text-date>
      </v-col>
      <v-col cols="12" sm="6">
        <c-text-date
          label="Fecha Hasta"
          v-model="data.hasta"
        ></c-text-date>
      </v-col>
      <v-col cols="6">
        <AutocompleteSector
          label="Sector"
          v-model="data.sector"
          :return-object="false"
        ></AutocompleteSector>
      </v-col>
      <v-spacer></v-spacer>
      <v-btn class="mt-3" outlined text color="primary" @click="$emit('filter',data)">Filtrar</v-btn>
        <v-btn class="mt-3 ml-1" outlined text color="red" @click="$emit('printer')">Imprimir Lote</v-btn>
    </v-row>

  </div>
</template>
<script>
import {
  current_first_date,
  current_last_date,
} from "@/services/util/date.service";
import AutocompleteSector from "@/views/RRHH/Mantenimiento/Sector/autocomplete";
export default {
  name: "VFilter",
  components: {
    AutocompleteSector,
  },
  data: () => ({
    data: {
      desde: current_first_date(),
      hasta: current_last_date(),
      sector: '',
    },
  }),
};
</script>
