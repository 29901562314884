<template>
    <v-overlay :z-index="4" :value="value">
        <v-dialog :value="value" max-width="700" persistent :fullscreen="mobile" @keydown.esc="cerrar()">
            <v-card>
                <v-card flat>
                    <v-row dense class="mx-1">
                        <v-card-title>
                            Seleccione el recetario
                        </v-card-title>
                        <v-spacer></v-spacer>
                        <c-text-table-search v-model="search"></c-text-table-search>
                    </v-row>
                </v-card>
                <v-container>
                    <v-data-table :loading="Vget_recetario_load" :headers="headers" :items="Vget_recetario_lote"
                        :search="search">
                        <template v-slot:[`item.accion`]="props">
                            <c-btn-table-next @click="selectRecetario(props.item)"></c-btn-table-next>
                        </template>
                    </v-data-table>
                </v-container>
                <v-divider></v-divider>
                <v-card-actions>
                    <c-btn-crud-close @click="cerrar"></c-btn-crud-close>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-overlay>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        datos: {
            type: Object,
        },
        lote: {
            type: String,
        },
        zafra: {
            type: Number,
        }
    },
    data() {
        return {
            search: "",
            headers: [
                { text: "Codigo", align: "left", value: "idrecetario" },
                { text: "Zafra", align: "left", value: "idcampana.descripcion" },
                { text: "Lote", align: "left", value: "idlote_agri.campo" },
                { text: "Comentario", align: "left", value: "comentario" },
                { text: "Select", value: "accion", sortable: false, align: "right" },
            ],
        }
    },
    created() {
        this.Vact_recetario_data({zafra: this.zafra, lote: this.lote});
    },
    computed: {
        ...mapGetters("recetario", ["Vget_recetario_lote", "Vget_recetario_load"]),
        mobile() {
            return this.$vuetify.breakpoint.mobile;
        }
    },
    methods: {
        ...mapActions("recetario", ["Vact_recetario_data", "Vact_recetario_load", "Vact_recetario_delete"]),
        cerrar() {
            this.$emit("input", false);
        },
        selectRecetario(item) {
            this.$emit("datos", item)
            this.cerrar()
        }
    }
}
</script>