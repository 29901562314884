<template>
  <div>
    <v-row dense class="my-3">
      <v-col cols="12" md="4" sm="4" class="d-flex flex-column">
        <div class="subtitle-1 align-self-center">TOTAL A COBRAR</div>
        <div class="ml-1 font-weight-black title align-self-center">
          {{ currencyFormat(montoCobrar) }}
        </div>
      </v-col>
      <v-col cols="12" md="4" sm="4" class="d-flex flex-column">
        <div class="subtitle-1 align-self-center">MONTO ENTREGADO</div>
        <div class="ml-1 font-weight-black title align-self-center">
          {{ currencyFormat(montoEntregado) }}
        </div>
      </v-col>
      <v-col cols="12" md="4" sm="4" class="d-flex flex-column">
        <div class="subtitle-1 align-self-center">VUELTO</div>
        <div class="ml-1 font-weight-black red--text title align-self-center">
          {{ currencyFormat(diferenciaMonto) }}
        </div>
        <v-progress-linear></v-progress-linear>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row dense v-if="!readonly">
      <v-spacer></v-spacer>
      <v-menu transition="slide-x-transition" bottom right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="ma-1"
            color="primary"
            outlined
            small
            text
            v-bind="attrs"
            v-on="on"
            >Agregar Metodo de Pago</v-btn
          >
        </template>

        <v-list>
          <v-list-item v-if="!pago.efectivo.isEfectivo" @click="addEfectivo()">
            <v-list-item-title>Efectivo</v-list-item-title>
          </v-list-item>
          <v-list-item @click="addCheque()">
            <v-list-item-title>Cheque</v-list-item-title>
          </v-list-item>
          <v-list-item @click="addTarjeta('Credito')">
            <v-list-item-title>Tarjeta Credito</v-list-item-title>
          </v-list-item>
          <v-list-item @click="addTarjeta('Debito')">
            <v-list-item-title>Tarjeta Debito</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-row>
    <v-row dense v-if="pago.efectivo.isEfectivo">
      <v-col cols="12" sm="1">
        <h4 class="mt-2">Efectivo</h4>
      </v-col>
      <v-col cols="9" sm="2">
        <c-text-currency
          :readonly="readonly"
          :rules="validarCosto"
          outlined
          dense
          label="Monto"
          @keyup.native="setEfectivo()"
          v-model="pago.efectivo.monto"
        />
      </v-col>
      <v-spacer></v-spacer>
      <v-btn
        class="mt-1"
        color="red"
        outlined
        fab
        small
        text
        @click="deleteEfectivo()"
        v-if="!readonly"
      >
        <v-icon>clear</v-icon>
      </v-btn>
    </v-row>
    <v-row dense v-for="(item, i) of pago.cheque" :key="i">
      <v-col cols="12" sm="1">
        <h4 class="mt-2">Cheque</h4>
      </v-col>
      <v-col cols="12" sm="2">
        <c-text-currency
          :readonly="readonly"
          label="Monto"
          outlined
          dense
          :rules="validarCosto"
          @keyup.native="setCheque()"
          v-model="pago.cheque[i].monto"
        />
      </v-col>
      <v-col cols="12" sm="2">
        <v-autocomplete
          :readonly="readonly"
          :items="Vget_banco_data"
          item-text="descbanco"
          item-value="idbanco"
          outlined
          dense
          :rules="validar"
          autocomplete="off"
          :loading="Vget_banco_load"
          v-model="pago.cheque[i].idbanco.idbanco"
          label="Banco"
          @change="setCheque()"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="2">
        <vue-text-field
          :readonly="readonly"
          label="Numero"
          @keyup.native="setCheque()"
          v-model="pago.cheque[i].nrocheque"
        ></vue-text-field>
      </v-col>
      <v-col cols="12" sm="2">
        <c-text-date
          dense
          outlined
          :readonly="readonly"
          label="Fecha de Emision"
          @keyup.native="setCheque()"
          v-model="pago.cheque[i].fechaemision"
        ></c-text-date>
      </v-col>
      <v-col cols="9" sm="2">
        <c-text-date
          dense
          outlined
          :readonly="readonly"
          label="Fecha de Pago"
          @keyup.native="setCheque()"
          v-model="pago.cheque[i].fechapago"
        ></c-text-date>
      </v-col>
      <v-spacer></v-spacer>
      <v-btn
        class="mt-1"
        color="red"
        outlined
        fab
        small
        text
        @click="deleteCheque(i)"
        v-if="!readonly"
      >
        <v-icon>clear</v-icon>
      </v-btn>
    </v-row>
    <v-row dense v-for="(item, i) of pago.tarjeta" :key="'t' + i">
      <v-col cols="12" sm="1">
        <h5 class="mt-1">Tarjeta {{ item.type }}</h5>
      </v-col>
      <v-col cols="12" sm="2">
        <c-text-currency
          :readonly="readonly"
          label="Monto"
          outlined
          dense
          :rules="validarCosto"
          @keyup.native="setTarjeta()"
          v-model="pago.tarjeta[i].monto"
        />
      </v-col>
      <v-col cols="12" sm="2">
        <vue-text-field
          :readonly="readonly"
          label="Voucher"
          @keyup.native="setTarjeta()"
          v-model="pago.tarjeta[i].voucher"
        ></vue-text-field>
      </v-col>
      <v-col cols="9" sm="2">
        <v-autocomplete
          :readonly="readonly"
          :items="Vget_pasarelapago_data"
          item-text="descripcion"
          item-value="idpasarela_pago"
          outlined
          dense
          :rules="validar"
          autocomplete="off"
          :loading="Vget_pasarelapago_load"
          v-model="pago.tarjeta[i].idpasarela_pago.idpasarela_pago"
          label="Pasarela"
          @change="setTarjeta()"
        ></v-autocomplete>
      </v-col>
      <v-spacer></v-spacer>
      <v-btn
        class="mt-1"
        color="red"
        outlined
        text
        fab
        small
        @click="deleteTarjeta(i)"
        v-if="!readonly"
      >
        <v-icon>clear</v-icon>
      </v-btn>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { current_date } from "@/services/util/date.service";
import { currency } from "@/services/util/number.service";
export default {
  props: {
    montoCobrar: {
      type: [Number, String],
      default: 0,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    datos: {
      type: Object,
      default: null,
    },
  },
  watch: {
    montoCobrar(val) {
      if (this.pago.efectivo.isEfectivo) {
        this.pago.efectivo.monto = Number(val);
        this.setEfectivo();
      }
    },
  },
  computed: {
    ...mapGetters("banco", ["Vget_banco_data", "Vget_banco_load"]),
    ...mapGetters("pasarelapago", [
      "Vget_pasarelapago_data",
      "Vget_pasarelapago_load",
    ]),
    montoEntregado() {
      let saldo = 0;
      this.pago.tarjeta.forEach((tarjeta) => {
        saldo = saldo + Number(tarjeta.monto) || 0;
      });
      this.pago.cheque.forEach((cheque) => {
        saldo = saldo + Number(cheque.monto);
      });
      saldo = saldo + Number(this.pago.efectivo.monto);
      return saldo;
    },
    diferenciaMonto() {
      if (this.montoCobrar > 0)
        return Number(this.montoEntregado) - Number(this.montoCobrar);
      return 0;
    },
  },
  mounted() {
    this.Vact_banco_data();
    this.Vact_pasarelapago_data();
    if (this.datos != null) this.pago = JSON.parse(JSON.stringify(this.datos));
  },
  methods: {
    ...mapActions("banco", ["Vact_banco_data"]),
    ...mapActions("pasarelapago", ["Vact_pasarelapago_data"]),
    setEfectivo() {
      this.$emit("efectivo", this.pago.efectivo);
    },
    setTarjeta() {
      this.$emit("tarjeta", this.pago.tarjeta);
    },
    setCheque() {
      this.$emit("cheque", this.pago.cheque);
    },
    addEfectivo() {
      this.pago.efectivo.isEfectivo = true;
    },
    currencyFormat(val) {
      return currency(val);
    },
    deleteEfectivo() {
      this.pago.efectivo.monto = 0;
      this.pago.efectivo.isEfectivo = false;
      this.setEfectivo();
    },
    addCheque() {
      this.pago.cheque.push({
        idbanco: {
          idbanco: "",
        },
        nrocheque: "",
        monto: "",
        fechaemision: current_date(),
        fechapago: current_date(),
      });
      this.setCheque();
    },
    deleteCheque(i) {
      this.pago.cheque.splice(i, 1);
      this.setCheque();
    },
    addTarjeta(type) {
      this.pago.tarjeta.push({
        type: type,
        monto: "",
        voucher: "",
        idpasarela_pago: {
          idpasarela_pago: "",
        },
      });
      this.setTarjeta();
    },
    deleteTarjeta(i) {
      this.pago.tarjeta.splice(i, 1);
      this.setTarjeta();
    },
  },
  data() {
    return {
      validar: [(v) => !!v || "Seleccione un campo"],
      validarCosto: [
        (v) => !!v || "Este campo es obligatorio",
        (v) => !(Number(v) <= 0) || "Este campo no puede ser menor o igual 0",
      ],
      pago: {
        efectivo: {
          idcaja: 0,
          idformapago: {
            idformapago: 1,
          },
          entrega: 0,
          monto: 0,
          isEfectivo: true,
        },
        tarjeta: [],
        cheque: [],
      },
    };
  },
};
</script>

<style></style>
