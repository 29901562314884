<template>
  <v-dialog persistent max-width="800" :value="value">
    <v-card>
      <v-toolbar flat dense>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
      </v-toolbar>
      <v-container>
        <v-form
          ref="form"
          lazy-validation
          @submit.prevent="next(1, data.descreportes)"
        >
          <v-row dense>
            <v-col cols="12">
              <c-text-field
                label="Descripcion"
                id="input1"
                dense
                outlined
                v-model="data.descreportes"
              />
            </v-col>
            <v-col cols="12" v-if="!editable">
              <v-file-input
                label="Plantilla"
                v-model="data.plantilla"
                filled
                prepend-icon="camera"
              ></v-file-input>
            </v-col>
            <v-col cols="12" v-if="editable">
              <v-file-input
                label="Plantilla"
                v-model="data.plantilla_update"
                filled
                prepend-icon="camera"
              ></v-file-input>
            </v-col>
            <v-col cols="12">
              <v-textarea
                label="Parametros"
                :hint="!parametros_error ? 'Error JSON invalido!!!' : ''"
                :persistent-hint="!parametros_error"
                outlined
                v-model="parametros_fake"
                :error="!parametros_error"
              ></v-textarea>
            </v-col>
            <v-col
              cols="12"
              md="6"
              sm="6"
              v-if="editable && data.plantilla_update === null"
            >
              <v-img
                :src="data.plantilla"
                class="white--text align-end"
                gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                aspect-ratio="1"
                max-width="280"
                @click="dialog_img = true"
                height="500px"
              >
                <v-card-title>Plantilla</v-card-title>
              </v-img>
            </v-col>
            <v-col cols="12" md="6" sm="6" v-if="editable">
              <vue-json-pretty
                :deep="1"
                :data="parametros_pretty"
              ></vue-json-pretty>
            </v-col>
          </v-row>
        </v-form>

        <c-overlay :value="isConfigImpresionLoading" />

      </v-container>
      <v-card-actions>
        <c-btn-crud-close @click="close()"></c-btn-crud-close>
        <v-spacer></v-spacer>
        <c-btn-crud-done @click="guardar()"></c-btn-crud-done>
      </v-card-actions>
    </v-card>
    <v-dialog v-if="dialog_img" :value="true" width="800">
      <v-card>
        <v-img :src="data.plantilla"></v-img>
        <v-card-actions>
          <v-spacer></v-spacer>
          <c-btn-crud-close @click="dialog_img = false"></c-btn-crud-close>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { focus } from "@/services/focus.service";
import VueJsonPretty from "vue-json-pretty";

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    props: {
      type: Object,
    },
  },
  components: {
    VueJsonPretty,
  },
  data() {
    return {
      data: {
        plantilla_update: null,
      },
      default: {
        plantilla_update: null,
      },
      dialog_img: false,
      parametros_pretty: "",
      parametros_fake: "",
      parametros_error: true,
    };
  },

  computed: {
    ...mapGetters("configimpresion", ["isConfigImpresionLoading"]),
    title() {
      return this.editable
        ? "Modificar Configuracion de Impresion"
        : "Registrar Configuracion de Impresion";
    },
  },
  watch: {
    $route() {
      this.close();
    },
  },
  async mounted() {
    if (this.editable) {
      this.data = JSON.parse(JSON.stringify(this.props));
      this.data.plantilla_update = null;
      this.parametros_pretty = JSON.parse(
        JSON.stringify(this.props.parametros)
      );
      this.parametros_fake = JSON.stringify(this.props.parametros, null, 2);
    }
    setTimeout(() => this.next(0, "-"), 500);
  },

  methods: {
    ...mapActions("configimpresion", [
      "setConfigImpresion",
      "setConfigImpresionUpdate",
      "fetchConfigImpresion",
    ]),
    next(id, data) {
      focus.nextid(id, data);
    },
    async guardar() {
      if (!this.$refs.form.validate()) return null;
      if (!this.isJSON(this.parametros_fake)) return null;
      this.data.parametros = null;
      this.data.parametros = JSON.parse(JSON.stringify(this.parametros_fake));
      const response = this.editable
        ? await this.setConfigImpresionUpdate({
            data: this.obtener_form(),
            id: this.data.idconfig_impresion,
          })
        : await this.setConfigImpresion(this.obtener_form());
      if (response.success) {
        this.fetchConfigImpresion();
        this.data = JSON.parse(JSON.stringify(this.default));
        this.$refs.form.resetValidation();

        this.next(0, "-");
        if (this.editable) this.close();
      }
    },

    obtener_form() {
      let form_data = new FormData();
      if (!this.editable) {
        form_data.append("plantilla", this.data.plantilla);
      }
      if (this.data.plantilla_update === null) {
        form_data.append("plantilla_update", "");
      } else {
        form_data.append("plantilla_update", this.data.plantilla_update);
      }
      form_data.append("descreportes", this.data.descreportes);
      form_data.append("parametros", this.data.parametros);
      form_data.append("idconfig_impresion", 0);
      return form_data;
    },

    close() {
      this.$emit("input", false);
    },
    isJSON(str) {
      if (/^\s*$/.test(str)) return (this.parametros_error = false);
      str = str.replace(/\\(?:["\\bfnrt]|u[0-9a-fA-F]{4})/g, "@");
      str = str.replace(
        /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+]?\d+)?/g,
        "]"
      );
      str = str.replace(/(?:^|:|,)(?:\s*\[)+/g, "");
      return (this.parametros_error = /^[\],:{}\s]*$/.test(str));
    },
  },
};
</script>
