import { db } from "@/services/db.service";
export default {
    async motivoajuste_create({commit},request){
        commit('motivoajuste_create',request);
    },
    async motivoajuste_update({commit},request){
        commit('motivoajuste_update',request);
    },
    async motivoajuste_reload({commit},request){
        commit('motivoajuste_reload',request);
    },
    async motivoajuste_show({commit},request){
        commit('motivoajuste_show',request);
    },

    async Vact_motivoajuste_data({commit}){
        commit('motivoajuste_load',true);
        try {
            const data = await db.get('/apimotivoajuste/');
            commit('motivoajuste_data',data);
            commit('motivoajuste_load',false);
            return true
        } catch (e) {
            commit('motivoajuste_load',false);
            return false
        } 
    },
}
