<template>
  <div>
    <v-dialog :value="value" max-width="600" persistent>
      <v-card>
        <v-toolbar flat dense>
          <v-toolbar-title>{{
            operacion ? "Crear nueva Sucursal" : "Editar Sucursal"
          }}</v-toolbar-title>
        </v-toolbar>
        <v-container>
          <v-form ref="form" lazy-validation>
            <c-text-field
              label="Nombre"
              id="input1"
              dense
              outlined
              v-model="data.nombtambo"
              @keyup.native.enter="next(1, data.nombtambo)"
            />
            <AutocompleteEmpresa
              v-model="data.idempresa"
              createsd
              dense
              outlined
              id="input2"
              @keyup.native.enter="next(2, data.idempresa.idempresa)"
            />

            <c-text-field
              label="Telefono"
              id="input3"
              dense
              outlined
              v-model="data.teletambo"
              @keyup.native.enter="next(3, data.teletambo)"
            />
            <c-text-field
              label="Correo"
              id="input4"
              dense
              outlined
              v-model="data.corrtambo"
              @keyup.native.enter="next(4, data.corrtambo)"
            />
            <c-text-field
              label="Direccion"
              id="input5"
              dense
              outlined
              v-model="data.diretambo"
              @keyup.native.enter="next(5, data.diretambo)"
            />
          </v-form>
        </v-container>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="red" text @click="cancelar()">
            <v-icon dark>close</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn id="aceptar" color="primary" text @click="guardar()">
            <v-icon dark>done</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { mensaje } from "@/services/notify.service";
import { focus } from "@/services/focus.service";
import AutocompleteEmpresa from "@/views/Mantenimientos/empresa/autocompleteEmpresa";
export default {
  components: {
    AutocompleteEmpresa,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      data: {
        idempresa: {
          idempresa: "",
        },
      },
      default: {
        idempresa: {
          idempresa: "",
        },
      },
      id: "",
      operacion: true,
    };
  },
  watch: {
    $route(to, from) {
      if (from.path === "/tambo") return this.close();
    },
  },
  computed: {
    ...mapGetters("tambo", {
      update_tambo: "tambo_update",
      Vget_tambo_save_load: "Vget_tambo_save_load",
    }),
  },
  created() {
    this.nuevo(this.update_tambo);
  },
  methods: {
    ...mapActions("tambo", [
      "Vact_tambo_save",
      "Vact_tambo_update",
      "Vact_tambo_data",
      "tambo_update",
    ]),
    next(id, data) {
      focus.nextid(id, data);
    },
    async guardar_finalizar() {
      let a = await this.Vact_tambo_save(this.data);
      if (!a.estado) {
        this.overlay = false;
        a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
        return false;
      }
      this.data = JSON.parse(JSON.stringify(this.default));
      this.$notify(a.info);
      this.$refs.form.resetValidation();
      setTimeout(() => document.getElementById("input1").focus(), 500);
      this.overlay = false;
      return true;
    },
    async editar_finalizar() {
      const id = this.id;
      let a = await this.Vact_tambo_update({ id, data: this.data });
      if (!a.estado) {
        this.overlay = false;
        return a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
      this.$notify(a.info);
      this.overlay = false;
    },
    async guardar() {
      if (!this.$refs.form.validate()) return this.$notify(mensaje.completar());
      this.overlay = true;
      if (this.operacion) {
        return await this.guardar_finalizar();
      }
      if (!this.operacion) return this.editar_finalizar();
    },
    cancelar() {
      this.$emit("input", false);
      this.Vact_tambo_data();
      this.tambo_update(null);
      this.operacion = false;
    },
    nuevo(data) {
      if (data === null) return null;
      this.data = data;
      this.operacion = false;
      this.id = this.data.idtambo;
    },
  },
};
</script>
