<template>
  <div>
    <v-dialog
      v-model="value"
      max-width="700px"
      persistent
      @keydown.esc="cancelar()"
    >
      <v-card>
        <v-form ref="form" lazy-validation>
          <v-toolbar flat dense>
            <v-toolbar-title>
              {{ isEdit ? "Modificar Preparto" : "Registrar Preparto" }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-checkbox class="mt-5" label="Historico" v-model="data.historico"></v-checkbox>
          </v-toolbar>
          <v-container>
            <v-row row dense>
              <v-col cols="12">
                <AutocompleteAnimal
                  :action="fetche"
                  :getter="gett"
                  id="input1"
                  no-create
                  v-model="data.idanimal.idanimal"
                  :animal="animal"
                />
              </v-col>

              <v-col cols="12">
                <c-text-date
                  label="Fecha de registro"
                  id="input4"
                  ref="input4"
                  outlined
                  dense
                  v-model="data.fecppreparto"
                  @keyup.native.enter="nextfocus(4)"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  dense
                  type="text"
                  id="input5"
                  ref="input5"
                  v-model="data.comnpreparto"
                  label="Comentario"
                  outlined
                  autocomplete="off"
                  @keyup.enter="nextfocus(5)"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>

          <v-card-actions>
            <c-btn-crud-close @click="cancelar()"></c-btn-crud-close>
            <v-spacer></v-spacer>
            <c-btn-crud-done id="aceptar" @click="guardar()"></c-btn-crud-done>
          </v-card-actions>
        </v-form>
        <c-overlay :value="isLoading"></c-overlay>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { current_date } from "@/services/util/date.service";


export default {
  components: {
    AutocompleteAnimal: () =>
      import("@/views/Animal/Animal/AutocompleteAnimal"),
  },
  props: {
    value: Boolean,
    props: {
      type: Object,
      default: null,
    },
    idanimal:{
      type: Number,
      default: null
    }
  },
  data() {
    return {
      isEdit: false,
      fetche: "fetchPrenada",
      gett: "getPrenada",
      data: {
        idpreparto: 0,
        fecppreparto: current_date(),
        fecsparto: null,
        idanimal:{
          idanimal: ""
        },
        comnpreparto: null,
        historico: false,
      },
      default: {
        idpreparto: 0,
        fecppreparto: current_date(),
        fecsparto: null,
        idanimal:{
          idanimal: ""
        },
        comnpreparto: null,
        historico: false,
      },
      animal: null,
    };
  },
  watch: {
    $route() {
      return this.cancelar();
    },
    'data.historico'() {
      if (this.data.secada == true) {
        this.fetche = 'fetchTodos'
        this.gett = 'getTodos'
      } else {
        this.fetche = 'fetchPrenada'
        this.gett = 'getPrenada'
      }
    },
  },
  computed: {

    ...mapGetters("preparto", ["isLoading"]),

  },
  created() {
    this.nuevo(this.props);
  },
  mounted() {
    setTimeout(() => document.getElementById("input1").focus(), 500);
    if(this.idanimal) this.data.idanimal.idanimal = this.idanimal;
  },
  methods: {
    ...mapActions("preparto", [
      "setPreparto",
      "setPrepartoUpdate",
      "fetchPreparto",
    ]),
    ...mapActions("animal", ["fetchPrenada"]),



    cancelar() {
      this.data = JSON.parse(JSON.stringify(this.default));
      this.$emit('input',false);
      this.$emit('reset', null);
      this.fetchPreparto();
      this.isEdit = true;
    },

    async guardar() {
      if (!this.$refs.form.validate()) return null;
      if (this.data.comnpreparto === "") {
        this.data.comnpreparto = null;
      }
      const id = this.data.idpreparto;
      const response = this.isEdit
        ? await this.setPrepartoUpdate({ id, data: this.data })
        : await this.setPreparto(this.data);
      if (response.success) {
        this.data = JSON.parse(JSON.stringify(this.default));
        if (this.isEdit) return this.cancelar();
        this.$refs.form.resetValidation();
      }
    },

    nuevo(val) {
      if (val !== null) {
        this.isEdit = true;
        this.data = JSON.parse(JSON.stringify(val));
        this.animal = JSON.parse(JSON.stringify(val.idanimal));
      }
    },
  },
};
</script>
