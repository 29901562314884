<template>
  <div>
    <v-dialog :value="value" persistent max-width="400">
      <v-card>
        <v-toolbar flat dense>
          <v-toolbar-title>
            <span> {{ operacion ? "Agregar " : "Editar " }} Asistencia </span>
          </v-toolbar-title>
        </v-toolbar>
        <v-container>
          <v-row row dense>
            <v-col cols="12" md="12" sm="12">
              <c-text-date
                id="gymasistencia0"
                dense
                outlined
                :readonly="false"
                label="Fecha"
                v-model="data.fecha"
                @keyup.native.enter="next(0, data.fecha)"
              ></c-text-date>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <c-btn-crud-close @click="cancelar()"></c-btn-crud-close>
          <v-spacer></v-spacer>
          <c-btn-crud-done
            id="gymasistencia1"
            @click="save()"
          ></c-btn-crud-done>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { mensaje } from "@/services/notify.service";
import { focus } from "@/services/focus.service";
import { current_date } from "@/services/util/date.service";
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    "sync-input": {
      type: String,
      default: null,
    },
    props: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      data: {
        idgymplanilla: "",
        idcontacto: {
          idcontacto: "",
        },
        fecha: current_date(),
      },
      default: {
        idgymplanilla: "",
        idcontacto: {
          idcontacto: "",
        },
        fecha: current_date(),
      },
      operacion: true,
    };
  },
  mounted() {
    setTimeout(() => document.getElementById("gymasistencia0").focus(), 500);
    this.data.idcontacto = this.$props.props.idcontacto;
    this.data.idgymplanilla = this.$props.props.idgymplanilla;
  },
  methods: {
    ...mapActions("gymasistencia", [
      "gymasistencia_update",
      "Vact_gymasistencia_save",
      "Vact_gymasistencia_data_by",
    ]),
    next(index, data) {
      focus.nextidName("gymasistencia", index, data);
    },
    nuevo(val) {
      if (val != null) {
        this.data = JSON.parse(JSON.stringify(val));
        this.operacion = false;
      }
    },
    async guardar_finalizer() {
      const sync = this.syncInput;
      let a = await this.Vact_gymasistencia_save(this.data);
      if (a.estado == true) {
        if (sync != null) {
          this.$emit("sync-input", a.data.data.id);
          return this.cancelar();
        }
        this.data = JSON.parse(JSON.stringify(this.default));
        this.$notify(a.info);
        setTimeout(
          () => document.getElementById("gymasistencia0").focus(),
          500
        );
      } else {
        a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
    },
    save() {
      if (this.operacion) {
        return this.guardar_finalizer();
      } else {
        return this.editar_finalizar();
      }
    },
    cancelar() {
      this.data = JSON.parse(JSON.stringify(this.default));
      this.gymasistencia_update(null);
      this.operacion = true;
      this.$emit("input", false);
      this.$emit("datos", null);
      this.Vact_gymasistencia_data_by({ id: this.$props.props.idgymplanilla });
    },
  },
};
</script>
