<template>
  <div>
    <v-row dense>
      <v-col cols="12" :sm="showChild ? 8 : 12">
     
          <v-alert type="error" v-if="permission_alert"
            >Error!!! Este usuario no tiene permisos en esta ventana</v-alert
          >
          <v-toolbar flat dense>
            <v-toolbar-title>{{ titulo }}</v-toolbar-title>
            <widgets-Favorite :ruta="route.main" :formulario="titulo" />
            <v-divider class="mx-2" inset vertical></v-divider>

            <v-spacer></v-spacer>
           
             <c-text-table-search
              v-if="permission.can_view"
              v-model="search"
            ></c-text-table-search>

            <v-toolbar-items>
              <c-btn-table-add
                @click="$router.push({ path: route.add })"
                v-if="permission.can_add && $route.path != route.add"
              ></c-btn-table-add>
            </v-toolbar-items>
          </v-toolbar>

          <v-data-table
            :loading="Vget_division_load"
            :headers="headers"
            :items="Vget_division_data"
            :search="search"
            v-if="permission.can_view"
          >
            <template v-slot:item.accion="props">
              <v-icon
                class="mr-2"
                @click="mupdate(props.item)"
                v-if="permission.can_update"
                >edit</v-icon
              >
              <v-icon
                small
                @click="meliminar(props.item)"
                v-if="permission.can_delete"
                >delete</v-icon
              >
            </template>
            <template v-slot:no-data>
              <v-alert :value="true" color="info" icon="info" outlined
                >No existe ningun registro</v-alert
              >
            </template>
          </v-data-table>
      </v-col>
      <v-col cols="12" sm="4" v-if="showChild">
        <v-card>
          <router-view></router-view>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialogoeliminar" persistent max-width="350">
      <v-card>
        <v-card-title>
          <v-icon left>info</v-icon>
          <span class="title font-weight-light">Ventana de Confirmacion</span>
        </v-card-title>
        <v-card-text>Desea eliminar {{ objeto.descripcion }}?</v-card-text>
        <v-card-actions>
          <v-btn color="red" text @click="eliminarcancelar">
            <v-icon>clear</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="meliminarguardar">
            <v-icon>done</v-icon>
          </v-btn>
        </v-card-actions>
        <v-overlay :value="overlay">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { getPermission } from "@/services/storage/permissionStorage.service";
import { mensaje } from "@/services/notify.service";
import { mapActions, mapGetters } from "vuex";
import { DIVISION } from "@/constants/forms";
import {
  PUEDE_AGREGAR,
  PUEDE_EDITAR,
  PUEDE_ELIMINAR,
  PUEDE_LISTAR,
} from "@/constants/permissions";

export default {
  data() {
    return {
      titulo: DIVISION,
      showChild: false,
      search: "",
      objeto: {},
      dialogoeliminar: false,
      overlay: false,
      route: {
        main: "/diariocaja/division",
        add: "/diariocaja/division/add",
        edit: "/diariocaja/division/edit",
      },
      permission: {
        can_add: false,
        can_view: false,
        can_update: false,
        can_delete: false,
      },
      headers: [
        { text: "Codigo", align: "left", value: "iddivision" },
        { text: "Descripcion", align: "left", value: "descripcion" },
        { text: "Accion", value: "accion", sortable: false, align: "right" },
      ],
    };
  },
  created() {
    this.Vact_division_data();
    const permission = getPermission(DIVISION).permiso;
    this.permission = {
      can_add: permission[PUEDE_AGREGAR],
      can_view: permission[PUEDE_LISTAR],
      can_update: permission[PUEDE_EDITAR],
      can_delete: permission[PUEDE_ELIMINAR],
    };
  },
  mounted() {
    this.showChild = this.$route.path != this.route.main ? true : false;
  },
  watch: {
    $route() {
      //if(this.$route.path === this.route.main)
      this.showChild = this.$route.path != this.route.main ? true : false;
    },
  },
  computed: {
    ...mapGetters("division", ["Vget_division_data", "Vget_division_load"]),
    permission_alert() {
      return mensaje.permission(this.permission);
    },
  },
  methods: {
    ...mapActions("division", ["Vact_division_data", "Vact_division_del"]),
    meliminar(item) {
      this.objeto = Object.assign({}, item);
      this.dialogoeliminar = true;
    },
    eliminarcancelar() {
      this.objeto = "";
      this.dialogoeliminar = false;
    },
    async meliminarguardar() {
      this.overlay = true;
      const id = this.objeto.iddivision;
      const a = await this.Vact_division_del(id);
      if (a.estado == true) {
        this.$notify(a.info);
        this.showChild = false;
        this.dialogoeliminar = false;
        this.eliminarcancelar();
      } else {
        a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
      this.overlay = false;
    },
    mupdate(val) {
      const path = this.route.edit + "/" + val.iddivision;
      if (this.$route.path != path) this.$router.push({ path, query: val });
    },
  },
};
</script>

<style>
</style>