<template>
  <c-dialog-confirmation
    :value="value"
    @cancel="close()"
    @done="save()"
    :loading="Vget_venta_load"
    >Deseas anular la venta <strong>FACT: {{ props.numfventa }}</strong
    >?</c-dialog-confirmation
  >
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    value: Boolean,
    props: Object,
  },
  computed: {
    ...mapGetters("venta", ["Vget_venta_load"]),
  },
  methods: {
    ...mapActions("venta", ["Vact_venta_del"]),
    ...mapActions("stock", ["Vact_stock_data"]),
    ...mapActions("caja", ["Vact_caja_data"]),

    async save() {
      const id = this.props.idventa;
      const response = await this.Vact_venta_del(id);
      if (!response.success) return null;
      this.Vact_stock_data();
      this.Vact_caja_data();
      this.close();
      this.$emit("fetch");
    },
    close() {
      this.$emit("datos", null);
      this.$emit("input", false);
    },
  },
};
</script>

<style></style>
