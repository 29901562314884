<template>
  <div>
    <div v-if="abrircuenta">
      <Item-Cuenta-create />
    </div>
    <v-dialog :value="value" max-width="700px" persistent @keydown.esc="cancelar()">
      <v-card>
        <v-form ref="form" lazy-validation>
          <v-toolbar flat>
            <v-toolbar-title>{{operacion ? 'Agregar Concepto' : 'Editar Concepto'}}</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-row row dense>
              <v-col cols="12">
                <v-text-field
                  dense
                  type="text"
                  :rules="validar"
                  id="conceptoInput1"
                  v-model="data.desconcepto"
                  label="Descripcion"
                  required
                  outlined
                  autocomplete="off"
                  @keyup.enter="nextfocus(1,data.desconcepto)"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <vue-autocomplete-generic
                  id="conceptoInput2"
                  action="categoria/Vact_categoria_data"
                  getter="categoria/Vget_categoria_data"
                  load="categoria/Vget_categoria_load"
                  pk="idcategoriaitem"
                  label="Seleccione categoria"
                  title="desccategoriaitem"
                  permission="Categoria Item"
                  @sync-input="search = $event"
                  :create="true"
                  @create="abrircategoria = $event"
                  v-model="data.idcategoriaitem.idcategoriaitem"
                  @keyup.native.enter="nextfocus(2,data.idcategoriaitem.idcategoriaitem)"
                />
                <div v-if="abrircategoria">
                  <Item-Categoria-create
                    v-model="abrircategoria"
                    :sync-input="search"
                    @sync-input="data.idcategoriaitem.idcategoriaitem = $event ,nextfocus(2,'-')"
                  />
                </div>
              </v-col>
              <v-col cols="12">
                <vue-autocomplete-generic
                  id="conceptoInput3"
                  action="cuenta/Vact_cuenta_data"
                  getter="cuenta/Vget_cuenta_data"
                  load="cuenta/Vget_cuenta_load"
                  pk="idcuenta"
                  label="Seleccione cuenta"
                  title="desccuenta"
                  permission="Cuenta"
                  :create="true"
                  @create="abrircuenta = $event"
                  @sync-input="search = $event"
                  v-model="data.idcuenta.idcuenta"
                  @keyup.native.enter="nextfocus(3,data.idcuenta.idcuenta)"
                />
                <div v-if="abrircuenta">
                  <Item-Cuenta-create
                    v-model="abrircuenta"
                    :sync-input="search"
                    @sync-input="data.idcuenta.idcuenta = $event ,nextfocus(3 ,'-')"
                  />
                </div>
              </v-col>
              <v-col cols="12">
                <vue-text-currency
                  :rules="true"
                  :error-messages="this.mensajito_loco.precconcepto"
                  id="conceptoInput4"
                  v-model="data.precconcepto "
                  label="Precio"
                  @keyup.native.enter="nextfocus(4,data.precconcepto )"
                ></vue-text-currency>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn color="red" text @click="cancelar()">
              <v-icon>clear</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn id="conceptoInputaceptar" color="primary" text @click="guardar">
              <v-icon>done</v-icon>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { db } from "@/services/db.service";
import { focus } from "@/services/focus.service";
import { mensaje } from "@/services/notify.service";
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    "sync-input": {
      type: [String, Number],
      default: null,
    },
    datos: {
      type: [Object, Array],
      default: null,
    },
    'return-object': {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      overlay: false,
      noData: true,
      search: "",
      abrircategoria: false,
      abrircuenta: false,
      data: {
        desconcepto: "",
        idcategoriaitem: {
          idcategoriaitem: "",
        },
        idcuenta: {
          idcuenta: "",
        },
        precconcepto: "",
      },
      default: {
        desconcepto: "",
        idcategoriaitem: {
          idcategoriaitem: "",
        },
        idcuenta: {
          idcuenta: "",
        },
        precconcepto: "",
      },
      url: "/apiconcepto/",
      validar: [(v) => !!v || "Campo requerido"],
      validars: [(v) => !!v || "Seleccione un campo"],
      operacion: true,

      mensajito_loco: [],
    };
  },
  watch: {
    $route(to, from) {
      if (from.path === "/concepto") return this.cancelar();
    },
  },

  mounted() {
    this.nuevo(this.datos);
    setTimeout(() => this.nextfocus(0, "-"), 100);
    if (this.syncInput != null) this.data.desconcepto = this.syncInput;
  },
  methods: {
    ...mapActions("concepto", ["Vact_concepto_data"]),
    nextfocus(id, data) {
      focus.nextidName("conceptoInput", id, data);
    },

    cancelar() {
      this.data = JSON.parse(JSON.stringify(this.default));
      this.operacion = true;
      this.Vact_concepto_data();
      this.$emit("input", false);
      this.$emit("datos", null);
    },
    async guardar_finalizar() {
      const sync = this.syncInput;
      let a = await db.save(this.url, this.data);
      if (!a.estado) {
        this.overlay = false;
        return a.info.map((x) => {
          this.$notify(mensaje.error(x));
          this.mensajito_loco = mensaje.error(x);
        });
      }
      if (sync != null) {
        this.cancelar();
        if(this.returnObject)  return this.$emit("sync-input", a.data.data.id);
        this.$emit("sync-input",a.data.data.id.idconcepto)
      }
      this.data = JSON.parse(JSON.stringify(this.default));
      this.$notify(a.info);
      this.$refs.form.resetValidation();
      this.step = 1;
      setTimeout(() => document.getElementById("input1").focus(), 500);
      this.overlay = false;
    },
    async editar_finalizar() {
      const id = this.datos.idconcepto;
      let a = await db.update(`${this.url}${id}/`, this.data);
      if (!a.estado) {
        this.overlay = false;
        this.mensajito_loco = a.data.response.data;
        return a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
      this.$notify(a.info);
      this.cancelar();
      this.overlay = false;
    },
    guardar() {
      if (!this.$refs.form.validate()) return this.$notify(mensaje.completar());
      this.overlay = true;
      if (this.operacion) return this.guardar_finalizar();
      this.editar_finalizar();
    },
    nuevo(val) {
      if (val === null) return null;
      this.data = val;
      this.operacion = false;
    },
  },
};
</script>