<template>
  <div>
    <v-toolbar flat dense>
      <v-toolbar-title>{{ $route.name }}</v-toolbar-title>
      <widgets-Favorite :ruta="$route.path" :formulario="$route.name" />
    </v-toolbar>
    <v-row dense class="mx-2">
      <v-col cols="12" sm="3">
        <c-text-date
          dense
          ref="reporAdv1"
          label="Desde"
          v-model="data.fecha_inicio"
          @keyup.native.enter="next(1, data.fecha_inicio)"
        ></c-text-date>
      </v-col>
      <v-col cols="12" sm="3">
        <c-text-date
          dense
          ref="reporAdv2"
          label="Hasta"
          v-model="data.fecha_fin"
          @keyup.native.enter="next(2, data.fecha_fin)"
        ></c-text-date>
      </v-col>
      <v-col cols="12" sm="3">
        <v-btn text small color="red" @click="generarPdf()">PDF</v-btn>
        <c-btn-table-reload
          class="ml-2"
          @click="fetchGeneral(data)"
        ></c-btn-table-reload>
      </v-col>
    </v-row>
    <c-overlay absolute :value="isLoading"></c-overlay>
    <v-tabs right v-model="tab">
      <v-tab v-for="(item, i) of items" :key="i">{{ item.moneda }}</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="(item, i) of items" :key="i">
        <div v-for="(sub, j) of item.detalle" :key="j" class="mb-5">
          <div class="ml-4 subtitle-2">{{ sub.cliente }} - {{ sub.ruc }}</div>
          <v-data-table
            :headers="headers"
            :items="sub.detalle"
            dense
            hide-default-footer
            :loading="isLoading"
            :items-per-page="999999"
          >
            <template v-slot:[`item.femision`]="{ item }">
              <div>
                {{ formatDate(item.femision) }}
              </div>
            </template>
            <template v-slot:[`item.fvencimiento`]="{ item }">
              <div>
                {{ formatDate(item.fvencimiento) }}
              </div>
            </template>
            <template v-slot:[`item.montopagar`]="{ item }">
              <div class="font-weight-black">
                {{ currencyFormat(item.montopagar) }}
              </div>
            </template>
            <template v-slot:[`item.montopago`]="{ item }">
              <div class="font-weight-black">
                {{ currencyFormat(item.montopago) }}
              </div>
            </template>
            <template v-slot:[`item.totalpago`]="{ item }">
              <div class="font-weight-black">
                {{ currencyFormat(item.totalpago) }}
              </div>
            </template>
            <template v-slot:[`item.total`]="{ item }">
              <div class="font-weight-black">
                {{ currencyFormat(item.total) }}
              </div>
            </template>
          </v-data-table>
        </div>
        <v-divider></v-divider>
        <v-simple-table>
          <template v-slot:default>
            <tbody>
              <tr>
                <td class="subtitle-2 font-weight-black text-start">
                  Total General
                </td>
                <td class="subtitle-2 font-weight-black text-end">
                  {{ currencyFormat(subtotal[item.moneda].pagar) }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { currency } from "@/services/util/number.service";
import {
  current_first_date,
  current_last_date,
} from "@/services/util/date.service";
import { dmy } from "@/services/util/date.service";
import { groupByKey } from "@/services/util/array.service";
import { exportGeneralPDF } from "./export";
export default {
  data: () => ({
    data: {
      fecha_inicio: current_first_date(),
      fecha_fin: current_last_date(),
    },
    tab: null,
    items: [],
    headers: [
      {
        text: "Factura",
        align: "start",
        sortable: false,
        width: "20%",
        value: "factura",
      },
      {
        text: "Emision",
        value: "femision",
        align: "end",
        width: "20%",
        sortable: false,
      },
      {
        text: "Vencimiento",
        value: "fvencimiento",
        align: "end",
        width: "20%",
        sortable: false,
      },
      {
        text: "Pendiente",
        value: "montopagar",
        align: "end",
        sortable: false,
        width: "10%",
      },
      {
        text: "Pagado",
        value: "montopago",
        align: "end",
        sortable: false,
        width: "10%",
      },
      {
        text: "Monto",
        value: "totalpago",
        align: "end",
        sortable: false,
        width: "10%",
      },
      {
        text: "Total",
        value: "total",
        width: "10%",
        align: "end",
      },
    ],
  }),
  mounted() {
    this.fetchGeneral(this.data);
  },
  watch: {
    getGeneral(data) {
      this.items = [];
      data.map((mn) => {
        const rawDetalle = groupByKey(mn.detalle, "ruc");
        const detalle = [];
        Object.entries(rawDetalle).map(([key, value]) => {
          const calc = value;
          calc[calc.length - 1].total = value.reduce(
            (acc, curr) => (acc = acc + curr.montopagar),
            0
          );
          detalle.push({
            cliente: value[0].nombre,
            ruc: key,
            detalle: calc,
          });
        });
        this.items.push({
          moneda: mn.moneda,
          detalle: detalle,
        });
      });
    },
  },
  computed: {
    ...mapGetters("extracto", ["isLoading", "getGeneral"]),
    subtotal() {
      let moneda = {};
      this.getGeneral.map((x) => {
        moneda[x.moneda] = {
          pagar: 0,
          pagado: 0,
          total: 0,
        };
      });
      this.getGeneral.map((mn) => {
        mn.detalle.map((x) => {
          moneda[mn.moneda].pagar = moneda[mn.moneda].pagar + x.montopagar;
          moneda[mn.moneda].pagado = moneda[mn.moneda].pagado + x.montopago;
          moneda[mn.moneda].total = moneda[mn.moneda].total + x.totalpago;
        });
      });
      return moneda;
    },
  },
  methods: {
    ...mapActions("extracto", ["fetchGeneral"]),
    currencyFormat(val) {
      if (val) return currency(val);
    },
    formatDate(date) {
      return dmy(date);
    },
    generarPdf() {
      exportGeneralPDF(
        this.items,
        this.headers,
        this.$route.name,
        this.subtotal
      );
    },
  },
};
</script>
