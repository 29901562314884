<template>
  <v-autocomplete
    :value="value"
    :id="id"
    @input="$emit('input', $event)"
    :rules="rules_active"
    :filter="customFilter"
    :dense="dense"
    type="text"
    :items="items"
    :search-input.sync="syncInput"
    :label="label"
    item-text="descripcion"
    :outlined="outlined"
    :readonly="readonly"
    :disabled="disabled"
    autocomplete="off"
    :loading="Vget_load"
    :no-data-text="`No existe ninguna concidencia`"
    v-on:change="change"
    :small-chips="chips"
    return-object
  >
    <template slot="item" slot-scope="data">
      <v-list-item-content>
        <v-list-item-title>
          {{data.item.descripcion}}
        </v-list-item-title>
      </v-list-item-content>
    </template>
    <template v-if="noData && create != false && addpermission" v-slot:no-data>
      <v-list-item @click="open_ref()" dense>
        <v-list-item-content>
          <v-list-item-title>
            No existe concidencia. Click para agregar
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>

<script>
import store from "@/store";
import { PUEDE_AGREGAR } from "@/constants/permissions";
import { getPermission } from "@/services/storage/permissionStorage.service";
export default {
  props: {
    label: String,
    value: [Object, String, Number],
    pk: String,
    title: String,
    action: String,
    getter: String,
    vuex: String,
    load: String,
    id: String,
    create: {
      type: [String, Boolean],
      default: false,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    chips: {
      type: Boolean,
      default: false,
    },
    permission: {
      type: [String, Boolean],
      default: false,
    },
    rules: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      dialog: false,
      validar: [(v) => !!v || "Seleccion un campo"],
      items: [],
      noData: true,
      syncInput: "",
    };
  },
  created() {
    store.dispatch(this.action);
  },
  watch: {
    Vget_data(val) {
      this.procesar(val);
    },
    syncInput(val) {
      this.$emit("sync-input", val);
    },
  },
  computed: {
    addpermission() {
      if (this.permission === false) return false;
      return getPermission(this.permission).permiso[PUEDE_AGREGAR];
    },
    rules_active() {
      if (this.rules) return this.validar;
      return [];
    },
    Vget_data() {
      return store.getters[this.getter];
    },
    Vget_load() {
      return store.getters[this.load];
    },
  },
  methods: {
    procesar(val) {
      this.items = [];
      if (val[0] === undefined) return null;
      val.forEach((x) => {
        this.items.push({...x});
      });
    },
    customFilter(item, queryText) {
      const textOne = item[this.$props.pk].toString();
      const textTwo = item[this.$props.title].toLowerCase();
      const searchText = queryText.toLowerCase();
      return (
        textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
      );
    },
    open_ref() {
      if (this.vuex != undefined) return this.$store.commit(this.vuex, true);
      return this.$emit("create", true);
    },
    change(evt) {
      this.$emit("change", evt);
    },
  },
};
</script>