import { get, post, put, del } from "@/services/api/api.service";
import {
  url,
  SET_LOADING,
  SET_FUCIONARIO_ACTIVE,
  SET_FUCIONARIO_INACTIVE,
  SET_ANHOS,
  SET_EMPLEADO_OBRERO,
  SET_SUELDOS_JORNALES,
  SET_RESUMEN_GENERAL,
  SET_DOCUMENTOS,
  SET_REPORTE_LOADING,
  SET_PLANILLA_SUELDO_IPS,
  SET_CARGO,
  SET_SALDOS
} from "./constants";
export default {
  async fetchFuncionario({ commit, dispatch }, { active, dateStart, dateEnd }) {
    commit(SET_LOADING, true);
    try {
      if (active) {
        let urlParams = `${url.funcionarios}?active=yes`;
        if (dateStart != null && dateEnd != null)
          urlParams = `${urlParams}&inicio=${dateStart}&fin=${dateEnd}`;
        const response = await get(urlParams);
        commit(SET_FUCIONARIO_ACTIVE, response);
      } else {
        const response = await get(`${url.funcionarios}?active=no`);
        commit(SET_FUCIONARIO_INACTIVE, response);
      }
    } catch (e) {
      dispatch("snackbar/setNotification", e, { root: true });
      commit(SET_LOADING, false);
      throw e;
    }
    commit(SET_LOADING, false);
  },
  async fetchAnhos({ commit, dispatch }) {
    commit(SET_LOADING, true);
    try {
      const response = await get(url.anhos);
      commit(SET_ANHOS, response);
    } catch (e) {
      dispatch("snackbar/setNotification", e, { root: true });
      commit(SET_LOADING, false);
      throw e;
    }
    commit(SET_LOADING, false);
  },
  async fetchDocumentos({ commit, dispatch },id) {
    commit(SET_LOADING, true);
    try {
      const response = await get(`${url.documentos}?funcionario=${id}`);
      commit(SET_DOCUMENTOS, response);
    } catch (e) {
      dispatch("snackbar/setNotification", e, { root: true });
      commit(SET_LOADING, false);
      throw e;
    }
    commit(SET_LOADING, false);
  },
  async fetchSaldos({ commit, dispatch },id) {
    commit(SET_LOADING, true);
    try {
      const response = await get(`${url.saldos}?funcionario=${id}`);
      commit(SET_SALDOS, response);
    } catch (e) {
      dispatch("snackbar/setNotification", e, { root: true });
      commit(SET_LOADING, false);
      throw e;
    }
    commit(SET_LOADING, false);
  },
  async fetchCargo({ commit, dispatch }) {
    commit(SET_LOADING, true);
    try {
      const response = await get(url.cargo);
      commit(SET_CARGO, response);
    } catch (e) {
      dispatch("snackbar/setNotification", e, { root: true });
      commit(SET_LOADING, false);
      throw e;
    }
    commit(SET_LOADING, false);
  },
  async setFuncionario({ commit, dispatch }, data) {
    commit(SET_LOADING, true);
    const response = await post(url.funcionarios, data);
    dispatch("snackbar/setNotification", response, { root: true });
    commit(SET_LOADING, false);
    return response;
  },
  async setDocumentos({ commit, dispatch }, data) {
    commit(SET_LOADING, true);
    const response = await post(url.documentos, data);
    dispatch("snackbar/setNotification", response, { root: true });
    commit(SET_LOADING, false);
    return response;
  },
  async setDocumentosUpdate({ commit, dispatch }, { data, id }) {
    commit(SET_LOADING, true);
    const response = await put(`${url.documentos}${id}/`, data);
    dispatch("snackbar/setNotification", response, { root: true });
    commit(SET_LOADING, false);
    return response;
  },
  async setDocumentosDelete({ commit, dispatch }, id) {
    commit(SET_LOADING, true);
    const response = await del(`${url.documentos}${id}/`);
    dispatch("snackbar/setNotification", response, { root: true });
    commit(SET_LOADING, false);
    return response;
  },
  async setFuncionarioUpdate({ commit, dispatch }, { data, id }) {
    commit(SET_LOADING, true);
    const response = await put(`${url.funcionarios}${id}/`, data);
    dispatch("snackbar/setNotification", response, { root: true });
    commit(SET_LOADING, false);
    return response;
  },
  
  async setFuncionarioDelete({ commit, dispatch }, id) {
    commit(SET_LOADING, true);
    const response = await del(`${url.funcionarios}${id}/`);
    dispatch("snackbar/setNotification", response, { root: true });
    commit(SET_LOADING, false);
    return response;
  },

  async fetchEmpleadosObreros({ commit, dispatch }, year) {
    commit(SET_REPORTE_LOADING, true);
    try {
      const response = await get(`${url.empleadoAndObrero}${year}/`);
      commit(SET_EMPLEADO_OBRERO, response);
    } catch (e) {
      dispatch("snackbar/setNotification", e, { root: true });
      commit(SET_REPORTE_LOADING, false);
      throw e;
    }
    commit(SET_REPORTE_LOADING, false);
  },
  async fetchPlanillaIps({ commit, dispatch }, {year,month}) {
    commit(SET_REPORTE_LOADING, true);
    try {
      const response = await get(`${url.sueldosAndJornalesIps}${year}/${month}/`);
      commit(SET_PLANILLA_SUELDO_IPS , response);
    } catch (e) {
      dispatch("snackbar/setNotification", e, { root: true });
      commit(SET_REPORTE_LOADING, false);
      throw e;
    }
    commit(SET_REPORTE_LOADING, false);
  },
  async fetchResumenGeneral({ commit, dispatch }, year) {
    commit(SET_REPORTE_LOADING, true);
    try {
      const response = await get(`${url.resumenGeneral}${year}/`);
      commit(SET_RESUMEN_GENERAL, response);
    } catch (e) {
      dispatch("snackbar/setNotification", e, { root: true });
      commit(SET_REPORTE_LOADING, false);
      throw e;
    }
    commit(SET_REPORTE_LOADING, false);
  },
  async fetchSueldoJornales({ commit, dispatch }, year) {
    commit(SET_REPORTE_LOADING, true);
    try {
      const response = await get(`${url.sueldosAndJornales}${year}/`);
      commit(SET_SUELDOS_JORNALES, response);
    } catch (e) {
      dispatch("snackbar/setNotification", e, { root: true });
      commit(SET_REPORTE_LOADING, false);
      throw e;
    }
    commit(SET_REPORTE_LOADING, false);
  },
};
