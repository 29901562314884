<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :mobile-breakpoint="0"
    :items-per-page="1000"
    hide-default-footer
  >
    <template v-slot:[`item.cantidad`]="{ item }">
      {{ currencyFormat(item.cantidad) }}
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <c-btn-table-edit  @click="$emit('edit', item)"></c-btn-table-edit>
      <c-btn-table-delete @click="$emit('delete', item)"></c-btn-table-delete>
    </template>
  </v-data-table>
</template>
<script>
import { currency } from "@/services/util/number.service";
export default {
  props: {
    items: Array,
    readonly: Boolean,
    parcela: Boolean
  },
  data: () => ({
    headers: [
      {
        text: "Producto",
        align: "start",
        sortable: false,
        value: "iditem.descitem",
      },
      { text: "Cant.", sortable: false, value: "cantdetracion" },
      { text: "Accion", value: "actions", sortable: false, align: "right" },
    ],
  }),
  
  methods: {
    currencyFormat(value) {
      return currency(Number(value));
    },
  },
};
</script>
