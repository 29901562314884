export default {
  tipoajuste_create(state, request) {
    state.tipoajuste_create = request;
  }, 
  tipoajuste_update(state, request) {
    state.tipoajuste_update = request;
  },   
  tipoajuste_reload(state, request) {
    state.tipoajuste_reload = request;
  },
  tipoajuste_show(state, request) {
    state.tipoajuste_show = request;
  }
}