<template>
    <v-btn color="error" small dark @click="$emit('click')" :to="to">
        <v-icon dark>list</v-icon>
    </v-btn>
</template>
  
<script>
export default {
    props: {
        to: String,
    },
};
</script>
  