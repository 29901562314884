<template>
  <div>
    <v-dialog
      :value="value"
      max-width="1000px"
      persistent
      @keydown.esc="close()"
    >
      <v-card>
        <v-card-title>Detalle de Cobro</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="form" lazy-validation>
            <v-row dense>
              <v-col cols="12" sm="6" md="6">
                <c-text-date
                  :readonly="true"
                  outlined
                  dense
                  label="Fecha"
                  v-model="data.fechreciboventa"
                ></c-text-date>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <vue-text-field
                  label="Recibo Numero"
                  v-model="data.nroreciboventa"
                  readonly
                ></vue-text-field>
              </v-col>
              <v-col cols="12" md="7" sm="12">
                <vue-text-field
                  label="Cliente"
                  v-model="data.idcontacto.idcontacto.descontacto"
                  :rules="false"
                  readonly
                ></vue-text-field>
              </v-col>
              <v-col cols="10" md="4" sm="10">
                <vue-text-field
                  label="Moneda"
                  :rules="false"
                  v-model="data.idmoneda.descmoneda"
                  readonly
                ></vue-text-field>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="12">
                <v-data-table
                  :headers="headers"
                  :items="data.detrecibo"
                  item-key="idcuentaventa"
                  :single-expand="true"
                  :expanded.sync="expanded"
                  show-expand
                  :items-per-page="100"
                  hide-default-footer
                >
                  <template v-slot:expanded-item="{ item }">
                    <td :colspan="headers.length">
                      <v-data-table
                        dense
                        :headers="subheaders"
                        item-key="idcuotaventa"
                        :items="item.detalle"
                        hide-default-footer
                        :items-per-page="100"
                      >
                        <template v-slot:[`item.montocuotacuenta`]="{ item }">
                          <div>{{ toCurrency(item.montocuotacuenta) }}</div>
                        </template>
                        <template v-slot:[`item.accion`]="{ item }">
                          <v-simple-checkbox
                            dense
                            :ripple="false"
                            v-model="item.estadocobro"
                          ></v-simple-checkbox>
                        </template>
                      </v-data-table>
                    </td>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <v-row
              align="center"
              class="ma-2"
              v-if="data.cuentaventapagocaja.length > 0"
            >
              <vue-text-currency
                :dense="false"
                :outlined="false"
                readonly
                label="Cobro Parcial"
                v-model="data.cuentaventapagocaja[0].entrega"
              ></vue-text-currency>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card
          outlined
          class="ma-4"
          :color="!$vuetify.theme.dark ? 'blue lighten-5' : 'dark'"
          v-if="data.cuentaventapagocaja.length > 0"
        >
          <Venta-CobroCuenta-FormaPago
            class="ma-4"
            :montoCobrar="total"
            :datos="{
              efectivo: {
                monto: data.cuentaventapagocaja[0].monto,
                isEfectivo:
                  data.cuentaventapagocaja[0].monto > 0 ? true : false,
              },
              cheque: data.cuentaventapagocaja[0].cuentaventapagocheque,
              tarjeta: data.cuentaventapagocaja[0].cuentaventapagotarjeta,
            }"
            readonly
          />
        </v-card>

        <v-col cols="12">
          <vue-text-field
            :rules="false"
            readonly
            label="Comentario"
            v-model="data.descreciboventa"
          ></vue-text-field>
        </v-col>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn @click="close()" color="red" text>
            <v-icon>clear</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
        <c-overlay :value="Vget_cobrocuenta_load"></c-overlay>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { currency } from "@/services/util/number.service";
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    datos: {
      type: [Object, Array],
      default: null,
    },
  },
  watch: {
    $route(to, from) {
      if (from.path === "/cobrocuenta") return this.close();
    },

    Vget_cobrocuenta_data_id(val) {
      this.data = JSON.parse(JSON.stringify(val));
    },
  },
  mounted() {
    this.Vact_cobrocuenta_data_id(this.datos.idreciboventa);
  },
  computed: {
    ...mapGetters("cobrocuenta", [
      "Vget_cobrocuenta_data_id",
      "Vget_cobrocuenta_load",
    ]),
    changemonto() {
      var sum = 0;
      this.data.cuentaventapagocaja.forEach((x) => {
        if (x.cuentaventapagocheque.length > 0) {
          x.cuentaventapagocheque.forEach((y) => {
            sum = sum + Number(y.monto);
          });
        }
        if (x.cuentaventapagotarjeta.length > 0) {
          x.cuentaventapagotarjeta.forEach((y) => {
            sum = sum + Number(y.monto);
          });
        }
        sum = sum + Number(x.monto);
      });
      return sum;
    },

    total() {
      let total = 0;
      this.data.detrecibo.forEach((x) => {
        x.detalle.forEach((y) => {
          total = Number(total) + Number(y.montocuotacuenta);
        });
      });
      return Number(total.toFixed(2));
    },
    diferenciamonto() {
      if (this.total === 0) {
        this.$emit("diferenciamonto", -1);
        return 0;
      }
      const diferencia = this.changemonto - this.total;
      this.$emit("diferenciamonto", diferencia);
      console.log(this.changemonto);
      return diferencia;
    },
  },
  methods: {
    ...mapActions("cobrocuenta", ["Vact_cobrocuenta_data_id"]),
    toCurrency(value) {
      return currency(value);
    },
    close() {
      this.limpiar();
      this.$emit("input", false);
    },
    limpiar() {
      this.data = JSON.parse(JSON.stringify(this.default));
    },
  },
  data() {
    return {
      cotizacionerror: null,
      data: {
        idmoneda: {
          idmoneda: "",
        },
        idcontacto: {
          idcontacto: {
            desccontacto: "Hola",
          },
        },
        detrecibo: [],
        cuentaventapagocaja: [],
      },
      default: {
        idmoneda: {
          idmoneda: "",
        },
        idcontacto: {
          idcontacto: {
            desccontacto: "Hola",
          },
        },
        detrecibo: [],
        cuentaventapagocaja: [],
      },
      keyFormaPago: 1,
      idmoneda: "",
      selected: [],
      detrecibo: [],
      expanded: [],
      headers: [
        {
          text: "Factura",
          align: "left",
          sortable: false,
          value: "factura",
        },
        { text: "", value: "data-table-expand" },
      ],
      subheaders: [
        {
          text: "Vencimiento",
          value: "fechavence",
        },
        {
          text: "Monto",
          align: "end",
          value: "montocuotacuenta",
        },
      ],
    };
  },
};
</script>
