<template>
  <div>
    <v-dialog :value="value" max-width="700px" persistent @keydown.esc="cancelar()">
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>{{operacion ? 'Agregar Establecimiento' : 'Modificar Establecimiento'}}</v-toolbar-title>
        </v-toolbar>

        <v-form ref="form" lazy-validation>
          <v-container>
            <v-row dense>
              <v-col cols="12">
                <vue-text-field
                  label="Descripcion"
                  id="estaInput1"
                  v-model="data.descestablecimiento"
                  @keyup.native.enter="next(1,data.descestablecimiento)"
                />
              </v-col>
              <v-col cols="12">
                <vue-text-field
                  label="Establecimiento"
                  id="estaInput2"
                  v-model="data.establecimiento"
                  @keyup.native.enter="next(2,data.establecimiento)"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
        <v-card-actions>
          <v-btn color="red" text @click="close()">
            <v-icon dark>close</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn id="estaInputaceptar" color="primary" text @click="guardar()">
            <v-icon dark>done</v-icon>
          </v-btn>
        </v-card-actions>
        <c-overlay :value="Vget_establecimiento_save_load"></c-overlay>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { focus } from "@/services/focus.service";
import { mapActions, mapGetters } from "vuex";
import { mensaje } from "@/services/notify.service";
export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    datos: {
      type: [Object, Array],
      default: null,
    },
    "sync-input": {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      data: {
        descestablecimiento: '',
        establecimiento: ''
      },
      default: {},
      id: "",
      operacion: true,
    };
  },
  watch: {
    $route(to, from) {
      if (from.path === "/establecimiento") return this.close();
    },
  },
  computed: {
    ...mapGetters("establecimiento", ["Vget_establecimiento_save_load"]),
  },
  created() {
    this.update(this.datos);
  },
  mounted() {
    if (this.syncInput != null) this.data.descestablecimiento = this.syncInput;
    this.next(0, "-");
  },
  methods: {
    ...mapActions("establecimiento", [
      "Vact_establecimiento_data",
      "Vact_establecimiento_save",
      "Vact_establecimiento_update",
    ]),
    close() {
      this.data = JSON.parse(JSON.stringify(this.default));
      this.$emit("datos", null);
      this.$emit("input", false);
      this.Vact_establecimiento_data();
      this.operacion = true;
    },
    update(data) {
      if (data === null) return null;
      this.data = JSON.parse(JSON.stringify(data));
      this.operacion = false;
      this.id = this.data.idestablecimiento;
    },
    next(id, data) {
      focus.nextidName("estaInput", id, data);
    },
    async guardar_finalizar() {
      const sync = this.syncInput;
      let a = await this.Vact_establecimiento_save(this.data);
      if (!a.estado) {
        a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
        return false;
      }
      if (sync != null) {
        this.close();
        return this.$emit("sync-input", a.data.data.id);
      }
      this.data = JSON.parse(JSON.stringify(this.default));
      this.$notify(a.info);
      this.$refs.form.resetValidation();
      this.next(0, "-");
      return true;
    },
    async editar_finalizar() {
      let a = await this.Vact_establecimiento_update({
        id: this.id,
        data: this.data,
      });
      if (!a.estado) {
        return a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
      this.$notify(a.info);
    },
    async guardar() {
      if (!this.$refs.form.validate()) return this.$notify(mensaje.completar());
      if (this.operacion) return await this.guardar_finalizar();
      if (!this.operacion) return this.editar_finalizar();
    },
  },
};
</script>