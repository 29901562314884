export default {
  cuentadiario_data(state, request) {
    state.cuentadiario_data = request;
  }, 
  cuentadiario_load(state, request) {
    state.cuentadiario_load = request;
  }, 
  cuentadiario_save_load(state, request) {
    state.cuentadiario_save_load = request;
  }, 
}