<template>
    <div>
        <div v-if="crud.add">
            <Agro-Transaccion-Recetario-Create 
                v-model="crud.add" 
                :datos="crud.datos" 
                :readonly="crud.readonly" 
                @datos="crud.datos = $event" 
                @readonly="crud.readonly = $event" 
            />
        </div>
        <v-alert type="error" v-if="permission_alert">Error!!! Este usuario no tiene permisos en esta ventana</v-alert>
        <v-card>
            <v-toolbar flat dense>
                <v-toolbar-title>{{ $route.name }}</v-toolbar-title>
                <widgets-Favorite :ruta="$route.path" :formulario="$route.name" />
                <v-divider class="mx-2" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <c-text-table-search v-if="permission.can_view" v-model="search"></c-text-table-search>
                <v-toolbar-items>
                    <c-btn-table-add class="mr-1" @click="crud.add = true" v-if="permission.can_add"></c-btn-table-add>
                    <c-btn-table-reload @click="Vact_recetario_data({zafra: null, lote: null})"></c-btn-table-reload>
                </v-toolbar-items>
            </v-toolbar>
            <v-data-table :loading="Vget_recetario_load" :headers="headers" :items="Vget_recetario_data"
                :search="search" v-if="permission.can_view">
                <template v-slot:[`item.estado`]="props">
                    <v-chip :color="colorb(props.item.estado)" dark>
                        {{ activo(props.item.estado) }}
                    </v-chip>
                </template>
                <template v-slot:[`item.accion`]="props">
                    <c-btn-table-view
                        @click="rowselect(props.item)"
                        v-if="permission.can_view"
                    ></c-btn-table-view>
                    <c-btn-table-edit
                        @click="editar(props.item)"
                        v-if="permission.can_update"
                    ></c-btn-table-edit>
                    <c-btn-table-delete
                        @click="eliminar(props.item)"
                        v-if="permission.can_delete"
                    ></c-btn-table-delete>
                </template>
            </v-data-table>
        </v-card>
        <v-dialog v-model="crud.delete" persistent max-width="400">
            <v-card>
                <v-card-title>
                    <v-icon left>info</v-icon>
                    <span class="subtitle-1 font-weight-light">Ventana de Confirmacion</span>
                </v-card-title>
                <v-form ref="form" @submit.prevent="eliminarRecetario()">
                    <v-card-subtitle>
                        Desea eliminar el recetario {{ objeto.descripcion }}?
                    </v-card-subtitle>
                </v-form>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn color="red" text="text" @click="crud.delete = false">
                        <v-icon>clear</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text="text" @click="eliminarRecetario()">
                        <v-icon>done</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
            <c-overlay :value="overlay" absolute></c-overlay>
        </v-dialog>
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { mensaje } from "@/services/notify.service";
import { RECETARIO } from "@/constants/forms";
import {
    PUEDE_AGREGAR,
    PUEDE_EDITAR,
    PUEDE_ELIMINAR,
    PUEDE_LISTAR,
} from "@/constants/permissions";
export default {
    data() {
        return {
            search: "",
            headers: [
                { text: "Codigo", align: "left", value: "idrecetario" },
                { text: "Zafra", align: "left", value: "idcampana.descripcion" },
                { text: "Lote", align: "left", value: "idlote_agri.campo" },
                { text: "Comentario", align: "left", value: "comentario" },
                { text: "Estado", align: "right", value: "estado" },
                { text: "Accion", value: "accion", sortable: false, align: "right" },
            ],
            crud: {
                add: false,
                delete: false,
                readonly: false,
                operacion: true,
                datos: null,
            },
            overlay: false,
            objeto: {
                descripcion: "",
            },
            permission: {
                can_add: false,
                can_view: false,
                can_update: false,
                can_delete: false,
            }
        }
    },
    created() {
        this.$store.commit("SET_LAYOUT", "principal-layout");
        this.Vact_recetario_data({zafra: null, lote: null});
        const permission = getPermission(RECETARIO).permiso;
        this.permission = {
            can_add: permission[PUEDE_AGREGAR],
            can_view: permission[PUEDE_LISTAR],
            can_update: permission[PUEDE_EDITAR],
            can_delete: permission[PUEDE_ELIMINAR],
        };
    },
    computed: {
        ...mapGetters("recetario", ["Vget_recetario_data", "Vget_recetario_load"]),
        permission_alert() {
            return mensaje.permission(this.permission);
        },
    },
    methods: {
        ...mapActions("recetario", ["Vact_recetario_data", "Vact_recetario_load", "Vact_recetario_delete"]),
        editar(item) {
            this.crud.datos = JSON.parse(JSON.stringify(item));
            this.crud.add = true;
        },
        eliminar(item) {
            this.crud.delete = true;
            this.objeto = item;
        },
        async eliminarRecetario() {
            this.overlay = true;
            const id = this.objeto.idrecetario;
            const a = await this.Vact_recetario_delete(id);
            if (a.estado == true) {
                this.$notify(a.info);
                this.eliminarCancelar();
                this.Vact_recetario_data({zafra: null, lote: null});
            } else {
                a.info.map((x) => {
                    this.$notify(mensaje.error(x));
                });
            }
        },
        eliminarCancelar() {
            this.objeto.descripcion = "";
            this.crud.delete = false;
        },
        rowselect(item) {
            this.crud.datos = JSON.parse(JSON.stringify(item));
            this.crud.readonly = true;
            this.crud.add = true;
        },
        colorb(val) {
            if (val === true) {
                return "green";
            }
            return "red";
        },
        activo(val) {
            if (val === true) {
                return "Activo";
            }
            return "Inactivo";
        }
    },
}
</script>