import { db } from "@/services/db.service";
export default {
    async egresoagro_update({commit},request){
        commit('egresoagro_update',request);
    },
    async Vact_egresoagro_data({commit}){
        commit('egresoagro_load',true);
        try {
            const data = await db.get('/apiegresoagro/');
            commit('egresoagro_data',data);
            commit('egresoagro_load',false);
            return true
        } catch (e) {
            commit('egresoagro_load',false);
            return false
        } 
    },
    async Vact_egresoagro_data_byId({commit},id){
        commit('egresoagro_load',true);
        try {
            const data = await db.get('/apiegresoagro/'+id+'/');
            commit('egresoagro_data_byId',data);
            commit('egresoagro_load',false);
            return true
        } catch (e) {
            commit('egresoagro_load',false);
            return false
        } 
    },
    async Vact_egresoagro_del({dispatch},id){
        try {
            const data = await db.delete('/apiegresoagro/'+ id+ '/');
            dispatch('Vact_egresoagro_data');
            return data
        } catch (e) {
            return false
        } 
    },
    async Vact_egresoagro_save({commit},data){
        commit('egresoagro_save_load',true);
        try {
            const resp = await db.save('/apiegresoagro/',data);
            commit('egresoagro_save_load',false);
            return resp;
        } catch (e) {
            commit('egresoagro_save_load',false);
            return e
        } 
    },
    async Vact_egresoagro_update({commit},{id, data}){
        commit('egresoagro_save_load',true);
        try {
            const resp = await db.update('/apiegresoagro/'+id+'/',data);
            commit('egresoagro_save_load',false);
            return resp;
        } catch (e) {
            commit('egresoagro_save_load',false);
            return e
        } 
    },
}
