<template>
  <div>
    <div v-if="formularios.deposito">
      <Item-Deposito-Create v-model="formularios.deposito" />
    </div>
    <div v-if="abrirmotivoajuste">
      <Stock-Motivoajuste-Create />
    </div>
    <div v-if="abrirtipoajuste">
      <Stock-Tipoajuste-Create />
    </div>
    <v-dialog :value="value" max-width="700px" persistent @keydown.esc="cancelar()">
      <v-card>
        <v-form ref="form" lazy-validation>
          <v-toolbar flat>
           
            <v-toolbar-title> Registrar Ajuste de Stock</v-toolbar-title>
            
          </v-toolbar>
          <v-card-text>
            <v-row row dense>
              <v-col cols="12">
                <c-text-date
                  label="Fecha de registro"
                  id="input0"
                  v-model="data.fechajustestock"
                  dense
                  outlined
                  @keyup.native.enter="nextfocus(0,data.fechajustestock)"
                />
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  dense
                  v-model="data.iditem.iditem"
                  id="input1"
                  :rules="validars"
                  :search-input.sync="searchitem"
                  :filter="customFilter"
                  v-bind:items="item"
                  fkdes="item"
                  label="Seleccione un item"
                  item-text="descitem"
                  item-value="iditem"
                  outlined
                  :loading="Vget_item_load"
                  height="12"
                  autocomplete="off"
                  @keyup.enter="nextfocus(1,data.iditem.iditem)"
                  :no-data-text="`No existe ninguna concidencia`"
                  small-chips
                ></v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  dense
                  v-model="data.idmotivoajuste.idmotivoajuste"
                  id="input2"
                  :rules="validars"
                  v-bind:items="motivoajuste"
                  fkdes="item"
                  label="Seleccione un motivo"
                  item-text="fkdes"
                  item-value="id"
                  outlined
                  height="12"
                  autocomplete="off"
                  :loading="motivoajuste_load"
                  @keyup.enter="nextfocus(2,data.idmotivoajuste.idmotivoajuste)"
                  :no-data-text="`No existe ninguna concidencia`"
                  small-chips
                >
                  <template v-if="noData && motivo_permission" v-slot:no-data>
                    <v-list-item @click="motivoajuste_create(true)">
                      <v-list-item-content>
                        <v-list-item-title>No existe concidencia. Click para agregar</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  dense
                  v-model="data.idtipoajuste.idtipoajuste"
                  id="input3"
                  :rules="validars"
                  v-bind:items="tipoajuste"
                  fkdes="item"
                  label="Seleccione un tipo"
                  item-text="fkdes"
                  item-value="id"
                  outlined
                  height="12"
                  autocomplete="off"
                  :loading="tipoajuste_load"
                  @keyup.enter="nextfocus(3,data.idtipoajuste.idtipoajuste)"
                  :no-data-text="`No existe ninguna concidencia`"
                  small-chips
                >
                  <template v-if="noData && tipoajuste_permission" v-slot:no-data>
                    <v-list-item @click="tipoajuste_create(true)">
                      <v-list-item-content>
                        <v-list-item-title>No existe concidencia. Click para agregar</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12">
                <vue-autocomplete-generic
                  id="input4"
                  action="deposito/Vact_deposito_data"
                  getter="deposito/Vget_deposito_data"
                  load="deposito/Vget_deposito_load"
                  pk="iddeposito"
                  label="Deposito"
                  title="desdeposito"
                  :create="true"
                  @create="formularios.deposito = $event"
                  permission="Deposito"
                  v-model="data.iddeposito.iddeposito"
                  @keyup.native.enter="nextfocus(4,data.iddeposito.iddeposito)"
                />
              </v-col>
              <v-col cols="12">
                <vue-text-currency
                  id="input5"
                  v-model="data.cantajustestock"
                  label="Cantidad"
                  @keyup.native.enter="nextfocus(5,data.cantajustestock)"
                ></vue-text-currency>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-btn color="red" text @click="cancelar">
              <v-icon dark>close</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn id="aceptar" color="primary" text @click="guardar">
              <v-icon dark>done</v-icon>
            </v-btn>
          </v-card-actions>
        </v-form>
        <c-overlay :value="Vget_ajuste_save_load"></c-overlay>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { db } from "@/services/db.service";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { current_date } from "@/services/util/date.service";
import { mensaje } from "@/services/notify.service";
import { MOTIVO_AJUSTE, TIPO_AJUSTE } from "@/constants/forms";
import { PUEDE_AGREGAR } from "@/constants/permissions";
export default {
  props:{
    value:{
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      overlay: false,
      item_load: true,
      step: 1,
      noData: true,
      searchitem: "",
      awaitingSearch: false,
      data: {
        fechajustestock: current_date(),
        idajustestock: 0,
        cantajustestock: "",
        iditem: {
          iditem: 0
        },
        idmotivoajuste: {
          idmotivoajuste: 0
        },
        idtipoajuste: {
          idtipoajuste: 0
        },
        iddeposito: {
          iddeposito: 0
        }
      },
      default: {
        fechajustestock: current_date(),
        idajustestock: 0,
        cantajustestock: "",
        iditem: {
          iditem: 0
        },
        idmotivoajuste: {
          idmotivoajuste: 0
        },
        idtipoajuste: {
          idtipoajuste: 0
        },
        iddeposito: {
          iddeposito: 0
        }
      },
      validar: [v => !!v || "Campo requerido"],
      validars: [v => !!v || "Seleccione un campo"],
      menu1: false,
      reload: 0,
      operacion: true,
      item: [],
      motivoajuste_load: true,
      motivoajuste: [],
      tipoajuste_load: true,
      tipoajuste: [],
      formularios:{
        deposito: false
      }
    };
  },
  watch: {
    searchitem() {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.obtain_item();
          this.awaitingSearch = false;
        }, 1000)
      }
      this.awaitingSearch = true;
    },
    Vget_item_stockeable(val) {
      this.item = []
      this.item = JSON.parse(JSON.stringify(val));
      if (this.searchItemId != null) return this.search()
    },
    searchItemId() {
      this.search();
    },
    $route(to, from) {
      if (from.path === "/ajuste") return this.cancelar();
    },
    get_motivoajuste_reload(val) {
      if (val === true) {
        this.nuevo_motivoajuste();
      }
    },
    get_tipoajuste_reload(val) {
      if (val === true) {
        this.nuevo_tipoajuste();
      }
    },
  },
  computed: {
    ...mapGetters("ajuste", {
      getupdate: "ajuste_update"
    }),
    ...mapGetters("ajuste",["Vget_ajuste_save_load"]),
    ...mapGetters("motivoajuste", {
      abrirmotivoajuste: "motivoajuste_create",
      get_motivoajuste_reload: "motivoajuste_reload"
    }),
    ...mapGetters("tipoajuste", {
      abrirtipoajuste: "tipoajuste_create",
      get_tipoajuste_reload: "tipoajuste_reload"
    }),
    ...mapGetters("item",["Vget_item_stockeable","Vget_item_load"]),

    motivo_permission() {
      return getPermission(MOTIVO_AJUSTE).permiso[PUEDE_AGREGAR];
    },
    tipoajuste_permission() {
      return getPermission(TIPO_AJUSTE).permiso[PUEDE_AGREGAR];
    },
  },
  created() {
    this.nuevo(this.getupdate);
  },
  mounted() {
    setTimeout(() => document.getElementById("input0").focus(), 500);
  },
  methods: {
    ...mapActions("ajuste", [
      "ajuste_update",
      "Vact_ajuste_data",
      "Vact_ajuste_save",
      "Vact_ajuste_load"
    ]),
    ...mapActions("motivoajuste", [
      "motivoajuste_create",
      "motivoajuste_reload"
    ]),
    ...mapActions("tipoajuste", ["tipoajuste_create", "tipoajuste_reload"]),
    ...mapActions("item", ["Vact_item_stockeable"]),
    ...mapActions("stock", ["Vact_stock_data"]),

    obtain_item() {
      if (this.searchitem != null && this.searchitem.length >= 3) {
        this.Vact_item_stockeable({
          desc: this.searchitem
        })
      }
    },

    customFilter(item, queryText) {
      const textOne = item.iditem.toString();
      const textTwo = item.descitem.toLowerCase();
      const searchText = queryText.toLowerCase();
      return (
        textOne.indexOf(searchText) > -1 ||
        textTwo.indexOf(searchText) > -1
      );
    },

    search() {
      this.item.forEach((x) => {
        if (x.iditem.iditem === this.searchItemId) {
          return;
        }
      })
    },

    nextfocus(index, data) {
      if (data != null && data != "") {
        try {
          index = index + 1;
          document.getElementById(`input` + index).focus();
        } catch (e) {
          document.getElementById("aceptar").focus();
        }
      } else {
        document.getElementById(`input` + index).focus();
      }
    },

    cancelar() {
      this.data = JSON.parse(JSON.stringify(this.default));
      this.$emit('input',false);
      this.ajuste_update(null);
      this.Vact_ajuste_data();
      this.Vact_stock_data();
      this.operacion = true;
    },
    async guardar_finalizar() {
      let a = await this.Vact_ajuste_save(this.data)//db.save(this.url, this.data);
      if (a.estado == true) {
        this.data = JSON.parse(JSON.stringify(this.default));
        this.$notify(a.info);
        this.$refs.form.resetValidation();
        setTimeout(() => document.getElementById("input0").focus(), 500);
      } else {
        a.info.map(x => {
          this.$notify(mensaje.error(x));
        });
      }
      this.overlay = false;
    },
    async editar_finalizar() {
      const id = this.getupdate.idajuste;
      let a = await db.update(`${this.url}${id}/`, this.data);
      if (a.estado == true) {
        this.$notify(a.info);
        this.cancelar();
      } else {
        a.info.map(x => {
          this.$notify(mensaje.error(x));
        });
      }
      this.overlay = false;
    },
    guardar() {
      if (this.$refs.form.validate()) {
        this.overlay = true;
        if (this.operacion === true) return this.guardar_finalizar();
        if (this.operacion === false) return this.editar_finalizar();
      } else {
        this.$notify(mensaje.completar());
      }
    },

    nuevo_motivoajuste() {
      db.get("/apimotivoajuste/").then(a => {
        a.map(element => {
          this.motivoajuste.push({
            id: element.idmotivoajuste,
            fkdes: element.descmotivoajuste
          });
        });
      });
      this.motivoajuste_load = false;
      this.motivoajuste_reload(false);
    },
    nuevo_tipoajuste() {
      db.get("/apitipoajuste/").then(a => {
        a.map(element => {
          this.tipoajuste.push({
            id: element.idtipoajuste,
            fkdes: element.desctipoajuste
          });
        });
      });
      this.tipoajuste_load = false;
      this.tipoajuste_reload(false);
    },
    nuevo(val) {
      this.Vact_item_stockeable();
      this.nuevo_motivoajuste();
      this.nuevo_tipoajuste();
      if (val !== null) {
        this.data = val;
        this.operacion = false;
      }
    }
  }
};
</script>