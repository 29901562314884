<template>
    <div>
        <InputAutocomplete :label="label" item-value="idfinanza_grupo" item-text="nombre" :id="id" :ref="id" dense :outlined="outlined"
            :rounded="rounded" :filled="filled" :readonly="readonly" :value="value" :return-object="returnObject"
            :items="getGrupo" :multiple="multiple" :loading="isLoadingGrupo" :permission="permission"
            @sync-input="formulario.search = $event" @create="formulario.crud = true" @input="$emit('input', $event)" />
        <Crud v-if="formulario.crud" v-model="formulario.crud" :sync-input="formulario.search"
            @sync-input="syncInputHandler($event)" />
    </div>
</template>

<script>
import InputAutocomplete from "@/ncomponents/InputAutocomplete";
import { mapActions, mapGetters } from "vuex";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { FINANZA } from "@/constants/forms";
import { PUEDE_AGREGAR } from "@/constants/permissions";
import Crud from "./Crud.vue";
export default {
    props: {
        id: String,
        value: [String, Object, Number],
        returnObject: Boolean,
        readonly: Boolean,
        rounded: Boolean,
        filled: Boolean,
        multiple: Boolean,
        outlined: {
            type: Boolean,
            default: true
        },
        label: {
            type: String,
            default: 'Grupo'
        }
    },
    components: {
        InputAutocomplete,
        Crud,
    },
    data: () => ({
        formulario: {
            crud: false,
            search: "",
        }
    }),
    computed: {
        ...mapGetters("finanza", ["getGrupo", "isLoadingGrupo"]),
        permission: () => getPermission(FINANZA).permiso[PUEDE_AGREGAR],
    },
    mounted() {
        this.fetchGrupo()
    },
    methods: {
        ...mapActions("finanza", ["fetchGrupo"]),
        syncInputHandler(event) {
            if (this.returnObject) this.$emit("input", event);
            if (!this.returnObject) this.$emit("input", event.idfinanza_grupo);
            this.$emit("nextfocus");
            this.fetchGrupo();
        },
    }
}
</script>