import { get, post } from "@/services/api/api.service";
import { SET_LOADING, SET_MAQUINARIA_CONFIGURACION, url } from './constants'
export default {
    async fetchMaquinariaConfiguracion({ commit, dispatch }) {
        commit(SET_LOADING, true);
        try {
            const response = await get(url);
            commit(SET_MAQUINARIA_CONFIGURACION, response);
        } catch (e) {
            dispatch('snackbar/setNotification', e, { root: true });
            commit(SET_LOADING, false);
            throw e
        }
        commit(SET_LOADING, false);
    },
    async setMaquinariaConfiguracion({ commit, dispatch }, data) {
        commit(SET_LOADING, true);
        const response = await post(url, data);
        dispatch('snackbar/setNotification', response, { root: true })
        commit(SET_LOADING, false);
        return response
    },
}
