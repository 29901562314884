
export default {

  Vget_stock_data: (state) => {
    return state.stock_data
  },
  Vget_stock_load: (state) => {
    return state.stock_load
  },
}
