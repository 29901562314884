export default {
    async diagnosticoutero_create({commit},request){
        commit('diagnosticoutero_create',request);
    },
    async diagnosticoutero_update({commit},request){
        commit('diagnosticoutero_update',request);
    },
    async diagnosticoutero_reload({commit},request){
        commit('diagnosticoutero_reload',request);
    },
    async diagnosticoutero_show({commit},request){
        commit('diagnosticoutero_show',request);
    },
    async diagnosticoutero_key({commit},request){
        commit('diagnosticoutero_key',request);
    },
}
