<template>
  <div>
    <v-card>
    <v-toolbar flat dense>
      <v-toolbar-title>Diario de Caja</v-toolbar-title>

      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-tabs
          right
          :background-color="darkMode ? 'black' : 'white'"
          color="deep-purple accent-4"
          v-model="tab"
        >
          <v-tab
            v-for="(item, i) of tabs"
            :key="i"
            @click="toRoute(item.route)"
            >{{ item.title }}</v-tab
          >
          <v-tab
            v-for="(item, i) of more"
            :key="'a'+i"
            @click="toRoute(item.route)"
            >{{ item.title }}</v-tab
          >
     
        </v-tabs>
      </v-toolbar-items>
    </v-toolbar>
    <v-divider></v-divider>
    <router-view></router-view>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tab1: null,
      tab: null,
      more: [
        {
          title: "Reportes",
          route: "/diariocaja/reporteavanzado",
        },
      ],
      tabs: [
        {
          title: "Asiento",
          route: "/diariocaja/asiento",
        },
        {
          title: "Referencia",
          route: "/diariocaja/referencia",
        },
        {
          title: "Rubro",
          route: "/diariocaja/rubro",
        },
        {
          title: "Division",
          route: "/diariocaja/division",
        },
        {
          title: "Cuenta",
          route: "/diariocaja/cuentadiario",
        },
      ],
      reportes: [
        {
          title: "Reporte",
          route: "/diariocaja/reporteavanzado",
        },
      ],
    };
  },
  created() {
    this.$store.commit("SET_LAYOUT", "principal-layout");
  },
  mounted() {
    this.tab = this.$route.meta.tab;
  },
  computed: {
    darkMode() {
      return this.$vuetify.theme.dark;
    },
  },
  methods: {
    toRoute(path) {
      if (this.$router.history.current.path != path)
        this.$router.push({ path });
    },
    addToMore(item) {
      this.more = [];
      this.more.push(item);
      this.toRoute(item.route);
      this.tab = 5;
    },
  },
};
</script>

<style>
</style>