export default {
    async proveedor_create({commit},request){
        commit('proveedor_create',request);
    },
    async proveedor_update({commit},request){
        commit('proveedor_update',request);
    },
    async proveedor_reload({commit},request){
        commit('proveedor_reload',request);
    },
    async proveedor_show({commit},request){
        commit('proveedor_show',request);
    },
}
