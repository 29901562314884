import { db } from "@/services/db.service";
export default {
    async cuenta_update({commit},request){
        commit('cuenta_update',request);
    },
    async cuenta_show({commit},request){
        commit('cuenta_show',request);
    },
    async Vact_cuenta_data({commit}){
        commit('cuenta_load',true);
        try {
            const data = await db.get('/apicuenta/');
            commit('cuenta_data',data);
            commit('cuenta_load',false);
            return true
        } catch (e) {
            commit('cuenta_load',false);
            return false
        } 
    },
    async Vact_cuenta_del({dispatch},id){
        try {
            const data = await db.delete('/apicuenta/'+id);
            dispatch('Vact_cuenta_data');
            return data
        } catch (e) {
            return false
        } 
    },
    async Vact_cuenta_save({commit},data){
        commit('cuenta_save_load',true);
        try {
            const resp = await db.save('/apicuenta/',data);
            commit('cuenta_save_load',false);
            return resp;
        } catch (e) {
            commit('cuenta_save_load',false);
            return e
        } 
    },
    async Vact_cuenta_update({commit},{id, data}){
        commit('cuenta_save_load',true);
        try {
            const resp = await db.update('/apicuenta/'+id+'/',data);
            commit('cuenta_save_load',false);
            return resp;
        } catch (e) {
            commit('cuenta_save_load',false);
            return e
        } 
    },
}
