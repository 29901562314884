<template>
  <v-dialog persistent max-width="900" fullscreen :value="value">
    <v-card>
      <v-toolbar dense flat>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="red" fab small text
          ><v-icon @click="cancel()">clear</v-icon></v-btn
        >
      </v-toolbar>
      <v-divider></v-divider>
      <v-form ref="form">
        <v-row dense class="mx-2">
          <v-col cols="12" sm="6">
            <autocomplete-cuentabanco
              label="Cuenta Bancaria de Origen"
              v-model="data.idcuentabanco"
              @change="changeMoneda()"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <c-text-field
              readonly
              label="Moneda"
              v-model="data.idmoneda.descmoneda"
            />
          </v-col>
        </v-row>
        <v-row dense class="mx-2">
          <v-col cols="12" sm="3">
            <c-text-date
              ref="pagoSalario1"
              label="Desde"
              v-model="data.periodo_inicio"
              @keyup.native.enter="next(1, data.periodo_inicio)"
              @keyup.native="(data.idcontacto = ''), destroydata()"
              @change="(data.idcontacto = ''), destroydata()"
            ></c-text-date>
          </v-col>
          <v-col cols="12" sm="3">
            <c-text-date
              label="Hasta"
              ref="pagoSalario2"
              v-model="data.periodo_fin"
              @keyup.native.enter="next(2, data.periodo_fin)"
              @keyup.native="(data.idcontacto = ''), destroydata()"
              @change="(data.idcontacto = ''), destroydata()"
            ></c-text-date>
          </v-col>
          <v-btn
            text
            color="primary"
            ref="pagoSalario4"
            fab
            outlined
            small
            class="mt-4 ml-2"
            @click="keyContacto++"
            ><v-icon>get_app</v-icon></v-btn
          >
          <v-spacer></v-spacer>
          <v-col cols="12" sm="4">
            <autocomplete-funcionario
              ref="pagoSalario3"
              v-model="data.idcontacto"
              clearable
              label="Seleccione Funcionario"
              @change="reset()"
              :key="keyContacto"
              :date-start="data.periodo_inicio"
              :date-end="data.periodo_fin"
              @keyup.native.enter="
                next(3, data.idcontacto.idcontacto.idcontacto)
              "
            />
          </v-col>
        </v-row>
      </v-form>
      <v-row dense class="mx-2 d-flex justify-space-between" v-if="funcionario">
        <v-col cols="12" sm="2">
          <c-text-currency
            label="Salario Base"
            readonly
            outlined
            dense
            :rules="[]"
            :value="funcionario.salario.base"
          ></c-text-currency>
        </v-col>
        <v-col cols="12" sm="2">
          <c-text-field
            label="Unidad"
            readonly
            outlined
            dense
            :rules="[]"
            :value="
              funcionario.unidad ? funcionario.unidad.descunidadmedida : ''
            "
          ></c-text-field>
        </v-col>
        <v-col cols="12" sm="2">
          <c-text-currency
            label="Jornal Diario"
            readonly
            outlined
            dense
            :rules="[]"
            :value="funcionario.salario.diario"
          ></c-text-currency>
        </v-col>
        <v-col cols="12" sm="2">
          <c-text-currency
            label="Jornal Hora"
            readonly
            outlined
            dense
            :rules="[]"
            :value="funcionario.salario.hora"
          ></c-text-currency>
        </v-col>
        <v-col cols="12" sm="2">
          <c-text-currency
            label="Horas Trabajadas"
            readonly
            outlined
            dense
            :rules="[]"
            :value="funcionario.asistencia.total.asistencia"
          ></c-text-currency>
        </v-col>
      </v-row>
      <v-row dense class="mx-2">
        <v-col cols="6">
          <v-card-actions>
            <span class="ml-2 subtitle-1 mr-1 font-weight-black">Ingresos</span>
            <v-divider></v-divider>
          </v-card-actions>
          <v-data-table
            hide-default-footer
            hide-default-header
            :headers="headers"
            :items="dataTable.ingreso"
            :items-per-page="999999"
            item-key="idconcepto_salario.idconcepto_salario"
          >
            <template v-slot:[`item.active`]="props">
              <v-simple-checkbox
                :ripple="false"
                v-model="props.item.active"
              ></v-simple-checkbox>
            </template>
            <template v-slot:[`item.cantidad`]="props">
              <c-text-currency
                class="mb-n1"
                v-show="!props.item.cantDisabled"
                v-model="props.item.cantidad"
                :disabled="!props.item.active"
                :label="props.item.label || 'Cantidad'"
                dense
                hide-details
                @keyup.native="calcularMonto(props.item, 'INGRESO')"
              ></c-text-currency>
            </template>
            <template v-slot:[`item.monto`]="props">
              <c-text-currency
                class="mb-n1"
                v-model="props.item.monto"
                :disabled="!props.item.active"
                :readonly="props.item.disabledMonto"
                label="Monto"
                dense
                hide-details
              ></c-text-currency>
            </template>
            <template slot="body.append">
              <tr class="black--text">
                <th class="subtitle-1 font-weight-black customtext--text">
                  TOTAL
                </th>
                <th></th>
                <th></th>
                <th
                  class="subtitle-1 text-start font-weight-black customtext--text"
                >
                  {{ toCurrency(totalIngreso) }}
                </th>
              </tr>
            </template>
          </v-data-table>
        </v-col>
        <v-col cols="6">
          <v-card-actions>
            <span class="ml-2 subtitle-1 mr-1 font-weight-black">Egresos</span>
            <v-divider></v-divider>
          </v-card-actions>
          <v-data-table
            hide-default-footer
            hide-default-header
            :headers="headers"
            :items="dataTable.egreso"
            :items-per-page="999999"
            item-key="idconcepto_salario.idconcepto_salario"
          >
            <template v-slot:[`item.active`]="props">
              <v-simple-checkbox
                :ripple="false"
                v-model="props.item.active"
              ></v-simple-checkbox>
            </template>
            <template v-slot:[`item.cantidad`]="props">
              <c-text-currency
                class="mb-n1"
                v-show="!props.item.cantDisabled"
                v-model="props.item.cantidad"
                :disabled="!props.item.active"
                :label="props.item.label || 'Cantidad'"
                dense
                hide-details
                @keyup.native="calcularMonto(props.item, 'EGRESO')"
              ></c-text-currency>
            </template>
            <template v-slot:[`item.monto`]="props">
              <c-text-currency
                class="mb-n1"
                v-model="props.item.monto"
                :disabled="!props.item.active"
                label="Monto"
                :readonly="props.item.disabledMonto"
                dense
                hide-details
              ></c-text-currency>
            </template>
            <template slot="body.append">
              <tr class="black--text">
                <th class="subtitle-1 font-weight-black customtext--text">
                  TOTAL
                </th>
                <th></th>
                <th></th>
                <th
                  class="subtitle-1 text-start font-weight-black customtext--text"
                >
                  {{ toCurrency(totalEgreso) }}
                </th>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row dense>
        <v-spacer></v-spacer>
      </v-row>
      <v-divider></v-divider>
      <v-card-actions>
        <v-col cols="6">
          <v-btn color="red" large block dark @click="cancel()">Cancelar</v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn color="primary" large block @click="resumenAndConfirmation()"
            >Guardar</v-btn
          >
        </v-col>
      </v-card-actions>
    </v-card>
    <c-overlay
      :value="isContratoFuncionarioLoading || isConfigRrhhLoading || isLoading"
    ></c-overlay>
    <ConfirmarPago
      v-if="crud.dialog"
      v-model="crud.dialog"
      :props="crud.datos"
      @save="save()"
    />
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import autocompleteFuncionario from "@/views/RRHH/Funcionarios/Information/autocomplete";
import autocompleteCuentabanco from "@/views/Apertura/Cuentabanco/autocomplete";
import ConfirmarPago from "./ConfirmarPago";
import { generarRecibo } from "./recibo";
import { currency } from "@/services/util/number.service";
import {
  current_first_date,
  current_last_date,
  current_date,
} from "@/services/util/date.service";
import { configRecibo } from "./constants";
import {
  configurarConcepto,
  obtenerDatosFuncionario,
  calcularMonto,
  obtenerCantidadMonto,
  calcularAporteEmp,
} from "./salario";

export default {
  components: {
    "autocomplete-funcionario": autocompleteFuncionario,
    "autocomplete-cuentabanco": autocompleteCuentabanco,
    ConfirmarPago,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    props: {
      type: Object,
    },
  },

  async mounted() {
    await this.fetchConfigRrhh();
    if (this.editable) {
      this.data = JSON.parse(JSON.stringify(this.props));
    }
    setTimeout(() => this.next(0, "-"), 20);
  },

  watch: {
    $route(to, from) {
      if (from.path === "/rrhh/pago/salario") return this.cancel();
    },
    getConfigRrhh() {
      this.configurarCuentaMoneda();
    },
    getDetContrato(val) {
      if (val != "") {
        this.funcionario = obtenerDatosFuncionario(val);
        this.fetchConceptoPagoSalario();
      }
    },
    getConceptoPagoSalario(val) {
      this.asignarConceptos(val);
    },
    totalIngreso() {
      this.dataTable.egreso.map((x) => {
        if (x.idconcepto_salario.idconcepto_salario === 10) {
          this.funcionario.salario.ips = calcularAporteEmp(
            this.dataTable.ingreso
          );
          x.monto = calcularMonto(
            this.funcionario.salario,
            x.idconcepto_salario
          );
        }
      });
    },
  },
  computed: {
    ...mapGetters("configrrhh", ["getConfigRrhh", "isConfigRrhhLoading"]),
    ...mapGetters("contratofuncionario", [
      "getDetContrato",
      "isContratoFuncionarioLoading",
    ]),
    ...mapGetters("conceptosalario", ["getConceptoPagoSalario"]),

    totalIngreso() {
      return this.dataTable.ingreso.reduce((acc, curr) => {
        if (curr.active) return (acc = acc + Number(curr.monto));
        return acc;
      }, 0);
    },
    totalEgreso() {
      return this.dataTable.egreso.reduce((acc, curr) => {
        if (curr.active) return (acc = acc + Number(curr.monto));
        return acc;
      }, 0);
    },
    title() {
      return this.editable
        ? "Modificar Pago de Salario"
        : "Registrar Pago de Salario";
    },
  },

  methods: {
    ...mapActions("configrrhh", ["fetchConfigRrhh"]),
    ...mapActions("contratofuncionario", ["fetchDetContrato"]),
    ...mapActions("conceptosalario", ["fetchConceptoPagoSalario"]),
    ...mapActions("salario", [
      "setSalario",
      "setSalarioUpdate"
    ]),
    configurarCuentaMoneda() {
      this.data.idcuentabanco = JSON.parse(
        JSON.stringify(this.getConfigRrhh.idcuentabanco)
      );
      this.changeMoneda();
    },
    next(id, data) {
      if (data != "")
        try {
          this.$refs[`pagoSalario${id + 1}`].focus();
        } catch (error) {
          this.$refs[`pagoSalario${id + 1}`].$el.focus();
        }
    },
    changeMoneda() {
      this.data.idmoneda = JSON.parse(
        JSON.stringify(this.data.idcuentabanco.idmoneda)
      );
    },
    toCurrency(value) {
      if (value) return currency(value);
    },
    calcularMonto(item, tipo) {
      if (tipo === "INGRESO")
        this.dataTable.ingreso.map((x) => {
          if (
            x.idconcepto_salario.idconcepto_salario ===
            item.idconcepto_salario.idconcepto_salario
          ) {
            x.monto = calcularMonto(
              this.funcionario.salario,
              item.idconcepto_salario,
              x.cantidad
            );
          }
        });
      if (tipo === "EGRESO")
        this.dataTable.egreso.map((x) => {
          if (
            x.idconcepto_salario.idconcepto_salario ===
            item.idconcepto_salario.idconcepto_salario
          ) {
            x.monto = calcularMonto(
              this.funcionario.salario,
              item.idconcepto_salario,
              x.cantidad
            );
          }
        });
    },
    async obtenerContratos(val) {
      if (!this.$refs.form.validate()) return null;
      const id = val.idcontacto.idcontacto;
      const desde = this.data.periodo_inicio;
      const hasta = this.data.periodo_fin;
      const idmoneda = this.data.idmoneda.idmoneda;
      this.fetchDetContrato({ id, desde, hasta, idmoneda });
    },

    asignarConceptos(val) {
      let concepto = JSON.parse(JSON.stringify(val));
      this.isLoading = true;
      let data = { ingreso: [], egreso: [] };
      this.getDetContrato.detalle.forEach((x) => {
        concepto.map((conc) => {
          if (
            conc.idconcepto_salario === x.idconcepto_salario.idconcepto_salario
          ) {
            conc.monto = x.monto;
          }
        });
      });
      concepto.forEach((conc) => {
        const temp = configurarConcepto(conc, this.getConfigRrhh);
        const det = {
          idconcepto_salario: temp,
          label: temp.label || "Cantidad",
          cantDisabled: temp.cantDisabled || false,
          ...obtenerCantidadMonto(temp, this.funcionario, temp.monto),
        };
        conc.positivo_negativo ? data.ingreso.push(det) : data.egreso.push(det);
      });

      this.dataTable = JSON.parse(JSON.stringify(data));
      this.isLoading = false;
    },

    cancel() {
      this.$emit("input", false);
      this.$emit("filter");
    },
    obtenerDetalles() {
      const data = this.dataTable.ingreso.concat(this.dataTable.egreso);
      const filter = data.filter((x) => x.monto != "" && x.active);
      return filter;
    },
    resumenAndConfirmation() {
      this.data.detalle = JSON.parse(JSON.stringify(this.obtenerDetalles()));
      if (!this.$refs.form.validate() || this.data.detalle.length === 0)
        return null;
      this.data.monto = this.totalIngreso - this.totalEgreso;
      this.data.trabajadas = Math.round(this.funcionario.asistencia.total.asistencia * 100) / 100
      this.data.totalIngreso = this.totalIngreso;
      this.data.totalEgreso = this.totalEgreso;
      this.crud.dialog = true;
      this.crud.datos = JSON.parse(JSON.stringify(this.data));
    },
    async save() {
      let inicio = this.data.periodo_inicio
      let fin = this.data.periodo_fin
      const response = this.editable
        ? await this.setSalarioUpdate({
            data: this.data,
            id: this.data.idnivel,
          })
        : await this.setSalario(this.data);
      if (response.success) {
        this.$refs.form.resetValidation();
        generarRecibo(this.data, configRecibo, "Salario");
        this.data = JSON.parse(JSON.stringify(this.default));
        this.data.periodo_inicio = inicio
        this.data.periodo_fin = fin
        this.reset();
        this.next(0, "-");
        this.configurarCuentaMoneda();
        if (this.editable) this.cancel();
        this.$refs.pagoSalario3.getfuncionario();
      }
    },
    destroydata() {
      this.fetchDetContrato({ id: null });
      this.dataTable.ingreso = [];
      this.dataTable.egreso = [];
      this.crud.dialog = false;
      this.crud.datos = null;
      this.funcionario = null;
    },
    reset() {
      this.destroydata();
      this.obtenerContratos(this.data.idcontacto);
    },
  },
  data: () => ({
    headers: [
      { text: "", value: "active" },
      { text: "Concepto", value: "idconcepto_salario.descripcion" },
      { text: "Cantidad", value: "cantidad" },
      { text: "Saldo", value: "monto" },
    ],

    funcionario: null,
    dataTable: {
      ingreso: [],
      egreso: [],
    },
    isLoading: false,
    crud: {
      dialog: false,
      datos: null,
    },
    keyContacto: 1,
    data: {
      idpago_salario: 0,
      idcontacto: {
        idcontacto: "",
      },
      idcuentabanco: {
        idcuentabanco: "",
      },
      idmoneda: {
        idmoneda: "",
        descmoneda: "",
      },
      fecha: current_date(),
      periodo_inicio: current_first_date(),
      periodo_fin: current_last_date(),
      comentario: "",
      monto: 0,
      detalle: [],
      trabajadas: 0,
    },
    default: {
      idpago_salario: 0,
      idcontacto: {
        idcontacto: "",
      },
      idcuentabanco: {
        idcuentabanco: "",
      },
      idmoneda: {
        idmoneda: "",
        descmoneda: "",
      },
      fecha: current_date(),
      periodo_inicio: current_first_date(),
      periodo_fin: current_last_date(),
      comentario: "",
      monto: 0,
      detalle: [],
      trabajadas: 0,
    },
  }),
};
</script>
