<template>
  <div>
    <c-alert-permission v-model="permission"></c-alert-permission>
    <v-toolbar flat dense>
      <v-toolbar-title class="text-h5">{{ $route.name }}</v-toolbar-title>
      <widgets-Favorite :ruta="$route.path" :formulario="$route.name" />
      <v-divider class="mx-2" inset vertical></v-divider>
    </v-toolbar>
    <v-toolbar>
      <v-spacer></v-spacer>
      <c-text-date
        v-model="filtro.desde"
        label="Desde"
        class="mr-2 mt-6"
      ></c-text-date>
      <c-text-date
        v-model="filtro.hasta"
        label="Hasta"
        class="mr-2 mt-6"
      ></c-text-date>
      <v-btn text outlined fab small class="ml-2" @click="obtenerDatos()">
        <v-icon color="primary">cloud_download</v-icon>
      </v-btn>
      <v-btn text outlined fab small class="ml-2" @click="generarExcel()">
        <v-icon color="success">get_app</v-icon>
      </v-btn>
    </v-toolbar>
    <v-row>
      <v-col
        v-for="(item, index) in obtener"
        :key="index"
        :md="item.md"
        :cols="item.col"
      >
        <div v-if="item.title == 'Calendario'">
          <Asistence v-if="permission.asistencia == true" />
        </div>
        <div v-else>
          <Doughnut
            v-if="
              (permission.reporte == true) | (permission.asistencia == true)
            "
            :title="item.title"
            :api="item.api"
            :reload="load"
            :desde="filtro.desde"
            :hasta="filtro.hasta"
            @obtener="obtenerExcel"
          />
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { getPermission } from "@/services/storage/permissionStorage.service";
import { FUNCIONARIO, ASISTENCIA } from "@/constants/forms";
import { PUEDE_GENERAR_REPORTE } from "@/constants/permissions";
import Doughnut from "./Doughnut.vue";
import Asistence from "@/components/widgets/Asistence";
import { exportInformes } from "./export";
import { current_first_date, current_date } from "@/services/util/date.service";
export default {
  components: {
    Doughnut,
    Asistence,
  },
  data: () => ({
    load: false,
    data: [],
    filtro: {
      desde: current_first_date(),
      hasta: current_date(),
    },
    obtener: [
      {
        api: "/apiinformeasistencia/",
        title: "Ausencia",
        md: 12,
        col: 12,
      },
      {
        api: "/apiinformehoras/",
        title: "Hora",
        md: 12,
        col: 12,
      },
      {
        api: "/apiinformeedad/",
        title: "Edad",
        md: 12,
        col: 12,
      },
      {
        api: "/apiinformesalario/",
        title: "Salario",
        md: 12,
        col: 12,
      },
      {
        api: "/apiinformeantiguedad/",
        title: "Antigüedad",
        md: 12,
        col: 12,
      },
      {
        api: "/apiinformediversidad/",
        title: "Diversidad",
        md: 12,
        col: 12,
      },
      {
        api: "/apiinformegantasistencia/",
        title: "Calendario",
        md: 12,
        col: 12,
      },
    ],
    permission: {
      reporte: getPermission(FUNCIONARIO).permiso[PUEDE_GENERAR_REPORTE],
      asistencia: getPermission(ASISTENCIA).permiso[PUEDE_GENERAR_REPORTE],
    },
  }),
  watch: {},
  async mounted() {
    this.obtenerDatos();
  },
  computed: {},
  methods: {
    async obtenerDatos() {
      this.load = !this.load;
    },
    obtenerExcel(val) {
      let existe = false;
      this.data.forEach((x) => {
        if (x.api == val.api) {
          x.data = val.data;
          existe = true;
        }
      });
      if (existe == false) this.data.push(val);
    },
    generarExcel() {
      exportInformes(this.data, this.filtro.desde, this.filtro.hasta);
    },
  },
};
</script>
