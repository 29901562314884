
export default {
  getMaquinariaGasto: (state) => {
    return state.maquinariaGasto
  },
  getMaquinariaReporte: (state) => {
    return state.getMaquinariaReporte
  },
  isLoadingMaquinariaGasto: (state) => {
    return state.isLoadingMaquinariaGasto
  }
}
