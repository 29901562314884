import { get, put, del, post } from "@/services/api/api.service";
import { SET_LOADING, SET_JUSTIFICACION, SET_MOTIVO_AUSENCIA, SET_LISTA_VACACION, url } from './constants'
export default {
    async fetchJustificacion({ commit, dispatch }) {
        commit(SET_LOADING, true);
        try {
            const response = await get(url.justificacion);
            commit(SET_JUSTIFICACION, response);
        } catch (e) {
            dispatch('snackbar/setNotification', e, { root: true });
            commit(SET_LOADING, false);
            throw e
        }
        commit(SET_LOADING, false);
    },
    async fetchMotivoJustificacion({ commit, dispatch }) {
        commit(SET_LOADING, true);
        try {
            const response = await get(url.motivojustificacion);
            commit(SET_MOTIVO_AUSENCIA, response);
        } catch (e) {
            dispatch('snackbar/setNotification', e, { root: true });
            commit(SET_LOADING, false);
            throw e
        }
        commit(SET_LOADING, false);
    },
    async fetchListaVacacion({ commit, dispatch }, id) {
        commit(SET_LOADING, true);
        try {
            if (id) {
                const response = await get(`${url.listavacacion}${id}/`);
                commit(SET_LISTA_VACACION, response);
            }else{
                commit(SET_LISTA_VACACION,[]);
            }
        } catch (e) {
            dispatch('snackbar/setNotification', e, { root: true });
            commit(SET_LOADING, false);
            throw e
        }
        commit(SET_LOADING, false);
    },
    async setJustificacion({ commit, dispatch }, data) {
        commit(SET_LOADING, true);
        const response = await post(url.justificacion, data);
        dispatch('snackbar/setNotification', response, { root: true })
        commit(SET_LOADING, false);
        return response
    },
    async setJustificacionUpdate({ commit, dispatch }, { data, id }) {
        commit(SET_LOADING, true);
        const response = await put(`${url.justificacion}${id}/`, data);
        dispatch('snackbar/setNotification', response, { root: true })
        commit(SET_LOADING, false);
        return response
    },
    async setJustificacionDelete({ commit, dispatch }, id) {
        commit(SET_LOADING, true);
        const response = await del(`${url.justificacion}${id}/`);
        dispatch('snackbar/setNotification', response, { root: true })
        commit(SET_LOADING, false)
        return response;
    },
}
