<template>
  <div>
    <div v-if="abrirtiporesponsable">
      <Animal-Tiporesponsable-create />
    </div>
    <v-dialog
      v-model="dialogo"
      max-width="700px"
      persistent
      @keydown.esc="cancelar()"
    >
      <v-card>
        <v-form ref="form" lazy-validation>
          <v-card-title>
            <span class="headline">{{ titulo }}</span>
          </v-card-title>
          <v-card-text>
            <v-row row dense>
              <v-col cols="12">
                <v-text-field
                  dense
                  :rules="validar"
                  ref="input1"
                  v-model="data.descresponsable"
                  label="Descripcion"
                  required
                  outlined
                  autocomplete="off"
                  @keyup.enter="nextfocus(1)"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  dense
                  :rules="validar"
                  ref="input2"
                  v-model="data.teleresponsable"
                  label="Telefono"
                  required
                  outlined
                  autocomplete="off"
                  @keyup.enter="nextfocus(2)"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  dense
                  v-model="data.idtiporesponsable.idtiporesponsable"
                  ref="input3"
                  :loading="loadtiporesponsable"
                  :rules="validars"
                  v-bind:items="tiporesponsable"
                  fkdes="item"
                  label="Seleccione Tipo de Responsable"
                  item-text="fkdes"
                  item-value="id"
                  required
                  outlined
                  height="12"
                  @keyup.enter="nextfocus(3)"
                  :no-data-text="`No existe ninguna concidencia`"
                  chips
                >
                  <template
                    v-if="noData && tiporesponsable_permission"
                    v-slot:no-data
                  >
                    <v-list-item @click="tiporesponsable_create(true)">
                      <v-list-item-content>
                        <v-list-item-title
                          >No existe concidencia. Click para
                          agregar</v-list-item-title
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="cancelar">Cancelar</v-btn>
            <v-btn ref="aceptar" color="blue darken-1" text @click="guardar"
              >Guardar</v-btn
            >
          </v-card-actions>
        </v-form>
        <v-overlay :value="Vget_responsable_save_load">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { db } from "@/services/db.service";
import { mensaje } from "@/services/notify.service";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { TIPO_RESPONSABLE } from "@/constants/forms";
import { PUEDE_AGREGAR } from "@/constants/permissions";
export default {
  data() {
    return {
      overlay: false,
      noData: true,
      titulo: "Nuevo Responsable",
      data: {
        descresponsable: "",
        teleresponsable: "",
        idtiporesponsable: {
          idtiporesponsable: "",
        },
        estado: true
      },
      default: {
        descresponsable: "",
        teleresponsable: "",
        idtiporesponsable: {
          idtiporesponsable: "",
        },
        estado: true
      },
      validar: [(v) => !!v || "Campo requerido"],
      validars: [(v) => !!v || "Seleccione un campo"],
      dialogo: false,
      menu1: false,
      operacion: true,
      loadtiporesponsable: false,
      tiporesponsable: [],
    };
  },

  watch: {
    $route(to, from) {
      if (from.path === "/responsable") return this.cancelar();
    },
    // eslint-disable-next-line
    reload_tip(val) {
      if (val) {
        this.nuevo_tiporesponsable();
      }
    },
  },
  computed: {
    ...mapGetters("responsable", {
      getcreate: "responsable_create",
      getupdate: "responsable_update",
    }),
    ...mapGetters("responsable", ["Vget_responsable_save_load"]),
    ...mapGetters("tiporesponsable", {
      abrirtiporesponsable: "tiporesponsable_create",
      reload_tip: "tiporesponsable_reload",
    }),
    tiporesponsable_permission() {
      return getPermission(TIPO_RESPONSABLE).permiso[
        PUEDE_AGREGAR
      ];
    },
  },
  created() {
    this.nuevo(this.getupdate);
  },
  mounted() {
    setTimeout(() => this.$refs.input1.focus(), 500);
  },
  methods: {
    ...mapActions("responsable", [
      "responsable_create",
      "responsable_update",
      "Vact_responsable_data",
      "Vact_responsable_save",
      "Vact_responsable_update",
    ]),
    ...mapActions("tiporesponsable", [
      "tiporesponsable_create",
      "tiporesponsable_reload",
    ]),
    nextfocus(x) {
      switch (x) {
        case 1: {
          if (this.data.descresponsable != "") {
            this.$refs.input2.focus();
          }
          break;
        }
        case 2: {
          if (this.data.teleresponsable != "") {
            this.$refs.input3.focus();
          }
          break;
        }
        case 3: {
          if (this.data.idtiporesponsable.idtiporesponsable != "") {
            this.$refs.aceptar.$el.focus();
          }
          break;
        }
      }
    },
    cancelar() {
      this.data = this.default;
      this.dialogo = false;
      this.responsable_update(null);
      this.responsable_create(false);
      this.Vact_responsable_data();
      this.operacion = true;
    },
    async guardar_finalizar() {
      let a = await this.Vact_responsable_save(this.data);
      if (a.estado == true) {
        this.data = JSON.parse(JSON.stringify(this.default));
        this.$notify(a.info);
        this.$refs.form.resetValidation();
        this.$refs.input1.focus();
      } else {
        a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
      this.overlay = false;
    },
    async editar_finalizar() {
      const id = this.getupdate.idresponsable;
      let a = await this.Vact_responsable_update({ id, data: this.data });
      if (a.estado == true) {
        this.$notify(a.info);
        this.cancelar();
      } else {
        a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
      this.overlay = false;
    },
    guardar() {
      if (!this.$refs.form.validate()) return this.$notify(mensaje.completar());
      this.overlay = true;
      if (this.operacion === true) return this.guardar_finalizar();
      if (this.operacion === false) return this.editar_finalizar();
    },
    nuevo_tiporesponsable() {
      this.loadtiporesponsable = true;
      db.get("/apitiporesponsable/").then((a) => {
        a.map((element) => {
          this.tiporesponsable.push({
            id: element.idtiporesponsable,
            fkdes: element.desctiporesponsable,
          });
        });
      });
      this.loadtiporesponsable = false;
      this.tiporesponsable_reload(false);
    },
    async nuevo(val) {
      this.dialogo = true;
      this.nuevo_tiporesponsable();
      if (val !== null) {
        this.data = val;
        this.operacion = false;
        this.titulo = "Editar Responsable";
      }
    },
  },
};
</script>

