<template>
  <v-dialog :value="value" max-width="1000" persistent>
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title class="text-h5">Futuros de <strong>{{ title }} </strong></v-toolbar-title>
        <v-spacer></v-spacer>
        <div class="subtitle-2"><strong>Obs.</strong> Los datos de mercado se retrasan hasta 40 minutos.</div>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="data"
        :items-per-page="10000"
        hide-default-footer
      >
              <template v-slot:[`item.price.tn`]="props">
          <p class="font-weight-black text-center ma-1">{{ toCurrency(props.item.price.tn) }}</p>
        </template>
        <template v-slot:[`item.price.bsh`]="props">
          <p class="font-weight-black text-center ma-1">{{ toCurrency(props.item.price.bsh) }}</p>
        </template>
        <template v-slot:[`item.pounts`]="props">
          <p :class="`text-center ma-1 ${colorText(props.item.pounts)}`">
            {{ props.item.pounts }}
             <v-icon :color="colorCard(props.item.pounts)" small>{{ iconCard(props.item.pounts)}}</v-icon>
          </p>
         
        </template>
        <template v-slot:[`item.secondary_prices_tn.prev_close`]="props">
          <p class="text-center ma-1">
            {{ toCurrency(props.item.secondary_prices_tn.prev_close) }}
          </p>
        </template>
        <template v-slot:[`item.secondary_prices_tn.price_open`]="props">
          <p class="text-center ma-1">
            {{ toCurrency(props.item.secondary_prices_tn.price_open) }}
          </p>
        </template>
        <template v-slot:[`item.secondary_prices_tn.prev_range`]="props">
          <p class="text-center ma-1">
            {{ toCurrency(props.item.secondary_prices_tn.prev_range) }} / {{toCurrency(props.item.secondary_prices_tn.next_range)}}
          </p>
        </template>
        <template v-slot:[`item.action`]="props">
          <v-btn
            text
            small
            :color="props.item.month === currentMonth ? 'red' : 'primary'"
            @click="mostrar(props.item)"
            ><v-icon v-if="props.item.month === currentMonth"
              >check_circle_outline</v-icon
            ><v-icon small v-else>check_circle_outline</v-icon></v-btn
          >
        </template>
      </v-data-table>
      <v-card-actions>
        <v-btn color="red" text @click="cancelar()">
          <v-icon>clear</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { currency } from "@/services/util/number.service";
import { LocalService } from "@/services/auth/storage.service";
export default {
  data: () => ({
    headers: [
      {
        text: "Mes",
        align: "start",
        sortable: false,
        value: "month",
      },
      { text: "Ultimo Precio (x Tonelada)", sortable: false, value: "price.tn", align: "center" },
      { text: "Ultimo Precio (x Bushel)", sortable: false, value: "price.bsh", align: "center" },
      
      { text: "Cambio (Puntos Bushel)", sortable: false, value: "pounts", align: "center" },
      {
        text: "Cierre Anterior (Precio Tn)",
        sortable: false,
        value: "secondary_prices_tn.prev_close",
        align: "center",
      },
      {
        text: "Apertura (Precio Tn)",
        sortable: false,
        value: "secondary_prices_tn.price_open",
        align: "center",
      },
      {
        text: "Rango (Precio Tn)",
        sortable: false,
        value: "secondary_prices_tn.prev_range",
        align: "center",
      },
      { text: "Actualizado", sortable: false, value: "time", align: "center" },
      { text: "Dashboard", sortable: false, value: "action", align: "end" },
    ],
  }),

  props: {
    title: {
      type: String,
      default: "Soja",
    },
    value: {
      type: Boolean,
      default: false,
    },
    data: {
      type: [Object, Array],
    },
    "current-month": {
      type: String,
    },
  },

  methods: {
    toCurrency(value) {
      return value != "-" ? currency(value) : "-";
    },
    async mostrar(item) {
      await LocalService.save(
        item.commodity,
        JSON.stringify({ server: item.server, month: item.month })
      );
      this.$emit("change", null);
      this.cancelar();
    },
    cancelar() {
      this.$emit("input", false);
    },
    colorText(value) {
      return value.indexOf("+") > -1 ? "green--text" : "red--text";
    },
    colorCard(value) {
      return value.indexOf("+") > -1 ? "green accent-3" : "red";
    },
    iconCard(value) {
      return value.indexOf("+") > -1 ? "north_east" : "south_east";
    },
  },
};
</script>

<style>
</style>