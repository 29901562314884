<template>
  <div>
    <v-row row dense>
      <v-col cols="12" md="12" sm="12">
        <vue-text-currency
          :error="true"
          v-model="total"
          :readonly="true"
          label="Total a Pagar"
        ></vue-text-currency>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    total: {
      type: Number,
      required: true
    }
  },
  watch: {
    total() {
      this.$emit("input", this.data.cuentaventapagocaja);
    },
    cuentaventapagocaja(val) {
      this.$emit("input", val);
    },
  },
  computed: {
    montoentregado() {
      var sum = 0;
      this.data.cuentaventapagocaja.forEach(x => {
        x.venta_pago_cheque.forEach(y => {
          sum = sum + Number(y.monto);
        });
        x.venta_pago_tarjeta.forEach(y => {
          sum = sum + Number(y.monto);
        });
        sum = sum + Number(x.monto);
      });
      return sum;
    },
    diferenciamonto() {
      if(this.total === 0){
        this.$emit("diferenciamonto", -1);
        return 0;
      }
      const diferencia = this.montoentregado - this.total;
      this.$emit("diferenciamonto", diferencia);
      return diferencia;
    },
    cuentaventapagocaja() {
      return this.data.cuentaventapagocaja;
    }
  },
  methods: {
    resetearmonto(index){
      this.data.cuentaventapagocaja[index].monto = 0;
      this.data.cuentaventapagocaja[index].venta_pago_cheque[0].monto = 0;
      this.data.cuentaventapagocaja[index].venta_pago_tarjeta[0].monto = 0;
    },
    addFormaPago() {
      this.data.cuentaventapagocaja.push({
        idcaja: 0,
        idformapago: {
          idformapago: ""
        },
        monto: 0,
        venta_pago_cheque: [
          {
            idbanco: {
              idbanco: ""
            },
            nrocheque: "",
            monto: "",
            fechaemision: "",
            fechapago: ""
          }
        ],
        venta_pago_tarjeta: [
          {
            monto: "",
            voucher: "",
            idpasarela_pago: {
              idpasarela_pago: ""
            }
          }
        ],
        opcion: false
      });
    },
    deletFormaPago(i) {
      this.data.cuentaventapagocaja.splice(i, 1);
    }
  },
  data() {
    return {
      data: {
        cuentaventapagocaja: [
          {
            idcaja: 0,
            idformapago: {
              idformapago: 1
            },
            monto: 0,
            venta_pago_cheque: [
              {
                idbanco: {
                  idbanco: ""
                },
                nrocheque: "",
                monto: 0,
                fechaemision: "",
                fechapago: ""
              }
            ],
            venta_pago_tarjeta: [
              {
                monto: 0,
                voucher: "",
                idpasarela_pago: {
                  idpasarela_pago: ""
                }
              }
            ],
            opcion: false
          }
        ]
      }
    };
  }
};
</script>

<style>
</style>
