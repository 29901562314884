export default {


  gymplanilla_data(state, request) {
    state.gymplanilla_data = request;
  }, 
  gymplanilla_data_by(state, request) {
    state.gymplanilla_data_by = request;
  },
  gymplanilla_update(state, request) {
    state.gymplanilla_update = request;
  }, 
  gymplanilla_load(state, request) {
    state.gymplanilla_load = request;
  }, 
  gymplanilla_save_load(state, request) {
    state.gymplanilla_save_load = request;
  }, 
}