<template>
  <div>
    <v-card-text>
      <h3 align="center">Siembra/Aplicacion</h3>
      <v-divider></v-divider>
      <Navegacion />
      <v-row dense align="center" justify="center">
        <v-col cols="12">
          <h4>2 - Opcion</h4>
        </v-col>
        <div>
          <v-row dense align="center" justify="center">
            <v-col cols="10">
              <v-switch v-model="switch1" inset :label="`Opcion: ${tipo}`"></v-switch>
            </v-col>
            <v-col cols="10">
              <li>
                <strong>Siembra:</strong>
                <span>
                  se utiliza cuando se registra la primera siembra. El sistema
                  <strong>SI</strong> tiene en cuenta las hectareas disponibles para siembra.
                </span>
              </li>
              <li>
                <strong>Replantacion:</strong>
                <span>
                  se utiliza cuando se va a registrar una siembra sobre otra ya
                  existente. El sistema
                  <strong>NO</strong> tiene en cuenta las hectareas disponibles para siembra.
                  <br />Ya que reemplazara las hectareas de cultivo ya existente
                </span>
              </li>
            </v-col>
          </v-row>
        </div>

        <v-col cols="12">
          <h4>3 - Tipo</h4>
        </v-col>
        <div>
          <v-row dense align="center" justify="center">
            <v-img
              class="mt-2"
              :src="require('@/assets/help/agro/operacion/tipo.png')"
              max-width="140"
            ></v-img>
            <v-col cols="12">
              <ul>
                <li>
                  <strong>Siembra:</strong>con esta opcion se habilita todos los productos con el "Tipo Item" igual a "Siembra"
                </li>
                <li>
                  <strong>Aplicacion:</strong>con esta opcion se habilita todos los productos con el "Tipo Item" igual a "Aplicacion"
                </li>
                <li>
                  <strong>Fumigacion:</strong>con esta opcion se habilita todos los productos con el
                  "Tipo Item" igual a "Fumigacion"
                </li>
              </ul>
            </v-col>
          </v-row>
        </div>
        <v-col cols="12">
          <h4>4 - Producto</h4>
        </v-col>

        <span>
          Escriba el nombre del producto y seleccione el producto a ser utilizado
          <br />
          <v-img
            class="mt-2"
            :src="require('@/assets/help/agro/operacion/producto.png')"
            max-width="240"
          ></v-img>
          <strong>Obs.</strong>En caso de que el producto no exista haga click en la opcion de crear y
          siga los pasos de la seccion
          <a
            @click="show = 'Item'"
          >Crear Item</a>
        </span>
        <v-img
          class="mt-2"
          :src="require('@/assets/help/agro/operacion/detalle.png')"
          max-width="240"
        ></v-img>
        <v-col cols="12">
          <h4>5 - Cantidad del Producto por hectarea</h4>
        </v-col>
        <v-col cols="12">
          <h4>6 - Unidad de medida del Producto</h4>
        </v-col>
        <v-col cols="12">
          <h4>7 - Precio del Producto por hectarea</h4>
        </v-col>
        <v-col cols="12">
          <h4>8 - Cantidad de Hectareas a trabajar</h4>
        </v-col>
        <v-col cols="12">
          <h4>9 - Click para agregar en el detalle</h4>
        </v-col>
        <v-col cols="12">
          <h4>10 -(Opcional) Observacion</h4>
        </v-col>
        <v-col cols="12">
          <li>
            Click en
            <v-icon color="primary" text>done</v-icon>para guardar
          </li>
          <li>
            Click en
            <v-icon color="red" text>clear</v-icon>para cancelar
          </li>
          <li>Mision Cumplida!!! :D</li>
        </v-col>
      </v-row>
    </v-card-text>
    <v-progress-linear rounded height="3" value="100"></v-progress-linear>
    <component :is="show"></component>
  </div>
</template>

<script>
export default {
  components: {
    Item: () =>
      import(/* webpackPrefetch: true */ "@/views/Help/Item/CrearItem.vue"),
    Navegacion: () =>
      import(/* webpackPrefetch: true */ "@/views/Help/Agro/Detail/Operacion/Navegacion.vue"),
    
  },
  data() {
    return {
      switch1: false,
      masNav: false,
      masOpc: false,
      masTip: false,
      masProd: false,
      show: ""
    };
  },
  computed: {
    tipo() {
      return this.switch1 ? "Siembra" : "Replantacion";
    }
  }
};
</script>

<style>
</style>