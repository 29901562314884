<template>
  <v-card>
    <v-toolbar flat dense>
      <v-toolbar-title>{{ $route.name }}</v-toolbar-title>
      <widgets-Favorite :ruta="$route.path" :formulario="$route.name" />
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-spacer></v-spacer>
      <c-text-table-search
        v-model="search"
        v-if="permission.can_view"
      ></c-text-table-search>
    </v-toolbar>
    <v-form ref="form">
      <v-row dense class="mx-2">
        <v-col cols="9" sm="6">
          <vue-autocomplete-contacto-proveedor
            :create="false"
            :rules="true"
            label="Proveedor"
            v-model="idproveedor"
          />
        </v-col>
        <v-spacer></v-spacer>
        <v-btn
          text
          color="primary"
          fab
          small
          outlined
          class="mr-2"
          @click="descargarDatos()"
        >
          <v-icon>cloud_download</v-icon>
        </v-btn>

        <v-btn
          v-if="items.datos != undefined"
          class="mr-2 hidden-sm-and-down"
          text
          fab
          small
          color="red"
          outlined
          @click="generarPDF()"
        >
          <v-icon>get_app</v-icon>
        </v-btn>
      </v-row>
    </v-form>
    <v-col cols="12" v-if="items.datos">
      <span class="ml-3 subtitle-2 font-weight-black">Contacto:</span>
      <span class="subtitle-2 "> {{ items.persona }}</span>
    </v-col>
    <v-col cols="12" v-for="(item, i) of items.datos" :key="i">
      <v-toolbar dense flat>
        <v-toolbar-title>Extracto {{ item.moneda }}</v-toolbar-title>
      </v-toolbar>
      <v-data-table
        :loading="isLoading"
        :headers="headers"
        :items="item.detalle"
        :search="search"
        v-if="permission.can_view"
      >
        <template v-slot:[`item.vencido`]="{ item }">
          <v-chip :color="item.vencido === 'Si' ? 'red' : 'primary'" dark>{{
            item.vencido === "Si" ? "Vencido" : "Pendiente"
          }}</v-chip>
        </template>
        <template v-slot:[`item.importe`]="props" class="text-end">
          <p class="text-end ma-2">
            {{ currencyFormat(props.item.importe) }}
          </p>
        </template>
        <template v-slot:[`item.saldo`]="props" class="text-end">
          <p class="text-end ma-2">
            {{ currencyFormat(props.item.saldo) }}
          </p>
        </template>

        <template slot="body.append">
          <tr class="black--text hidden-sm-and-down">
            <th class="subtitle-1">TOTAL</th>
            <th class="subtitle-1"></th>
            <th class="subtitle-1"></th>
            <th class="subtitle-1 text-end">
              {{ currencyFormat(item.total.importe) }}
            </th>
            <th class="subtitle-1 text-end">
              {{ currencyFormat(item.total.saldo) }}
            </th>
          </tr>
        </template>
      </v-data-table>
    </v-col>
  </v-card>
</template>

<script>
import { EXTRACTO_PROVEEDOR } from "@/constants/forms";
import { currency } from "@/services/util/number.service";
import exportAvanzado from "../../Personas/Extracto/Export";
import {
  PUEDE_AGREGAR,
  PUEDE_EDITAR,
  PUEDE_ELIMINAR,
  PUEDE_LISTAR,
} from "@/constants/permissions";

import { getPermission } from "@/services/storage/permissionStorage.service";
import { mapActions, mapGetters } from "vuex";
export default {
  data: () => ({
    idproveedor: {
      idcontacto: "",
    },
    search: "",
    permission: {
      can_add: false,
      can_view: false,
      can_update: false,
      can_delete: false,
    },
    headers: [
      { text: "Estado", align: "left", value: "vencido" },
      { text: "Fecha", align: "left", value: "fecha" },
      { text: "Factura", align: "left", value: "numf" },
      { text: "Importe", value: "importe", sortable: false, align: "right" },
      { text: "Saldo", value: "saldo", sortable: false, align: "right" },
    ],
  }),
  created() {
    const permission = getPermission(EXTRACTO_PROVEEDOR).permiso;
    this.permission = {
      can_add: permission[PUEDE_AGREGAR],
      can_view: permission[PUEDE_LISTAR],
      can_update: permission[PUEDE_EDITAR],
      can_delete: permission[PUEDE_ELIMINAR],
    };
  },
  watch: {
    $route(to) {
      if (to.path === "/extractoproveedor") {
        this.getById();
      }
    },
  },
  mounted() {
    this.$store.commit("SET_LAYOUT", "principal-layout");
    this.getById();
  },
  computed: {
    ...mapGetters("extracto", ["isLoading", "getProveedor"]),
    items() {
      let items = JSON.parse(JSON.stringify(this.getProveedor));
      if (!items.datos) return [];
      items.datos.map((moneda) => {
        let [importe, saldo] = [0, 0];
        moneda.detalle.map((x) => {
          importe = importe + Number(x.importe);
          saldo = saldo + Number(x.saldo);
        });
        moneda.total = {
          importe,
          saldo,
        };
      });
      return items;
    },
  },
  methods: {
    ...mapActions("extracto", ["fetchProveedor"]),
    descargarDatos() {
      if (!this.$refs.form.validate()) return null;
      this.fetchProveedor(this.idproveedor.idcontacto);
    },
    getById() {
      if (this.$route.query.idcontacto) {
        this.idproveedor.idcontacto = this.$route.query.idcontacto;
        this.fetchProveedor(this.idproveedor.idcontacto);
        this.$router.push({ query: {} });
      }
    },
    currencyFormat(value) {
      return currency(value);
    },
    generarPDF() {
      exportAvanzado(this.headers, {
        ...this.items,
        ...{ titulo: this.$route.name },
        ...{ mode: "Cliente" },
      });
    },
  },
};
</script>

<style></style>
