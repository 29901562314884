<template>
  <div>
    <v-dialog :value="value" max-width="650" persistent>
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>{{
            !editable ? "Agregar Ingeniero" : "Modificar Ingenerio"
          }}</v-toolbar-title>
        </v-toolbar>
        <v-container>
          <v-form ref="form" @submit.prevent="next(1, data.nomblote_ing)">
            <v-row dense>
              <v-col cols="12">
                <c-text-field
                  dense
                  outlined
                  label="Nombre"
                  ref="ingeniero1"
                  v-model="data.nomblote_ing"
                  @keyup.native.enter="next(1, data.nomblote_ing)"
                ></c-text-field>
              </v-col>
              <v-col cols="12">
                <c-text-field
                  dense
                  outlined
                  label="Matricula"
                  ref="ingeniero2"
                  v-model="data.matrlote_ing"
                  @keyup.native.enter="next(2, data.matrlote_ing)"
                ></c-text-field>
              </v-col>
              <v-col cols="12">
                <c-text-field
                  dense
                  outlined
                  label="Registro"
                  ref="ingeniero3"
                  v-model="data.regilote_ing"
                  @keyup.native.enter="next(3, data.matrlote_ing)"
                ></c-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <v-divider></v-divider>
        <v-card-actions>
          <c-btn-crud-close @click="cancelar()"></c-btn-crud-close>
          <v-spacer></v-spacer>
          <c-btn-crud-done ref="ingeniero4" @click="guardar()"></c-btn-crud-done>
        </v-card-actions>
        <c-overlay :value="isLoteingenieroLoading"></c-overlay>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    props: {
      type: Object,
    },
    "sync-input": {
      type: [String, Number],
      default: null,
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sync: null,
      data: {
        idlote_ing: "",
        nomblote_ing: "",
        matrlote_ing: "",
        regilote_ing: "",
        estado: true,
      },
      default: {
        idlote_ing: "",
        nomblote_ing: "",
        matrlote_ing: "",
        regilote_ing: "",
        estado: true,
      },
    };
  },
  watch: {
    $route() {
      return this.cancelar();
    },
  },
  computed: {
    ...mapGetters("loteingeniero", ["isLoteingenieroLoading"]),
  },
  mounted() {
    if (this.editable) this.data = JSON.parse(JSON.stringify(this.props));
    setTimeout(() => this.next(0, "-"), 200);
    if (this.syncInput != null) {
      this.data.nomblote_ing = this.syncInput;
      this.sync = this.syncInput;
    }
  },
  methods: {
    ...mapActions("loteingeniero", [
      "setLoteingeniero",
      "setLoteingenieroUpdate",
      "fetchLoteingeniero",
    ]),
    next(id, data) {
      if (data != "")
        try {
          this.$refs[`ingeniero${id + 1}`].focus();
        } catch (error) {
          this.$refs[`ingeniero${id + 1}`].$el.focus();
        }
    },
    cancelar() {
      this.$emit("input", false);
      this.$emit("datos", null);
    },

    async guardar() {
      if (!this.$refs.form.validate()) return null;
      const response = this.editable
        ? await this.setLoteingenieroUpdate({
            data: this.data,
            id: this.data.idlote_ing,
          })
        : await this.setLoteingeniero(this.data);
      if (response.success) {
        this.fetchLoteingeniero();
        if (this.sync != null) {
          this.$emit("sync-input", response.data.id);
          this.$emit("next-focus");
          return this.cancelar();
        }
        this.data = JSON.parse(JSON.stringify(this.default));
        this.$refs.form.resetValidation();
        if (this.editable) return this.cancelar();
        this.next(0, "-");
      }
    },
  },
};
</script>
