export default {


  gymcuota_data(state, request) {
    state.gymcuota_data = request;
  },
  gymcuota_data_by(state, request) {
    state.gymcuota_data_by = request;
  },
  gymcuota_update(state, request) {
    state.gymcuota_update = request;
  }, 
  gymcuota_load(state, request) {
    state.gymcuota_load = request;
  }, 
  gymcuota_save_load(state, request) {
    state.gymcuota_save_load = request;
  }, 
}