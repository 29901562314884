export default {

  unidadmedida_data(state, request) {
    state.unidadmedida_data = request;
  }, 
  unidadmedida_data_magnitud(state, request) {
    state.unidadmedida_data_magnitud = request;
  }, 
  unidadmedida_load(state, request) {
    state.unidadmedida_load = request;
  }, 
  unidadmedida_save_load(state, request) {
    state.unidadmedida_save_load = request;
  }, 
}