<template>
  <v-dialog :value="value" persistent max-width="700">
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>{{operacion ? 'Cerrar Campaña' : 'Reabrir Campaña'}}</v-toolbar-title>
      </v-toolbar>
      <v-container>
        <v-row dense>
          <v-col cols="12" md="6">
            <vue-text-field label="Campaña" readonly v-model="data.descripcion"></vue-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <c-text-date label="Fecha Apertura" dense
                outlined readonly v-model="data.fechacampana"></c-text-date>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-form ref="form">
          <v-row dense>
            <v-col cols="12" md="6">
              <c-text-date
                label="Fecha de Cierre"
                v-model="data.fechacierre"
                :readonly="!operacion"
                dense
                outlined
                id="cierreCampanainput1"
                @keyup.native.enter="next(1,data.fechacierre)"
              ></c-text-date>
            </v-col>
            <v-col cols="12" md="6">
              <vue-text-field
                label="Cotizacion de Cierre"
                v-model="data.cotizacion"
                :readonly="!operacion"
                id="cierreCampanainput2"
                @keyup.native.enter="next(2,data.cotizacion)"
              ></vue-text-field>
            </v-col>
            <v-col cols="12">
              <vue-text-field
                label="Comentario"
                v-model="data.comentario"
                :readonly="!operacion"
                id="cierreCampanainput3"
                @keyup.native.enter="next(3,data.comentario)"
              ></vue-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
      <v-card-actions>
        <v-btn color="red" text @click="cancelar()">
          <v-icon>clear</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" text id="cierreCampanainput4" @click="guardar()" v-if="operacion">
          <v-icon>done</v-icon>
        </v-btn>
        <v-btn
          outlined
          color="primary"
          text
          id="cierreCampanainput4"
          @click="guardar()"
          v-else
        >Reabrir</v-btn>
      </v-card-actions>
      <c-overlay :value="Vget_campana_save_load || isLoading"></c-overlay>
    </v-card>
  </v-dialog>
</template>

<script>
import { focus } from "@/services/focus.service";
import { current_date } from "@/services/util/date.service";
import { mensaje } from "@/services/notify.service";
import { db } from "@/services/db.service";
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    datos: {
      type: Object,
      default: null,
    },
    operacion: {
      type: Boolean,
      default: true,
    },
    idcampana: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      data: {},
      isLoading: false
    };
  },
  created() {
    if (this.idcampana != null) return this.getCampana(this.idcampana);
    this.data = JSON.parse(JSON.stringify(this.datos));
  },
  mounted() {
    this.next(0, "-");
    this.data.fechacierre = current_date();
  },
  computed: {
    ...mapGetters("campana", ["Vget_campana_save_load"]),
  },
  methods: {
    ...mapActions("campana", ["Vact_campana_cierre", "Vact_campana_data"]),
    cancelar() {
      this.$emit("input", false);
      this.$emit("datos", null);
      this.Vact_campana_data();
    },
    async getCampana(id) {
      this.isLoading =  true;
      try {
        const resp = await db.get("/apizafra/" + id + "/");
        this.data = JSON.parse(JSON.stringify(resp));
        this.data.fechacierre = current_date();
        this.$refs.form.resetValidation();
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
    next(index, data) {
      focus.nextidName("cierreCampanainput", index, data);
    },
    async guardar() {
      let data = JSON.parse(JSON.stringify(this.data));
      if (!this.operacion) {
        data.fechacierre = null;
        data.cotizacion = null;
        data.comentario = null;
      } else {
        if (!this.$refs.form.validate()) return null;
      }
      const a = await this.Vact_campana_cierre({
        id: data.idcampana,
        data,
      });
      if (a.estado == true) {
        this.$notify(a.info);
        this.cancelar();
      } else {
        a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
    },
  },
};
</script>

<style>
</style>