<template>
  <div>
    <v-card>
      <c-alert-permission v-model="permission"></c-alert-permission>
      <v-toolbar flat dense>
        <v-toolbar-title>Reporte de Produccion</v-toolbar-title>
        <widgets-Favorite
          ruta="/reporteproduccionanimal"
          formulario="Reporte de Produccion"
        />
        <v-divider class="mx-2" inset vertical></v-divider>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-sheet class="d-flex justify-end">
        <v-slide-group multiple show-arrows>
          <v-slide-item>
            <div>
              <v-btn
                active-class="primary--text"
                tile
                exact
                text
                to="/reporteproduccionanimal/reporteleche"
              >
                Produccion Leche
              </v-btn>
            </div>
          </v-slide-item>
          <v-slide-item>
            <div>
              <v-btn
                active-class="primary--text"
                tile
                exact
                text
                to="/reporteproduccionanimal/reporteanimal"
              >
                Produccion Animal
              </v-btn>
            </div>
          </v-slide-item>
        </v-slide-group>
      </v-sheet>
      <v-divider></v-divider>
      <transition>
        <router-view></router-view>
      </transition>
    </v-card>
  </div>
</template>
<script>
import { getPermission } from "@/services/storage/permissionStorage.service";
import { PUEDE_GENERAR_REPORTE } from "@/constants/permissions";
import { PRODUCCION_LECHE } from "@/constants/forms";
export default {
  data: () => ({
    permission: {
      reporte: getPermission(PRODUCCION_LECHE).permiso[PUEDE_GENERAR_REPORTE],
    },
  }),
  created() {
    this.$store.commit("SET_LAYOUT", "principal-layout");
  },
};
</script>