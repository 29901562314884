<template>
  <div>
    <v-card>
      <v-alert type="error" v-if="permission_alert">
        Error!!! Este usuario no tiene permisos en esta ventana
      </v-alert>
      <v-toolbar flat dense>
        <v-toolbar-title>{{ $route.name }}</v-toolbar-title>
        <widgets-Favorite :ruta="$route.path" :formulario="$route.name" />
        <v-divider class="mx-2" inset vertical></v-divider>

        <v-spacer></v-spacer>
        <c-text-table-search
          class="mr-1"
          v-model="search"
          v-if="permission.can_view"
        ></c-text-table-search>

        <v-toolbar-items>
          <c-btn-table-add
            @click="crud.add = true"
            v-if="permission.can_add"
            class="mr-1"
          ></c-btn-table-add>
          <c-btn-table-reload @click="fetchTipoEnfermedad()"></c-btn-table-reload>
        </v-toolbar-items>
      </v-toolbar>
      <v-data-table
        :loading="isLoading"
        :headers="headers"
        :items="getTipoEnfermedad"
        :search="search"
        class="elevation-1"
        v-if="permission.can_view"
      >
        <template v-slot:[`item.accion`]="props">
          <c-btn-table-edit
            @click="mupdate(props.item)"
            v-if="permission.can_update"
          ></c-btn-table-edit>
          <c-btn-table-delete
            @click="meliminar(props.item)"
            v-if="permission.can_delete"
          ></c-btn-table-delete>
        </template>
        <template v-slot:no-data>
          <v-alert :value="true" color="info" icon="info" outlined>
            No existe ningun registro
          </v-alert>
        </template>
      </v-data-table>
    </v-card>
    <Crud
      v-if="crud.add"
      v-model="crud.add"
      :props="crud.datos"
      @datos="crud.datos = $event"
    />
    <Delete
      v-if="crud.delete"
      v-model="crud.delete"
      :props="crud.datos"
      @datos="crud.datos = $event"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { mensaje } from "@/services/notify.service";
import { TIPO_ENFERMEDAD } from "@/constants/forms";
import Crud from "./Create";
import Delete from "./Delete";
import {
  PUEDE_AGREGAR,
  PUEDE_EDITAR,
  PUEDE_ELIMINAR,
  PUEDE_LISTAR,
} from "@/constants/permissions";
export default {
  components: {
    Delete,
    Crud,
  },
  data: () => ({
    search: "",
    crud: {
      delete: false,
      add: false,
      view: false,
      datos: null,
    },
    headers: [
      { text: "Descripcion", align: "left", value: "descenfermedad" },
      { text: "Abreviatura", align: "left", value: "abvrenfermedad" },
      { text: "Accion", value: "accion", sortable: false, align: "right" },
    ],
    permission: {
      can_add: false,
      can_view: false,
      can_update: false,
      can_delete: false,
    },
  }),

  created() {
    this.$store.commit("SET_LAYOUT", "principal-layout");
    this.fetchTipoEnfermedad();
    const permission = getPermission(TIPO_ENFERMEDAD).permiso;
    this.permission = {
      can_add: permission[PUEDE_AGREGAR],
      can_view: permission[PUEDE_LISTAR],
      can_update: permission[PUEDE_EDITAR],
      can_delete: permission[PUEDE_ELIMINAR],
    };
  },
  computed: {
    ...mapGetters("tipoenfermedad", ["getTipoEnfermedad", "isLoading"]),
    permission_alert() {
      return mensaje.permission(this.permission);
    },
  },

  methods: {
    ...mapActions("tipoenfermedad", ["fetchTipoEnfermedad"]),
    meliminar(item) {
      this.crud.datos = JSON.parse(JSON.stringify(item));
      this.crud.delete = true;
    },
    mupdate(val) {
      this.crud.datos = JSON.parse(JSON.stringify(val));
      this.crud.add = true;
    },
  },
};
</script>
