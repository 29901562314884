import { db } from "@/services/db.service";
export default {

    async Vact_division_data({commit}){
        commit('division_load',true);
        try {
            const data = await db.get('/apidivision/');
            commit('division_data',data);
            commit('division_load',false);
            return true
        } catch (e) {
            commit('division_load',false);
            return false
        } 
    },
    async Vact_division_save({commit},data){
        commit('division_save_load',true);
        try {
            const resp = await db.save('/apidivision/',data);
            commit('division_save_load',false);
            return resp;
        } catch (e) {
            commit('division_save_load',false);
            return e
        } 
    },
    async Vact_division_del({dispatch},id){
        try {
            const data = await db.delete('/apidivision/'+id);
            dispatch('Vact_division_data');
            return data
        } catch (e) {
            return false
        } 
    },
    async Vact_division_update({commit},{id, data}){
        commit('division_save_load',true);
        try {
            const resp = await db.update('/apidivision/'+id+'/',data);
            commit('division_save_load',false);
            return resp;
        } catch (e) {
            commit('division_save_load',false);
            return e
        } 
    },
}
