
export default {

  tipocontacto_create: (state) => {
    return state.tipocontacto_create
  },
  tipocontacto_update: (state) => {
    return state.tipocontacto_update
  },
  tipocontacto_reload: (state) => {
    return state.tipocontacto_reload
  },
  tipocontacto_show: (state) => {
    return state.tipocontacto_show
  },
}
