<template>
  <v-text-field
    :label="label"
    @input="setValue($event)"
    :outlined="outlined"
    :error="error"
    :error-messages="errorString"
    :clearable="clearable"
    :dense="dense"
    :rules="rules_active"
    :messages="messages"
    v-on:focus="$emit('focus')"
    :suffix="suffix"
    :decimal-length="length"
    :append-outer-icon="appendoutericon"
    :readonly="readonly"
    :disabled="disabled"
    :placeholder="placeholder"
    :hide-details="hideDetails"
    autocomplete="off"
    :id="id"
    :value="displayValue"
    @blur="isInputActive = false"
    @focus="isInputActive = true"
  ></v-text-field>
</template>

<script>
export default {
  props: {
    label: String,
    suffix: String,
    value: {
      type: [String, Number],
      default: "",
    },
    id: String,
    placeholder: String,
    outlined: {
      type: Boolean,
      default: true,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    messages: {
      type: [Number, String],
    },
    appendoutericon: {
      type: String,
      default: "",
    },
    rules: {
      type: Boolean,
      default: false,
    },
    "rules-option": {
      type: Array,
      default: null,
    },
    "hide-details": {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    length: {
      type: Number,
      default: 2,
    },
    error: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      validar: [(v) => !!v || "Campo requerido"],
      isInputActive: false,
      errorString: "",
    };
  },

  computed: {
    rules_active() {
      if (this.rules) {
        if (this.rulesOption != null) return this.rulesOption;
        return this.validar;
      }
      return [];
    },

    displayValue: {
      get: function () {
        if (isNaN(this.value)) {
          return this.$emit("input", "");
        }
        if (this.isInputActive) {
          return this.value;
        } else {
          if (this.value === "") return "";
          return new Intl.NumberFormat({ maximumSignificantDigits: 3 }).format(
            this.value
          );
        }
      },
      set: function (modifiedValue) {
        let newValue = parseFloat(modifiedValue.replace(/[^\d]/g, ""));
        if (isNaN(newValue)) {
          newValue = "";
        }
        console.log(newValue);
        this.$emit("input", Number(newValue));
      },
    },
  },
  methods: {
    setValue(event) {
      if (event === "" || event === null) {
        return this.$emit("input", "");
      }
      this.$emit("input", Number(event));
    },
  },
};
</script>