<template>
  <v-autocomplete
    :value="value"
    @input="$emit('input', $event)"
    ref="input"
    :items="ciudadList"
    :loading="isCiudadLoading"
    :search-input.sync="searchitem"
    item-value="idciudad"
    :rules="rules"
    return-object
    item-text="descciudad"
    :outlined="outlined"
    :dense="dense"
    :disabled="disabled"
    :chips="chips"
    :small-chips="smallChips"
    :label="label"
  >
    <template slot="selection" slot-scope="data" v-if="detailed">
      {{ data.item.descciudad }} - {{ data.item.iddepartamento.descripcion }}
    </template>
    <template slot="item" slot-scope="data" v-if="detailed">
      <v-list-item-content>
        <v-list-item-title>
          {{ data.item.descciudad }}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ data.item.iddepartamento.descripcion }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data: () => ({
    searchitem: "",
  }),
  props: {
    detailed: {type: Boolean, default: false},
    value: [Object, Number, String],
    outlined: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    chips: {
      type: Boolean,
      default: false,
    },
    "small-chips": {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "Ciudad",
    },
    rules: {
      type: Array,
      default: function() {
        return [(v) => !!v.idciudad || "Este campo es obligatorio"];
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    "filter-country": {
      type: [String, Number],
    },
    "filter-departament": {
      type: [String, Number],
    },
  },
  created() {
    //this.fetchCiudad();
  },
  watch: {
    searchitem(val) {
      if (val === null || val === undefined || val === "") return null;
      this.fetchCiudad({
        search: val,
        iddepartamento: this.filterDepartament,
        idnacionalidad: this.filterCountry,
      });
    },
  },
  computed: {
    ...mapGetters("ciudad", ["getCiudad", "isCiudadLoading"]),
    ciudadList() {
      if (this.filterCountry)
        return this.getCiudad.filter(
          (x) => x.idnacionalidad.idnacionalidad === this.filterCountry
        );
      if (this.filterDepartament)
        return this.getCiudad.filter(
          (x) => x.iddepartamento.iddepartamento === this.filterDepartament
        );
      return [];
    },
  },
  methods: {
    ...mapActions("ciudad", ["fetchCiudad"]),
    focus() {
      this.$refs.input.focus();
    },
    isMenuActive() {
      this.$refs.input.isMenuActive = false;
    },
  },
};
</script>

<style></style>
