<template>
  <v-btn
    text
    outlined
    fab
    small
    :id="id"
    :disabled="disabled"
    @click="$emit('click')"
  >
    <v-icon color="primary">add</v-icon>
  </v-btn>
</template>

<script>
export default {
  props: {
    id: String,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
</style>