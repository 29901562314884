
export default {


  Vget_gymcuota_data: (state) => {
    return state.gymcuota_data
  },
  Vget_gymcuota_data_by: (state) => {
    return state.gymcuota_data_by
  },
  Vget_gymcuota_update: (state) => {
    return state.gymcuota_update
  },
  Vget_gymcuota_load: (state) => {
    return state.gymcuota_load
  },
  Vget_gymcuota_save_load: (state) => {
    return state.gymcuota_save_load
  },
}
