<template>
  <div>
    <v-autocomplete
      :value="value"
      @input="$emit('input', $event)"
      ref="input"
      :items="getTipoPico"
      :loading="isTipoPicoLoading"
      item-value="idtipo_pico"
      :search-input.sync="syncInput"
      :rules="rules"
      :id="id"
      return-object
      :hide-details="hideDetails"
      item-text="descripcion"
      :outlined="outlined"
      :dense="dense"
      :disabled="disabled"
      :readonly="readonly"
      :chips="chips"
      :small-chips="smallChips"
      @change="$emit('change')"
      :label="label"
    >
      <template v-if="isPermission && create" v-slot:no-data>
        <v-list-item @click="crud.add = true">
          <v-list-item-content>
            <v-list-item-title
              >No existe concidencia. Click para agregar</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-autocomplete>
    <Crud
      v-model="crud.add"
      v-if="crud.add"
      :sync-input="syncInput"
      @sync-input="$emit('input', $event)"
      @next-focus="$emit('next-focus')"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import autocompleteProps from "@/services/mixins/autocomplete.mixin";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { ZAFRA } from "@/constants/forms";
import { PUEDE_AGREGAR } from "@/constants/permissions";
import Crud from "./Create"
export default {
  mixins: [autocompleteProps],
  components:{
    Crud
  },
  props: {
    rules: {
      type: Array,
      default: () => {
        return [(v) => !!v.idtipo_pico || "Este campo es obligatorio"];
      },
    },
  },
  data: () => ({
    syncInput: null,
    crud: {
      add: false
    }
  }),
  mounted() {
    this.fetchTipoPico();
  },
  computed: {
    ...mapGetters("tipopico", ["getTipoPico", "isTipoPicoLoading"]),
    isPermission() {
      return getPermission(ZAFRA).permiso[PUEDE_AGREGAR];
    },
  },
  methods: {
    ...mapActions("tipopico", ["fetchTipoPico"]),
    focus() {
      this.$refs.input.focus();
    },
    isMenuActive() {
      this.$refs.input.isMenuActive = false;
    },
  },
};
</script>

<style></style>
