import { db } from "@/services/db.service";
export default {

    async Vact_unidadmedida_data({ commit }) {
        commit('unidadmedida_load', true);
        try {
            const data = await db.get('/apimedida/');
            commit('unidadmedida_data', data);
            commit('unidadmedida_load', false);
            return true
        } catch (e) {
            commit('unidadmedida_load', false);
            return false
        }
    },
    async Vact_unidadmedida_data_magnitud({commit},id){
        if(id === null){
            commit('unidadmedida_data_magnitud',[]);
            return true;
        }
        commit('unidadmedida_load',true);
        try {
            const data = await db.get('/apimedida/?magnitud='+id);
            commit('unidadmedida_data_magnitud',data);
            commit('unidadmedida_load',false);
            return true
        } catch (e) {
            commit('unidadmedida_load',false);
            return false
        } 
    }
}
