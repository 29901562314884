<template>
  <div>
    <v-card>
      <v-card-actions>
        <span class="title ml-1">Informacion Personal </span
        ><v-divider class="mx-2"></v-divider>
        <v-btn
          v-if="editable"
          color="purple darken-3"
          fab
          text
          outlined
          small
          @click="isNotEditing = !isNotEditing"
        >
          <v-icon v-if="isNotEditing"> edit </v-icon>
          <v-icon v-else> close </v-icon>
        </v-btn>
      </v-card-actions>
      <v-form ref="form">
        <v-row dense class="mx-2">
          <v-col cols="12" md="6">
            <c-text-date
              ref="infoper0"
              :disabled="isNotEditing"
              label="Fecha de Ingreso"
              v-model="data.fecha_ingreso"
              @keyup.native.enter="next(0, data.fecha_ingreso)"
            ></c-text-date>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              label="CI / DNI"
              autocomplete="off"
              :loading="isLoadingContacto"
              :disabled="isNotEditing"
              ref="infoper1"
              type="number"
              :rules="[(v) => !!v || 'Campo obligatorio']"
              v-model="data.idcontacto.ruccontacto"
              :hint="
                !isNotEditing
                  ? hintSearch
                    ? 'Presione en la lupa para buscar el contacto'
                    : ''
                  : ''
              "
              :append-outer-icon="
                !isNotEditing ? (hintSearch ? 'search' : '') : ''
              "
              @click:append-outer="searchFirebase(data.idcontacto.ruccontacto)"
              @keyup.native.enter="next(1, data.idcontacto.ruccontacto)"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <c-text-field
              ref="infoper2"
              label="Nombres"
              :disabled="isNotEditing"
              v-model="data.nombre"
              @keyup.native.enter="next(2, data.nombre)"
            ></c-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <c-text-field
              ref="infoper3"
              label="Apellidos"
              :disabled="isNotEditing"
              v-model="data.apellido"
              @keyup.native.enter="next(3, data.apellido)"
            ></c-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <c-text-date
              ref="infoper4"
              :disabled="isNotEditing"
              label="Fecha Nacimiento"
              v-model="data.idcontacto.fechcontacto"
              @keyup.native.enter="next(4, data.idcontacto.fechcontacto)"
            ></c-text-date>
          </v-col>
          <v-col cols="12" md="6">
            <v-select
              ref="infoper5"
              :items="sexo"
              :disabled="isNotEditing"
              label="Genero"
              item-text="desc"
              item-value="id"
              v-model="data.sexo"
              @keyup.native.enter="
                next(5, '-'), ($refs.infoper5.isMenuActive = false)
              "
            ></v-select>
          </v-col>

          <v-col cols="12" md="6">
            <autocomplete-estadocivil
              ref="infoper6"
              :disabled="isNotEditing"
              v-model="data.idestado_civil"
              @keyup.native.enter="
                next(6, data.idestado_civil.idestado_civil),
                  $refs.infoper6.isMenuActive()
              "
            />
          </v-col>
          <v-col cols="12" md="6">
            <c-text-currency
              ref="infoper7"
              :disabled="isNotEditing"
              v-model="children"
              :rules="[
                (v) => v < 16 || 'No se puede registrar mas de 15 Hijos',
              ]"
              label="Cantidad Hijos/as"
              :messages="
                children > 0
                  ? 'Precaucion, evite modificar este campo si cargo Informacion de Hijos/as'
                  : ''
              "
              @keyup.native="generateChild()"
              @keyup.native.enter="next(7, '-')"
            ></c-text-currency>
          </v-col>
          <v-col cols="12" md="6">
            <c-text-field
              ref="infoper8"
              :disabled="isNotEditing"
              label="Telefono"
              v-model="data.idcontacto.telecontacto"
              @keyup.native.enter="next(8, data.idcontacto.telecontacto)"
            ></c-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <c-text-field
              ref="infoper9"
              :disabled="isNotEditing"
              label="Correo"
              :rules="[]"
              v-model="data.idcontacto.correo"
              @keyup.native.enter="next(9, '-')"
            ></c-text-field>
          </v-col>
          <v-col cols="12" md="12">
            <c-text-field
              ref="infoper10"
              :disabled="isNotEditing"
              label="Profesion"
              v-model="data.profesion"
              @keyup.native.enter="next(10, '-')"
            ></c-text-field>
          </v-col>
        </v-row>
      </v-form>
      <v-card-actions>
        <span class="title ml-1">Direccion</span><v-divider></v-divider>
      </v-card-actions>
      <v-row class="mx-2" dense>
        <v-col cols="12" md="6">
          <autocomplete-nacionalidad
            ref="infoper11"
            :rules="[]"
            :disabled="isNotEditing"
            label="Nacionalidad"
            v-model="data.idcontacto.idnacionalidad"
            @keyup.native.enter="next(11, '-'), $refs.infoper11.isMenuActive()"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <autocomplete-departamento
            ref="infoper12"
            label="Departamento"
            :disabled="isNotEditing"
            :rules="[]"
            :filter-country="idnacionalidad"
            v-model="data.idcontacto.iddepartamento"
            @keyup.native.enter="next(12, '-'), $refs.infoper12.isMenuActive()"
          />
        </v-col>
        <v-col cols="12" md="6">
          <autocomplete-ciudad
            ref="infoper13"
            label="Ciudad"
            :disabled="isNotEditing"
            :rules="[]"
            :filter-departament="iddepartamento"
            v-model="data.idcontacto.idciudad"
            @keyup.native.enter="next(13, '-'), $refs.infoper13.isMenuActive()"
          />
        </v-col>
        <v-col cols="12">
          <c-text-field
            ref="infoper14"
            :rules="[]"
            :disabled="isNotEditing"
            label="Direccion"
            v-model="data.idcontacto.direcontacto"
            @keyup.native.enter="next(14, '-')"
          ></c-text-field>
        </v-col>
      </v-row>
      <v-card-actions>
        <span class="title ml-1">Datos Reloj</span><v-divider></v-divider>
      </v-card-actions>
      <v-row class="mx-2" dense>
        <v-col cols="12" md="6">
          <c-text-currency
            ref="infoper7"
            :disabled="isNotEditing"
            v-model="data.idcontacto_reloj"
            label="Nro Reloj"
          ></c-text-currency>
        </v-col>
      </v-row>
      <v-card-actions>
        <span class="title ml-1">Datos Bancarios</span><v-divider></v-divider>
      </v-card-actions>
      <v-row class="mx-2" dense>
        <v-col cols="12" md="6">
          <autocomplete-banco
            ref="infoper15"
            label="Banco"
            create
            :disabled="isNotEditing"
            :rules="[]"
            v-model="data.idbanco"
            @keyup.native.enter="next(15, '-'), $refs.infoper15.isMenuActive()"
          />
        </v-col>
        <v-col cols="12" md="6">
          <c-text-field
            ref="infoper16"
            :rules="[]"
            :disabled="isNotEditing"
            label="Numero de Cuenta Bancaria"
            v-model="data.nrocuenta"
          ></c-text-field>
        </v-col>
      </v-row>
      <v-card-actions v-if="children > 0 && children < 11">
        <span class="title ml-1">Informacion de Hijos/as</span
        ><v-divider></v-divider>
      </v-card-actions>
      <div v-if="children > 0">
        <v-form ref="formChild">
          <v-row dense class="mx-2" v-for="(item, i) in data.hijo" :key="i">
            <v-col cols="12">
              <v-row dense class="mb-n3 mt-n3">
                <v-col cols="11" class="mt-4">
                  <v-progress-linear
                    height="2"
                    class="mb-0"
                    v-if="i > 0"
                    value="100"
                  ></v-progress-linear>
                </v-col>
                <v-spacer></v-spacer>

                <v-btn
                  color="red"
                  text
                  small
                  fab
                  @click="deletFile(i)"
                  :disabled="isNotEditing"
                  ><v-icon>clear</v-icon></v-btn
                >
              </v-row>
            </v-col>
            <v-col cols="12" md="6">
              <c-text-field
                dense
                label="Nombres"
                :disabled="isNotEditing"
                v-model="data.hijo[i].nombre"
              ></c-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <c-text-field
                dense
                label="Apellidos"
                :disabled="isNotEditing"
                v-model="data.hijo[i].apellido"
              ></c-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <c-text-date
                dense
                label="Fecha Nacimiento"
                :disabled="isNotEditing"
                v-model="data.hijo[i].fecha_nacimiento"
              ></c-text-date>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" md="7">
              <v-row align="center">
                <v-checkbox
                  v-model="data.hijo[i].totalmente_discapacitado"
                  hide-details
                  :disabled="isNotEditing"
                  class="shrink mr-1 mt-0 pb-3"
                ></v-checkbox>
                <c-text-field
                  dense
                  :rules="
                    data.hijo[i].totalmente_discapacitado ? undefined : []
                  "
                  v-model="data.hijo[i].comentario"
                  :disabled="
                    !data.hijo[i].totalmente_discapacitado && isNotEditing
                  "
                  :label="
                    !data.hijo[i].totalmente_discapacitado
                      ? 'Posee alguna discapacidad?'
                      : 'Especifique'
                  "
                ></c-text-field>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
        <v-btn
          text
          class="mb-2"
          v-if="children > 0 && children < 15 && !isNotEditing"
          color="deep-purple accent-4"
          block
          outlined
          @click="addChildren()"
          >Agregar otro Hijo</v-btn
        >
      </div>
      <v-divider></v-divider>
      <v-card-actions>
        <c-btn-crud-close @click="cancel()" v-if="!editable"></c-btn-crud-close>
        <v-spacer></v-spacer>
        <c-btn-crud-done
          @click="guardar()"
          v-if="!isNotEditing"
        ></c-btn-crud-done>
      </v-card-actions>
      <c-dialog-confirmation
        v-model="delet.dialog"
        @cancel="delet.dialog = false"
        @done="confirmDeleteChildren()"
        >Desea quitar esta fila?
      </c-dialog-confirmation>

      <c-overlay :value="isFuncionarioLoading"></c-overlay>
    </v-card>
  </div>
</template>

<script>
import autocompleteNacionalidad from "@/views/Mantenimientos/Nacionalidad/autocomplete";
import autocompleteCiudad from "@/views/Mantenimientos/Ciudad/autocomplete";
import autocompleteDepartamento from "@/views/Mantenimientos/Departamento/autocomplete";
import autocompleteEstadoCivil from "@/views/Mantenimientos/EstadoCivil/autocomplete";
import autocompleteBanco from "@/views/Apertura/Banco/autocomplete";
import { current_date } from "@/services/util/date.service";
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
    props: {
      type: Object,
      default: null,
    },
  },
  components: {
    "autocomplete-nacionalidad": autocompleteNacionalidad,
    "autocomplete-ciudad": autocompleteCiudad,
    "autocomplete-banco": autocompleteBanco,
    "autocomplete-departamento": autocompleteDepartamento,
    "autocomplete-estadocivil": autocompleteEstadoCivil,
  },
  data: () => ({
    isNotEditing: false,
    children: 0,
    data: {
      idcontacto: {
        idcontacto: 0,
        ruccontacto: "",
        direcontacto: "",
        fechcontacto: "",
        telecontacto: "",
        correo: "",
        idnacionalidad: {
          idnacionalidad: "",
        },
        idciudad: {
          idciudad: "",
        },
        iddepartamento: {
          iddepartamento: "",
        },
      },
      idcontacto_reloj: 0,
      fecha_ingreso: current_date(),
      funcionario_usuario: [],
      marcar_aplicacion: false,
      nombre: "",
      apellido: "",
      idestado_civil: {
        idestado_civil: "",
      },
      idbanco: {
        idbanco: "",
      },
      iddepartamento: {
        iddepartamento: "",
      },
      nrocuenta: "",
      sexo: false,
      hijo: [],
    },
    default: {
      idcontacto: {
        idcontacto: 0,
        ruccontacto: "",
        direcontacto: "",
        fechcontacto: "",
        telecontacto: "",
        correo: "",
        idnacionalidad: {
          idnacionalidad: "",
        },
        idciudad: {
          idciudad: "",
        },
      },
      idcontacto_reloj: 0,
      fecha_ingreso: current_date(),
      funcionario_usuario: [],
      marcar_aplicacion: false,
      nombre: "",
      apellido: "",
      idestado_civil: {
        idestado_civil: "",
      },
      idbanco: {
        idbanco: "",
      },
      nrocuenta: "",
      sexo: false,
      hijo: [],
    },
    hijo: {
      idfuncionario_hijo: 0,
      nombre: "",
      apellido: "",
      fecha_nacimiento: "",
      totalmente_discapacitado: false,
      comentario: "",
      idcontacto: 0,
    },
    delet: {
      dialog: false,
      index: null,
    },
    sexo: [
      { id: true, desc: "Femenino" },
      { id: false, desc: "Masculino" },
    ],
  }),
  watch: {
    $route(to, from) {
      if (from.path === "/rrhh/funcionarios") return this.cancel();
    },
    getContactoFirebase(val) {
      if (!val.data.desccontacto) return null;
      if (val != null) this.asignarContactoDesdeOrigen(val);
    },
  },
  mounted() {
    setTimeout(() => this.next(-1, "-"), 20);
    if (this.editable) {
      this.data = JSON.parse(JSON.stringify(this.props));
      this.isNotEditing = true;
    }
  },
  computed: {
    ...mapGetters("funcionario", ["isFuncionarioLoading"]),
    ...mapGetters("contacto", ["isLoadingContacto", "getContactoFirebase"]),
    idnacionalidad() {
      if (this.data.idcontacto.idnacionalidad)
        return this.data.idcontacto.idnacionalidad.idnacionalidad;
      return null;
    },
    iddepartamento() {
      if (this.data.idcontacto.iddepartamento)
        return this.data.idcontacto.iddepartamento.iddepartamento;
      return null;
    },

    hintSearch() {
      if (this.data.idcontacto.ruccontacto)
        return this.data.idcontacto.ruccontacto.toString().length > 4
          ? true
          : false;
      return false;
    },
  },

  methods: {
    ...mapActions("funcionario", [
      "setFuncionario",
      "setFuncionarioUpdate",
      "fetchFuncionario",
    ]),
    ...mapActions("contacto", ["fetchContactoFirebase"]),
    asignarContactoDesdeOrigen(item) {
      let desccontacto = item.data.desccontacto.split(" ");
      const mitad = Math.floor(desccontacto.length / 2);
      desccontacto.map((x, i) => {
        if (i < mitad) {
          item.data.nombre = item.data.nombre || "" + " " + x;
        } else {
          item.data.apellido = item.data.apellido || "" + " " + x;
        }
      });
      this.data.nombre = item.data.nombre;
      this.data.apellido = item.data.apellido;
      switch (item.origin) {
        case "db":
          this.data.idcontacto = JSON.parse(JSON.stringify(item.data));
          this.$emit("editable", true);
          break;
        case "firebase":
          this.data.idcontacto = { ...this.data.idcontacto, ...item.data };
          break;
        default:
          console.log("Caso desconocido");
          break;
      }
    },
    searchFirebase(ruc) {
      if (isNaN(ruc) || ruc.toString().length < 5) return null;
      this.fetchContactoFirebase(ruc);
    },
    addChildren() {
      this.children++;
      this.data.hijo.push(JSON.parse(JSON.stringify(this.hijo)));
    },
    cancel() {
      this.$emit("input", false);
    },
    generateChild() {
      this.data.hijo = [];
      if (this.children < 0 || this.children > 15) return null;
      let count = 0;
      while (this.children > count) {
        this.data.hijo.push(JSON.parse(JSON.stringify(this.hijo)));
        count++;
      }
    },

    next(id, data) {
      if (data != "")
        try {
          this.$refs[`infoper${id + 1}`].focus();
        } catch (error) {
          this.$refs[`infoper${id + 1}`].$el.focus();
        }
    },
    deletFile(index) {
      this.delet.dialog = true;
      this.delet.index = index;
    },
    confirmDeleteChildren() {
      this.data.hijo.splice(this.delet.index, 1);
      this.children = this.children - 1;
      this.delet.dialog = false;
    },
    isValid() {
      const form = this.$refs.form.validate();
      if (!this.$refs.formChild) return form;
      const child = this.$refs.formChild.validate();
      if (form && child) return true;
      return false;
    },
    async guardar() {
      if (!this.isValid()) return null;
      const response = this.editable
        ? await this.setFuncionarioUpdate({
            data: this.data,
            id: this.data.idcontacto.idcontacto,
          })
        : await this.setFuncionario(this.data);
      if (response.success) {
        this.fetchFuncionario({ active: true });
        if (this.editable) return (this.isNotEditing = true);
        this.data = JSON.parse(JSON.stringify(this.default));
        this.$refs.form.resetValidation();
        this.next(-1, "-");
      }
    },
  },
};
</script>

<style></style>
