<template>
  <v-card>
    <c-alert-permission v-model="permission"></c-alert-permission>
    <v-toolbar flat dense class="mb-3" v-if="permission.libroventa">
      <v-toolbar-title>{{ titulo }}</v-toolbar-title>
      <widgets-Favorite ruta="/libroventa" :formulario="titulo" />
      <v-divider class="mx-2" inset vertical></v-divider>
    </v-toolbar>
    <LibroCompraVenta  mode="VENTA" v-if="permission.libroventa"></LibroCompraVenta>
  </v-card>
</template>


<script>
import LibroCompraVenta from "@/views/Contabilidad/Libros/LibroCompraVenta";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { REPORTE_COMERCIAL } from "@/constants/forms";
import { LIBRO_VENTA } from "@/constants/permissions";
const FORM = "Libro de Ventas";
export default {
  components: { LibroCompraVenta },
  data() {
    return {
      titulo: FORM,
      permission: {
        libroventa: false,
      },
    };
  },
  mounted() {
    this.$store.commit("SET_LAYOUT", "principal-layout");
    const permission = getPermission(REPORTE_COMERCIAL)
      .permiso;
    this.permission = {
      libroventa: permission[LIBRO_VENTA],
    };
  },
};
</script>

<style>
</style>