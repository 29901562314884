<template>
  <v-dialog persistent max-width="700" :value="value">
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
      </v-toolbar>
      <v-container>
        <v-form ref="form" @submit.prevent="">
          <v-row dense>
            <v-col cols="6">
              <c-text-date
                autocomplete="off"
                label="Fecha"
                :rules="rules"
                outlined
                dense
                ref="maquinaria1"
                v-model="data.fecha"
                placeholder="Ingrese la fecha"
                @keyup.native.enter="next(1, data.fecha)"
                @change="getmonto()"
              ></c-text-date>
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                dense
                v-model="data.idmaquinaria_asignacion"
                ref="maquinaria2"
                v-bind:items="getMaquinariaAsignacion"
                label="Maquinaria Asignacion"
                item-value="idmaquinaria_asignacion"
                return-object
                :rules="[
                  (v) =>
                    !!v.idmaquinaria_asignacion || 'Seleccione una asignacion',
                ]"
                required
                outlined
                autocomplete="off"
                @change="getmonto()"
                @keyup.native.enter="
                  next(2, data.idmaquinaria_asignacion.idmaquinaria_asignacion)
                "
                :no-data-text="`No existe ninguna coincidencia`"
              >
                <template slot="selection" slot-scope="data">
                  {{ data.item.idmaquinaria.descripcion }} -
                  {{ data.item.idmaquinaria.chapa }}
                </template>
                <template slot="item" slot-scope="data">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ data.item.idmaquinaria.descripcion }} -
                      {{ data.item.idmaquinaria.chapa }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ data.item.idchofer.nombre }}
                      {{ data.item.idchofer.apellido }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="6">
              <c-text-currency
                autocomplete="off"
                label="Favor"
                :rules="rules"
                outlined
                dense
                ref="maquinaria3"
                v-model="data.favor"
                placeholder="Ingrese el monto a favor"
                @keyup.native.enter="next(3, data.favor)"
              ></c-text-currency>
            </v-col>
            <v-col cols="6">
              <c-text-currency
                autocomplete="off"
                label="Viatico"
                :rules="rules"
                outlined
                dense
                ref="maquinaria4"
                v-model="data.viaticos"
                placeholder="Ingrese el monto de viatico"
                @keyup.native.enter="next(4, data.viaticos)"
              ></c-text-currency>
            </v-col>
            <v-col cols="6">
              <c-text-currency
                autocomplete="off"
                label="Haberes"
                :rules="rules"
                outlined
                dense
                ref="maquinaria5"
                v-model="data.haberes"
                placeholder="Ingrese el monto del haber"
                @keyup.native.enter="next(5, data.haberes)"
              ></c-text-currency>
            </v-col>
            <v-col cols="6">
              <c-text-currency
                autocomplete="off"
                label="Reembolso"
                :rules="rules"
                outlined
                dense
                ref="maquinaria6"
                v-model="data.reembolso"
                placeholder="Ingrese el monto de reembolso"
                @keyup.native.enter="next(6, data.reembolso)"
              ></c-text-currency>
            </v-col>
            <v-col cols="6">
              <c-text-currency
                autocomplete="off"
                label="Monto"
                :rules="rules"
                outlined
                dense
                ref="maquinaria7"
                v-model="data.monto"
                placeholder="Ingrese el monto"
                @keyup.native.enter="next(7, data.monto)"
              ></c-text-currency>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
      <v-divider></v-divider>
      <v-card-actions>
        <c-btn-crud-close @click="cancel()"></c-btn-crud-close>
        <v-spacer></v-spacer>
        <c-btn-crud-done ref="maquinaria6" @click="save()"></c-btn-crud-done>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    props: {
      type: Object,
    },
    "sync-input": {
      type: [String, Number],
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    getMaquinariaMonto(value) {
      this.data.favor = (value.transporte * 14) / 100;
      this.data.viaticos = value.gastos_viaje;
      this.data.haberes = value.haberes;
      this.data.reembolso = value.gastos_reembolsar;
      this.data.monto =
        this.data.favor -
        this.data.viaticos +
        this.data.haberes +
        this.data.reembolso;
    },
  },
  mounted() {
    if (this.editable) {
      this.data = JSON.parse(JSON.stringify(this.props));
    }
    setTimeout(() => this.next(0, "-"), 200);
  },
  created() {
    this.fetchMaquinariaAsignacion();
  },
  computed: {
    ...mapGetters("maquinaria_asignacion", [
      "isLoadingMaquinariaAsignacion",
      "getMaquinariaAsignacion",
    ]),
    ...mapGetters("maquinaria_salario", [
      "isLoadingMaquinariaMonto",
      "getMaquinariaMonto",
    ]),
    title() {
      return this.editable
        ? "Modificar Transporte Maquinaria"
        : "Registrar Transporte Maquinaria";
    },
  },
  methods: {
    ...mapActions("maquinaria_asignacion", ["fetchMaquinariaAsignacion"]),
    ...mapActions("maquinaria_salario", [
      "fetchMaquinariaMonto", 
      "fetchMaquinariaSalario",
      "setMaquinariaSalario",
      "setMaquinariaSalarioUpdate"
    ]),
    next(id, data) {
      if (data != "")
        try {
          this.$refs[`maquinaria${id + 1}`].focus();
        } catch (error) {
          this.$refs[`maquinaria${id + 1}`].$el.focus();
        }
    },
    cancel() {
      this.$emit("input", false);
    },
    async save() {
      if (!this.$refs.form.validate()) return null;
      const response = this.editable
        ? await this.setMaquinariaSalarioUpdate({
            data: this.data,
            id: this.data.idmaquinaria_transporte,
          })
        : await this.setMaquinariaSalario(this.data);
      if (response.success) {
        if (this.sync != null) {
          this.cancel();
          this.fetchMaquinariaSalario();
          return this.$emit(
            "sync-input",
            response.data.idmaquinaria_transporte
          );
        }
        this.data = JSON.parse(JSON.stringify(this.default));
        this.$refs.form.resetValidation();
        this.fetchMaquinariaSalario();
        this.next(0, "-");
        if (this.editable) this.cancel();
      }
    },
    async getmonto() {
      if (
        this.data.fecha != "" & this.data.idmaquinaria_asignacion.idmaquinaria_asignacion != ""
      ) {
        await this.fetchMaquinariaMonto({
          y: this.data.fecha.split("-")[0],
          m: this.data.fecha.split("-")[1],
          a: this.data.idmaquinaria_asignacion.idmaquinaria_asignacion,
        });
      }
    },
  },
  data: () => ({
    rules: [(v) => !!v || "Este campo es obligatorio"],
    data: {
      idmaquinaria_salario: 0,
      fecha: "",
      idmaquinaria_asignacion: {
        idmaquinaria_asignacion: "",
      },
      favor: 0,
      viaticos: 0,
      haberes: 0,
      reembolso: 0,
      monto: 0,
    },
    default: {
      idmaquinaria_salario: 0,
      fecha: "",
      idmaquinaria_asignacion: {
        idmaquinaria_asignacion: "",
      },
      favor: 0,
      viaticos: 0,
      haberes: 0,
      reembolso: 0,
      monto: 0,
    },
    sync: null,
  }),
};
</script>