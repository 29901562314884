<template>
  <v-row dense>
    <c-overlay :value="isLoading"></c-overlay>
    <v-col cols="12" lg="2" md="3" sm="4" v-for="(item, i) in count" :key="i">
      <v-card dark @click="routeTo(item.query)" :color="item.color">
        <v-container>
          <v-row dense>
            <v-col cols="12"
              ><div class="subtitle-2">{{ item.nombre }}</div></v-col
            >
            <v-col cols="12" class="my-n3">
              <div class="d-flex justify-space-between">
                <v-icon>{{ item.icon }}</v-icon>
                <div class="text-h4 font-weight-black">{{ item.cantidad }}</div>
              </div>
            </v-col>
            <v-col cols="12" v-if="!item.sub">
              <div class="caption">Listar {{ item.nombre }}</div>
            </v-col>
            <v-col cols="12" v-else>
              <span v-for="(sub, j) in item.sub" :key="j" class="mr-1">
                <span class="caption">{{ sub.title }}: </span>
                <span class="caption font-weight-black">{{ sub.count }}</span>
              </span>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { CARDS } from "../../Animal/Configuracion/Configuracion";
import ApiService from "@/services/auth/api.service";

export default {
  props: {
    icon: String,
    title: String,
    subTitle: String,
    color: String,
  },
  data: () => ({
    count: [],
    isLoadingSocket: false,
    mountedFirst: true,
  }),
  mounted() {
    this.fetchCount();
    this.init();
  },
  watch: {
    getCount() {
      this.estructurarDatos();
    },
    $route(to) {
      if (to.path === "/dashboard") this.fetchCount();
      if (to.path === "/") this.fetchCount();
    },
    getSocketData(val) {
      if (this.mountedFirst) {
        this.count.forEach((value, index) => {
          val.data.forEach((x) => {
            if (value.nombre == x.nombre) {
              if (x.nombre === "Vivas") {
                this.count[index].sub = [
                  {
                    title: "Macho",
                    count: x.M
                  },
                  {
                    title: "Hembra",
                    count: x.H
                  }
                ];
                this.count[index].cantidad = x.cantidad;
              } else if (x.nombre === "Stock de Semen") {
                this.count[index].sub = [
                  {
                    title: "Animal en termo",
                    count: x.cantidad
                  }
                ];
                this.count[index].cantidad = x.P;
              } else {
                this.count[index].cantidad = x.cantidad;
              }
            }
          })
        })
      }
      return val
    }
  },
  computed: {
    ...mapGetters("animal", ["getCount", "isLoading", "getSocketData"]),
    ...mapGetters("auth", ["getAccessToken"]),
  },
  methods: {
    ...mapActions("animal", ["fetchCount", "setSocketData"]),
    init() {
      this.reset();
      this.connectWebSocket();
    },
    reset() {
      try {
        this.connection.close();
      } catch (error) {
        console.log(error.message);
      }
      this.setSocketData(null);
      this.mountedFirst = true;
    },
    estructurarDatos() {
      this.count = [];
      this.count = this.getCount.map((item) => {
        if (item.nombre === "Vivas") {
          item.sub = [
            {
              title: "Macho",
              count: item.M,
            },
            {
              title: "Hembra",
              count: item.H,
            },
          ];
        }
        if (item.nombre === "Stock de Semen") {
          item.sub = [
            {
              title: "Animal en termo",
              count: item.cantidad,
            },
          ];
          item.cantidad = item.P;
        }
        const card = CARDS.find((card) => card.nombre === item.nombre);
        return {
          ...item,
          icon: card.icon,
          query: card.query,
        };
      });
    },
    routeTo(query) {
      this.$router.push({ path: "/animal", query });
    },
    connectWebSocket() {
      this.isLoadingSocket = true;
      const wsRoute = "/ws/general/animal/?token=" + this.getAccessToken;
      const [protocol, hostWithRoute] = ApiService.getBaseURL().split("//");
      const [wsHost] = hostWithRoute.split("/");
      const wsProtocol = protocol === "https:" ? "wss://" : "ws://";
      this.connection = new WebSocket(wsProtocol + wsHost + wsRoute);
      const self = this;

      this.connection.onmessage = (event) => {
        self.setSocketData(JSON.parse(event.data));
      };
      this.connection.onopen = () => {
        self.isLoadingSocket = false;
        self.setSocketData(null);
        console.info("Successfully connected websocket");
      };
      this.connection.onerror = () => {
        self.setSocketData(null);
        self.isLoadingSocket = false;
        console.error("Failed connected websocket");
      };
      this.connection.onclose = (event) => {
        console.log("WebSocket connection closed:", event.code);
      };
    },
  },
};
</script>
