
export default {

  medicamento_create: (state) => {
    return state.medicamento_create
  },
  medicamento_update: (state) => {
    return state.medicamento_update
  },
  medicamento_reload: (state) => {
    return state.medicamento_reload
  },
  medicamento_show: (state) => {
    return state.medicamento_show
  },
  medicamento_validar: (state) => {
    return state.medicamento_validar
  },
}
