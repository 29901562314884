<template>
  <div>
    <v-dialog
      :value="value"
      max-width="900"
      persistent
      :fullscreen="$vuetify.breakpoint.xs"
    >
      <v-card>
        <v-form ref="formCabecera">
          <v-toolbar flat>
            <v-toolbar-title>{{
              readonly
                ? "Detalles"
                : operacion
                ? "Registro de Insumos"
                : "Modificar Insumos"
            }}</v-toolbar-title>
            <v-divider class="mx-2" inset vertical></v-divider>
            <v-spacer></v-spacer>
          </v-toolbar>
        </v-form>
        <v-container>
          <v-form ref="form" @submit.prevent="next(1, insumo.fecha)">
            <v-row dense>
              <v-col cols="12" md="2" sm="3">
                <c-text-date
                  label="Fecha"
                  id="insumoInput1"
                  outlined
                  dense
                  :readonly="readonly"
                  v-model="insumo.fecha"
                  @focus="setSelection(`insumoInput1`)"
                  @keyup.native.enter="next(1, insumo.fecha)"
                ></c-text-date>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="12" md="8" sm="8">
                <vue-autocomplete-contacto-proveedor
                  :readonly="readonly"
                  label="Proveedor"
                  id="insumoInput2"
                  v-model="insumo.idcontacto.idcontacto"
                  @keyup.native.enter="next(2, insumo.idcontacto.idcontacto)"
                />
              </v-col>
            </v-row>
          </v-form>
          <v-form ref="formdetalle">
            <v-row dense>
              <v-col cols="12" md="4" sm="4">
                <AutocompleteItem
                  dense
                  v-model="egresoagrodet.item"
                  label="Item"
                  @change="asignarPrecio()"
                  @keyup.enter="next(3, egresoagrodet.iditem.iditem)"
                />
                <v-autocomplete
                  dense
                  v-model="egresoagrodet.iditem"
                  id="insumoInput3"
                  v-bind:items="Vget_item_data"
                  :search-input.sync="search"
                  label="Item"
                  item-text="descitem"
                  return-object
                  :readonly="readonly"
                  :rules="[(v) => !!v.iditem || 'Seleccione un Item']"
                  :loading="Vget_item_load"
                  required
                  outlined
                  autocomplete="off"
                  @change="asignarPrecio()"
                  @keyup.enter="next(3, egresoagrodet.iditem.iditem)"
                  :no-data-text="`No existe ninguna concidencia`"
                >
                  <template v-if="item_permission" v-slot:no-data>
                    <v-list-item @click="formularios.item = true">
                      <v-list-item-content>
                        <v-list-item-title
                          >No existe concidencia. Click para
                          agregar</v-list-item-title
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
                <div v-if="formularios.item">
                  <Item-Item-create
                    :sync-input="search"
                    @sync-input="
                      (egresoagrodet.iditem = $event),
                        next(3, egresoagrodet.iditem.iditem)
                    "
                    v-model="formularios.item"
                  />
                </div>
              </v-col>

              <v-col cols="12" md="2" sm="2">
                <vue-text-currency
                  label="Cantidad"
                  id="insumoInput4"
                  type="number"
                  :readonly="readonly"
                  @focus="setSelection(`insumoInput4`)"
                  :rules="true"
                  v-model="egresoagrodet.cantidad"
                  @keyup.native.enter="next(4, egresoagrodet.cantidad)"
                ></vue-text-currency>
              </v-col>

              <v-col cols="12" md="2" sm="2">
                <vue-text-currency
                  label="Precio"
                  :rules="true"
                  :readonly="readonly"
                  v-model="egresoagrodet.precio"
                  @focus="setSelection(`insumoInput5`)"
                  id="insumoInput5"
                  @keyup.native.enter="next(5, egresoagrodet.precio)"
                ></vue-text-currency>
              </v-col>
              <v-col cols="12" md="2" sm>
                <vue-autocomplete-object
                  :readonly="readonly"
                  id="insumoInput6"
                  action="deposito/Vact_deposito_data"
                  getter="deposito/Vget_deposito_data"
                  load="deposito/Vget_deposito_load"
                  label="Deposito"
                  title="desdeposito"
                  :create="true"
                  validar="iddeposito"
                  @create="formularios.deposito = $event"
                  permission="Deposito"
                  v-model="egresoagrodet.iddeposito"
                  @keyup.native.enter="
                    next(6, egresoagrodet.iddeposito.iddeposito)
                  "
                />
                <div v-if="formularios.deposito">
                  <Item-Deposito-Create
                    :sync-input="search"
                    @sync-input="
                      (egresoagrodet.iddeposito = $event),
                        next(6, egresoagrodet.iddeposito.iddeposito)
                    "
                    v-model="formularios.deposito"
                  />
                </div>
              </v-col>
              <v-spacer></v-spacer>
              <v-btn
                id="insumoInput7"
                :disabled="readonly"
                outlined
                color="primary"
                fab
                small
                @click="addTable()"
              >
                <v-icon>add</v-icon>
              </v-btn>
              <v-col cols="12">
                <v-data-table
                  :headers="headers"
                  :items="insumo.egresoagrodet"
                  :items-per-page="100"
                  hide-default-footer
                >
                  <template v-slot:item="props">
                    <tr>
                      <td class="text-xs-letf">
                        {{ props.item.iditem.descitem }}
                      </td>
                      <td class="text-xs-letf">
                        {{ props.item.iddeposito.desdeposito }}
                      </td>
                      <td class="text-xs-letf">
                        {{ currencyFormat(props.item.precio) }}
                      </td>
                      <td class="text-xs-letf">
                        {{ currencyFormat(props.item.cantidad) }}
                      </td>
                      <td class="text-end">
                        {{ currencyFormat(props.item.total) }}
                      </td>
                      <td class="text-end">
                        <v-icon
                          class="mr-2"
                          @click="editarDetalle(props.item)"
                          :disabled="readonly"
                          >edit</v-icon
                        >
                        <v-icon
                          small
                          @click="eliminarDetalle(props.item)"
                          :disabled="readonly"
                          >delete</v-icon
                        >
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-form>
          <v-divider class="mb-2"></v-divider>
          <v-row dense no-gutters>
            <v-spacer></v-spacer>
            <v-col cols="6" sm="3">
              <vue-text-currency
                label="Total Egreso"
                :rules="false"
                :readonly="true"
                :length="4"
                :value="totalEgreso"
              ></vue-text-currency>
            </v-col>
            <v-col cols="12">
              <vue-text-field
                label="Comentario"
                :rules="false"
                :readonly="readonly"
                v-model="insumo.comentario"
              ></vue-text-field>
            </v-col>
            <v-btn color="red" text @click="cerrar()">
              <v-icon>clear</v-icon>
            </v-btn>
            <v-spacer></v-spacer>

            <v-btn
              color="primary"
              :disabled="readonly"
              text
              @click="guardarEgreso()"
            >
              <v-icon>done</v-icon>
            </v-btn>
          </v-row>
        </v-container>
        <c-overlay :value="Vget_egresoagro_save_load"></c-overlay>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { current_date } from "@/services/util/date.service";
import { focus } from "@/services/focus.service";
import { mensaje } from "@/services/notify.service";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { ITEM } from "@/constants/forms";
import { PUEDE_AGREGAR } from "@/constants/permissions";
export default {
  components: {
    AutocompleteItem: () => import("@/views/Item/Item/AutocompleteItemVivo2"),
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    datos: {
      type: [Object, Array],
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters("egresoagro", ["Vget_egresoagro_save_load"]),
    ...mapGetters("item", ["Vget_item_data", "Vget_item_load"]),

    item_permission() {
      return getPermission(ITEM).permiso[PUEDE_AGREGAR];
    },

    totalEgreso() {
      let total = 0;
      this.insumo.egresoagrodet.forEach((x) => {
        total = (Number(total) + Number(x.total)).toFixed(4);
      });
      return total;
    },
  },
  mounted() {
    this.next(0, "-");
    this.Vact_item_data();
    if (this.datos != null) {
      this.asignarDatos(this.datos);
      this.operacion = false;
    }
  },
  methods: {
    ...mapActions("egresoagro", [
      "Vact_egresoagro_save",
      "Vact_egresoagro_data",
      "Vact_egresoagro_update",
    ]),
    ...mapActions("item", ["Vact_item_data"]),
    asignarDatos(val) {
      val.egresoagrodet.forEach((x) => {
        x.total = Number(x.precio) * Number(x.cantidad);
      });
      this.insumo = JSON.parse(JSON.stringify(val));
    },
    asignarPrecio() {
      if (this.egresoagrodet.iditem.precitem)
        this.egresoagrodet.precio = this.egresoagrodet.iditem.precitem;
    },
    setSelection(id) {
      setTimeout(() => {
        let input = document.getElementById(id);
        input.focus();
        input.setSelectionRange(0, 10);
      }, 200);
    },
    currencyFormat(value) {
      return new Intl.NumberFormat({ maximumSignificantDigits: 3 }).format(
        value
      );
    },
    async next(id, data) {
      if (data === "" || data <= 0) return null;
      focus.nextidName("insumoInput", id, data);
    },
    editarDetalle(val) {
      const index = this.insumo.egresoagrodet.indexOf(val);
      this.insumo.egresoagrodet.splice(index, 1);
      this.egresoagrodet = val;
    },
    eliminarDetalle(val) {
      const index = this.insumo.egresoagrodet.indexOf(val);
      this.insumo.egresoagrodet.splice(index, 1);
    },

    limpiarCampos() {
      this.insumo = JSON.parse(JSON.stringify(this.default));
      this.egresoagrodet = JSON.parse(
        JSON.stringify(this.egresoagrodet_default)
      );
      this.$refs.formdetalle.resetValidation();
    },
    verificarDetalle() {
      let repite = true;
      this.insumo.egresoagrodet.forEach((x) => {
        if (x.iditem.iditem === this.egresoagrodet.iditem.iditem) {
          repite = false;
        }
      });
      return repite;
    },
    addTable() {
      if (!this.$refs.formdetalle.validate()) return null;
      if (!this.verificarDetalle())
        return this.$notify(mensaje.error("Ya existe este detalle"));
      const total =
        Number(this.egresoagrodet.precio) * Number(this.egresoagrodet.cantidad);
      this.egresoagrodet.total = total;
      this.insumo.egresoagrodet.push(this.egresoagrodet);
      this.egresoagrodet = JSON.parse(
        JSON.stringify(this.egresoagrodet_default)
      );
      this.$refs.formdetalle.resetValidation();
      setTimeout(() => this.next(2, "-"), 200);
    },
    async guardarEgreso() {
      if (!this.$refs.form.validate() || this.insumo.egresoagrodet.length === 0)
        return this.$notify(mensaje.completar());

      let a;
      if (this.operacion) {
        a = await this.Vact_egresoagro_save(this.insumo);
      } else {
        let id = this.insumo.idegresoagro;
        a = await this.Vact_egresoagro_update({ id, data: this.insumo });
      }
      if (!a.estado)
        return a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      this.operacion = true;
      this.$notify(a.info);
      this.$refs.form.resetValidation();
      this.limpiarCampos();
      this.next(0, "-");
    },
    cerrar() {
      this.$emit("input", false);
      this.$emit("datos", null);
      this.$emit("readonly", false);
      this.Vact_egresoagro_data();
      this.limpiarCampos();
    },
  },

  data() {
    return {
      search: "",
      operacion: true,
      formularios: {
        item: false,
        deposito: false,
      },
      insumo: {
        idegresoagro: 0,
        fecha: current_date(),
        idcontacto: {
          idcontacto: "",
        },

        comentario: "",
        estado: true,
        egresoagrodet: [],
      },
      default: {
        idegresoagro: 0,
        fecha: current_date(),
        idcontacto: {
          idcontacto: "",
        },

        comentario: "",
        estado: true,
        egresoagrodet: [],
      },
      egresoagrodet: {
        iditem: {
          iditem: "",
        },
        iddeposito: {
          iddeposito: "",
        },
        cantidad: "",
        precio: "",
      },
      egresoagrodet_default: {
        iditem: {
          iditem: "",
        },
        iddeposito: {
          iddeposito: "",
        },
        cantidad: "",
        precio: "",
      },

      headers: [
        {
          text: "Item",
          align: "start",
          sortable: false,
        },
        {
          text: "Deposito",
          align: "start",
          sortable: false,
        },
        { text: "Precio", sortable: false },
        { text: "Cantidad", sortable: false },
        { text: "SubTotal", sortable: false, align: "right" },
        { text: "Accion", sortable: false, align: "right" },
      ],
    };
  },
};
</script>
