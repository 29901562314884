export default {
  causaaborto_create(state, request) {
    state.causaaborto_create = request;
  }, 
  causaaborto_update(state, request) {
    state.causaaborto_update = request;
  },   
  causaaborto_show(state, request) {
    state.causaaborto_show = request;
  },
  causaaborto_data(state, request) {
    state.causaaborto_data = request;
  },
  causaaborto_load(state, request) {
    state.causaaborto_load = request;
  },
  causaaborto_save_load(state, request) {
    state.causaaborto_save_load = request;
  }
}