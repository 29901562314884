import { db } from "@/services/db.service";
export default {
    async causaaborto_create({commit},request){
        commit('causaaborto_create',request);
    },
    async causaaborto_update({commit},request){
        commit('causaaborto_update',request);
    },
    async causaaborto_show({commit},request){
        commit('causaaborto_show',request);
    },
    async Vact_causaaborto_data({commit}){
        commit('causaaborto_load',true);
        try {
            const data = await db.get('/apicausaaborto/');
            commit('causaaborto_data',data);
            commit('causaaborto_load',false);
            return true
        } catch (e) {
            commit('causaaborto_load',false);
            return false
        } 
    },
    async Vact_causaaborto_del({dispatch},id){
        try {
            const data = await db.delete('/apicausaaborto/'+id);
            dispatch('Vact_causaaborto_data');
            return data
        } catch (e) {
            return false
        } 
    },
    async Vact_causaaborto_save({commit},data){
        commit('causaaborto_save_load',true);
        try {
            const resp = await db.save('/apicausaaborto/',data);
            commit('causaaborto_save_load',false);
            return resp;
        } catch (e) {
            commit('causaaborto_save_load',false);
            return e
        } 
    },
    async Vact_causaaborto_update({commit},{id, data}){
        commit('causaaborto_save_load',true);
        try {
            const resp = await db.update('/apicausaaborto/'+id+'/',data);
            commit('causaaborto_save_load',false);
            return resp;
        } catch (e) {
            commit('causaaborto_save_load',false);
            return e
        } 
    },
}
