import { db } from "@/services/db.service";
import dbMoneda from '@/db/dbMoneda'
import { TokenService } from '@/services/auth/storage.service'

export default {

    async Vact_moneda_data({commit}){
        const xidusuario = TokenService.getToken();
        commit('moneda_load',true);
        try {
            const data = await db.get('/apimoneda/');
            dbMoneda.transaction("rw", dbMoneda.moneda, () => {
                dbMoneda.moneda.clear();
                data.map(x => {
                    let moneda = {...x,...{xidusuario: xidusuario}}
                    dbMoneda.moneda.add(moneda)
                })
                dbMoneda.moneda.toArray().then((x) => {
                    commit('moneda_data',x);
                })
            }).catch(function (e) {
                console.log(e);
            });
            commit('moneda_load',false);
            return true
        } catch (e) {
            dbMoneda.moneda.where('xidusuario').equals(xidusuario).toArray().then((x) => {
                commit('moneda_data', x)
            })
            commit('moneda_load',false);
            return false
        } 
    },
    async Vact_moneda_data_all({commit}){
        commit('moneda_load',true);
        try {
            const data = await db.get('/apimoneda/?band=yes');
            commit('moneda_data_all',data);
            commit('moneda_load',false);
            return true
        } catch (e) {
            commit('moneda_load',false);
            return false
        } 
    },
    async Vact_moneda_cotizacion_day({commit}){
        commit('moneda_load',true);
        try {
            const data = await db.get('/apicotizacion/?band=day');
            commit('moneda_cotizacion_day',data);
            commit('moneda_load',false);
            return true
        } catch (e) {
            commit('moneda_load',false);
            return false
        } 
    },
    async Vact_moneda_cotizacion({commit}){
        commit('moneda_load',true);
        try {
            const data = await db.get('/apimoneda/?band=cotizacion');
            commit('moneda_cotizacion',data);
            commit('moneda_load',false);
            return true
        } catch (e) {
            commit('moneda_load',false);
            return false
        } 
    },
}
