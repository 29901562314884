<template>
  <div>
    <v-dialog
      :value="value"
      max-width="900px"
      persistent
      @keydown.esc="cancelar()"
    >
      <div v-if="formulario.item">
        <Item-Item-create v-model="formulario.item" :sync-input="search" />
      </div>
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>{{
            datos != null ? "Modificar Venta" : "Registrar Venta"
          }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-col v-show="!isCredito">
            <v-autocomplete
              :items="Vget_pedidoventa_data"
              :search-input.sync="searchpedido"
              item-text="idcontacto.desccontacto"
              item-value="idpedido_venta"
              return-object
              autocomplete="off"
              hide-details
              v-model="pedidoventa"
              @change="addPedidoventa(pedidoventa)"
              :loading="Vget_pedidoventa_load"
              label="Preventas Pendientes"
            >
              <template v-slot:no-data>
                <v-list-item dense>
                  <v-list-item-content>
                    <v-list-item-title
                      >No se encontro ninguna Pedido</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template slot="item" slot-scope="data">
                <v-list-item-content>
                  <v-list-item-title>
                    {{data.item.idcontacto.desccontacto}} - {{data.item.idcontacto.ruccontacto}}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{data.item.total}} {{data.item.idmoneda.abreviatura}} - {{data.item.fecha}}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-col>
        </v-toolbar>
        <v-container>
          <v-form ref="form">
            <v-row dense v-show="!isCredito">
              <v-col cols="12" v-show="!itemLimite">
                <v-alert dense type="info">
                  Ya alcanzo el limite de
                  <strong>Items</strong> en una factura para este Punto de Venta
                </v-alert>
              </v-col>
              <v-col cols="12" md="2" sm="3">
                <autocomplete-tipofactura
                  ref="ventas1"
                  outlined
                  dense
                  v-model="data.idtipofactura"
                  @keyup.native.enter="
                    next(1, data.idtipofactura.idtipofactura)
                  "
                />
              </v-col>
              <v-col cols="12" md="2" sm="3">
                <c-text-date
                  dense
                  outlined
                  :readonly="true"
                  label="Fecha"
                  v-model="data.fechventa"
                ></c-text-date>
              </v-col>
              <v-col md="3"></v-col>
              <v-col cols="12" md="2" sm="2">
                <c-text-field
                  label="Timbrado"
                  :value="data.idtimbrado.timbrado"
                  readonly
                  dense
                  outlined
                ></c-text-field>
              </v-col>
              <v-col cols="12" md="3" sm="3">
                <c-text-field
                  label="Factura"
                  :value="configuracion.facturaFormateada"
                  readonly
                  outlined
                  dense
                ></c-text-field>
              </v-col>
              <v-col cols="12" md="8" sm="8">
                <vue-autocomplete-contacto-cliente
                  label="Cliente"
                  ref="ventas2"
                  :messages="lineaCredito"
                  v-model="data.idcontacto.idcontacto"
                  @keyup.native.enter="next(2, data.idcontacto.idcontacto)"
                />
              </v-col>
              <v-col cols="12" md="4" sm="4" v-if="existResponsable">
                <v-autocomplete
                  dense
                  ref="ventas3"
                  v-model="data.ventares[0].idresp.idresp.idcontacto"
                  :items="data.idcontacto.idcontacto.responsable"
                  label="Responsable"
                  item-text="idresp.desccontacto"
                  item-value="idresp.idcontacto"
                  outlined
                  autocomplete="off"
                  :no-data-text="`No existe ninguna concidencia`"
                  @keyup.enter="
                    next(3, data.ventares[0].idresp.idresp.idcontacto)
                  "
                ></v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                :md="existResponsable ? 12 : 4"
                :sm="existResponsable ? 12 : 4"
              >
                <vue-autocomplete-moneda-day
                  label="Moneda"
                  ref="ventas4"
                  :chips="false"
                  v-model="data.idmoneda"
                  :readonly="data.venta[0] === undefined ? false : true"
                  :disabled="item.seleccionado === '' ? false : true"
                  @keyup.native.enter="next(4, data.idmoneda.idmoneda)"
                />
              </v-col>
            </v-row>
            <v-form ref="formDet" v-show="!isCredito">
              <v-row>
                <v-col cols="12" md="7" sm="12" v-show="itemLimite">
                  <vue-autocomplete-item-stock2
                    :idmoneda="data.idmoneda.idmoneda"
                    @change="asignarPrecio()"
                    ref="ventas5"
                    :rules="true"
                    v-model="item.seleccionado"
                    @create="formulario.item = $event"
                    :readonly="data.idmoneda.idmoneda === '' ? true : false"
                    @keyup.native.enter="next(5, item.seleccionado)"
                    @sync-input="search = $event"
                  ></vue-autocomplete-item-stock2>
                </v-col>
                <v-col cols="12" md="2" sm="5" v-show="itemLimite">
                  <c-text-currency
                    label="Costo"
                    ref="ventas6"
                    dense
                    outlined
                    :rules="validarCosto"
                    v-model="item.precio"
                    @keyup.native.enter="next(6, item.precio)"
                  ></c-text-currency>
                </v-col>
                <v-col cols="10" md="2" sm="5" v-show="itemLimite">
                  <c-text-currency
                    label="Cantidad"
                    ref="ventas7"
                    :length="0"
                    dense
                    outlined
                    :rules="validarCosto"
                    v-model="item.cantidad"
                    @keyup.native.enter="addtabla()"
                  ></c-text-currency>
                </v-col>
                <v-spacer></v-spacer>

                <v-btn
                  text
                  outlined
                  fab
                  small
                  class="mt-2 mr-2"
                  @click="addtabla()"
                  v-show="itemLimite"
                >
                  <v-icon color="primary">add</v-icon>
                </v-btn>
              </v-row>
            </v-form>
            <DataTable-Detalle
              :headers="headers"
              :items="data.venta"
              @rowdelete="rowdelete($event)"
            />
            <Total-Viewer :total="totalventa" />

            <Forma-Pago-Venta
              v-if="data.idtipofactura.idtipofactura === 1"
              :montoCobrar="totalventa"
              :key="keyFormaPago"
              @efectivo="addEfectivo($event)"
              @tarjeta="addTarjeta($event)"
              @cheque="addCheque($event)"
            />
          </v-form>
        </v-container>
        <v-divider></v-divider>

        <Venta-Venta-Financiar
          :key="key"
          v-model="isCredito"
          :monto="totalventa"
          :timbrado="data.idtimbrado.idtimbrado"
          :data="data"
          @respuesta="addFinanciamiento($event)"
        >
          <template slot="action">
            <v-btn color="blue darken-1" id="aceptar" text @click="guardar()">
              <v-icon dark>check</v-icon>
            </v-btn>
          </template>
        </Venta-Venta-Financiar>

        <v-card-actions v-if="!isCredito">
          <v-btn color="red" text @click="cancelar()">
            <v-icon dark>close</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            id="aceptar"
            text
            @click="
              data.idtipofactura.idtipofactura === 1
                ? guardar()
                : opcionFinanciamiento()
            "
          >
            <v-icon dark>check</v-icon>
          </v-btn>
        </v-card-actions>
        <v-dialog persistent v-model="dialogContinuarVenta" max-width="340">
          <v-card>
            <v-list dense>
              <v-card-subtitle class="text-center"
                >Desea Continuar con una nueva Venta?</v-card-subtitle
              >
            </v-list>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn color="red" text @click="cancelar">
                <v-icon>clear</v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="continuarVenta()">
                <v-icon>done</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card>
      <c-overlay :value="Vget_venta_load"></c-overlay>
      <c-dialog-confirmation
        v-model="formulario.editar"
        @cancel="formulario.editar = $event"
        @done="addSumOrReplace()"
      >
        <div class="subtitle-2">
          Ya existe este Item en el detalle, que desea hacer de este item?
        </div>
        <v-radio-group class="mb-n6" v-model="formulario.sum_or_replace">
          <v-radio
            label="Reemplazar el item existente"
            :value="false"
          ></v-radio>
          <v-radio label="Sumar con el item existente" :value="true"></v-radio>
        </v-radio-group>
      </c-dialog-confirmation>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { current_date, sum_days } from "@/services/util/date.service";
import { db } from "@/services/db.service";
import { utilidad } from "@/services/compra_utilidad.service";
import { mensaje } from "@/services/notify.service";
import { factura } from "@/services/factura.service";
import { browser } from "@/services/browser";
import { currency } from "@/services/util/number.service";
import autocompleteTipoFactura from "@/views/Compra/AutocompleteTipoFactura";
import formaPagoVenta from "@/views/Venta/CobroCuenta/FormaPago";
import dataTableDetalle from "./Detalle";
import totalviewer from "./Totales";
//import {getIva5, getIva10, getTotal} from "./calculos";
import Worker from "worker-loader!@/services/workers/jspdf.worker.js";
var worker = new Worker();
export default {
  components: {
    "autocomplete-tipofactura": autocompleteTipoFactura,
    "Forma-Pago-Venta": formaPagoVenta,
    "DataTable-Detalle": dataTableDetalle,
    "Total-Viewer": totalviewer,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    datos: {
      type: [Object, Array],
      default: null,
    },
    datos_cuota: {
      type: [Object, Array],
      default: null,
    }
  },
  data() {
    return {
      searchpedido: "",
      search: null,
      isCredito: false,
      dialogContinuarVenta: false,
      key: 0,
      pedidoventa: null,
      keyFormaPago: 1,
      formulario: {
        item: false,
        editar: false,
        sum_or_replace: true,
      },
      validarCosto: [
        (v) => !!v || "Este campo es obligatorio",
        (v) => !(Number(v) <= 0) || "Este campo no puede ser menor o igual 0",
      ],

      data: {
        idventa: 0,
        meta: [0, 0],
        idtimbrado: {
          idtimbrado: "",
        },
        fechventa: current_date(),
        fechvencimiento: sum_days(30),
        horaventa: "00:00:00",
        anulventa: false,
        idtipofactura: {
          idtipofactura: "",
        },
        numfventa: "",
        idcontacto: {
          idcontacto: "",
        },
        idmoneda: {
          idmoneda: "",
        },
        idcotizacion: {
          idcotizacion: "",
        },
        idpuntoventa: {
          idpuntoventa: "",
          idcaja: "",
        },
        venta: [],
        ventapago: [
          {
            idcaja: 0,
            idformapago: {
              idformapago: 1,
            },
            monto: 0,
            venta_pago_cheque: [],
            venta_pago_tarjeta: [],
            opcion: false,
          },
        ],
        cuenta: [],
        ventares: [
          {
            idcontacto: "",
            idresp: {
              idresp: {
                idcontacto: "",
              },
            },
          },
        ],
        ventapedido: [],
        cuotagym: []
      },
      default: {
        idventa: 0,
        meta: [0, 0],
        idtimbrado: {
          idtimbrado: "",
        },
        fechventa: current_date(),
        fechvencimiento: sum_days(30),
        horaventa: "00:00:00",
        anulventa: false,
        idtipofactura: {
          idtipofactura: "",
        },
        numfventa: "",
        idcontacto: {
          idcontacto: "",
        },
        idmoneda: {
          idmoneda: "",
        },
        idcotizacion: {
          idcotizacion: "",
        },
        idpuntoventa: {
          idpuntoventa: "",
          idcaja: "",
        },
        venta: [],
        ventapago: [
          {
            idcaja: 0,
            idformapago: {
              idformapago: 1,
            },
            monto: 0,
            venta_pago_cheque: [],
            venta_pago_tarjeta: [],
            opcion: false,
          },
        ],
        cuenta: [],
        ventares: [
          {
            idcontacto: "",
            idresp: {
              idresp: {
                idcontacto: "",
              },
            },
          },
        ],
        ventapedido: [],
        cuotagym: [],
      },
      headers: [
        {
          text: "Deposito",
          sortable: false,
          value: "iddeposito.desdeposito",
        },
        { text: "Cantidad", sortable: false, value: "cantdetventa" },
        { text: "Item", sortable: false, value: "iditem.descitem" },
        { text: "Precio Unitario", sortable: false, value: "precdetventa" },

        {
          text: "Sub Total",
          sortable: false,
          align: "right",
          value: "iditem.subtotal",
        },
        { text: "Accion", sortable: false, align: "right", value: "actions" },
      ],
      item: {
        seleccionado: "",
        temporal: null,
        precio: "",
        cantidad: "",
      },
      configuracion: {
        facturaNumero: "",
        facturaFormateada: "",
        filasfactura: 0,
        impresion: "",
      },
    };
  },
  watch: {
    searchpedido(val) {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.obtain_pedido(val);
          this.awaitingSearch = false;
        }, 1000)
      }
      this.awaitingSearch = true;
    },
    Vget_puntoventa_parametros(val) {
      if (val.length === 0) return null;
      this.configurarFacturaVenta(val);
      this.iniciarworker();
    },
    $route(to, from) {
      if (from.path === "/venta") return this.cancelar();
    },
  },
  computed: {
    ...mapGetters("pedidoventa", [
      "Vget_pedidoventa_data",
      "Vget_pedidoventa_load",
    ]),
    ...mapGetters("venta", ["Vget_venta_load"]),
    ...mapGetters("puntoventa", [
      "Vget_puntoventa_save_load",
      "Vget_puntoventa_parametros",
    ]),
    totalventa() {
      return this.data.venta.reduce(
        (acc, curr) => acc + curr.cantdetventa * curr.precdetventa,
        0
      );
    },
    lineaCredito() {
      let mensaje = "";
      if (this.data.idcontacto.idcontacto != "") {
        if (
          this.data.idcontacto.idcontacto === undefined ||
          this.data.idcontacto.idcontacto.cliente === undefined
        )
          return "";
        const disponible = currency(
          this.data.idcontacto.idcontacto.cliente[0].disponible
        );
        const utilizado = currency(
          this.data.idcontacto.idcontacto.cliente[0].utilizado
        );
        mensaje =
          "Credito Disponible: " +
          disponible +
          " | Credito Utilizado: " +
          utilizado;
      }
      return mensaje;
    },
    itemLimite() {
      if (this.data.venta.length < this.configuracion.filasfactura) return true;
      return false;
    },
    existResponsable() {
      if (this.data.idcontacto.idcontacto === undefined) return false;
      if (this.data.idcontacto.idcontacto.responsable != undefined)
        if (this.data.idcontacto.idcontacto.responsable.length > 0) return true;
      return false;
    },
  },
  async mounted() {
    setTimeout(() => this.next(0, "0"), 50);
    if (this.datos != null) {
      let resp = await db.get("/apiventa/" + this.datos.idventa);
      resp.venta = [];
      this.data = JSON.parse(JSON.stringify(resp));
      let data = {
        id: this.data.idpuntoventa.idpuntoventa,
        idca: this.data.idpuntoventa.idcaja.idcaja,
      };
      this.Vact_puntoventa_punto_save({ data, mode: "factura" });
      if (this.data.ventapago.length === 0)
        this.data.ventapago = this.default.ventapago;
      return;
    }
    this.configurarFacturaVenta(this.Vget_puntoventa_parametros);
    this.iniciarworker();
    if (this.$props.datos_cuota) {
      let monto_cuota = 0;
      this.data.idmoneda = {idmoneda: 4, descmoneda: 'GUARANI', plural: 'GUARANIES', singular: 'GUARANI', centplural: 'GUARANIES', centsingular: 'GUARANI', abreviatura: 'GS'}
      this.data.idcontacto = this.$props.datos_cuota.idcontacto;
      this.$props.datos_cuota.detalle.forEach((x) => {
        monto_cuota = monto_cuota + x.monto;
        this.data.venta.push({
          idventa: 0,
          idtimbrado: x.idgymtipo.idtimbrado,
          cantdetventa: 1,
          precdetventa: Number(x.monto),
          porcdetventa: 0,
          ...x.idgymtipo,
          iditem: {
            ...x.idgymtipo.iditem,
            ...this.obtener_item(x, x.monto, 1),
          }
        });
      });
      this.data.cuotagym = [
        {
          idgymplanilla: this.$props.datos_cuota.idgymplanilla,
          monto: monto_cuota,
        }
      ]
    }
  },
  methods: {
    ...mapActions("venta", [
      "Vact_venta_data",
      "Vact_venta_save",
      "Vact_venta_update",
    ]),
    ...mapActions("gymcuota", [
      "Vact_gymcuota_data_by"
    ]),
    ...mapActions("stock", ["Vact_stock_data"]),
    ...mapActions("caja", ["Vact_caja_data"]),
    ...mapActions("pedidoventa", ["Vact_pedidoventa_data"]),
    ...mapActions("puntoventa", ["Vact_puntoventa_punto_save"]),
    obtain_pedido(val) {
      console.log(val)
      if (this.searchpedido != null && this.searchpedido.length >= 3) {
        this.Vact_pedidoventa_data({limit: null, desc: this.searchpedido})
      }
    },
    currencyFormat(value) {
      if (value != "") return currency(value);
      return "";
    },
    addEfectivo(event) {
      this.data.ventapago[0].monto = event.monto;
    },
    addTarjeta(event) {
      this.data.ventapago[0].venta_pago_tarjeta = event;
    },
    addCheque(event) {
      this.data.ventapago[0].venta_pago_cheque = event;
    },
    async configurarFacturaVenta(val) {
      if (val === undefined) return null;
      this.data.idtimbrado = {
        idtimbrado: val[0].id,
        idestablecimiento: {
          establecimiento: val[0].establecimiento,
        },
        idpuntoexpedicion: {
          puntoexpedicion: val[0].punto,
        },
        timbrado: val[0].timbrado,
      };
      this.configuracion = {
        filasfactura: val[0].filasfactura,
        facturaNumero:
          this.datos != null ? this.datos.numfventa : val[0].actual,
        impresion: {
          orientacion: val[0].parametros.config_hoja.orientacion,
          unidad: val[0].parametros.config_hoja.unidad,
          formato: val[0].parametros.config_hoja.formato,
          config_hoja: val[0].parametros.config_hoja,
          parametros: val[0].parametros,
        },
      };
      this.data.idpuntoventa.idpuntoventa = val[1].idpuntoventa;
      this.data.idpuntoventa.idcaja = val[1].idcaja;
      this.datos_factura();
    },
    asignarPrecio() {
      if (this.item.seleccionado === undefined) return null;
      this.item.precio = this.item.seleccionado.iditem.precitem;
    },
    datos_factura() {
      const est = this.data.idtimbrado.idestablecimiento.establecimiento;
      const pun = this.data.idtimbrado.idpuntoexpedicion.puntoexpedicion;
      const fac = utilidad.completar_nrof(
        this.configuracion.facturaNumero.toString()
      );
      this.configuracion.facturaFormateada = `${est}-${pun}-${fac}`;
    },
    addPedidoventa(pedido) {
      this.data.idcontacto.idcontacto = pedido.idcontacto;
      if (pedido.idresp != null)
        this.data.ventares[0].idresp.idresp.idcontacto =
          pedido.idresp.idcontacto;
      this.data.idmoneda = pedido.idmoneda;
      let detalle = [];
      pedido.pedidoventadet.forEach((x) => {
        detalle.push({
          ...x,
          iditem: {
            ...x.iditem,
            ...this.obtener_item(x, x.precio, x.cantidad),
          },
          idventa: 0,
          idtimbrado: this.data.idtimbrado.idtimbrado,
          cantdetventa: Number(x.cantidad),
          precdetventa: Number(x.precio),
          porcdetventa: 0,
        });
      });
      this.data.venta = JSON.parse(JSON.stringify(detalle));
      this.data.ventapedido.push({ idpedido_venta: pedido });
    },
    continuarVenta() {
      let firstFactura = this.configuracion.facturaNumero;
      this.dialogContinuarVenta = false;
      this.isCredito = false;
      this.limpiarcampos();
      this.keyFormaPago++;
      this.$refs.form.resetValidation();
      this.configurarFacturaVenta(this.Vget_puntoventa_parametros);
      this.configuracion.facturaNumero = firstFactura + 1;
      this.datos_factura();
      setTimeout(() => this.next(0, "-"), 500);
    },
    addFinanciamiento(respFinanciamiento) {
      this.data = JSON.parse(JSON.stringify(respFinanciamiento));
    },

    opcionFinanciamiento() {
      if (!this.isValid()) return this.$notify(mensaje.completar());
      this.isCredito = true;
    },

    async iniciarworker() {
      let operation = {
        type: "init",
        ...this.configuracion.impresion,
        plantilla: await factura.getDataUri(
          this.Vget_puntoventa_parametros[0].plantilla[0]
        ),
      };
      worker.onmessage = (e) => {
        switch (e.data.type) {
          case "init":
            console.log(e.data.data);
            break;
          case "processing":
            var x = "";
            if (browser.name() === "Firefox") {
              x = window.open();
            }
            if (browser.name() === "Chrome") {
              x = window.open(`about: whatever`);
            } else {
              x = window.open();
            }
            x.document.open();
            x.document.write(e.data.data);
            x.document.close();
            worker.postMessage(operation);
            break;
          default:
            console.log(e.data);
            break;
        }
      };
      worker.postMessage(operation);
    },
    generarfactura(venta) {
      //const iva5 = getIva5(venta);
      //const iva10 = getIva10(venta);
      //const ivatotal = iva5 + iva10;
      //const totalventa = getTotal(venta);
      let operacion = {
        type: "processing",
        datos: venta,
        config: JSON.parse(JSON.stringify(this.Vget_puntoventa_parametros[0])),
        factura: this.configuracion.facturaFormateada,
        iva5: venta.gravadas[3],
        iva10: venta.gravadas[1],
        ivatotal: venta.gravadas[3] + venta.gravadas[1],
        ventatotal: venta.gravadas[5],
        moneda: venta.idmoneda,
      };
      worker.postMessage(operacion);
    },
    next(id, data) {
      if (id === 2) if (!this.existResponsable) id = 3;
      if (data != "")
        try {
          this.$refs[`ventas${id + 1}`].focus();
        } catch (error) {
          this.$refs[`ventas${id + 1}`].$el.focus();
        }
    },

    rowdelete(item) {
      this.index = this.data.venta.indexOf(item);
      this.data.venta.splice(this.index, 1);
      this.index = null;
      this.isCredito = false;
    },

    obtener_item(itemSelect, precio, cantidad) {
      return {
        subtotal: Number(cantidad) * Number(precio),
        exenta: 0,
        iva5: 0,
        iva10: 0,
      };
    },

    limpiar_det() {
      this.item.seleccionado = "";
      this.item.precio = "";
      this.item.cantidad = "";
      this.$refs.formDet.resetValidation();
    },
    isValid() {
      if (!this.$refs.form.validate() || this.data.venta.length === 0)
        return false;
      return true;
    },
    verificar_duplicado() {
      const temp = this.data.venta.find(
        (x) =>
          x.iditem.iditem === this.item.seleccionado.iditem.iditem &&
          x.iddeposito.iddeposito ===
            this.item.seleccionado.iddeposito.iddeposito
      );
      if (temp) {
        this.formulario.editar = true;
        this.item.temporal = temp;
      }
      return temp ? false : true;
    },
    addSumOrReplace() {
      const object = this.item.temporal;
      this.data.venta.splice(this.data.venta.indexOf(object), 1);
      if (this.formulario.sum_or_replace)
        this.item.cantidad = this.item.cantidad + object.cantdetventa;

      this.addtabla();
      this.formulario.editar = false;
    },
    async addtabla() {
      if (!this.$refs.formDet.validate() || !this.verificar_duplicado())
        return null;
      this.data.venta.push({
        ...this.item.seleccionado,
        idstock: { idstock: this.item.seleccionado.idstock },
        iditem: {
          ...this.item.seleccionado.iditem,
          ...this.obtener_item(
            this.item.seleccionado,
            this.item.precio,
            this.item.cantidad
          ),
        },
        idventa: 0,
        idtimbrado: this.data.idtimbrado.idtimbrado,
        cantdetventa: Number(this.item.cantidad),
        precdetventa: Number(this.item.precio),
        porcdetventa: 0,
      });
      setTimeout(() => this.limpiar_det(), 200);
      this.next(4, "-");
    },
    cancelar() {
      this.data = JSON.parse(JSON.stringify(this.default));
      this.$emit("input", false);
      this.$emit("datos", null);
      this.Vact_caja_data();
      if (this.$props.datos_cuota) {
        this.Vact_gymcuota_data_by({id: this.$props.datos_cuota.idgymplanilla});
      } else {
        this.Vact_venta_data();
      }
      this.Vact_stock_data();
    },
    limpiarcampos() {
      this.data = JSON.parse(JSON.stringify(this.default));
    },

    async guardar() {
      if (!this.isValid()) return this.$notify(mensaje.completar());
      if (
        this.existResponsable &&
        this.data.ventares[0].idresp.idresp.idcontacto != undefined
      )
        this.data.ventares[0].idcontacto = this.data.idcontacto.idcontacto.idcontacto;
      if (this.data.idtipofactura.idtipofactura === 2)
        if (this.data.cuenta[0].entrega === 0) this.data.ventapago = [];
      this.data.ventares = JSON.parse(JSON.stringify(this.default.ventares));
      this.data.numfventa = this.configuracion.facturaNumero;
      if (this.data.cotizacion === "") this.data.cotizacion = null;
      let response;
      if (this.datos != null) {
        response = await this.Vact_venta_update({
          id: this.datos.idventa,
          data: this.data,
        });
      }
      if (this.datos === null) {
        response = await this.Vact_venta_save(this.data);
      }
      if (response.success) {
        this.generarfactura(response.data.venta);
        this.key++;
        this.dialogContinuarVenta = true;
      }
    },
  },
};
</script>
