export default {
    densidad_update(state, data) {
        state.densidad_update = data;
    },    
    densidad_load(state, data) {
        state.densidad_load = data;
    },
    densidad_data(state, data) {
        state.densidad_data = data;
    },
    densidad_save_load(state, data) {
        state.densidad_save_load = data;
    },

}