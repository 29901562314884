<template>
  <v-text-field
    :label="label"
    @input="setValue($event)"
    :outlined="outlined"
    :error="error"
    :error-messages="errorString"
    :clearable="clearable"
    :dense="dense"
    ref="input"
    :type="isInputActive ? 'number' : 'text'"
    :rules="rules"
    :messages="messages"
    :focus="$emit('focus')"
    :suffix="suffix"
    :prefix="prefix"
    :append-outer-icon="appendOuterIcon"
    :prepend-inner-icon="prependInnerIcon"
    :readonly="readonly"
    :disabled="disabled"
    :placeholder="placeholder"
    :hide-details="hideDetails"
    autocomplete="off"
    :id="id"
    :value="displayValue"
    @click:prepend-inner="$emit('click:prepend')"
    @blur="isInputActive = false"
    @focus="isInputActive = true"
  ></v-text-field>
</template>

<script>
export default {
  props: {
    label: String,
    prefix: String,
    suffix: String,
    value: {
      type: [String, Number],
      default: "",
    },
    id: String,
    placeholder: String,
    outlined: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    messages: {
      type: [Number, String],
    },
    "prepend-inner-icon":{
      type: String,
      default: "",
    },
    "append-outer-icon": {
      type: String,
      default: "",
    },
    rules: {
      type: Array,
    },
    "hide-details": {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    minimum: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      isInputActive: false,
      errorString: "",
    };
  },

  computed: {
    displayValue: {
      get: function() {
        if (isNaN(this.value)) {
          return this.$emit("input", "");
        }
        if (this.isInputActive) {
          return this.value;
        } else {
          if (this.value === "") return "";
          return new Intl.NumberFormat({ maximumSignificantDigits: 3 }).format(
            this.value
          );
        }
      },
      set: function(modifiedValue) {
        let newValue = parseFloat(modifiedValue.replace(/[^\d]/g, ""));
        if (isNaN(newValue)) {
          newValue = "";
        }
        this.$emit("input", Number(newValue));
      },
    },
  },
  methods: {
    setValue(event) {
      if (event === "" || event === null) {
        return this.$emit("input", "");
      }
      this.$emit("input", Number(event));
    },
    focus() {
      this.$refs.input.focus();
    },
  },
};
</script>
