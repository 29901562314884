// https://vuex.vuejs.org/en/getters.html

export default {
  Vget_pagocuenta_load: (state) => {
    return state.pagocuenta_load;
  },

  Vget_pagocuenta_data: (state) => {
    return state.pagocuenta_data;
  },
  Vget_pagocuenta_data_id: (state) => {
    return state.pagocuenta_data_id;
  },
  Vget_pagocuenta_by_id: (state) => {
    return state.pagocuenta_by_id;
  },
  
  Vget_pagocuenta_num_rec_load: (state) => {
    return state.pagocuenta_num_rec_load;
  },
  Vget_pagocuenta_num_rec: (state) => {
    return state.pagocuenta_num_rec;
  },
}