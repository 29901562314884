import { get, post, put, del } from "@/services/api/api.service";
import { SET_LOADING, SET_CONTRATO, SET_CONTRATO_BY_FUN, SET_DET_CONTRATO_BY_FUN, url } from './constants'
export default {
    async fetchContratoFuncionario({ commit, dispatch }) {
        commit(SET_LOADING, true);
        try {
            const response = await get(url.contrato);
            commit(SET_CONTRATO, response);
        } catch (e) {
            dispatch('snackbar/setNotification', e, { root: true });
            commit(SET_LOADING, false);
            throw e
        }
        commit(SET_LOADING, false);
    },
    async fetchDetContrato({ commit, dispatch }, { id, desde, hasta, idmoneda }) {
        if (!id) { commit(SET_DET_CONTRATO_BY_FUN, []); return  }
        commit(SET_LOADING, true);
        try {
            const response = await get(`${url.detalle}${id}&inicio=${desde}&fin=${hasta}&moneda=${idmoneda}`);
            commit(SET_DET_CONTRATO_BY_FUN, response);
        } catch (e) {
            dispatch('snackbar/setNotification', e, { root: true });
            commit(SET_LOADING, false);
            throw e
        }
        commit(SET_LOADING, false);
    },
    async fetchContratoFuncionarioById({ commit, dispatch }, idfuncionario) {
        commit(SET_LOADING, true);
        try {
            const response = await get(`${url.contrato}?funcionario=` + idfuncionario);
            commit(SET_CONTRATO_BY_FUN, response);
        } catch (e) {
            dispatch('snackbar/setNotification', e, { root: true });
            commit(SET_LOADING, false);
            throw e
        }
        commit(SET_LOADING, false);
    },
    async setContratoFuncionario({ commit, dispatch }, data) {
        commit(SET_LOADING, true);
        const response = await post(url.contrato, data);
        dispatch('snackbar/setNotification', response, { root: true })
        commit(SET_LOADING, false);
        return response
    },
    async setContratoFuncionarioUpdate({ commit, dispatch }, { data, id }) {
        commit(SET_LOADING, true);
        const response = await put(`${url.contrato}${id}/`, data);
        dispatch('snackbar/setNotification', response, { root: true })
        commit(SET_LOADING, false);
        return response
    },
    async setContratoFuncionarioDelete({ commit, dispatch }, id) {
        commit(SET_LOADING, true);
        const response = await del(`${url.contrato}${id}/`);
        dispatch('snackbar/setNotification', response, { root: true })
        commit(SET_LOADING, false)
        return response;
    },
}
