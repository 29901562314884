export default {
  apertura_data(state, request) {
    state.apertura_data = request;
  },
  apertura_load(state, request) {
    state.apertura_load = request;
  },
  apertura_save_load(state, request) {
    state.apertura_save_load = request;
  },
}