<template>
  <div>
    <v-dialog
      :value="value"
      max-width="1000px"
      persistent
      @keydown.esc="cancelar()"
    >
      <v-card>
        <v-toolbar flat dense>
          <v-toolbar-title>{{
            operacion ? `Registrar ${crudMode}` : `Modificar ${crudMode}`
          }}</v-toolbar-title>
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text>
          <v-alert
            dense
            outlined
            type="error"
            v-if="existeFactura.length > 0"
            >{{ existeFactura }}</v-alert
          >
          <v-window v-model="step">
            <v-window-item :value="1">
              <v-form ref="form" lazy-validation class="mt-3">
                <v-row dense>
                  <v-col cols="12" md="4" sm="4">
                    <c-text-date
                      label="Fecha"
                      dense
                      outlined
                      :readonly="readonly"
                      id="compraInput0"
                      v-model="data.fechcompra"
                      @keyup.native.enter="nextfocus(0, data.fechcompra)"
                    />
                  </v-col>
                  <v-col cols="12" md="4" sm="4">
                    <vue-autocomplete-generic
                      :readonly="readonly"
                      label="Tipo Factura"
                      id="compraInput1"
                      action="tipofactura/Vact_tipofactura_data"
                      getter="tipofactura/Vget_tipofactura_data"
                      load="tipofactura/Vget_tipofactura_load"
                      pk="idtipofactura"
                      :chips="false"
                      title="desctipofactura"
                      v-model="data.idtipofactura.idtipofactura"
                      @keyup.native.enter="
                        nextfocus(
                          data.idtipofactura.idtipofactura === 1 ? 4 : 1,
                          data.idtipofactura.idtipofactura
                        )
                      "
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                    sm="4"
                    v-if="data.idtipofactura.idtipofactura === 2"
                  >
                    <c-text-date
                      :readonly="readonly"
                      label="Vencimiento"
                      id="compraInput2"
                      dense
                      outlined
                      :rules="[]"
                      v-model="data.fechvencimiento"
                      @keyup.native.enter="nextfocus(4, data.fechvencimiento)"
                    />
                  </v-col>
                  <v-col cols="12" md="8" sm="8">
                    <vue-autocomplete-contacto-proveedor
                      :readonly="readonly"
                      label="Proveedor"
                      id="compraInput5"
                      v-model="data.idcontacto.idcontacto"
                      @keyup.native.enter="
                        nextfocus(5, data.idcontacto.idcontacto)
                      "
                    />
                  </v-col>
                  <v-col cols="12" md="2" sm="4">
                    <vue-text-field
                      :readonly="readonly"
                      label="Timbrado"
                      id="compraInput6"
                      v-model="data.timb_compra"
                      v-mask="mask.tim"
                      @keyup.native.enter="nextfocus(6, data.timb_compra)"
                    />
                  </v-col>
                  <v-col cols="12" md="2" sm="4">
                    <vue-text-field
                      :readonly="readonly"
                      label="Regimen"
                      id="compraInput7"
                      v-model="data.regi_compra"
                      v-mask="mask.reg"
                      @keyup.native.enter="nextfocus(7, data.regi_compra)"
                    />
                  </v-col>
                  <v-col cols="12" md="2" sm="4">
                    <vue-text-field
                      :readonly="readonly"
                      label="Nro Factura"
                      id="compraInput8"
                      v-model="data.numfcompra"
                      v-mask="mask.fac"
                      @keyup.native.enter="
                        nextfocus(8, data.numfcompra),
                          completar_nrof(data.numfcompra),
                          verificar_fact()
                      "
                    />
                  </v-col>
                  <v-col cols="12" md="4" sm="4">
                    <vue-autocomplete-moneda-day
                      :readonly="readonly"
                      label="Moneda"
                      id="compraInput9"
                      v-model="data.idmoneda"
                      :chips="false"
                      @keyup.native.enter="nextfocus(9, data.idmoneda.idmoneda)"
                    />
                  </v-col>
                  <v-col cols="12" md="2" sm="4">
                    <vue-text-currency
                      :readonly="readonly"
                      label="Cotizacion"
                      id="compraInput10"
                      :rules="false"
                      v-model="data.cotizacion"
                      @keyup.native.enter="
                        crudMode === 'Compra'
                          ? nextfocus(10, '0')
                          : nextwindows(11, '-')
                      "
                    />
                  </v-col>
                  <v-col cols="12" md="4" sm="4" v-if="crudMode === 'Compra'">
                    <vue-autocomplete-generic
                      :readonly="readonly"
                      id="compraInput11"
                      action="deposito/Vact_deposito_data"
                      getter="deposito/Vget_deposito_data"
                      load="deposito/Vget_deposito_load"
                      pk="iddeposito"
                      label="Deposito"
                      title="desdeposito"
                      :create="true"
                      @create="formularios.deposito = $event"
                      permission="Deposito"
                      v-model="data.iddeposito.iddeposito"
                      @keyup.native.enter="
                        nextwindows(11, data.iddeposito.iddeposito)
                      "
                    />
                    <div v-if="formularios.deposito">
                      <Item-Deposito-Create v-model="formularios.deposito" />
                    </div>
                  </v-col>
                </v-row>
              </v-form>
            </v-window-item>
            <v-window-item :value="2">
              <v-form ref="formDetalle">
                <v-row dense class="ma-1">
                  <v-col cols="12" md="6" sm="12" v-if="crudMode === 'Egreso'">
                    <v-autocomplete
                      :readonly="readonly"
                      dense
                      v-model="detalle.idconcepto"
                      id="compraInput15"
                      v-bind:items="Vget_concepto_data"
                      :search-input.sync="searchconcepto"
                      label="Concepto"
                      item-text="desconcepto"
                      return-object
                      :rules="[
                        (v) => !!v.idconcepto || 'Seleccione un concepto',
                      ]"
                      :loading="Vget_concepto_load"
                      required
                      outlined
                      @change="obtenerPrecio()"
                      autocomplete="off"
                      @keyup.enter="
                        nextfocus(15, detalle.idconcepto.idconcepto)
                      "
                      :no-data-text="`No existe ninguna concidencia`"
                    >
                      <template v-if="concepto_permission" v-slot:no-data>
                        <v-list-item @click="formularios.concepto = true">
                          <v-list-item-content>
                            <v-list-item-title
                              >No existe concidencia. Click para
                              agregar</v-list-item-title
                            >
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                    <div v-if="formularios.concepto">
                      <Item-Concepto-Create
                        v-model="formularios.concepto"
                        :sync-input="search"
                        return-object
                        @item="itemCreado = $event"
                        @sync-input="
                          (detalle.idconcepto = $event),
                            nextfocus(15, detalle.idconcepto.idconcepto)
                        "
                      />
                    </div>
                  </v-col>
                  <v-col cols="12" md="6" sm="12" v-else>
                    <v-autocomplete
                      :readonly="readonly"
                      dense
                      v-model="detalle.iditem"
                      id="compraInput15"
                      v-bind:items="item"
                      :filter="customFilter"
                      :search-input.sync="searchitem"
                      label="Item"
                      item-text="descitem"
                      return-object
                      :rules="[(v) => !!v.iditem || 'Seleccione un Item']"
                      :loading="Vget_item_load"
                      item-value="iditem"
                      required
                      outlined
                      @change="obtenerPrecio()"
                      autocomplete="off"
                      @keyup.enter="nextfocus(15, detalle.iditem.iditem)"
                      :no-data-text="`No existe ninguna concidencia`"
                    >
                      <template v-if="item_permission" v-slot:no-data>
                        <v-list-item @click="formularios.item = true">
                          <v-list-item-content>
                            <v-list-item-title
                              >No existe concidencia. Click para
                              agregar</v-list-item-title
                            >
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                    <div v-if="formularios.item">
                      <Item-Item-create
                        :sync-input="searchitem"
                        @sync-input="
                          (detalle.iditem = $event),
                            nextfocus(15, detalle.iditem.iditem)
                        "
                        @item="searchItemId = $event"
                        v-model="formularios.item"
                        :crudMode="'COMPRA'"
                      />
                    </div>
                  </v-col>
                  <v-col cols="12" md="3" sm="6">
                    <vue-text-currency
                      :readonly="readonly"
                      :rules="true"
                      v-model="detalle.precio"
                      id="compraInput16"
                      label="Costo"
                      @keyup.native.enter="nextfocus(16, detalle.precio)"
                    ></vue-text-currency>
                  </v-col>
                  <v-col cols="9" md="3" sm="6">
                    <vue-text-currency
                      :readonly="readonly"
                      :rules="true"
                      v-model="detalle.cantidad"
                      id="compraInput17"
                      label="Cantidad"
                      @keyup.native.enter="nextfocus(17, detalle.cantidad)"
                    ></vue-text-currency>
                  </v-col>
                  <v-col cols="9" md="2" sm="3">
                    <vue-text-currency
                      :readonly="readonly"
                      v-model="detalle.exenta"
                      id="compraInput18"
                      label="Exenta"
                      @keyup.native.enter="nextfocus(18, '-')"
                    ></vue-text-currency>
                  </v-col>
                  <v-col cols="9" md="2" sm="3">
                    <vue-text-currency
                      :readonly="readonly"
                      v-model="detalle.iva5"
                      id="compraInput19"
                      label="Iva 5%"
                      @keyup.native.enter="nextfocus(19, '-')"
                    ></vue-text-currency>
                  </v-col>
                  <v-col cols="9" md="2" sm="3">
                    <vue-text-currency
                      :readonly="readonly"
                      v-model="detalle.iva10"
                      id="compraInput20"
                      label="Iva 10%"
                      @keyup.native.enter="nextfocus(20, '-')"
                    ></vue-text-currency>
                  </v-col>
                  <v-col cols="12" sm="3" v-if="isSemen">
                    <AutocompleteTermoSemen
                      v-model="
                        detalle.itempajuela[0].idpajuelas.idtermosemen
                          .idtermosemen
                      "
                      id="compraInput21"
                      @keyup.native.enter="
                        nextfocus(
                          21,
                          detalle.itempajuela[0].idpajuelas.idtermosemen
                            .idtermosemen
                        )
                      "
                    />
                  </v-col>
                  <v-col cols="12" sm="2" v-if="isSemen"
                    ><AutocompleteSexo
                      id="compraInput22"
                      returnObject
                      :rules="[]"
                      v-model="detalle.itempajuela[0].idpajuelas.idsexo"
                      @keyup.native.enter="
                        nextfocus(
                          22,
                          detalle.itempajuela[0].idpajuelas.idtermosemen
                            .idtermosemen
                        )
                      "
                  /></v-col>
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    outlined
                    fab
                    id="compraInputaceptar"
                    small
                    @click="addtabla()"
                  >
                    <v-icon color="blue" :disabled="readonly">add</v-icon>
                  </v-btn>

                  <v-col cols="12" md="12" sm="12" v-if="crudMode === 'Compra'">
                    <v-data-table
                      hide-default-footer
                      :headers="headers.compra"
                      :items="data.compra"
                      class="elevation-1"
                    >
                      <template v-slot:item="props">
                        <tr>
                          <td class="text-xs-letf">
                            {{ currencyFormat(props.item.cantdetcompra) }}
                          </td>
                          <td class="text-xs-letf">
                            {{ props.item.iditem.descitem }}
                            {{
                              props.item.iditem.issemen
                                ? ` (Semen - ${props.item.itempajuela[0].idpajuelas.idsexo.descsexocria})`
                                : ""
                            }}
                          </td>
                          <td class="text-xs-letf">
                            {{ currencyFormat(props.item.costdetcompra) }}
                          </td>
                          <td class="text-xs-letf">
                            {{ currencyFormat(props.item.exenta) }}
                          </td>
                          <td class="text-xs-letf">
                            {{ currencyFormat(props.item.iva5) }}
                          </td>
                          <td class="text-xs-letf">
                            {{ currencyFormat(props.item.iva10) }}
                          </td>
                          <td class="text-end">
                            <v-icon
                              small
                              @click="rowdelete(props.item)"
                              :disabled="readonly"
                              >delete</v-icon
                            >
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                  </v-col>
                  <v-col cols="12" md="12" sm="12" v-else>
                    <v-data-table
                      hide-default-footer
                      :headers="headers.egreso"
                      :items="data.compraconcepto"
                      class="elevation-1"
                    >
                      <template v-slot:item="props">
                        <tr>
                          <td class="text-xs-letf">
                            {{ currencyFormat(props.item.cantdetconcepto) }}
                          </td>
                          <td class="text-xs-letf">
                            {{ props.item.idconcepto.desconcepto }}
                          </td>
                          <td class="text-xs-letf">
                            {{ currencyFormat(props.item.costconcepto) }}
                          </td>
                          <td class="text-xs-letf">
                            {{ currencyFormat(props.item.exenta) }}
                          </td>
                          <td class="text-xs-letf">
                            {{ currencyFormat(props.item.iva5) }}
                          </td>
                          <td class="text-xs-letf">
                            {{ currencyFormat(props.item.iva10) }}
                          </td>
                          <td class="justify-right">
                            <v-icon
                              small
                              @click="rowdelete(props.item)"
                              :disabled="readonly"
                              >delete</v-icon
                            >
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                  </v-col>
                  <v-col cols="6" md="3" sm="3">
                    <vue-text-currency
                      :readonly="true"
                      v-model="subtotal.iva5"
                      label="Iva (5%)"
                    ></vue-text-currency>
                  </v-col>
                  <v-col cols="6" md="3" sm="3">
                    <vue-text-currency
                      :readonly="true"
                      v-model="subtotal.iva10"
                      label="Iva (10%)"
                    ></vue-text-currency>
                  </v-col>
                  <v-col cols="6" md="3" sm="3">
                    <vue-text-currency
                      :readonly="true"
                      v-model="subtotal.ivatotal"
                      label="Total IVA"
                    ></vue-text-currency>
                  </v-col>
                  <v-col cols="6" md="3" sm="3">
                    <vue-text-currency
                      :readonly="true"
                      v-model="subtotal.total"
                      label="Total Compra"
                    ></vue-text-currency>
                  </v-col>
                  <v-col cols="12" md="12" sm="12">
                    <vue-text-field
                      :readonly="readonly"
                      v-model="data.obse_compra"
                      label="Observacion"
                      :rules="false"
                      @keyup.native.enter="nextfocus(18, data.obse_compra)"
                    ></vue-text-field>
                  </v-col>
                </v-row>
              </v-form>
              <Compra-FormaPago
                v-if="
                  data.idtipofactura.idtipofactura === 1 &&
                    data.idmoneda.idmoneda != ''
                "
                @caja="addCaja($event)"
                @cuentabanco="addCuentaBanco($event)"
                @cheque="addCheque($event)"
                @tarjetacredito="addTarjeta($event)"
                class="ma-4"
                :datos="dataPago"
                :readonly="readonly"
                :montoCobrar="subtotal.total"
                :idmoneda="data.idmoneda.idmoneda"
              ></Compra-FormaPago>
            </v-window-item>
          </v-window>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="blue darken-1"
            :disabled="step === 1"
            text
            @click="step--"
          >
            <v-icon dark>arrow_back_ios</v-icon>
          </v-btn>
          <v-btn color="red" text @click="cancelar">
            <v-icon dark>close</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            v-if="step != 2"
            ref="sig1"
            text
            depressed
            @click="isValidCabecera()"
          >
            <v-icon dark>arrow_forward_ios</v-icon>
          </v-btn>
          <v-btn
            color="blue darken-1"
            v-if="step === 2 && !readonly"
            id="aceptar"
            text
            @click="guardar()"
          >
            <v-icon dark>check</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
      <c-overlay
        :value="Vget_compra_save_load || isLoading"
        absolute
      ></c-overlay>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { db } from "@/services/db.service";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { current_date, sum_days } from "@/services/util/date.service";
import { utilidad } from "@/services/compra_utilidad.service";
import { mensaje } from "@/services/notify.service";
import { mask } from "vue-the-mask";
import { focus } from "@/services/focus.service";
import { ITEM, CONCEPTO } from "@/constants/forms";
import AutocompleteTermoSemen from "@/views/Animal/TermoSemen/Autocomplete";
import AutocompleteSexo from "@/views/Animal/SexoCria/Autocomplete";
import { PUEDE_AGREGAR } from "@/constants/permissions";
import {getTotal, getIva5, getIva10} from './calculos'
export default {
  components: {
    AutocompleteTermoSemen,
    AutocompleteSexo,
  },
  props: {
    value: Boolean,
    datos: {
      type: [Object, Array],
      default: null,
    },
    crudMode: {
      type: String,
      default: "Compra",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  directives: {
    mask,
  },
  data() {
    return {
      searchItemId: null,
      operacion: true,
      awaitingSearch: false,
      item: [],
      isLoading: false,
      searchitem: "",
      existeFactura: "",
      step: 1,
      mask: {
        tim: "##.###.###",
        reg: "###-###",
        fac: "#######",
      },
      dataPago: null,
      data: {
        idcompra: 0,
        fechcompra: current_date(),
        idmoneda: {
          idmoneda: "",
        },
        idtipofactura: {
          idtipofactura: "",
        },
        idcontacto: {
          idcontacto: {
            idcontacto: "",
            docucontactos: "",
            ruccontacto: "",
          },
        },
        numfcompra: "",
        iddeposito: {
          iddeposito: "",
        },
        anulcompra: false,
        tipocompra: true,
        fechvencimiento: sum_days(30),
        timb_compra: "",
        regi_compra: "",
        obse_compra: "",
        cotizacion: "",
        compra: [],
        compraconcepto: [],
        compracaja: [],
        comprabanco: [],
        compracheque: [],
        compratarjeta: [],
      },
      default: {
        idcompra: 0,
        fechcompra: current_date(),
        idmoneda: {
          idmoneda: "",
        },
        idtipofactura: {
          idtipofactura: "",
        },
        idcontacto: {
          idcontacto: {
            idcontacto: "",
            docucontactos: "",
            ruccontacto: "",
          },
        },
        numfcompra: "",
        iddeposito: {
          iddeposito: "",
        },
        anulcompra: false,
        tipocompra: true,
        fechvencimiento: sum_days(30),
        timb_compra: "",
        regi_compra: "",
        obse_compra: "",
        cotizacion: "",
        compra: [],
        compraconcepto: [],
        compracaja: [],
        comprabanco: [],
        compracheque: [],
        compratarjeta: [],
      },
      headers: {
        compra: [
          { text: "Cantidad", sortable: false },
          { text: "Item", sortable: false },
          { text: "Precio Unitario", sortable: false },
          { text: "Exenta", sortable: false },
          { text: "Iva 5%", sortable: false },
          { text: "Iva 10%", sortable: false },
          { text: "Accion", sortable: false },
        ],
        egreso: [
          { text: "Cantidad", sortable: false },
          { text: "Concepto", sortable: false },
          { text: "Precio Unitario", sortable: false },
          { text: "Exenta", sortable: false },
          { text: "Iva 5%", sortable: false },
          { text: "Iva 10%", sortable: false },
          { text: "Accion", sortable: false },
        ],
      },
      formularios: {
        item: false,
        concepto: false,
        deposito: false,
      },

      detalle: {
        idcompra: 0,
        iditem: {
          iditem: "",
          issemen: false,
        },
        idconcepto: {
          idconcepto: "",
        },
        iva5: 0,
        iva10: 0,
        exenta: 0,
        itempajuela: [
          {
            idpajuelas: {
              idtermosemen: {
                idtermosemen: null,
              },
              idsexo: {
                idsexo: null,
              },
            },
          },
        ],
        cantidad: "",
        precio: "",
      },
      detalle_default: {
        idcompra: 0,
        iditem: {
          iditem: "",
          issemen: false,
        },
        idconcepto: {
          idconcepto: "",
        },
        iva5: 0,
        iva10: 0,
        exenta: 0,
        cantidad: "",
        precio: "",
        itempajuela: [
          {
            idpajuelas: {
              idtermosemen: {
                idtermosemen: null,
              },
              idsexo: {
                idsexo: null,
              },
            },
          },
        ],
      },
    };
  },
  watch: {
    searchitem(val) {
      this.obtain_item(val)
    },

    Vget_item_data(val) {
      this.item = [];
      this.item = JSON.parse(JSON.stringify(val));
      if (this.searchItemId != null) return this.search()
    },

    searchItemId() {
      this.search();
    },

    $route(to, from) {
      if (from.path === "/compra") return this.cancelar();
    },

    contactochange(val) {
      if (val === undefined)
        return (this.data.idcontacto = JSON.parse(
          JSON.stringify(this.default.idcontacto)
        ));
    },
    cantidad() {
      this.obtenerIva();
    },
    precio() {
      this.obtenerIva();
    },
  },
  computed: {
    ...mapGetters("item", ["Vget_item_data", "Vget_item_load"]),
    ...mapGetters("concepto", ["Vget_concepto_data", "Vget_concepto_load"]),
    ...mapGetters("compra", ["Vget_compra_save_load"]),
    ...mapGetters("egreso", ["Vget_egreso_save_load"]),

    cantidad() {
      return this.detalle.cantidad;
    },
    precio() {
      return this.detalle.precio;
    },
    contactochange() {
      return this.data.idcontacto.idcontacto;
    },
    item_permission() {
      return getPermission(ITEM).permiso[PUEDE_AGREGAR];
    },
    concepto_permission() {
      return getPermission(CONCEPTO).permiso[PUEDE_AGREGAR];
    },
    isSemen() {
      return this.detalle.iditem.issemen;
    },

    subtotal() {
      const iva5 = getIva5(this.crudMode, this.data);
      const iva10 = getIva10(this.crudMode, this.data);
      return {
        iva5,
        iva10,
        ivatotal: iva5 + iva10,
        total: getTotal(this.crudMode,this.data)
      }
    },
  },
  created() {
    this.crudMode === "Compra"
      ? this.Vact_item_data()
      : this.Vact_concepto_data();
  },
  async mounted() {
    if (this.datos != null) return this.activeEditionMode();
    setTimeout(() => this.nextfocus(-1, "-"), 500);
  },
  methods: {
    ...mapActions("compra", [
      "Vact_compra_data",
      "Vact_compra_save",
      "Vact_compra_update",
    ]),
    ...mapActions("egreso", [
      "Vact_egreso_save",
      "Vact_egreso_data",
      "Vact_egreso_update",
    ]),
    ...mapActions("item", ["Vact_item_data", "item_update"]),
    ...mapActions("concepto", ["Vact_concepto_data"]),
    ...mapActions("stock", ["Vact_stock_data"]),
    obtain_item(val) {
      if (val != null && val.length == 3) {
        this.Vact_item_data({
          desc: this.searchitem
        })
      } else if (val != null && val.length < 3) {
        this.item = []
        
      } else if (val == null) {
        this.detalle = JSON.parse(JSON.stringify(this.detalle_default))
      }
    },
    customFilter(item, queryText) {
      const textOne = item.iditem.toString();
      const textTwo = item.descitem.toLowerCase();
      const searchText = queryText.toLowerCase();
      return (
        textOne.indexOf(searchText) > -1 ||
        textTwo.indexOf(searchText) > -1
      )
    },
    search() {
      this.item.forEach((x) => {
        if (x.iditem === this.searchItemId) {
          this.detalle.precio = x.precitem
          return
        }
      })
    },
    addCaja(event) {
      this.data.compracaja = JSON.parse(JSON.stringify(event));
    },
    addTarjeta(event) {
      this.data.compratarjeta = JSON.parse(JSON.stringify(event));
    },
    addCuentaBanco(event) {
      this.data.comprabanco = JSON.parse(JSON.stringify(event));
    },
    addCheque(event) {
      this.data.compracheque = JSON.parse(JSON.stringify(event));
    },
    isValidCabecera() {
      if (!this.$refs.form.validate()) return null;
      this.step++;
    },
    async activeEditionMode() {
      this.isLoading = true;
      try {
        let resp;
        let detalle;
        switch (this.crudMode) {
          case "Compra":
            resp = await db.get(
              "/apicompra/" + this.datos.idcompra + "/?band=compra"
            );
            detalle = JSON.parse(JSON.stringify(resp.compra));
            resp.compra = [];
            this.data = JSON.parse(JSON.stringify(resp));
            detalle.map((det) => {
              this.detalle = {
                ...det,
                cantidad: det.cantdetcompra,
                precio: det.costdetcompra,
              };
              this.addtabla("edit");
            });
            break;

          case "Egreso":
            resp = await db.get(
              "/apicompra/" + this.datos.idcompra + "/?band=egreso"
            );
            detalle = JSON.parse(JSON.stringify(resp.compraconcepto));
            resp.compraconcepto = [];
            this.data = JSON.parse(JSON.stringify(resp));
            detalle.map((det) => {
              this.detalle = {
                ...det,
                cantidad: det.cantdetconcepto,
                precio: det.costconcepto,
              };
              this.addtabla("edit");
            });
            break;
        }
        this.dataPago = JSON.parse(JSON.stringify(resp));
        this.isLoading = false;
        return (this.operacion = false);
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }
    },
    currencyFormat(value) {
      return new Intl.NumberFormat({ maximumSignificantDigits: 3 }).format(
        value
      );
    },
    async verificar_fact() {
      let verificar = {
        timb_compra: this.data.timb_compra,
        regi_compra: this.data.regi_compra,
        numfcompra: this.data.numfcompra,
        idcompra: this.operacion ? this.data.idcompra : null,
      };
      try {
        let r = await db.save("/apigetfactura/", verificar);
        if (!r.estado) return (this.existeFactura = r.info[0]);
        this.existeFactura = "";
      } catch (error) {
        console.log(error);
      }
    },
    nextfocus(index, data) {
      if (index === 11 && this.data.idtipofactura.idtipofactura === 2)
        return this.nextwindows(data);
      return focus.nextidName("compraInput", index, data);
    },
    nextwindows(data) {
      if (data === null || data === "") return null;
      this.isValidCabecera();
      setTimeout(() => this.nextfocus(14, "-"), 500);
    },
    rowdelete(item) {
      this.index = this.data.compra.indexOf(item);
      this.data.compra.splice(this.index, 1);
      this.index = null;
    },
    completar_nrof(factura) {
      this.data.numfcompra = utilidad.completar_nrof(factura);
    },
    obtenerIva() {
      let idiva =
        this.crudMode === "Compra"
          ? this.detalle.iditem.idcategoriaitem?.idiva?.idiva
          : this.detalle.idconcepto.idcategoriaitem?.idiva?.idiva;
      switch (idiva) {
        case 1:
          this.detalle.exenta = 
            (Number(this.detalle.cantidad) * Number(this.detalle.precio));
          break;
        case 2:
          this.detalle.iva5 =
            (Number(this.detalle.cantidad) * Number(this.detalle.precio));
          break;
        case 3:
          this.detalle.iva10 =
            (Number(this.detalle.cantidad) * Number(this.detalle.precio));
          break;
      }
    },

    verificar_duplicado() {
      let temp = null;
      switch (this.crudMode) {
        case "Egreso":
          this.data.compraconcepto.find((x) => {
            if (x.idconcepto.idconcepto === this.detalle.idconcepto.idconcepto)
              return (temp = x);
          });
          if (temp != null) {
            this.index = this.data.compraconcepto.indexOf(temp);
            this.data.compraconcepto.splice(this.index, 1);
            this.detalle.cantidad =
              Number(this.detalle.cantidad) + Number(temp.cantdetconcepto);
          }
          break;
      }
    },
    validar_det() {
      if (!this.$refs.formDetalle.validate()) return false;
      if (this.detalle.precio > 0 && this.detalle.cantidad > 0) return true;
      return false;
    },
    addtabla(edit) {
      if (!edit) {
        if (!this.validar_det()) return null;
        this.verificar_duplicado();
      }
      this.crudMode === "Compra"
        ? this.data.compra.push({
            iditem: this.detalle.iditem,
            cantdetcompra: this.detalle.cantidad,
            costdetcompra: this.detalle.precio,
            itempajuela: this.detalle.itempajuela,
            iva5: this.detalle.iva5,
            iva10: this.detalle.iva10,
            exenta: this.detalle.exenta,
            porcdetcompra: 0,
          })
        : this.data.compraconcepto.push({
            idconcepto: this.detalle.idconcepto,
            cantdetconcepto: this.detalle.cantidad,
            costconcepto: this.detalle.precio,
            iva5: this.detalle.iva5,
            iva10: this.detalle.iva10,
            exenta: this.detalle.exenta,
            porcdetconcepto: 0,
          });

      this.detalle = JSON.parse(JSON.stringify(this.detalle_default));
      if (!edit) this.$refs.formDetalle.resetValidation();
      if (!edit) this.nextfocus(14, "-");
    },
    cancelar() {
      this.data = JSON.parse(JSON.stringify(this.default));
      this.crudMode === "Compra"
        ? (this.Vact_compra_data(), this.Vact_stock_data())
        : this.Vact_egreso_data();

      this.$emit("input", false);
      this.$emit("datos", null);
    },
    limpiarcampos() {
      this.data = JSON.parse(JSON.stringify(this.default));
      this.step = 1;
    },
    obtenerPrecio() {
      switch (this.crudMode) {
        case "Compra":
          if (this.detalle.iditem.idunidadmedida == null || this.detalle.iditem.idunidadmedida == undefined) {
            this.item_update(this.detalle.iditem);
            this.searchitem = this.detalle.iditem.descitem;
            this.formularios.item = true;
            this.$notify(mensaje.error("Verifique la unidad de medida del item"));
          }
          if (this.detalle.iditem.precitem != undefined)
            this.detalle.precio = this.detalle.iditem.precitem;
          break;

        case "Egreso":
          if (this.detalle.idconcepto.precconcepto != undefined)
            this.detalle.precio = this.detalle.idconcepto.precconcepto;
          break;
      }
    },
    async guardar() {
      if (!this.$refs.form.validate()) return this.$notify(mensaje.completar());
      if (this.data.cotizacion === "") {
        this.data.cotizacion = null;
      }
      let a;
      switch (this.crudMode) {
        case "Compra":
          a = this.operacion
            ? await this.Vact_compra_save(this.data)
            : await this.Vact_compra_update({
                id: this.data.idcompra,
                data: this.data,
              });
          break;

        case "Egreso":
          a = this.operacion
            ? await this.Vact_egreso_save(this.data)
            : await this.Vact_egreso_update({
                id: this.data.idcompra,
                data: this.data,
              });
          break;
      }
      if (!a.estado) {
        return a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
      this.$notify(a.info);
      if (!this.operacion) return this.cancelar();
      this.limpiarcampos();
      this.$refs.form.resetValidation();
      this.step = 1;
      setTimeout(() => this.nextfocus(-1, "-"), 500);
    },
  },
};
</script>
