export default {
    async tipoanalisis_create({commit},request){
        commit('tipoanalisis_create',request);
    },
    async tipoanalisis_update({commit},request){
        commit('tipoanalisis_update',request);
    },
    async tipoanalisis_reload({commit},request){
        commit('tipoanalisis_reload',request);
    },
    async tipoanalisis_show({commit},request){
        commit('tipoanalisis_show',request);
    },
    async tipoanalisis_key({commit},request){
        commit('tipoanalisis_key',request);
    },
}
