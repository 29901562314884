<template>
  <c-dialog-confirmation
    :value="value"
    @cancel="close()"
    @done="save()"
    :loading="isLoadingMaquinariaAsignacion"
    >Desea eliminar la maquinaria <strong>{{ props.idmaquinaria.descripcion }}</strong
    >?</c-dialog-confirmation
  >
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    value: Boolean,
    props: Object,
  },
  computed:{
    ...mapGetters('maquinaria_asignacion', ["isLoadingMaquinariaAsignacion"])
  },
    watch: {
    $route() {
      return this.close();
    },
  },
  methods: {
    ...mapActions("maquinaria_asignacion", ["setMaquinariaAsignacionDelete", "fetchMaquinariaAsignacion"]),
    async save() {
      const id = this.props.idmaquinaria_asignacion;
      const response = await this.setMaquinariaAsignacionDelete(id);
      if (!response.success) return null;
      this.close();
    },
    close() {
      this.$emit("datos", null);
      this.$emit("input", false);
      this.fetchMaquinariaAsignacion();
    },
  }
};
</script>