<template>
    <v-dialog persistent max-width="700" :value="value">
        <v-card>
            <v-toolbar flat>
                <v-toolbar-title>{{ title }}</v-toolbar-title>
            </v-toolbar>
            <v-container>
                <v-form ref="form" @submit.prevent="">
                    <v-row dense>
                        <v-col cols="12">
                            <c-text-field label="Nombre" autocomplete="off" :rules="rules" ref="grupo1"
                                v-model="data.nombre" outlined dense placeholder="Ingrese nombre" 
                                @keyup.native.enter="next(1, data.nombre)"></c-text-field>
                        </v-col>
                        <v-col cols="2">
                            <v-btn class="mx-2" fab dark small :color="`${data.activo ? 'green' : 'red'}`"
                                @click="data.activo = !data.activo">
                                <v-icon dark>
                                    {{ `${data.activo ? 'add' : 'remove'}` }}
                                </v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-container>
            <v-divider></v-divider>
            <v-card-actions>
                <c-btn-crud-close @click="cancel()"></c-btn-crud-close>
                <v-spacer></v-spacer>
                <c-btn-crud-done ref="grupo2" @click="save()"></c-btn-crud-done>
            </v-card-actions>
            <c-overlay :value="isLoadingGrupo"></c-overlay>
        </v-card>
    </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        editable: {
            type: Boolean,
            default: false,
        },
        props: {
            type: Object,
        },
        "sync-input": {
            type: [String, Number],
            default: null,
        },
    },
    mounted() {
        if (this.editable) this.data = JSON.parse(JSON.stringify(this.props));
        setTimeout(() => this.next(0, "-"), 20);
        if (this.syncInput != null) this.data.nombre = this.syncInput;
        this.sync = this.syncInput;
    },
    computed: {
        ...mapGetters("finanza", ["isLoadingGrupo"]),
        title() {
            return this.editable ? "Modificar Grupo" : "Registrar Grupo";
        },
    },
    methods: {
        ...mapActions("finanza", ["setGrupo", "setGrupoUpdate", "fetchGrupo"]),

        next(id, data) {
            if (data != "") {
                try {
                    this.$refs[`grupo${id + 1}`].focus();
                } catch (error) {
                    this.$refs[`grupo${id + 1}`].$el.focus();
                }
            }
        },
        cancel() {
            this.$emit("input", false);
        },
        async save() {
            if (!this.$refs.form.validate()) return null;
            const response = this.editable
                ? await this.setGrupotaUpdate({
                    data: this.data,
                    id: this.data.idfinanza_grupo,
                })
                : await this.setGrupo(this.data);
            if (response.success) {
                if (this.sync != null) {
                    this.cancel();
                    this.fetchGrupo();
                    return this.$emit("sync-input", response.data.idfinanza_grupo);
                }
                this.data = JSON.parse(JSON.stringify(this.default));
                this.$refs.form.resetValidation();
                this.fetchGrupo();
                this.next(0, '-')
                if (this.editable) this.cancel();
            }
        }
    },
    data: () => ({
        rules: [(v) => !!v || "Este campo es obligatorio"],
        data: {
            idfinanza_grupo: 0,
            nombre: "",
            activo: true,
        },
        default: {
            idfinanza_grupo: 0,
            nombre: "",
            activo: true,
        }
    })
}
</script>