<template>
  <div>
    <InputAutocomplete
      label="Que costo de nacimiento tiene?"
      item-value="idcostonacimiento"
      item-text="monto"
      :id="id"
      dense
      outlined
      :readonly="readonly"
      :value="value"
      :return-object="returnObject"
      :items="getCosto"
      :loading="isLoading"
      :permission="permission"
      @sync-input="formulario.search = $event"
      @create="formulario.crud = true"
      @input="$emit('input', $event)"
    />
    <Crud
      v-if="formulario.crud"
      v-model="formulario.crud"
      :sync-input="formulario.search"
      @sync-input="syncInputHandler($event)"
    />
  </div>
</template>

<script>
import InputAutocomplete from "@/ncomponents/InputAutocomplete";
import { mapActions, mapGetters } from "vuex";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { COSTO_NACIMIENTO } from "@/constants/forms";
import { PUEDE_AGREGAR } from "@/constants/permissions";
import Crud from "./Create";
export default {
  props: {
    id: String,
    value: [String, Object, Number],
    returnObject: Boolean,
    readonly: Boolean,
  },
  components: {
    InputAutocomplete,
    Crud,
  },
  data: () => ({
    formulario: {
      crud: false,
      search: "",
    },
  }),
  computed: {
    ...mapGetters("costonacimiento", ["getCosto", "isLoading"]),
    permission: () => getPermission(COSTO_NACIMIENTO).permiso[PUEDE_AGREGAR],
  },
  mounted() {
    this.fetchCosto();
  },
  methods: {
    ...mapActions("costonacimiento", ["fetchCosto"]),
    syncInputHandler(event) {
      if (this.returnObject) this.$emit("input", event);
      if (!this.returnObject) this.$emit("input", event.idcostonacimiento);
      this.$emit("nextfocus");
      this.fetchCosto();
    },
  },
};
</script>
