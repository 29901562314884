import {SET_LOADING, SET_PARCELAS, SET_PARCELAS_BY_LOTE} from './constants'
export default {
  [SET_PARCELAS](state, request) {
    state.parcela = request;
  },
  [SET_PARCELAS_BY_LOTE](state, request) {
    state.parcelaLoteCampana = request;
  },
  [SET_LOADING](state, request) {
    state.isLoading = request;
  },
}