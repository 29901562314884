import { db } from "@/services/db.service";
export default {

    async Vact_compra_data({ commit }, search) {
        commit('compra_load', true);
        try {
            let response = []
            if (search) {
                response = await db.get(`/apicompra/?band=compra&busqueda=${search}`);
            }else{
                response = await db.get('/apicompra/?band=compra&limit=10');
            }
            commit('compra_data', response);
            commit('compra_load', false);
            return true
        } catch (e) {
            commit('compra_load', false);
            return false
        }
    },
    async Vact_compra_del({ dispatch }, id) {
        try {
            const data = await db.delete(`/apicompra/${id}/?band=compra`);
            dispatch('Vact_compra_data');
            return data
        } catch (e) {
            return false
        }
    },
    async Vact_compra_save({ commit }, data) {
        commit('compra_save_load', true);
        try {
            const resp = await db.save('/apicompra/?band=compra', data);
            commit('compra_save_load', false);
            return resp;
        } catch (e) {
            commit('compra_save_load', false);
            return e
        }
    }, 
    async Vact_compra_update({ commit }, { id, data }) {
        commit('compra_save_load', true);
        try {
            const resp = await db.update('/apicompra/' + id + '/?band=compra', data);
            commit('compra_save_load', false);
            return resp;
        } catch (e) {
            commit('compra_save_load', false);
            return e
        }
    }

}
