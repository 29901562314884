export default {
  tipoparto_create(state, request) {
    state.tipoparto_create = request;
  }, 
  tipoparto_update(state, request) {
    state.tipoparto_update = request;
  },   
  tipoparto_reload(state, request) {
    state.tipoparto_reload = request;
  }, 
  tipoparto_show(state, request) {
    state.tipoparto_show = request;
  }, 
  tipoparto_key(state, request) {
    state.tipoparto_key = request;
  }, 
}