<template>
  <div>
    <v-dialog
      max-width="800"
      :value="value"
      persistent
      @keydown.esc="cancelar()"
    >
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>{{
            operacion ? "Agregar Item" : "Editar este Item"
          }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-tabs
              right
              :background-color="darkMode ? 'black' : 'white'"
              color="deep-purple accent-4"
              v-model="tab"
            >
              <v-tab>General</v-tab>
            </v-tabs>
          </v-toolbar-items>
        </v-toolbar>

        <v-container>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-form ref="form">
                <v-row dense class="ma-1">
                  <v-col cols="12" md="12" sm="12" v-if="crudMode == 'AGRO' || crudMode == 'COMPRA'">
                    <Itemequal :buscar="data.descitem"/>
                  </v-col>
                  <v-col cols="12" md="6" sm="12">
                    <vue-text-field
                      label="Descripcion"
                      id="itemInput1"
                      v-model="data.descitem"
                      @keyup.native.enter="next(1, data.descitem)"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    :md="['AGRO', 'COMPRA'].indexOf(crudMode) == -1 ? 3 : 6"
                    :sm="['AGRO', 'COMPRA'].indexOf(crudMode) == -1 ? 6 : 12"
                  >
                    <c-text-currency
                      v-model="data.precitem"
                      id="itemInput2"
                      :rules="[(v) => !!v || 'Campo requerido']"
                      dense
                      outlined
                      label="Precio Compra"
                      @keyup.native.enter="
                        next(['AGRO', 'COMPRA'].indexOf(crudMode) == -1 ? 2 : 3, data.precitem)
                      "
                    ></c-text-currency>
                  </v-col>
                  <v-col cols="12" md="3" sm="6" v-if="['AGRO', 'COMPRA'].indexOf(crudMode) == -1">
                    <c-text-currency
                      v-model="data.preciov"
                      id="itemInput3"
                      :rules="[(v) => !!v || 'Campo requerido']"
                      dense
                      outlined
                      :label="
                        data.porcentaje ? 'Porcentaje Ganancia' : 'Precio Venta'
                      "
                      @keyup.native.enter="next(3, data.preciov)"
                    ></c-text-currency>
                  </v-col>
                  <v-col cols="12" md="4">
                    <vue-autocomplete-generic
                      id="itemInput4"
                      action="categoria/Vact_categoria_data"
                      getter="categoria/Vget_categoria_data"
                      load="categoria/Vget_categoria_load"
                      pk="idcategoriaitem"
                      label="Seleccione categoria"
                      title="desccategoriaitem"
                      permission="Categoria Item"
                      @sync-input="search = $event"
                      :create="true"
                      @create="abrircategoria = $event"
                      v-model="data.idcategoriaitem.idcategoriaitem"
                      @keyup.native.enter="
                        next(4, data.idcategoriaitem.idcategoriaitem)
                      "
                    />
                    <div v-if="abrircategoria">
                      <Item-Categoria-create
                        v-model="abrircategoria"
                        :sync-input="search"
                        @sync-input="
                          (data.idcategoriaitem.idcategoriaitem = $event),
                            next(4, '-')
                        "
                      />
                    </div>
                  </v-col>
                  <v-col cols="12" md="4">
                    <vue-autocomplete-generic
                      id="itemInput5"
                      action="marca/Vact_marca_data"
                      getter="marca/Vget_marca_data"
                      load="marca/Vget_marca_load"
                      pk="idmarca"
                      label="Seleccione marca"
                      title="descmarca"
                      @sync-input="search = $event"
                      permission="Marca"
                      :create="true"
                      @create="abrirmarca = $event"
                      v-model="data.idmarca.idmarca"
                      @keyup.native.enter="
                        next(['AGRO', 'COMPRA'].indexOf(crudMode) >= 0 ? 6 : 5, data.idmarca.idmarca)
                      "
                    />
                    <div v-if="abrirmarca">
                      <Item-Marca-create
                        v-model="abrirmarca"
                        :sync-input="search"
                        @sync-input="
                          (data.idmarca.idmarca = $event),
                            next(['AGRO', 'COMPRA'].indexOf(crudMode) >= 0 ? 6 : 5, '-')
                        "
                      />
                    </div>
                  </v-col>
                  <v-col cols="12" md="4">
                    <c-text-field
                      id="itemInput6"
                      label="Codigo de Barra"
                      :rules="[]"
                      v-model="data.cbarra"
                      outlined
                      dense
                      @keyup.native.enter="next(6, '-')"
                    ></c-text-field>
                  </v-col>
                </v-row>

                <v-expansion-panels accordion focusable v-model="panel">
                  <v-expansion-panel>
                    <v-expansion-panel-header
                      id="itemInput7"
                      @keyup.enter="next(7, 'A')"
                      >Opciones Avanzadas</v-expansion-panel-header
                    >
                    <v-expansion-panel-content>
                      <Item-Item-Avanzado
                        v-model="data_avanzado"
                        ref="refAdvanced"
                        :crudMode="crudMode"
                        :error="error"
                      />
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header
                      id="itemInput14"
                      @keyup.enter="next(14, 'A')"
                      >Propiedades Quimicas</v-expansion-panel-header
                    >
                    <v-expansion-panel-content>
                      <Item-Item-Atributos v-model="itemquimica" />
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-form>
            </v-tab-item>
          </v-tabs-items>
        </v-container>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="red" text @click="cancelar()">
            <v-icon>clear</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            id="itemInputaceptar"
            text
            @click="validarcampos()"
          >
            <v-icon>done</v-icon>
          </v-btn>
        </v-card-actions>
        <c-overlay :value="Vget_item_load_save" absolute></c-overlay>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogoStock" persistent max-width="450">
      <v-card>
        <v-card-title>
          <v-icon left>info</v-icon>
          <span class="title font-weight-light">Desea ajustar Stock?</span>
        </v-card-title>
        <v-container>
          <v-form ref="formStock">
            <v-row dense>
              <v-col cols="12">
                <AutocompleteDeposito
                  id="itemInput20"
                  ref="itemInput20"
                  outlined
                  dense
                  label="Seleccione el Deposito"
                  v-model="ajustestock.iddeposito.iddeposito"
                  create
                  @next-focus="next(20, '-')"
                  @keyup.native.enter="
                    next(20, ajustestock.iddeposito.iddeposito)
                  "
                />
              </v-col>
              <v-col cols="12">
                <vue-text-field
                  label="Cantidad"
                  id="itemInput21"
                  v-model="ajustestock.cantajustestock"
                  @keyup.native.enter="next(21, ajustestock.cantajustestock)"
                ></vue-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="red" text @click="dialogoStock = false">
            <v-icon>clear</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" id="itemInput22" text @click="guardar_stock()">
            <v-icon>done</v-icon>
          </v-btn>
        </v-card-actions>
        <c-overlay :value="Vget_ajuste_save_load"></c-overlay>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { mensaje } from "@/services/notify.service";
import { focus } from "@/services/focus.service";
import AutocompleteDeposito from "../Deposito/autocomplete";
import Itemequal from "@/views/Item/Item/Itemequal";
export default {
  components: {
    AutocompleteDeposito,
    Itemequal
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    crudMode: {
      type: String,
      default: "FULL",
    },
    "sync-input": {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      tab: null,
      sync: "",
      search: "",
      abrircategoria: false,
      dialogoStock: false,
      abrirmarca: false,
      showAvanzada: false,
      error: [],
      panel: [],
      itemquimica: [],
      ajustestock: {
        fechajustestock: "2000-01-01",
        idajustestock: 0,
        cantajustestock: "",
        iditem: {
          iditem: 0,
        },
        idmotivoajuste: {
          idmotivoajuste: 1,
        },
        idtipoajuste: {
          idtipoajuste: 1,
        },
        iddeposito: {
          iddeposito: "",
        },
      },
      ajustestock_default: {
        fechajustestock: "2000-01-01",
        idajustestock: 0,
        cantajustestock: "",
        iditem: {
          iditem: 0,
        },
        idmotivoajuste: {
          idmotivoajuste: 1,
        },
        idtipoajuste: {
          idtipoajuste: 1,
        },
        iddeposito: {
          iddeposito: "",
        },
      },
      data_avanzado: {
        idtipo_item: {
          desctipo_item: "Default",
          idtipo_item: 7,
        },
        idunidadmedida: {
          idunidadmedida: 1,
          descunidadmedida: "Unidad",
          idmagnitud: {
            descripcion: "Unidad",
            idmagnitud: 1,
          },
        },
        cant_contiene: 1,
        idmoneda: "",
        porcentaje: false,
        minitem: 1,
        tipoitem: false,
        stock: false,
        idcuenta: {
          idcuenta: 1,
        },
        idobjetivo: "",
        idmedicamento: null,
        band: "",
        active: true,
      },
      data_avanzado_default: {
        idtipo_item: {
          desctipo_item: "Default",
          idtipo_item: 7,
        },
        idunidadmedida: {
          idunidadmedida: 1,
          descunidadmedida: "Unidad",
          idmagnitud: {
            descripcion: "Unidad",
            idmagnitud: 1,
          },
        },
        cant_contiene: 1,
        idmoneda: "",
        porcentaje: false,
        minitem: 1,
        tipoitem: false,
        stock: false,
        idcuenta: {
          idcuenta: 1,
        },
        idobjetivo: "",
        idmedicamento: null,
        band: "",
        active: true,
      },
      data: {
        idcategoriaitem: {
          idcategoriaitem: "",
        },
        descitem: "",
        idmarca: {
          idmarca: "",
        },
        preciov: "",
        precitem: "",
        itemquimica: [],
        active: true,
      },
      default: {
        idcategoriaitem: {
          idcategoriaitem: "",
        },
        idtipo_item: {
          desctipo_item: "Default",
          idtipo_item: 7,
        },
        idunidadmedida: {
          idunidadmedida: 1,
          descunidadmedida: "Unidad",
          idmagnitud: {
            descripcion: "Unidad",
            idmagnitud: 1,
          },
        },
        cant_contiene: 1,
        descitem: "",
        idmarca: {
          idmarca: "",
        },
        idmoneda: "",
        preciov: "",
        porcentaje: false,
        precitem: "",
        minitem: 1,
        tipoitem: false,
        stock: false,
        idcuenta: {
          idcuenta: 1,
        },
        idmedicamento: null,
        itemquimica: [],
        band: "",
        active: true,
      },
      operacion: true,
      id: "",
    };
  },
  watch: {
    $route(to, from) {
      if (from.path === "/item") return this.cancelar();
    },
    getupdate(val) {
      if (val == null) {
        this.operacion = true;
        this.data = JSON.parse(JSON.stringify(this.default));
        this.ajustestock = JSON.parse(JSON.stringify(this.ajustestock_default));
        this.data_avanzado = JSON.parse(JSON.stringify(this.data_avanzado_default));
      } else {
        this.nuevo(val);
      }
    } 
  },
  computed: {
    ...mapGetters("empresa", ["Vget_empresa_moneda"]),
    ...mapGetters("item", {
      getupdate: "item_update",
    }),
    ...mapGetters("item", ["Vget_item_load_save"]),
    ...mapGetters("ajuste", ["Vget_ajuste_save_load"]),
    darkMode() {
      return this.$vuetify.theme.dark;
    },
  },

  created() {
    this.nuevo(this.getupdate);
  },
  mounted() {
    setTimeout(() => document.getElementById("itemInput1").focus(), 500);
    if (this.syncInput != null) {
      this.data.descitem = this.syncInput;
      this.sync = this.syncInput;
    }
    if (this.operacion)
      setTimeout(
        () => (this.data_avanzado.idmoneda = this.Vget_empresa_moneda),
        500
      );
    if (['AGRO', 'COMPRA'].indexOf(this.crudMode) >= 0) this.panel = 0;
  },
  methods: {
    ...mapActions("item", [
      "item_update",
      "Vact_item_data",
      "Vact_item_save",
      "Vact_item_update",
      "Vact_item_agrostock"
    ]),
    ...mapActions("ajuste", ["Vact_ajuste_save", "Vact_ajuste_data"]),
    ...mapActions("medicamento", ["medicamento_update", "medicamento_validar"]),
    opcionesAvanzadas() {
      this.showAvanzada
        ? (this.showAvanzada = false)
        : (this.showAvanzada = true);
    },
    next(index, data) {
      if (index === 6 && data != "") setTimeout(500);
      if (index === 13) setTimeout(500);
      focus.nextidName("itemInput", index, data);
    },
    limpiar() {
      this.data = JSON.parse(JSON.stringify(this.default));
      this.ajustestock = JSON.parse(JSON.stringify(this.ajustestock_default));
      this.dialogoStock = false;
      this.item_update(null);
      this.Vact_item_data();
      this.operacion = true;
    },
    async guardar_stock() {
      this.$refs.formStock.validate();
      let a = await this.Vact_ajuste_save(this.ajustestock);
      if (!a.estado) {
        a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
        return false;
      }
      if (this.sync != null && (['AGRO', 'COMPRA'].indexOf(this.crudMode) >= 0)) {
        this.Vact_item_agrostock({
          search: a.data.data.data.iditem.descitem,
          idmoneda: 1,
        });
        this.$emit("sync-input", this.ajustestock.iditem);
        this.$emit("item", this.ajustestock.iditem.iditem);
        return this.cancelar();
      }
      this.$notify(a.info);
      this.limpiar();
      this.Vact_ajuste_data();
      this.$refs.formStock.resetValidation();
      this.$refs.form.resetValidation();

      setTimeout(() => document.getElementById("itemInput1").focus(), 500);
    },
    async guardar_finalizar() {
      if (['AGRO', 'COMPRA'].indexOf(this.crudMode) >= 0) {
        this.data.preciov = this.data.precitem;
      }
      let a = await this.Vact_item_save(this.data);
      if (!a.estado) {
        a.info.map((x) => {
          this.error = { ...a.data.response.data };
          this.$notify(mensaje.error(x));
        });
        return false;
      }
      this.ajustestock.iditem = a.data.data.id;
      this.$notify(a.info);
      if (['AGRO', 'COMPRA'].indexOf(this.crudMode) == -1) this.Vact_item_data({limit: 100, desc: null});
      if (this.data.stock) {
        this.dialogoStock = true;
        setTimeout(() => this.next(19, "-"), 200);
        return;
      }

      this.$refs.form.resetValidation();
      if (this.sync != null) {
        if (this.crudMode === "AGRO") {
          this.Vact_item_agrostock({
            search: a.data.data.id.descitem,
            idmoneda: 1,
          });
        } else {
          this.Vact_item_data({
            desc: a.data.data.id.descitem
          })
        }
        this.$emit("item", a.data.data.id.iditem);
        this.$emit("sync-input", a.data.data.id);
        return this.cancelar();
      }
      this.limpiar();
      setTimeout(() => document.getElementById("itemInput1").focus(), 500);
      return true;
    },
    async editar_finalizar() {
      let a = await this.Vact_item_update({ id: this.id, data: this.data });
      if (!a.estado) {
        return a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
      this.$notify(a.info);
      if (['AGRO', 'COMPRA'].indexOf(this.crudMode) == -1) this.Vact_item_data({limit: 100, desc: null});
      if (this.data.stock) {
        this.ajustestock.iditem.iditem = this.id;
        this.dialogoStock = true;
        return;
      }
      this.$notify(a.info);
      if (this.sync != null) {
        if (this.crudMode === "AGRO") {
          this.Vact_item_agrostock({
            search: a.data.data.id.descitem,
            idmoneda: 1,
          });
        } else {
          this.Vact_item_data({
            desc: a.data.data.id.descitem
          })
        }
        this.$emit("item", this.id);
        a.data.data.id.iditem = this.id
        this.$emit("sync-input", a.data.data.id);
        return this.cancelar();
      }
      this.limpiar();
      this.cancelar();
    },
    guardar() {
      if (this.data.tipoitem) this.stock = false;
      if (!this.$refs.form.validate()) return this.$notify(mensaje.completar());
      if (this.operacion) return this.guardar_finalizar();
      if (!this.operacion) return this.editar_finalizar();
    },

    validarcampos() {
      this.data = { ...this.data, ...this.data_avanzado };
      this.data.itemquimica = JSON.parse(JSON.stringify(this.itemquimica));
      if (this.$refs.form.validate()) {
        if (
          this.data.idtipo_item.idtipo_item === 2 ||
          this.data.idtipo_item.idtipo_item === 3
        ) {
          if (this.$refs.refAdvanced) {
            if (this.$refs.refAdvanced.validateMedicina()) {
              this.data.band = "medicamento";
              this.data.idmedicamento = this.$refs.refAdvanced.getDataMedicina();
              return this.guardar();
            }
            return null;
          }
          this.data.band = "medicamento";
          return this.guardar();
        }
        this.data.band = "";
        this.guardar();
      }
    },
    cancelar() {
      this.item_update(null);
      this.$emit("input", false);
    },

    unirEditar(val) {
      let data_avanzado = {
        ...{ idtipo_item: val.idtipo_item },
        ...{ idunidadmedida: val.idunidadmedida },
        ...{ cant_contiene: val.cant_contiene },
        ...{ idmoneda: val.idmoneda },
        ...{ porcentaje: val.porcentaje },
        ...{ minitem: val.minitem },
        ...{ tipoitem: val.tipoitem },
        ...{ stock: val.stock },
        ...{ idcuenta: val.idcuenta },
        ...{ idobjetivo: val.idobjetivo },
        ...{ idmedicamento: val.medicamentos[0] },
        ...{ band: val.band },
        ...{ active: val.active }
      };
      let data = {
        ...{ idcategoriaitem: val.idcategoriaitem },
        ...{ descitem: val.descitem },
        ...{ idmarca: val.idmarca },
        ...{ preciov: val.preciov },
        ...{ precitem: val.precitem },
        ...{ itemquimica: val.itemquimica },
        cbarra: val.cbarra,
        active: val.active
      };
      this.itemquimica = JSON.parse(JSON.stringify(val.itemquimica));
      this.data_avanzado = JSON.parse(JSON.stringify(data_avanzado));
      this.data = JSON.parse(JSON.stringify(data));
    },
    nuevo(val) {
      if (val === null) return null;
      if (val.iditem != 0) {
        this.unirEditar(val);
        this.id = val.iditem;
        this.operacion = false;
      } else {
        this.unirEditar(val);
        this.operacion = true;
      }
    },
  },
};
</script>
