<template>
  <div>
    <v-toolbar flat dense>
      <v-toolbar-title>Dashboard Recursos Humanos</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-sheet class="d-flex justify-end mb-2">
      <v-slide-group multiple show-arrows>
        <v-slide-item
          ><v-btn
            active-class="deep-purple--text accent-4"
            exact
            text
            tile
            to="/rrhh"
            >Inicio</v-btn
          ></v-slide-item
        >
        <v-slide-item
          ><v-btn
            active-class="deep-purple--text accent-4"
            exact
            text
            tile
            to="/rrhh/funcionarios"
            >Funcionarios</v-btn
          ></v-slide-item
        >
        <v-slide-item
          ><v-btn
            active-class="deep-purple--text accent-4"
            exact
            text
            tile
            to="/rrhh/asistencias"
            >Asistencias</v-btn
          ></v-slide-item
        >
        <v-slide-item>
          <v-menu bottom left offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                text
                tile
                class="align-self-center"
                v-bind="attrs"
                v-on="on"
              >
                Pagos
                <v-icon right>arrow_drop_down</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item
                v-for="(sub, subkey) in menu.pagos"
                :key="subkey"
                :to="sub.route"
                active-class="deep-purple--text accent-4"
                >{{ sub.title }}</v-list-item
              >
            </v-list>
          </v-menu>
        </v-slide-item>
        <v-slide-item>
          <v-menu bottom left offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                text
                tile
                class="align-self-center"
                v-bind="attrs"
                v-on="on"
              >
                Movimientos
                <v-icon right>arrow_drop_down</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item
                v-for="(sub, subkey) in menu.operaciones"
                :key="subkey"
                :to="sub.route"
                active-class="deep-purple--text accent-4"
                >{{ sub.title }}</v-list-item
              >
            </v-list>
          </v-menu>
        </v-slide-item>
        <v-slide-item>
          <v-menu bottom left offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                text
                tile
                class="align-self-center"
                v-bind="attrs"
                v-on="on"
              >
                Mantenimientos
                <v-icon right>arrow_drop_down</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item
                v-for="(sub, subkey) in menu.mantenimientos"
                :key="subkey"
                :to="sub.route"
                active-class="deep-purple--text accent-4"
                >{{ sub.title }}</v-list-item
              >
            </v-list>
          </v-menu>
        </v-slide-item>
        <v-slide-item
          ><v-btn
            active-class="deep-purple--text accent-4"
            exact
            text
            tile
            to="/rrhh/reporterrhh"
            >Reporte RRHH</v-btn
          ></v-slide-item
        >
        <v-slide-item v-if="!configRRHH">
          <v-btn
            tile
            text
            exact
            active-class="deep-purple--text accent-4"
            to="/rrhh/settings"
            ><v-icon>settings</v-icon></v-btn
          >
        </v-slide-item>
      </v-slide-group>
    </v-sheet>
    <v-divider></v-divider>
    <v-card>
      <transition>
        <keep-alive>
          <router-view></router-view>
        </keep-alive>
      </transition>
    </v-card>
  </div>
</template>

<script>
import { getPermission } from "@/services/storage/permissionStorage.service";
import { INFORME_CAJA_CUENTA, CONFIG_RRHH } from "@/constants/forms";
import { SALDO_CAJA_CUENTA } from "@/constants/permissions";
import { mensaje } from "@/services/notify.service";

export default {
  data() {
    return {
      permission: {
        Saldos: false,
      },
      menu: {
        mantenimientos: [
          {
            title: "Sector",
            route: "/rrhh/mantenimiento/sector",
          },
          {
            title: "Nivel",
            route: "/rrhh/mantenimiento/nivel",
          },
          {
            title: "Concepto Salarial",
            route: "/rrhh/mantenimiento/concepto",
          },
          {
            title: "Estado Asistencia",
            route: "/rrhh/mantenimiento/estadoasistencia",
          },
          {
            title: "Reloj Biometrico",
            route: "/rrhh/mantenimiento/relojbio",
          },
          {
            title: "Poligono de Marcacion",
            route: "/rrhh/mantenimiento/poligono",
          },
        ],
        pagos: [
          {
            title: "Salario",
            route: "/rrhh/pago/salario",
          },
           {
            title: "Adelanto",
            route: "/rrhh/pago/adelanto",
          },
          {
            title: "Aguinaldo",
            route: "/rrhh/pago/aguinaldo",
          },
          {
            title: "Despido/Renuncia",
            route: "/rrhh/pago/despido",
          },
        ],
        operaciones: [
          {
            title: "Ausencias/Vacaciones",
            route: "/rrhh/operacion/ausencias",
          },
        ],
      },
    };
  },
  created() {
    this.$store.commit("SET_LAYOUT", "principal-layout");
  },
  mounted() {
    const permission = getPermission(INFORME_CAJA_CUENTA).permiso;
    this.permission = {
      saldos: permission[SALDO_CAJA_CUENTA],
    };
  },
  computed: {
    configRRHH() {
      const permissionConfigRRHH = getPermission(CONFIG_RRHH).permiso;
      return mensaje.permission(permissionConfigRRHH);
    },
  }
};
</script>
