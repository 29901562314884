
export default {

  causarechazo_create: (state) => {
    return state.causarechazo_create
  },
  causarechazo_update: (state) => {
    return state.causarechazo_update
  },
  causarechazo_show: (state) => {
    return state.causarechazo_show
  },
  Vget_causarechazo_data: (state) => {
    return state.causarechazo_data
  },
  Vget_causarechazo_load: (state) => {
    return state.causarechazo_load
  },  
  Vget_causarechazo_save_load: (state) => {
    return state.causarechazo_save_load
  },
}
