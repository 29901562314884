<template>
    <div id="editar">
    <v-row dense align="center" justify="center">
      <h4>Editar Campo</h4>
      <v-img :src="require('@/assets/help/agro/editarcampo.png')" max-width="240"></v-img>
      <v-col cols="12">
        <span>
          <v-icon color="info" small>info</v-icon>Obs: No se puede editar los poligonos
        </span>
        <li>Modifique los campos</li>
        <li>Click en EDITAR para guardar</li>
        <li>Click en CANCELAR para cancelar</li>
        <li>Mision Cumplida!!! :D</li>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>