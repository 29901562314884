<template>
  <div>
    <h4 >
      1 - (Opcional) Navegacion
      <a @click="masNav = !masNav">{{masNav ? 'Menos' : 'Mas'}}</a>
    </h4>
    <div v-if="masNav">
      <v-img
        class="mt-2"
        :src="require('@/assets/help/agro/operacion/siembra.png')"
        max-width="240"
      ></v-img>
      <ol>
        <li>Seleccion de CAMPAÑA actual</li>
        <li>Seleccion de CAMPO actual</li>
      </ol>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      masNav: false
    };
  }
};
</script>

<style>
</style>