import { db } from "@/services/db.service";
export default {

    async Vact_transladocajabanco_data({commit}){
        commit('transladocajabanco_load',true);
        try {
            const data = await db.get('/apitransladobanco/');
            commit('transladocajabanco_data',data);
            commit('transladocajabanco_load',false);
            return true
        } catch (e) {
            commit('transladocajabanco_load',false);
            return false
        } 
    },
    async Vact_transladocajabanco_save({commit},data){
        commit('transladocajabanco_save_load',true);
        try {
            const resp = await db.save('/apitransladobanco/',data);
            commit('transladocajabanco_save_load',false);
            return resp;
        } catch (e) {
            commit('transladocajabanco_save_load',false);
            return e
        } 
    },
}
