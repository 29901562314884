import { get, del, put, post } from "@/services/api/api.service";
import {
  SET_LOADING,
  SET_VENTA,
  SET_ITEM_VENTA,
  SET_VENTA_ADV,
  url,
  SET_VENTA_BYID,
  SET_REIMPRESION,
  SET_REBUILD,
} from "./constants";
export default {
  async Vact_venta_data({ commit, dispatch }, search) {
    commit(SET_LOADING, true);
    try {
      let response = [];
      if (search) {
        response = await get(`${url.venta}?busqueda=${search}`);
      } else {
        response = await get(`${url.venta}?limit=10`);
      }
      commit(SET_VENTA, null);
      commit(SET_VENTA, response);
    } catch (e) {
      dispatch("snackbar/setNotification", e, { root: true });
      commit(SET_LOADING, false);
      throw e;
    }
    commit(SET_LOADING, false);
  },
  async fetchVentaRebuild({ commit, dispatch }) {
    commit(SET_LOADING, true);
    try {
      const response = await get(url.rebuild);
      commit(SET_REBUILD, response);
    } catch (e) {
      dispatch("snackbar/setNotification", e, { root: true });
      commit(SET_LOADING, false);
      throw e;
    }
    commit(SET_LOADING, false);
  },
  async Vact_venta_del({ commit, dispatch }, id) {
    commit(SET_LOADING, true);
    const response = await del(url.venta + "" + id + "/?band=venta");
    dispatch("Vact_venta_data");
    dispatch("snackbar/setNotification", response, { root: true });
    commit(SET_LOADING, false);
    return response;
  },

  async Vact_venta_save({ commit, dispatch }, data) {
    commit(SET_LOADING, true);
    const response = await post(url.venta + "?band=venta", data);
    dispatch("snackbar/setNotification", response, { root: true });
    commit(SET_LOADING, false);
    return response;
  },

  async setVentaRebuild({ commit, dispatch }, data) {
    commit(SET_LOADING, true);
    const response = await post(url.rebuild, data);
    dispatch("snackbar/setNotification", response, { root: true });
    commit(SET_LOADING, false);
    return response;
  },

  async setVentaReimpresion({ commit, dispatch },id) {
    commit(SET_LOADING, true);
    const response = await post(`${url.venta}${url.reimpresion}${id}/`);
    dispatch("snackbar/setNotification", response, { root: true });
    commit(SET_LOADING, false);
    return response;
  },
  async Vact_venta_update({ commit, dispatch }, { data, id }) {
    commit(SET_LOADING, true);
    const response = await put(`${url.venta}${id}/`, data);
    dispatch("snackbar/setNotification", response, { root: true });
    commit(SET_LOADING, false);
    return response;
  },
  async Vact_venta_itemvendido({ commit, dispatch }, params) {
    commit(SET_LOADING, true);
    try {
      const data = await get(`${url.itemventa}?${params}`);
      commit(SET_ITEM_VENTA, data);
    } catch (e) {
      dispatch("snackbar/setNotification", e, { root: true });
      commit(SET_LOADING, false);
      throw e;
    }
    commit(SET_LOADING, false);
  },

  async Vact_venta_advanced({ commit, dispatch }, params) {
    commit(SET_LOADING, true);
    try {
      const data = await get(`${url.advanced}?${params}`);
      commit(SET_VENTA_ADV, data);
      commit(SET_LOADING, false);
    } catch (e) {
      dispatch("snackbar/setNotification", e, { root: true });
      commit(SET_LOADING, false);
      throw e;
    }
  },
  async Vact_venta_reimpresion({ commit, dispatch }, id) {
    commit(SET_LOADING, true);
    try {
      const data = await get(`${url.venta}${url.reimpresion}${id}/`);
      commit(SET_REIMPRESION, data);
      commit(SET_LOADING, false);
    } catch (e) {
      dispatch("snackbar/setNotification", e, { root: true });
      commit(SET_REIMPRESION, { autorizado: false });
      commit(SET_LOADING, false);
      throw e;
    }
  },
  async Vact_venta_byId({ commit, dispatch }, id) {
    commit(SET_LOADING, true);
    try {
      const data = await get(`${url.venta}${id}/`);
      commit(SET_VENTA_BYID, data);
      commit(SET_LOADING, false);
    } catch (e) {
      dispatch("snackbar/setNotification", e, { root: true });
      commit(SET_LOADING, false);
      throw e;
    }
  },
};
