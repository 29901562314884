import { db } from "@/services/db.service";
export default {
    
    async Vact_apertura_data({commit}){
        commit('apertura_load',true);
        try {
            const data = await db.get('/apiapertura/');
            commit('apertura_data',data);
            commit('apertura_load',false);
            return true
        } catch (e) {
            commit('apertura_load',false);
            return false
        } 
    },

    async Vact_apertura_save({commit},data){
        commit('apertura_save_load',true);
        try {
            const resp = await db.save('/apiapertura/',data);
            commit('apertura_save_load',false);
            return resp;
        } catch (e) {
            commit('apertura_save_load',false);
            return e
        } 
    },
}
