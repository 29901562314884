import { db } from "@/services/db.service";
export default {
    async Vact_concepto_data({commit}){
        commit('concepto_load',true);
        try {
            const data = await db.get('/apiconcepto/');
            commit('concepto_data',data);
            commit('concepto_load',false);
            return true
        } catch (e) {
            commit('concepto_load',false);
            return false
        } 
    },
    async Vact_concepto_del({dispatch},id){
        try {
            const data = await db.delete('/apiconcepto/'+id);
            dispatch('Vact_concepto_data');
            return data
        } catch (e) {
            return false
        } 
    },
    async Vact_concepto_save({commit},data){
        commit('concepto_save_load',true);
        try {
            const resp = await db.save('/apiconcepto/',data);
            commit('concepto_save_load',false);
            return resp;
        } catch (e) {
            commit('concepto_save_load',false);
            return e
        } 
    },
    async Vact_concepto_update({commit},{id, data}){
        commit('concepto_save_load',true);
        try {
            const resp = await db.update('/apiconcepto/'+id+'/',data);
            commit('concepto_save_load',false);
            return resp;
        } catch (e) {
            commit('concepto_save_load',false);
            return e
        } 
    },
}
