<template>
  <div>
    <v-card>
      <v-toolbar flat dense>
        <v-toolbar-title>Cajas y Bancos</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-divider></v-divider>
      <v-sheet class="d-flex justify-end mb-2">
        <v-slide-group multiple show-arrows>
          <v-slide-item>
            <div>
              <v-btn active-class="primary--text" exact tile text to="/cajabanco" v-if="permission.saldos"
                >Saldos</v-btn
              >
            </div>
          </v-slide-item>
          <v-slide-item v-for="(item, key) of menus" :key="key">
            <v-menu bottom left offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn text tile class="align-self-center" v-bind="attrs" v-on="on">
                  {{ item.title }}
                  <v-icon right>arrow_drop_down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(sub, subkey) in item.subs"
                  :key="subkey"
                  active-class="primary--text"
                  :to="sub.route"
                  >{{ sub.title }}</v-list-item
                >
              </v-list>
            </v-menu>
          </v-slide-item>
        </v-slide-group>
      </v-sheet>
      <CajaBanco v-show="$route.path === '/cajabanco'" />
      <router-view></router-view>
    </v-card>
  </div>
</template>

<script>
import CajaBanco from "./CajaBanco";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { INFORME_CAJA_CUENTA } from "@/constants/forms";
import { SALDO_CAJA_CUENTA } from "@/constants/permissions";
export default {
  components: {
    CajaBanco,
  },
  data() {
    return {
      tab1: null,
      tab: null,
      more: [],
      permission: {
        Saldos: false,
      },
      menus: [
        {
          title: "Operaciones",
          subs: [
            {
              title: "Emitir Cheque",
              route: "/cajabanco/transaccion/emitircheque",
            },
            {
              title: "Desembolso y Confirmacion de Cheque",
              route: "/cajabanco/transaccion/confirmacioncheque",
            },
          ],
        },
        {
          title: "Transferancias",
          subs: [
            {
              title: "Caja a Caja",
              route: "/cajabanco/transferencia/cajacaja",
            },
            {
              title: "Caja a Banco",
              route: "/cajabanco/transferencia/cajabanco",
            },
            {
              title: "Banco a Caja",
              route: "/cajabanco/transferencia/bancocaja",
            },
            {
              title: "Banco a Banco",
              route: "/cajabanco/transferencia/bancobanco",
            },
            {
              title: "Historial de Transferencias",
              route: "/cajabanco/transferencia/historial",
            },
          ],
        },
        {
          title: "Ajustes de Cuentas",
          subs: [
            {
              title: "Ajuste de Caja",
              route: "/cajabanco/ajuste/caja",
            },
            {
              title: "Ajuste de Cuenta Bancaria",
              route: "/cajabanco/ajuste/cuentabancaria",
            },
          ],
        },
        {
          title: "Mantenimientos",
          subs: [
            {
              title: "Caja",
              route: "/cajabanco/mantenimiento/caja",
            },
            {
              title: "Banco",
              route: "/cajabanco/mantenimiento/banco",
            },
            {
              title: "Cuenta Bancaria",
              route: "/cajabanco/mantenimiento/cuentabanco",
            },
            {
              title: "Chequera",
              route: "/cajabanco/mantenimiento/chequera",
            },
            {
              title: "Tarjetas de Credito",
              route: "/cajabanco/mantenimiento/tarjetacredito",
            },
          ],
        },
      ],
      reportes: [
        {
          title: "Avanzado",
          route: "/diariocaja/reporteavanzado",
        },
      ],
    };
  },
  created() {
    this.$store.commit("SET_LAYOUT", "principal-layout");
  },
  mounted() {
    this.tab = this.$route.meta.tab;
    const permission = getPermission(INFORME_CAJA_CUENTA).permiso;
    this.permission = {
      saldos: permission[SALDO_CAJA_CUENTA],
    };
  },

  methods: {
    toRoute(path) {
      if (this.$router.history.current.path != path)
        this.$router.push({ path });
    },
    addToMore(item) {
      this.more = [];
      this.more.push(item);
      this.toRoute(item.route);
      this.tab = 5;
    },
  },
};
</script>
