<template>
  <div>
    <v-select
      :hide-details="hideDetails"
      :label="label"
      placeholder="Busqueda"
      :items="estados"
      :rounded="rounded"
      return-object
      item-value="text"
      item-text="text"
      :rules="[(v) => !!v || 'Seleccione un fitro']"
      :dense="dense"
      :filled="filled"
      :value="value"
      @input="$emit('input', $event)"
    ></v-select>
  </div>
</template>
<script>
export default {
  props: {
    value: Object,
    label: String,
    filled: {
      type: Boolean,
      default: true,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    rounded: {
      type: Boolean,
      default: true,
    },
    "hide-details": {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    estados: [
      { text: "Todos", action: "fetchTodos", getter: "getTodos", band: "todo" },
      {
        text: "Semen en Termo",
        action: "fetchAnimalTermo",
        getter: "getTermo",
        band: "termo",
      },
      {
        text: "Machos",
        action: "fetchAnimalToro",
        getter: "getToro",
        band: "toro",
      },
      {
        text: "Hembras",
        action: "fetchAnimalHembra",
        getter: "getHembra",
        band: "hembra",
      },
      {
        text: "Terneros",
        action: "fetchAnimalByEstado",
        getter: "getAnimalByEstado",
        idestado: 1,
        band: "ternero",
      },
      {
        text: "Vaquillas",
        action: "fetchVaquilla",
        getter: "getVaquilla",
        band: "vaquilla",
      },
      {
        text: "Disponibles",
        action: "fetchAnimalByEstado",
        getter: "getAnimalByEstado",
        idestado: 2,
        band: "disponible",
      },
      {
        text: "Celo",
        action: "fetchAnimalByEstado",
        getter: "getAnimalByEstado",
        idestado: 3,
        band: "celo",
      },
      {
        text: "Inseminadas",
        action: "fetchAnimalByEstado",
        getter: "getAnimalByEstado",
        idestado: 4,
        band: "inseminada",
      },
      {
        text: "Preñadas",
        action: "fetchPrenada",
        getter: "getPrenada",
        band: "prenada",
      },
      {
        text: "Secado",
        action: "fetchAnimalByEstado",
        getter: "getAnimalByEstado",
        idestado: 6,
        band: "secada",
      },
      {
        text: "Anestro Post-Parto",
        action: "fetchAnimalByEstado",
        getter: "getAnimalByEstado",
        idestado: 7,
        band: "anestro",
      },
      {
        text: "Enfermos",
        action: "fetchAnimalByEstado",
        getter: "getAnimalByEstado",
        idestado: 8,
        band: "enfermo",
      },
      {
        text: "Muertos",
        action: "fetchAnimalByEstado",
        getter: "getAnimalByEstado",
        idestado: 9,
        band: "muerte",
      },
      {
        text: "Vivos",
        action: "fetchVivo",
        getter: "getVivo",
        band: "vivo",
      },
      {
        text: "Productoras",
        action: "fetchNomina",
        getter: "getNomina",
        band: "productora",
      },
      {
        text: "Venta",
        action: "fetchAnimalByEstado",
        getter: "getAnimalByEstado",
        idestado: 10,
        band: "vendido",
      },
      {
        text: "Preparto",
        action: "fetchPreparto",
        getter: "getPreparto",
        band: "preparto",
      },
    ],
  }),
};
</script>
