export default {
  transladobancocaja_data(state, request) {
    state.transladobancocaja_data = request;
  }, 
  transladobancocaja_load(state, request) {
    state.transladobancocaja_load = request;
  }, 
  transladobancocaja_save_load(state, request) {
    state.transladobancocaja_save_load = request;
  }, 
}