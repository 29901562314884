<template>
  <div>
    <v-autocomplete
      :value="value"
      :id="id"
      @input="$emit('input', $event)"
      :rules="rules"
      :search-input.sync="searchText"
      type="text"
      :messages="messages"
      :items="getMotivo"
      :label="label"
      :dense="dense"
      :filter="customFilter"
      return-object
      item-text="descripcion"
      item-value="idjustificacion_motivo"
      outlined
      :readonly="readonly"
      :disabled="disabled"
      height="12"
      autocomplete="off"
      :loading="isDespidoLoading"
      :no-data-text="`No existe ninguna concidencia`"
      v-on:change="change"
    >
      <template slot="item" slot-scope="data">
        <v-list-item-content>
          <div class="subtitle-2">
            {{ data.item.inciso }}) {{ data.item.descripcion }}
          </div>
        </v-list-item-content>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: {
    label: String,
    value: [Number, String, Object],
    id: String,
    readonly: {
      type: Boolean,
      default: false,
    },
    create: {
      type: Boolean,
      default: true,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: function() {
        return [(v) => v!== '' || "Campo requerido",(v) => v!== undefined || "Campo requerido"];
      },
    },
    messages: {
      type: [String, Number],
      default: "",
    },
    tipo: {
      type: String,
      default: "despido",
    },
  },
  data() {
    return {
      searchText: null,
    };
  },
  watch: {
    tipo(val) {
      this.fetchMotivo(val);
    },
  },
  mounted() {
    this.fetchMotivo(this.tipo);
  },

  computed: {
    ...mapGetters("despido", ["getMotivo", "isDespidoLoading"]),
  },
  methods: {
    ...mapActions("despido", ["fetchMotivo"]),

    change(evt) {
      this.$emit("change", evt);
    },
    customFilter(item, queryText) {
      const textOne = item.inciso.toLowerCase();
      const textTwo = item.descripcion.toLowerCase();
      const searchText = queryText.toLowerCase();
      return (
        textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
      );
    },
  },
};
</script>
<style></style>
